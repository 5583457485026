import { RootState } from 'reducers';
import { createSelector, Selector } from 'reselect';
import { RegulationReducerState } from '../types';

const selectRegulation: Selector<RootState, RegulationReducerState> = (state) =>
  state.regulation;

export const getRegulationVersions = createSelector(
  selectRegulation,
  (state) => state.versions,
);

export const getRegulationBEVersion = createSelector(
  selectRegulation,
  (state) => state.backendVersion,
);
