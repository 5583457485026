import React, { useCallback } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import { useHistory } from 'react-router';
import Routes from 'routes';
import {
  BODY1,
  Button,
  Container,
  GridContainer,
  GridItem,
  H1,
} from '@tuunetech/tuune-components';

const NotFoundPage: React.FC = () => {
  const history = useHistory();

  const handleClick = useCallback(() => {
    history.replace(Routes.HOME);
  }, [history]);

  return (
    <IonPage>
      <IonContent>
        <Container $marginTop={100}>
          <GridContainer justify="center">
            <GridItem>
              <H1 paragraph>Oops!</H1>
              <BODY1 paragraph>
                We couldn’t find the page you’re looking for. Let’s try
                something different.
              </BODY1>
              <Button onClick={handleClick}>Go back home</Button>
            </GridItem>
          </GridContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default NotFoundPage;
