import logo from 'assets/images/logo.png';
import logoWebp from 'assets/images/webp/logo.webp';
import md from 'assets/images/md.jpeg';
import mdWebp from 'assets/images/webp/md.webp';
import ce from 'assets/images/ce.png';
import ceWebp from 'assets/images/webp/ce.webp';
import companyFilled from 'assets/images/company_filled.png';
import companyFilledWebp from 'assets/images/webp/company_filled.webp';
import companyOutline from 'assets/images/company_outline.png';
import companyOutlineWebp from 'assets/images/webp/company_outline.webp';
import warning from 'assets/images/warning.png';
import warningWebp from 'assets/images/webp/warning.webp';
import ecAddress from 'assets/images/ec_address.png';
import ecAddressWebp from 'assets/images/webp/ec_address.webp';
import saveForLater from 'assets/images/saveForLater.jpg';
import saveForLaterWebp from 'assets/images/webp/saveForLater.webp';
import girls from 'assets/images/hormones-girl.jpg';
import girlWebp from 'assets/images/webp/hormones-girl.webp';
import authBg from 'assets/images/auth_bg.png';
import authBgWebp from 'assets/images/webp/auth_bg.webp';
import landing from 'assets/images/landing.jpg';
import landingWebp from 'assets/images/webp/landing.webp';
import activationEmail from 'assets/images/activation_email.jpg';
import activationEmailWebp from 'assets/images/webp/activation_email.webp';
import startHA from 'assets/images/start_ha.jpg';
import startHAWebp from 'assets/images/webp/start_ha.webp';
import lauraJoigneau from 'assets/images/laura_joigneau.png';
import lauraJoigneauWebp from 'assets/images/webp/laura_joigneau.webp';
import peterFish from 'assets/images/peter_fish.png';
import peterFishWebp from 'assets/images/webp/peter_fish.webp';
import arusheePrasad from 'assets/images/arushee_prasad.png';
import arusheePrasadWebp from 'assets/images/webp/arushee_prasad.webp';
import aleOntiveros from 'assets/images/ale_ontiveros.png';
import aleOntiverosWebp from 'assets/images/webp/ale_ontiveros.webp';
import topMoon from 'assets/images/top_moon.png';
import topMoonWebp from 'assets/images/webp/top_moon.webp';
import BottomMoon from 'assets/images/bottom_moon.png';
import BottomMoonWebp from 'assets/images/webp/bottom_moon.webp';

export const getImage = (name: string, webpSupported: boolean): string => {
  const images: Record<string, string> = {
    'logo.png': webpSupported ? logoWebp : logo,
    'md.jpeg': webpSupported ? mdWebp : md,
    'ce.png': webpSupported ? ceWebp : ce,
    'company_filled.png': webpSupported ? companyFilledWebp : companyFilled,
    'company_outline.png': webpSupported ? companyOutlineWebp : companyOutline,
    'warning.png': webpSupported ? warningWebp : warning,
    'ec_address.png': webpSupported ? ecAddressWebp : ecAddress,
    'saveForLater.jpg': webpSupported ? saveForLaterWebp : saveForLater,
    'hormones-girl.jpg': webpSupported ? girlWebp : girls,
    'auth_bg.png': webpSupported ? authBgWebp : authBg,
    'landing.jpg': webpSupported ? landingWebp : landing,
    'activation_email.jpg': webpSupported
      ? activationEmailWebp
      : activationEmail,
    'start_ha.jpg': webpSupported ? startHAWebp : startHA,
    'laura_joigneau.png': webpSupported ? lauraJoigneauWebp : lauraJoigneau,
    'peter_fish.png': webpSupported ? peterFishWebp : peterFish,
    'arushee_prasad.png': webpSupported ? arusheePrasadWebp : arusheePrasad,
    'ale_ontiveros.png': webpSupported ? aleOntiverosWebp : aleOntiveros,
    'top_moon.png': webpSupported ? topMoonWebp : topMoon,
    'bottom_moon.png': webpSupported ? BottomMoonWebp : BottomMoon,
  };

  return images[name];
};
