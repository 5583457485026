import React, { useEffect, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import Routes from 'routes';
import { activateUser } from 'modules/Auth/actions';
import { getAuthErrors, getIsEmailActivated } from 'modules/Auth/selectors';
import { CircularProgress } from '@material-ui/core';

export const ActivateAccountPage: React.FC = () => {
  const dispatch = useDispatch();
  const activationErrors = useSelector(getAuthErrors);
  const isActivated = useSelector(getIsEmailActivated);
  const history = useHistory();
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const hasErrors = useCallback((): boolean => {
    return Object.keys(activationErrors).length !== 0;
  }, [activationErrors]);

  useEffect(() => {
    if (uid && token) {
      dispatch(activateUser({ uid, token }));
    }
  }, [dispatch, uid, token]);

  useEffect(() => {
    if (isActivated) {
      history.push(Routes.START_HA);
    } else if (hasErrors()) {
      history.push(Routes.RESEND_EMAIL_CUSTOM);
    }
  }, [history, isActivated, hasErrors]);

  return <CircularProgress color="primary" />;
};
