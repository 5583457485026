import React, { ReactNode } from 'react';
import { Hidden, useMediaQuery, useTheme } from '@material-ui/core';
import { Button } from './styles';

interface NavButtonProps {
  handleClick?: () => void;
  icon: ReactNode;
  withIcon?: boolean;
  text: string;
}

const NavButton: React.FC<NavButtonProps> = ({
  handleClick,
  icon,
  withIcon = true,
  text,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return handleClick ? (
    <Button variant="text" onClick={handleClick} color="default">
      {isMobile ? icon : withIcon ? icon : undefined}
      <Hidden smDown>{text}</Hidden>
    </Button>
  ) : null;
};

export default NavButton;
