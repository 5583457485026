import React, { useCallback, useEffect, useMemo, useState } from 'react';
import update from 'immutability-helper';
import { AnswerComponentProps } from '../../types';
import { ChoiceAnswer } from '../../components/Answers';
import {
  checkIsDirtyOption,
  getAnswersFromOptions,
  getIsShowButton,
  mapOptionsFromFacts,
  setActiveStateOnOptions,
} from '../../helpers';
import { AnswerOption } from '../../types';
import {
  AnswerTypesMap,
  isDesktop,
  ServerQuestionTypesMap,
} from '../../constants';
import CheckboxOption from '../../components/CheckboxOption';
import Option from '../../components/Option';
import {
  DesktopCycleOption,
  MobileCycleOption,
} from '../../components/CycleOption';
import MoodOption from '../../components/MoodOption';
import { useSelector } from 'react-redux';
import { getChoiceQuestionType } from 'modules/Questionnaire/selectors';

// This is a reusable choice container for Bool, Choice, MultiChoice
const ChoiceContainer: React.FC<
  AnswerComponentProps & { notaAnswer: string }
> = ({
  answers,
  notaAnswer,
  previousAnswers,
  handleSubmitAnswer,
  answerType,
  handleRequestNext,
}) => {
  const isMultiChoice =
    answerType === AnswerTypesMap.MULTI_CHOICE ||
    answerType === AnswerTypesMap.CYCLE;

  const factType = useSelector(getChoiceQuestionType);

  const [initialOptions] = useState(
    setActiveStateOnOptions(
      mapOptionsFromFacts(answers, answerType, notaAnswer),
      previousAnswers,
      isMultiChoice,
    ),
  );

  const [options, setOptions] = useState(initialOptions);
  const [showContinue] = useState(
    getIsShowButton(isMultiChoice, initialOptions),
  );
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);
  const [disableContinue, setDisableContinue] = useState(true);
  const [dirty, setDirty] = useState(false);

  const optionComponent = useMemo(() => {
    if (answerType === AnswerTypesMap.CYCLE) {
      return isDesktop ? DesktopCycleOption : MobileCycleOption;
    }

    if (answerType === AnswerTypesMap.SUBJECTIVE) {
      return MoodOption;
    }

    if (factType === ServerQuestionTypesMap.FACT_RADIO) {
      return CheckboxOption;
    }

    return Option;
  }, [answerType, factType]);

  const handleSubmit = useCallback(() => {
    const result = getAnswersFromOptions(options, isMultiChoice);
    handleSubmitAnswer(result);
  }, [handleSubmitAnswer, options, isMultiChoice]);

  const handleOptionClick = useCallback(
    (option: AnswerOption): void => {
      const searchField = isMultiChoice ? 'factId' : 'value';
      const optionIndex = options.findIndex(
        (opt: AnswerOption) => opt[searchField] === option[searchField],
      );

      // copy previous options and change clicked option isActive flag to true
      // for multi choice to opposite
      const newValue = isMultiChoice ? !options[optionIndex].isActive : true;
      let resultOptions = update(options, {
        [optionIndex]: { isActive: { $set: newValue } },
      });

      resultOptions =
        isMultiChoice && option.factId !== -1
          ? // if index -1: 'None of the above' option selected, same behavior as for single choice
            // if MULTI_CHOICE leave array as it is
            resultOptions
          : // if !MULTI_CHOICE set other options isActive flag to false
            resultOptions.map((option, idx) =>
              idx !== optionIndex ? { ...option, isActive: false } : option,
            );

      if (isMultiChoice) {
        const anyActiveOption = resultOptions.some(
          (option) => option.isActive && option.factId !== -1,
        );
        if (anyActiveOption) {
          const noneOptionIndex = resultOptions.findIndex(
            (option) => option.factId === -1,
          );
          // disable noneOfTheAbove option if any other option is active
          // if noneOfTheAbove option exists
          noneOptionIndex !== -1 &&
            (resultOptions[noneOptionIndex].isActive = false);
        }
      }

      setOptions(resultOptions);

      if (!showContinue) {
        setIsSubmitEnabled(true);
      }
    },
    [setOptions, showContinue, options, setIsSubmitEnabled, isMultiChoice],
  );

  useEffect(() => {
    if (isSubmitEnabled) {
      setIsSubmitEnabled(false);
      handleSubmit();
    }
  }, [options, isSubmitEnabled, handleSubmit, setIsSubmitEnabled]);

  useEffect(() => {
    setDisableContinue(!options.some((option) => option.isActive));
    setDirty(checkIsDirtyOption(initialOptions, options));
  }, [options, initialOptions]);

  useEffect(() => {
    setOptions(
      setActiveStateOnOptions(
        mapOptionsFromFacts(answers, answerType, notaAnswer),
        previousAnswers,
        isMultiChoice,
      ),
    );
    setIsSubmitEnabled(false);
  }, [
    answers,
    answerType,
    previousAnswers,
    setOptions,
    setIsSubmitEnabled,
    isMultiChoice,
    notaAnswer,
  ]);

  return (
    <ChoiceAnswer
      // isDesktop={isDesktop}
      handleOptionClick={handleOptionClick}
      options={options}
      optionComponent={optionComponent}
      handleSubmit={handleSubmit}
      disableContinue={disableContinue}
      showContinue={showContinue}
      handleRequestNext={handleRequestNext}
      dirty={dirty}
    />
  );
};

export default ChoiceContainer;
