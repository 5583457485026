import React, { useCallback } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountInfoAnswer } from 'modules/Questionnaire/components/Answers';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { accountInfoSchema, MARKETING } from 'modules/Questionnaire/constants';
import { useDomain } from 'utils/hooks/useDomain';
import { DOMAIN_MAP } from 'utils/axios';
import { registerToQuestionnaireRequest } from '../../actions';
import { getStartedData } from '../../selectors';

export const AccountInfoContainer: React.FC = () => {
  const dispatch = useDispatch();
  const domain = useDomain();
  const { privacyOptions } = useSelector(getStartedData);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<AccountInfoFormData>({
    defaultValues: {
      [MARKETING.researchPreference]: domain !== DOMAIN_MAP.uk,
      [MARKETING.marketingPreference]: domain !== DOMAIN_MAP.uk,
    },
    resolver: yupResolver(accountInfoSchema),
    mode: 'onTouched',
  });

  const submit = useCallback(
    ({
      firstName,
      lastName,
      email,
      password,
      rePassword,
      ...optionAnswers
    }) => {
      const marketingOptions = new Set();
      if (optionAnswers[MARKETING.marketingPreference])
        marketingOptions.add(MARKETING.marketingPreference);
      if (optionAnswers[MARKETING.researchPreference])
        marketingOptions.add(MARKETING.researchPreference);

      dispatch(
        registerToQuestionnaireRequest({
          firstName,
          lastName,
          email,
          password,
          rePassword,
          privacyOptions,
          marketingOptions,
        }),
      );
    },
    [dispatch, privacyOptions],
  );

  return (
    <AccountInfoAnswer
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
    />
  );
};
