import { ReactNode } from 'react';
import {
  Endometriosis,
  Menopause,
  PCOS,
  PmsPmddSymptoms,
  Headaches,
  Migraines,
  Bloating,
  AppetiteChanges,
  Indigestion,
  Ibs,
  Nausea,
  WeightChanges,
  FluidRetention,
  BreastTenderness,
  Thrush,
  HeavyPeriods,
  LibidoLow,
  PeriodPain,
  Spotting,
  VaginalDryness,
  Anxiety,
  Depression,
  Fatigue,
  MoodIrritability,
  AcanthosisNigricans,
  Acne,
  SkinDry,
  Melasma,
  SkinHairOily,
  UnwantedHairGrowth,
  HairLoss,
  HayFever,
  HotFlashes,
  VaricoiseVeins,
  AmenorrheaPrimary,
  ThyroidCondition,
  SleepQuality,
  Cystitis,
  VisionChanges,
  BowelFunction,
  VaginalBleeding,
} from './icons';

export const symptomIcons: Record<string, ReactNode> = {
  endometriosis: Endometriosis,
  pms_symptoms: PmsPmddSymptoms,
  pms_pmdd: PmsPmddSymptoms,
  menopause: Menopause,
  pcos: PCOS,
  headaches: Headaches,
  migraines: Migraines,
  bloating: Bloating,
  appetite_changes: AppetiteChanges,
  indigestion_or_heart_burn: Indigestion,
  ibs_or_symptoms: Ibs,
  nausea_vomiting: Nausea,
  weight_unwanted_changes: WeightChanges,
  fluid_retention: FluidRetention,
  breast_tenderness: BreastTenderness,
  thrush: Thrush,
  heavy_or_long_periods: HeavyPeriods,
  libido_low: LibidoLow,
  period_pain: PeriodPain,
  spotting_or_breakthrough_bleeding: Spotting,
  vaginal_dryness: VaginalDryness,
  anxiety: Anxiety,
  depression: Depression,
  fatigue: Fatigue,
  mood_irritability: MoodIrritability,
  acanthosis_nigricans: AcanthosisNigricans,
  acne: Acne,
  skin_dry: SkinDry,
  melasma: Melasma,
  skin_hair_oily: SkinHairOily,
  unwanted_hair_growth: UnwantedHairGrowth,
  hair_loss: HairLoss,
  hay_fever_allergic_rhinitis: HayFever,
  hot_flashes_or_flushes: HotFlashes,
  varicose_veins: VaricoiseVeins,
  amenorrhea_primary: AmenorrheaPrimary,
  thyroid_related_condition: ThyroidCondition,
  sleep_decreased_quality: SleepQuality,
  cystitis: Cystitis,
  visual_changes_cyclic: VisionChanges,
  change_in_bowel_function: BowelFunction,
  post_coital_vaginal_bleeding: VaginalBleeding,
};
