import React from 'react';
import {
  BODY2,
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { TuuneInput } from 'shared';
import { Control, FieldErrors } from 'react-hook-form';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { PhoneInput } from './PhoneInput';
import { ButtonWrapper, FieldWrapper, Form } from './styles';
import { ContactDetailFormData } from '../../containers';

export const ContactDetail: React.FC<{
  handleSubmit: () => void;
  control: Control<ContactDetailFormData>;
  errors: FieldErrors<ContactDetailFormData>;
}> = ({ handleSubmit, control, errors }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Container disableGutters={!isMobile}>
      <Form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} lg={10}>
            <GridContainer>
              <GridItem xs={12} lg={6}>
                <FieldWrapper>
                  <TuuneInput
                    name="firstName"
                    placeholder="First name"
                    control={control}
                    autoComplete="given-name"
                    helperText={errors.firstName?.message}
                    error={!!errors.firstName}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <FieldWrapper>
                  <TuuneInput
                    name="lastName"
                    control={control}
                    placeholder="Surname"
                    autoComplete="family-name"
                    helperText={errors.lastName?.message}
                    error={!!errors.lastName}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <FieldWrapper>
                  <TuuneInput
                    name="email"
                    control={control}
                    placeholder="Email"
                    type="email"
                    disabled
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={6}>
                <FieldWrapper>
                  <PhoneInput
                    control={control}
                    helperText={errors.phone?.message}
                    error={!!errors.phone}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} md={10}>
                <BODY2 color="textSecondary" $marginTop={8}>
                  If you need to change your email please contact us at
                  help@tuune.com before placing your order.
                </BODY2>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <ButtonWrapper>
          <Button type="submit" onClick={handleSubmit}>
            PROCEED TO SHIPPING
          </Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};
