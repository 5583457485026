import styled from 'styled-components';
import { ContentBlock } from '@tuunetech/tuune-components';
import { BODY1 } from '@tuunetech/tuune-components';

export const Option = styled(ContentBlock)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;

export const ListItem = styled(ContentBlock)`
  padding-left: 10px;
`;

export const StyledBody1 = styled(BODY1)`
  span {
    font-weight: bold;
  }
`;
