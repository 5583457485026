import thunk from 'redux-thunk';
import { applyMiddleware } from 'redux';
import logger from './logger';
import loading from './loading';
import {
  apiRequestMiddleware,
  apiProcessResponseMiddleware,
  apiRetryRequest,
  apiSetLoginStatusToStorage,
  apiSetTokenMiddleware,
} from './api';
import { checkoutSetVivaWalletTokenMiddleware } from './checkout';
import { analyticsMiddleware } from './analytics';

const devMiddlewares = [];
if (process.env.REACT_APP_ENV === 'local') {
  devMiddlewares.push(logger);
}
const rootMiddleware = applyMiddleware(
  thunk,
  ...devMiddlewares,
  apiRequestMiddleware,
  apiProcessResponseMiddleware,
  apiSetTokenMiddleware,
  apiRetryRequest,
  apiSetLoginStatusToStorage,
  checkoutSetVivaWalletTokenMiddleware,
  analyticsMiddleware,
  loading,
);

export default rootMiddleware;
