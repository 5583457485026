import React from 'react';
import { Form as FormikForm } from 'formik';

interface FormProps {
  disableSubmit?: boolean;
}

const Form: React.FC<FormProps> = ({ children, disableSubmit, ...props }) => (
  <FormikForm {...props}>
    {children}
    <input disabled={disableSubmit} type="submit" className="ion-hide" />
  </FormikForm>
);

export default Form;
