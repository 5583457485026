import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import ForgotPassword from '../components/ForgotPassword';
import { resetPasswordRequest } from '../actions';
import { getResetPasswordStage } from '../selectors';

const ForgotPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const recoveryStage = useSelector(getResetPasswordStage);
  const {
    location: { state: { isLinkValid = true } = {} },
  } = useHistory<{ isLinkValid?: boolean }>();

  const initialValues = {
    email: '',
  };

  const onSubmit = useCallback(
    ({ email }) => {
      dispatch(resetPasswordRequest({ email }));
    },
    [dispatch],
  );

  return (
    <ForgotPassword
      recoveryStage={recoveryStage}
      onSubmit={onSubmit}
      initialValues={initialValues}
      isLinkValid={isLinkValid}
    />
  );
};

export default ForgotPasswordPage;
