import React from 'react';
import { IonCheckbox } from '@ionic/react';
import styles from './style.module.css';

const Check: React.FC<{ active?: boolean; className?: string }> = ({
  active = false,
  className = '',
}) => {
  // 'disabled' prop is used to prevent checkbox self-changing behaviour and make it fully controlled
  return (
    <IonCheckbox
      checked={active}
      className={`${styles.check} ${className}`}
      mode="ios"
      disabled
    />
  );
};

export default Check;
