import styled from 'styled-components';

export const List = styled.ul`
  list-style: inside;
  margin-top: -16px;

  p {
    display: inline;
  }
`;
