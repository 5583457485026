import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ContentfulProvider } from 'modules/Contentful/constants/ContentfulContext';
import { ThemeProvider } from '@tuunetech/tuune-components';
import { GlobalStyle } from './theme/theme';
import { DomainProvider } from './utils/DomainProvider';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './reduxStore';
import CookieWrapper from 'utils/cookies/CookieWrapper';
import 'utils/sentry';
import { ImageProvider } from 'utils/image';

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider>
      <DomainProvider>
        <ContentfulProvider>
          <CookieWrapper>
            <ImageProvider>
              <App />
              <GlobalStyle />
            </ImageProvider>
          </CookieWrapper>
        </ContentfulProvider>
      </DomainProvider>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
