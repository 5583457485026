export enum ANALYTICS_EVENT {
  CREATE_ACCOUNT = 'create account',
  ACTIVATE_ACCOUNT = 'activate account',
  LOGIN_ACCOUNT = 'login to account',
  LOGIN_FAILED = 'failed to login',
  LOGOUT_ACCOUNT = 'logout from account',
  FORGOT_PASSWORD = 'click forgot password',
  RESET_PASSWORD = 'click reset password',
  START_HA = 'start health assessment',
  COMPLETE_QUESTION = 'complete a question',
  COMPLETE_HA = 'complete health assessment',
  ASSESSMENT_REPORT_TAB = 'view assessment report',
  RECOMMENDATION_REPORT_TAB = 'click recommendation report tab',
  ADD_TO_PLAN = 'click add to plan',
  QUESTION_HELP = 'click info icon',
  QUESTION_BACK = 'click back button',
  RECOMMENDATION_BENEFITS = 'click benefits',
  RECOMMENDATION_HOW_TO_TAKE_PILL = 'click how should I take my pill',
  RECOMMENDATION_CONSIDERATIONS = 'click considerations',
  RECOMMENDATION_HOW_TO_START = 'click how to start this method',
  RECOMMENDATION_HOW_WAS_IT_MADE = 'click how have you made my rec',
  ASSESSMENT_HOW_TO_READ_SCALES = 'click how to read your scales',
  ASSESSMENT_SYMPTOMS_LEARN_MORE = 'click learn more in symptom',
  PILL_NOT_LISTED = 'click pill not listed',
  PILL_UNKNOWN = 'click pill unknown',
}

export enum ANALYTICS_CATEGORY {
  LOGIN = 'login',
  HEALTH_ASSESSMENT = 'health assessment',
  ASSESSMENT_REPORT = 'assessment report',
  RECOMMENDATION_REPORT = 'recommendation report',
  ORDER = 'order',
  CHECKOUT = 'checkout',
  ACCOUNT = 'account',
}
