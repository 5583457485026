import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import {
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { TuuneInput } from 'shared';
import { CountriesSelect } from './CountriesSelect';
import { Form, ButtonWrapper, FieldWrapper } from '../ContactDetail/styles';
import { ShippingDetailFormData } from '../../containers';

export type ShippingDetailsProps = {
  handleSubmit: () => void;
  control: Control<ShippingDetailFormData>;
  errors: FieldErrors<ShippingDetailFormData>;
};

export const ShippingDetails: React.FC<ShippingDetailsProps> = ({
  handleSubmit,
  control,
  errors,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));

  return (
    <Container disableGutters={!isMobile}>
      <Form onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} lg={9}>
            <GridContainer>
              <GridItem xs={12}>
                <FieldWrapper>
                  <TuuneInput
                    name="addressLine1"
                    autoComplete="address-line1"
                    control={control}
                    placeholder="Address line 1*"
                    type="text"
                    helperText={errors.addressLine1?.message}
                    error={!!errors.addressLine1}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12}>
                <FieldWrapper>
                  <TuuneInput
                    name="addressLine2"
                    autoComplete="address-line2"
                    control={control}
                    placeholder="Address line 2"
                    type="text"
                    helperText={errors.addressLine2?.message}
                    error={!!errors.addressLine2}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={7}>
                <FieldWrapper>
                  <TuuneInput
                    name="city"
                    autoComplete="address-level2"
                    control={control}
                    placeholder="Town / City*"
                    type="text"
                    helperText={errors.city?.message}
                    error={!!errors.city}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={7}>
                <FieldWrapper>
                  <TuuneInput
                    name="county"
                    autoComplete="address-level1"
                    control={control}
                    placeholder="County*"
                    type="text"
                    helperText={errors.county?.message}
                    error={!!errors.county}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={7}>
                <CountriesSelect
                  control={control}
                  helperText={errors.country?.message}
                  error={!!errors.country}
                />
              </GridItem>
              <GridItem xs={12} lg={5}>
                <FieldWrapper>
                  <TuuneInput
                    name="postcode"
                    autoComplete="postal-code"
                    control={control}
                    placeholder="Postcode*"
                    type="text"
                    helperText={errors.postcode?.message}
                    error={!!errors.postcode}
                  />
                </FieldWrapper>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <ButtonWrapper>
          <Button type="submit">PROCEED TO payment</Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
};
