class TokenStorage {
  private _authToken: string;
  constructor(accessToken = '') {
    this._authToken = accessToken;
  }

  get accessToken(): string {
    return this._authToken;
  }

  set accessToken(accessToken: string) {
    this._authToken = accessToken;
  }
}

const vivaWalletAuthTokenStorage = new TokenStorage();

export { vivaWalletAuthTokenStorage };
