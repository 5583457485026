import React from 'react';
import {
  Autocomplete,
  Container,
  GridContainer,
  GridItem,
  Input,
  Select,
  SelectProps,
} from '@tuunetech/tuune-components';
import { ContinueButton, TuuneInput } from 'shared';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  MetricSystemOption,
  NonMetricSystemOption,
} from 'modules/Questionnaire/constants';
import { NumAnswerFormData } from 'modules/Questionnaire/containers/Answers/NumberContainer';
import { MenuItem } from '@material-ui/core';

export type NumberAnswerProps = {
  handleSubmit: () => void;
  options?: (NonMetricSystemOption | MetricSystemOption)[];
  control: Control<NumAnswerFormData>;
  errors: FieldErrors<NumAnswerFormData>;
  isAnswerSpecific: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  defaultValue: any;
  selectorUnits?: string[];
  selectedUnit: string;
  handleUnitChange: SelectProps['onChange'];
};

const NumberAnswer: React.FC<NumberAnswerProps> = ({
  handleSubmit,
  options,
  control,
  isAnswerSpecific,
  errors,
  defaultValue,
  selectorUnits,
  selectedUnit,
  handleUnitChange,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <GridContainer justify="center">
          <GridItem xs={12} md={isAnswerSpecific ? 5 : 2}>
            {isAnswerSpecific && options ? (
              <GridContainer justify="center">
                <GridItem xs={8}>
                  <Controller
                    control={control}
                    name="answer"
                    defaultValue={defaultValue}
                    render={({ field: { onChange, onBlur, value } }) => {
                      return (
                        <Autocomplete
                          options={options}
                          getOptionLabel={(option) =>
                            typeof option === 'string'
                              ? option
                              : (option as NonMetricSystemOption).displayValue
                          }
                          onChange={(e, newValue) => {
                            onChange({
                              ...e,
                              target: { value: newValue },
                            });
                          }}
                          onBlur={(e) => {
                            if (!(e.target as HTMLInputElement).value) {
                              onChange({
                                ...e,
                                target: { value: defaultValue },
                              });
                            }
                            onBlur();
                          }}
                          value={value}
                          filterOptions={(options, { inputValue }) => {
                            const matchRegexp = new RegExp(
                              '^' +
                                inputValue
                                  .replaceAll("'", '')
                                  .replaceAll("''", ''),
                              'i',
                            );
                            return options.filter((option) => {
                              let stringOption = option;
                              if (typeof option !== 'string') {
                                stringOption = (option as NonMetricSystemOption)
                                  .displayValue;
                              }

                              stringOption = (stringOption as string)
                                .replaceAll("'", '')
                                .replaceAll("''", '');

                              return matchRegexp.test(stringOption as string);
                            });
                          }}
                          renderInput={(params) => {
                            return (
                              <Input
                                {...params}
                                ref={params.InputProps.ref}
                                fullWidth
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: 'disabled',
                                }}
                                error={!!errors.answer}
                                helperText={errors.answer?.message}
                              />
                            );
                          }}
                        />
                      );
                    }}
                  />
                </GridItem>
                <GridItem>
                  <Select value={selectedUnit} onChange={handleUnitChange}>
                    {selectorUnits?.map((unit) => (
                      <MenuItem key={unit} value={unit}>
                        {unit}
                      </MenuItem>
                    ))}
                  </Select>
                </GridItem>
              </GridContainer>
            ) : (
              <TuuneInput
                autoFocus
                name="answer"
                control={control}
                type="number"
                inputProps={{ inputMode: 'numeric' }}
                fullWidth
                helperText={errors.answer?.message}
                error={!!errors.answer}
                defaultValue={defaultValue}
              />
            )}
          </GridItem>
        </GridContainer>
      </Container>
      <ContinueButton type="submit" />
    </form>
  );
};

export default NumberAnswer;
