import React, { useCallback, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { useDispatch, useSelector } from 'react-redux';
import QuestionLayout from '../components/QuestionLayout';
import { FeedbackAnswer } from '../components/Answers';
import { getFeedbackSubmitted, getHasFeedback } from '../selectors';
import { getFeedbackRequest, submitFeedbackRequest } from '../actions';

const DATA = {
  questionTitle:
    'Before we finish, is there something else we should know about we didn’t bring up?',
  subTitle:
    'Write your other concerns here and we’ll get back to you as soon as possible!',
};

const FeedbackPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isSubmitted = useSelector(getFeedbackSubmitted);
  const hasFeedback = useSelector(getHasFeedback);

  const [feedback, setFeedback] = useState('');
  const [skipped, setSkipped] = useState(false);

  const handleSubmit = useCallback(() => {
    if (feedback) {
      dispatch(submitFeedbackRequest(feedback));
    } else {
      setSkipped(true);
    }
  }, [feedback, dispatch]);

  const handleFeedbackChange = useCallback((fb: string) => {
    setFeedback(fb);
  }, []);

  useEffect(() => {
    dispatch(getFeedbackRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isSubmitted || skipped || hasFeedback) {
      history.replace({
        pathname: Routes.RESULTS,
        state: { from: Routes.QUESTIONNAIRE },
      });
    }
  }, [isSubmitted, history, skipped, hasFeedback]);

  return (
    <QuestionLayout
      questionData={DATA}
      customAnswer={
        <FeedbackAnswer
          handleSubmit={handleSubmit}
          feedback={feedback}
          handleFeedbackChange={handleFeedbackChange}
        />
      }
    />
  );
};

export default FeedbackPage;
