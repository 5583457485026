import React from 'react';
import { Check } from 'shared';
import { BODY1 } from '@tuunetech/tuune-components';
import styles from './style.module.css';

interface CheckboxOptionProps {
  text: string;
  active: boolean;
  onClick: () => void;
}

const CheckboxOption: React.FC<CheckboxOptionProps> = ({
  text,
  active,
  onClick,
}) => {
  return (
    <div
      onClick={(): void => onClick()}
      onKeyPress={(e): void => (e.key === 'Enter' ? onClick() : undefined)}
      className={styles.checkbox}
      tabIndex={0}
    >
      <Check active={active} />
      <BODY1 color="textPrimary" $paddingLeft={7} align="left">
        {text}
      </BODY1>
    </div>
  );
};

export default CheckboxOption;
