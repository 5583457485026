import React from 'react';
import { BaseLink } from '@tuunetech/tuune-components';
import { LinkProps as MuiLinkProps } from '@material-ui/core';
import { IonRouterLink } from '@ionic/react';

type LinkProps = React.ComponentProps<typeof IonRouterLink> & MuiLinkProps;

const Link: React.FC<LinkProps> = ({
  children,
  // IonRouter Internal Link Props
  href,
  target,
  routerAnimation,
  rel,
  color,
  //  Mui Styling Link Props
  underline = 'always',
  ...props
}) => {
  return (
    <BaseLink
      href={href}
      InternalLinkComponent={
        <IonRouterLink
          href={href}
          target={target}
          routerAnimation={routerAnimation}
          rel={rel}
          color={color}
        >
          {children}
        </IonRouterLink>
      }
      underline={underline}
      color={color}
      target={target}
      {...props}
    >
      {children}
    </BaseLink>
  );
};

export default Link;
