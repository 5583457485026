import { Box, Paper } from '@material-ui/core';
import { BODY2, Button, Container, H3 } from '@tuunetech/tuune-components';
import {
  MARKETING,
  MARKETING_OPTIONS_LABEL,
} from 'modules/Questionnaire/constants';
import React, { useEffect, useState } from 'react';
import { Checkbox, Option } from './styles';

type MarketingPreferenceProps = {
  marketingPreference: Set<MARKETING>;
  onSubmit: (marketingPreference: Set<MARKETING>) => void;
};

export const MarketingPreference: React.FC<MarketingPreferenceProps> = ({
  marketingPreference,
  onSubmit,
}) => {
  const [marketingOptions, setMarketingOptions] = useState<Set<MARKETING>>(
    new Set(marketingPreference),
  );

  useEffect(() => {
    setMarketingOptions(marketingPreference);
  }, [marketingPreference]);

  const handleChange = (id: MARKETING) => {
    const options = new Set(marketingOptions);
    if (marketingOptions.has(id)) {
      options.delete(id);
    } else {
      options.add(id);
    }
    setMarketingOptions(options);
  };

  const handleSave = () => {
    onSubmit(marketingOptions);
  };

  const hasChanged = () => {
    return (
      marketingOptions.has(MARKETING.marketingPreference) ===
        marketingPreference.has(MARKETING.marketingPreference) &&
      marketingOptions.has(MARKETING.researchPreference) ===
        marketingPreference.has(MARKETING.researchPreference)
    );
  };

  return (
    <Paper>
      <Container $verticalPadding={24} $horizontalPading={24}>
        <H3>Email preferences</H3>
        {MARKETING_OPTIONS_LABEL.map(({ id, label }) => {
          return (
            <Option
              key={id}
              onClick={() => handleChange(id)}
              onKeyPress={(e): void => {
                if (e.key === 'Enter') {
                  handleChange(id);
                }
              }}
              tabIndex={0}
            >
              <Checkbox active={marketingOptions.has(id)} />
              <BODY2 align="left" color="textPrimary">
                {label}
              </BODY2>
            </Option>
          );
        })}
        <Box display="flex" justifyContent="center" alignItems="center">
          <Button onClick={handleSave} disabled={hasChanged()}>
            save changes
          </Button>
        </Box>
      </Container>
    </Paper>
  );
};
