import React from 'react';
import { IonIcon } from '@ionic/react';
import { Check, Text } from 'shared';
import styles from './style.module.css';

interface MoodOptionProps {
  text: { icon: string; option: string };
  active?: boolean;
  onClick: () => void;
}

const MoodOption: React.FC<MoodOptionProps> = ({
  text,
  active,
  onClick,
  ...props
}) => {
  return (
    <div
      className={styles.option}
      onClick={(): void => onClick()}
      tabIndex={0}
      {...props}
      onKeyPress={(e): void => (e.key === 'Enter' ? onClick() : undefined)}
    >
      <IonIcon className={styles.moodIcon} icon={text.icon} />
      <div className={styles.check}>
        <Check active={active} />
      </div>
      <Text className={`ion-text-start ion-text-md-center ${styles.moodText}`}>
        {text.option}
      </Text>
    </div>
  );
};

export default MoodOption;
