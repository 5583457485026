import React, { ReactNode, useEffect, useMemo } from 'react';
import { SwipeableDrawerProps } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { CONTENT_TYPE, keyGen } from 'modules/Contentful/constants';
import { getIsLoading } from 'modules/Loading/selectors';
import { useDomain } from 'utils/hooks/useDomain';
import { getEntries } from 'modules/Contentful/actions';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { ContentfulEntry } from 'modules/Contentful/types';
import { getResultsContentfulOptions, PILL_TYPE_CODE } from '../constants';
import Benefits from '../components/Benefits';
import { getBenefitsCurrent, getBenefitsSlugs } from '../selectors';
import { BenefitCurrent } from '../types';

export type BenefitsProps = {
  isBenefitsOpen: boolean;
  handleBenefitsOpen: (pill?: BenefitCurrent) => void;
  handleBenefitsClose: SwipeableDrawerProps['onClose'];
};

export const CK = {
  myBenefits: keyGen.getKey(
    CONTENT_TYPE.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED,
  ),
  otherBenefits: keyGen.getKey(
    CONTENT_TYPE.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED,
  ),
  [PILL_TYPE_CODE.pop]: keyGen.getKey(
    CONTENT_TYPE.CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC,
  ),
  [PILL_TYPE_CODE.chc]: keyGen.getKey(
    CONTENT_TYPE.CONTRACEPTION_TYPE_BENEFIT_DETAIL_GENERIC,
  ),
};

export const BenefitsContainer: React.FC<BenefitsProps> = ({
  isBenefitsOpen,
  handleBenefitsClose,
  handleBenefitsOpen,
}) => {
  const dispatch = useDispatch();

  const benefitsCurrent = useSelector(getBenefitsCurrent);
  const benefitsSlugs = useSelector(getBenefitsSlugs);

  // get each benefit details for my and other benefits tabs
  const myBenefitsDetail = useSelector(
    getArrayContentMapped(CK.myBenefits.reqId),
  );
  const isMyBenefitsDetailLoading = useSelector(
    getIsLoading(CK.myBenefits.loadingId),
  );
  const otherBenefitsDetail = useSelector(
    getArrayContentMapped(CK.otherBenefits.reqId),
  );
  const isOtherBenefitsDetailLoading = useSelector(
    getIsLoading(CK.otherBenefits.loadingId),
  );
  // get benefit details for pill type
  const benefitsDetailGenericPOP = useSelector(
    getArrayContentMapped(CK[PILL_TYPE_CODE.pop].reqId),
  );
  const isBenefitsDetailGenericLoadingPOP = useSelector(
    getIsLoading(CK[PILL_TYPE_CODE.pop].loadingId),
  );
  const benefitsDetailGenericCHC = useSelector(
    getArrayContentMapped(CK[PILL_TYPE_CODE.chc].reqId),
  );
  const isBenefitsDetailGenericLoadingCHC = useSelector(
    getIsLoading(CK[PILL_TYPE_CODE.chc].loadingId),
  );
  const domain = useDomain();

  useEffect(() => {
    if (isBenefitsOpen) {
      // request benefits (recommendation and contraception) details
      if (isMyBenefitsDetailLoading === undefined && benefitsSlugs.my.length) {
        dispatch(
          getEntries({
            ...CK.myBenefits,
            domain,
            slugs: benefitsSlugs.my,
          }),
        );
      }

      if (
        isOtherBenefitsDetailLoading === undefined &&
        benefitsSlugs.other.length
      ) {
        dispatch(
          getEntries({
            ...CK.otherBenefits,
            domain,
            slugs: benefitsSlugs.other,
          }),
        );
      }

      // request contraception type benefits
      benefitsSlugs.type.forEach((pillType) => {
        const isBenefitsDetailGenericLoading = {
          [PILL_TYPE_CODE.pop]: isBenefitsDetailGenericLoadingPOP,
          [PILL_TYPE_CODE.chc]: isBenefitsDetailGenericLoadingCHC,
        };
        if (isBenefitsDetailGenericLoading[pillType] === undefined) {
          dispatch(
            getEntries({
              ...CK[pillType],
              domain,
              slugs: [pillType],
              match: true,
            }),
          );
        }
      });
    }
  }, [
    dispatch,
    domain,
    isBenefitsDetailGenericLoadingPOP,
    isBenefitsDetailGenericLoadingCHC,
    isBenefitsOpen,
    benefitsSlugs,
    isMyBenefitsDetailLoading,
    isOtherBenefitsDetailLoading,
  ]);

  const content = useMemo(() => {
    const remapDataToNodes = (benefits: Record<string, ContentfulEntry>) => {
      return Object.keys(benefits).reduce((result, key) => {
        result[key] = {
          content: documentToReactComponents(
            benefits[key]?.content,
            getResultsContentfulOptions(),
          ),
          name: benefits[key]?.name,
          slug: benefits[key]?.slug,
        };
        return result;
      }, {} as Record<string, { content: ReactNode; name: string; slug: string }>);
    };

    return {
      my: remapDataToNodes(myBenefitsDetail),
      other: remapDataToNodes(otherBenefitsDetail),
      [PILL_TYPE_CODE.pop]: remapDataToNodes(benefitsDetailGenericPOP),
      [PILL_TYPE_CODE.chc]: remapDataToNodes(benefitsDetailGenericCHC),
    };
  }, [
    benefitsDetailGenericCHC,
    benefitsDetailGenericPOP,
    myBenefitsDetail,
    otherBenefitsDetail,
  ]);

  const isLoading = useMemo(() => {
    let loading = true;

    if (benefitsSlugs.my.length) {
      loading = loading && isMyBenefitsDetailLoading === 0;
    }
    if (benefitsSlugs.other.length) {
      loading = loading && isOtherBenefitsDetailLoading === 0;
    }
    if (benefitsSlugs.type.includes(PILL_TYPE_CODE.pop)) {
      loading = loading && isBenefitsDetailGenericLoadingPOP === 0;
    }
    if (benefitsSlugs.type.includes(PILL_TYPE_CODE.chc)) {
      loading = loading && isBenefitsDetailGenericLoadingCHC === 0;
    }

    return !loading;
  }, [
    benefitsSlugs.my.length,
    benefitsSlugs.other.length,
    benefitsSlugs.type,
    isBenefitsDetailGenericLoadingCHC,
    isBenefitsDetailGenericLoadingPOP,
    isMyBenefitsDetailLoading,
    isOtherBenefitsDetailLoading,
  ]);

  return benefitsCurrent ? (
    <Benefits
      isLoading={isLoading}
      handleBenefitsClose={handleBenefitsClose}
      handleBenefitsOpen={handleBenefitsOpen}
      isBenefitsOpen={isBenefitsOpen}
      benefits={{
        my: Object.values(content.my || {}).filter(({ slug }) =>
          slug.includes(benefitsCurrent.vmpSlug),
        ),
        other: Object.values(content.other || {}).filter(({ slug }) =>
          slug.includes(benefitsCurrent.vmpSlug),
        ),
        type: Object.values(content[benefitsCurrent.type] || {}),
      }}
    />
  ) : null;
};
