import * as yup from 'yup';

export const LoginFormSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
  // No need to validate already existing password.
  // Manually created account passwords can be atypical
  password: yup.string().required('Password is a required field'),
});
