import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import { AssessmentReducerState } from '../types';
import {
  GET_ASSESSMENT_FLAGS_SUCCESS,
  GET_ASSESSMENT_RESULTS_SUCCESS,
  GET_RECAP_GOALS_SUCCESS,
  remapRawVersions,
  SET_RECAP_GOALS_CLOSED,
} from '../constants';

const defaultState: AssessmentReducerState = {
  assessment: [],
  recapCurrent: [],
  recapDestination: [],
  recapRoute: [],
  isRecapGoalsClosed: undefined,
  flagRed: [],
  flagOrange: [],
  flagGreen: [],
};

const assessmentReducer: Reducer<AssessmentReducerState> = (
  state = defaultState,
  { type, response: { data = {} } = {}, payload = {} },
) => {
  switch (type) {
    case GET_ASSESSMENT_RESULTS_SUCCESS:
      return {
        ...state,
        assessment: data.assessment.map(
          ({
            contentful_personalised_slugs,
            issue_type,
            ...rest
          }: Record<string, unknown>) => ({
            contentfulPersonalisedSlugs: contentful_personalised_slugs,
            issueType: issue_type,
            ...rest,
          }),
        ),
        versions: {
          ...state.versions,
          ...remapRawVersions(data.versions),
        },
      };
    case GET_RECAP_GOALS_SUCCESS:
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const remapSnakeCaseProps = (data: any[]) =>
        data.map(({ content_header, content_slug, content_type, ...rest }) => ({
          contentHeader: content_header,
          contentSlug: content_slug,
          contentType: content_type,
          ...rest,
        }));
      return {
        ...state,
        recapCurrent: remapSnakeCaseProps(data.recap_current),
        recapDestination: remapSnakeCaseProps(data.recap_destination),
        recapRoute: remapSnakeCaseProps(data.recap_route),
      };
    case SET_RECAP_GOALS_CLOSED:
      return {
        ...state,
        isRecapGoalsClosed: payload.isClosed,
      };
    case GET_ASSESSMENT_FLAGS_SUCCESS:
      return {
        ...state,
        ...camelcaseKeys(data.rules, { deep: true }),
        versions: {
          ...state.versions,
          flags: data.version,
        },
      };
    default:
      return state;
  }
};

export default assessmentReducer;
