import { FAIL, REQUEST, SUCCESS } from 'utils/requestStates';

export const SET_CHECKOUT_PAGE_ID = 'SET_CHECKOUT_PAGE_ID';

export const GET_ORDER = 'GET_ORDER';
export const GET_ORDER_REQUEST = `${GET_ORDER}_${REQUEST}` as const;
export const GET_ORDER_SUCCESS = `${GET_ORDER}_${SUCCESS}` as const;

export const POST_PRODUCT_TO_ORDER = 'POST_PRODUCT_TO_ORDER';
export const POST_PRODUCT_TO_ORDER_REQUEST = `${POST_PRODUCT_TO_ORDER}_${REQUEST}` as const;
export const POST_PRODUCT_TO_ORDER_SUCCESS = `${POST_PRODUCT_TO_ORDER}_${SUCCESS}` as const;
export const POST_PRODUCT_TO_ORDER_FAIL = `${POST_PRODUCT_TO_ORDER}_${FAIL}` as const;

export const SET_IS_SHIPPING_DETAILS_UPDATED =
  'SET_IS_SHIPPING_DETAILS_UPDATED';
export const SET_IS_CONTACT_DETAILS_UPDATED = 'SET_IS_CONTACT_DETAILS_UPDATED';

export const GET_VIVA_WALLET_TOKEN = 'GET_VIVA_WALLET_TOKEN';
export const GET_VIVA_WALLET_TOKEN_REQUEST = `${GET_VIVA_WALLET_TOKEN}_${REQUEST}` as const;
export const GET_VIVA_WALLET_TOKEN_SUCCESS = `${GET_VIVA_WALLET_TOKEN}_${SUCCESS}` as const;
export const GET_VIVA_WALLET_TOKEN_FAIL = `${GET_VIVA_WALLET_TOKEN}_${FAIL}` as const;

export const CHECKOUT = 'CHECKOUT';
export const CHECKOUT_REQUEST = `${CHECKOUT}_${REQUEST}` as const;
export const CHECKOUT_SUCCESS = `${CHECKOUT}_${SUCCESS}` as const;
export const CHECKOUT_FAIL = `${CHECKOUT}_${FAIL}` as const;

export const SET_ORDER_CONFIRM = 'SET_ORDER_CONFIRM';
export const SET_ORDER_CONFIRM_REQUEST = `${SET_ORDER_CONFIRM}_${REQUEST}` as const;
export const SET_ORDER_CONFIRM_SUCCESS = `${SET_ORDER_CONFIRM}_${SUCCESS}` as const;

export const RESET_VIVA_WALLET_TOKEN_ERROR = 'RESET_VIVA_WALLET_TOKEN_ERROR';
