import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { IonRow, IonCol } from '@ionic/react';
import { Form, InputErrorMsg, ContinueButton } from 'shared';
import { DateAnswerFormValues } from 'modules/Questionnaire/types';
import styles from './style.module.css';
import { DateEditableFields } from 'modules/Questionnaire/helpers';
import { useDomain } from 'utils/hooks/useDomain';
import { DOMAIN_MAP } from 'utils/axios';
import DateInputs from './DateInputs';

interface DateAnswerProps {
  initialValues: DateAnswerFormValues;
  validate: (values: DateAnswerFormValues) => void;
  handleSubmit: (values: DateAnswerFormValues) => void;
  handleRequestNext: () => void;
  editableFields: DateEditableFields;
}

const DateAnswer: React.FC<DateAnswerProps> = ({
  initialValues,
  validate,
  handleSubmit,
  handleRequestNext,
  editableFields,
}) => {
  const domain = useDomain();

  return (
    <Formik
      initialValues={initialValues}
      validate={validate}
      onSubmit={handleSubmit}
      validateOnMount
    >
      {({
        values,
        errors,
        touched,
        isValid,
        dirty,
        submitForm,
        handleChange,
        handleBlur,
        setFieldTouched,
      }): ReactNode => {
        const submit = (): void | Promise<void> => {
          if (isValid) {
            return dirty ? submitForm() : handleRequestNext();
          } else {
            Object.keys(values).forEach((key) => setFieldTouched(key));
          }
        };

        return (
          <>
            <Form disableSubmit={!isValid}>
              <IonRow className="ion-row-expand ion-justify-content-center">
                <IonCol size="auto" className="ion-no-vertical-padding">
                  <div className={styles.containerWithError}>
                    <div className={styles.inputsContainer}>
                      {domain === DOMAIN_MAP.uk ? (
                        <>
                          {editableFields.day && (
                            <DateInputs
                              fieldName={'day'}
                              placeHolder={'dd'}
                              dateProps={{ min: 1, max: 31 }}
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          )}
                          {editableFields.month && (
                            <DateInputs
                              fieldName={'month'}
                              placeHolder={'mm'}
                              dateProps={{ min: 1, max: 12 }}
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          )}
                        </>
                      ) : (
                        <>
                          {editableFields.month && (
                            <DateInputs
                              fieldName={'month'}
                              placeHolder={'mm'}
                              dateProps={{ min: 1, max: 12 }}
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          )}
                          {editableFields.day && (
                            <DateInputs
                              fieldName={'day'}
                              placeHolder={'dd'}
                              dateProps={{ min: 1, max: 31 }}
                              values={values}
                              touched={touched}
                              errors={errors}
                              handleChange={handleChange}
                              handleBlur={handleBlur}
                            />
                          )}
                        </>
                      )}
                      <>
                        {editableFields.year && (
                          <DateInputs
                            fieldName={'year'}
                            placeHolder={'yyyy'}
                            dateProps={{
                              min: 1950,
                              max: new Date().getFullYear(),
                            }}
                            values={values}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            handleBlur={handleBlur}
                          />
                        )}
                      </>
                    </div>
                    <IonRow
                      className={`ion-align-self-start ${
                        Object.keys(errors).length !== 0 &&
                        Object.keys(touched).length !== 0
                          ? ''
                          : 'ion-hide'
                      }`}
                    >
                      <IonCol className="ion-no-padding">
                        {editableFields.day && (
                          <InputErrorMsg
                            isVisible={!!(touched.day && errors.day)}
                            error={errors.day}
                          />
                        )}
                        {editableFields.month && (
                          <InputErrorMsg
                            isVisible={!!(touched.month && errors.month)}
                            error={errors.month}
                          />
                        )}
                        {editableFields.year && (
                          <InputErrorMsg
                            isVisible={!!(touched.year && errors.year)}
                            error={errors.year}
                          />
                        )}
                      </IonCol>
                    </IonRow>
                  </div>
                </IonCol>
              </IonRow>
            </Form>
            <ContinueButton onClick={submit} />
          </>
        );
      }}
    </Formik>
  );
};

export default DateAnswer;
