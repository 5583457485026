export default `
  <svg width="25" height="32" class="ionicon" viewBox="0 0 25 32" xmlns="http://www.w3.org/2000/svg">
    <path d="M14.8018 11.0431C14.8018 11.3388 14.5917 11.5489 14.3037 11.5489H13.2454V12.6306C13.2454 12.9264 13.0353 13.1365 12.7395 13.1365C12.4516 13.1365 12.2415 12.9264 12.2415 12.6306V11.5489H11.1831C10.9107 11.5489 10.6851 11.3232 10.6851 11.0431C10.6851 10.7474 10.8952 10.5373 11.1831 10.5373H12.2415V9.45554C12.2415 9.15982 12.4516 8.94971 12.7395 8.94971C13.0353 8.94971 13.2454 9.15982 13.2454 9.45554V10.5373H14.3037C14.5917 10.5373 14.8018 10.7474 14.8018 11.0431Z" stroke-width="0.3"/>
    <path d="M23.9377 31.144H0.88716V0.902724H23.9455V31.144H23.9377ZM9.42412 0H0.44358C0.178988 0 0 0.178988 0 0.451362V31.5486C0 31.821 0.178988 32 0.44358 32H24.3813C24.6459 32 24.8249 31.821 24.8249 31.5486V0.451362C24.8249 0.178988 24.6459 0 24.3813 0H16.1556H9.42412Z" stroke-width="0.3"/>
    <path d="M16.1868 6.2181C14.9572 5.72783 13.6576 6.07803 12.7393 7.12861C11.8288 6.07803 10.5292 5.72783 9.29187 6.2181C8.17125 6.66168 7.13623 7.86013 7.13623 9.54884C7.13623 9.86791 7.16736 10.1948 7.24518 10.5372C8.03895 14.1247 12.3658 15.9691 12.5448 16.0391C12.607 16.0702 12.6771 16.078 12.7393 16.078C12.8094 16.078 12.8794 16.0702 12.9339 16.0391C13.1207 15.9691 17.4475 14.1247 18.2413 10.5372C18.7316 8.32705 17.5253 6.74729 16.1868 6.2181ZM17.2607 10.3193C16.6615 12.9885 13.5565 14.6383 12.7393 15.0274C11.93 14.6383 8.82495 12.9963 8.22572 10.3193C8.16347 10.0547 8.14012 9.80565 8.14012 9.56441C8.14012 8.33483 8.87164 7.47102 9.66541 7.15974C9.90666 7.06635 10.1635 7.01188 10.4125 7.01188C10.9417 7.01188 11.7043 7.22978 12.3113 8.24923C12.3969 8.39709 12.5604 8.48269 12.7393 8.48269C12.9261 8.48269 13.0895 8.39709 13.1751 8.24923C13.9378 6.97297 15.0428 6.84845 15.8211 7.15974C16.7705 7.53328 17.6187 8.68503 17.2607 10.3193Z" stroke-width="0.3"/>
    <path d="M4.07004 20.9183H20.7549C21.0195 20.9183 21.1985 20.7394 21.1985 20.467C21.1985 20.1946 21.0195 20.0156 20.7549 20.0156H4.07004C3.80545 20.0156 3.62646 20.1946 3.62646 20.467C3.62646 20.7394 3.85215 20.9183 4.07004 20.9183Z" stroke-width="0.3" stroke-miterlimit="10"/>
    <path d="M4.07004 23.8363H20.7549C21.0195 23.8363 21.1985 23.6573 21.1985 23.385C21.1985 23.1126 21.0195 22.9336 20.7549 22.9336H4.07004C3.80545 22.9336 3.62646 23.1126 3.62646 23.385C3.62646 23.6495 3.85215 23.8363 4.07004 23.8363Z" stroke-width="0.3" stroke-miterlimit="10"/>
    <path d="M4.07004 26.747H20.7549C21.0195 26.747 21.1985 26.568 21.1985 26.2956C21.1985 26.0232 21.0195 25.8442 20.7549 25.8442H4.07004C3.80545 25.8442 3.62646 26.0232 3.62646 26.2956C3.62646 26.568 3.85215 26.747 4.07004 26.747Z" stroke-width="0.3" stroke-miterlimit="10"/>
  </svg>
`;
