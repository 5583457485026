import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';

export const ReportContentfulKeys = {
  [CTN.ISSUE_SNIPPET_SPECIFIC]: keyGen.getKey(
    CONTENT_TYPE.ISSUE_SNIPPET_SPECIFIC,
  ),
  [CTN.CHC_UNSUITABILITY_EXPLANATION]: keyGen.getKey(
    CONTENT_TYPE.CHC_UNSUITABILITY_EXPLANATION,
  ),
  [CTN.CLINICIAN_RECOMMENDATION]: keyGen.getKey(
    CONTENT_TYPE.CLINICIAN_RECOMMENDATION,
  ),
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
  [CTN.OC_SUITABILITY]: keyGen.getKey(CONTENT_TYPE.OC_SUITABILITY),
  [CTN.OC_SUITABILITY_EXPLANATION]: keyGen.getKey(
    CONTENT_TYPE.OC_SUITABILITY_EXPLANATION,
  ),
  [CTN.RECOMMENDATION_INTRO]: keyGen.getKey(CONTENT_TYPE.RECOMMENDATION_INTRO),
  [CTN.ISSUE_SNIPPET_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.ISSUE_SNIPPET_GENERIC,
  ),
  [CTN.IMPACT_SCORE]: keyGen.getKey(CONTENT_TYPE.IMPACT_SCORE),
  [CTN.IMPACT_SCORE_EXPLAINED]: keyGen.getKey(
    CONTENT_TYPE.IMPACT_SCORE_EXPLAINED,
  ),
  [CTN.ISSUE_ICON]: keyGen.getKey(CONTENT_TYPE.ISSUE_ICON),
  [CTN.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED]: keyGen.getKey(
    CONTENT_TYPE.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED,
  ),
  [CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY]: keyGen.getKey(
    CONTENT_TYPE.RECOMMENDED_CONSIDERATIONS_SUMMARY,
  ),
  [CTN.RECOMMENDED_REGIMEN_SUMMARY]: keyGen.getKey(
    CONTENT_TYPE.RECOMMENDED_REGIMEN_SUMMARY,
  ),
  [CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC,
  ),
};
