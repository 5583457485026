import React, { useCallback } from 'react';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { StartLayout } from '../components/StartLayout';

export const StartPage: React.FC = () => {
  const history = useHistory();

  const handleNext = useCallback(() => {
    history.replace(Routes.QUESTIONNAIRE);
  }, [history]);

  return <StartLayout handleNext={handleNext} />;
};
