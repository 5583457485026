import { REQUEST, SUCCESS } from 'utils/requestStates';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAIL = 'LOGIN_FAIL';
export const LOGIN_ERROR = 'LOGIN_ERROR';

export const GET_USER_ACCESS_TOKEN_REQUEST = 'GET_USER_ACCESS_TOKEN_REQUEST';
export const GET_USER_ACCESS_TOKEN_SUCCESS = 'GET_USER_ACCESS_TOKEN_SUCCESS';
export const GET_USER_ACCESS_TOKEN_FAIL = 'GET_USER_ACCESS_TOKEN_FAIL';
export const GET_USER_ACCESS_TOKEN_ERROR = 'GET_USER_ACCESS_TOKEN_ERROR';

export const LOG_OUT_REQUEST = 'LOG_OUT_REQUEST';
export const LOG_OUT_SUCCESS = 'LOG_OUT_SUCCESS';

export const SET_IS_AUTHENTICATED = 'SET_IS_AUTHENTICATED';

export const RESET_PASSWORD_REQUEST = 'RESET_PASSWORD_REQUEST';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';

export const RESEND_ACTIVATION = 'RESEND_ACTIVATION';
export const RESEND_ACTIVATION_REQUEST = `${RESEND_ACTIVATION}_${REQUEST}`;
export const RESEND_ACTIVATION_SUCCESS = `${RESEND_ACTIVATION}_${SUCCESS}`;

export const RESET_PASSWORD_CONFIRM_REQUEST = 'RESET_PASSWORD_CONFIRM_REQUEST';
export const RESET_PASSWORD_CONFIRM_SUCCESS = 'RESET_PASSWORD_CONFIRM_SUCCESS';
export const RESET_PASSWORD_CONFIRM_FAIL = 'RESET_PASSWORD_CONFIRM_FAIL';

export const RESET_PASSWORD_FLOW_RESET = 'RESET_PASSWORD_FLOW_RESET';

export const ACTIVATE_EMAIL_REQUEST = 'ACTIVATE_EMAIL_REQUEST';
export const ACTIVATE_EMAIL_SUCCESS = 'ACTIVATE_EMAIL_SUCCESS';
export const ACTIVATE_EMAIL_FAIL = 'ACTIVATE_EMAIL_FAIL';
export const ACTIVATE_EMAIL_ERROR = 'ACTIVATE_EMAIL_ERROR';
