import styled from 'styled-components';
import { SwipeableDrawer as MuiDrawer } from '@material-ui/core';

export const StickyContainer = styled.div`
  position: sticky;
  padding-top: var(--ion-safe-area-top);
  top: 0;
  background-color: ${({ theme }) => theme.palette.common.white};
`;

export const Drawer = styled(MuiDrawer)`
  .MuiDrawer-paper {
    width: ${({ theme }) => `${theme.breakpoints.values.sm}px`};
    max-width: 100%;
  }
`;
