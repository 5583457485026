import React from 'react';
import { Paper, Box, Dialog, Link as MuiLink } from '@material-ui/core';
import { Link } from 'shared';
import { Button, GridContainer, GridItem } from '@tuunetech/tuune-components';
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  GridItemButton,
  StyledButton,
  PaperStyle,
} from './styles';

export type RecapProps = {
  onClose: () => void;
  isOpen: boolean;
};

export const UnsupportedFeatureDialog: React.FC<RecapProps> = ({
  onClose,
  isOpen,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      PaperProps={{
        variant: 'outlined',
        style: PaperStyle,
      }}
      PaperComponent={Paper}
      maxWidth="sm"
    >
      <DialogTitle disableTypography id="alert-dialog-title">
        Not supported... yet!
      </DialogTitle>
      <DialogContent>
        We&#39;re working on this feature and will be able to support you with
        it soon! Please get in touch with us on{' '}
        <Link
          href="mailto:help@tuune.com"
          display="inline"
          variant="body1"
          color="textSecondary"
          target="_blank"
        >
          help@tuune.com
        </Link>{' '}
        if you have any questions about your report or how to purchase your
        recommendation
      </DialogContent>
      <DialogActions>
        <GridContainer alignItems="center" direction="column">
          <GridItemButton>
            <Box mb={1} textAlign="center">
              <MuiLink
                href="mailto:help@tuune.com"
                onClick={onClose}
                target="_blank"
                component={StyledButton}
                underline="none"
              >
                Send email
              </MuiLink>
            </Box>
          </GridItemButton>
          <GridItem>
            <Button onClick={onClose} variant="text">
              Go back
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};
