import React from 'react';
import {
  BODY1,
  Container,
  ContentBlock,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, Checkbox } from '@material-ui/core';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import { AccountInfoFormData } from 'modules/Questionnaire/types';
import { MARKETING_OPTIONS_LABEL } from 'modules/Questionnaire/constants';
import { Input, Button } from './styles';

export type AccountInfoAnswerProps = {
  control: Control<AccountInfoFormData>;
  handleSubmit: () => void;
  errors: FieldErrors<AccountInfoFormData>;
};

export const AccountInfoAnswer: React.FC<AccountInfoAnswerProps> = ({
  control,
  handleSubmit,
  errors,
}) => {
  return (
    <form onSubmit={handleSubmit}>
      <Container>
        <GridContainer justify="center">
          <GridItem xs={12} md={10} lg={6}>
            <GridContainer>
              <GridItem xs={12} md={6}>
                <Input
                  name="firstName"
                  placeholder="First name"
                  control={control}
                  fullWidth
                  autoComplete="given-name"
                  helperText={errors.firstName?.message}
                  error={!!errors.firstName}
                />
              </GridItem>
              <GridItem xs={12} md={6}>
                <Input
                  name="lastName"
                  placeholder="Surname"
                  control={control}
                  fullWidth
                  autoComplete="family-name"
                  helperText={errors.lastName?.message}
                  error={!!errors.lastName}
                />
              </GridItem>
              <GridItem xs={12}>
                <Input
                  name="email"
                  placeholder="Email"
                  control={control}
                  fullWidth
                  autoComplete="email"
                  helperText={errors.email?.message}
                  error={!!errors.email}
                />
              </GridItem>
              <GridItem xs={12}>
                <Input
                  name="password"
                  placeholder="Password"
                  control={control}
                  fullWidth
                  autoComplete="new-password"
                  type="password"
                  error={!!errors.password}
                />
              </GridItem>
              <GridItem xs={12}>
                <Input
                  name="rePassword"
                  placeholder="Confirm password"
                  control={control}
                  fullWidth
                  type="password"
                  helperText={
                    errors.rePassword
                      ? errors.rePassword.message
                      : 'Your password needs to be unique, contain letters and numbers, and be at least 8 characters long'
                  }
                  error={!!errors.password || !!errors.rePassword}
                />
              </GridItem>
              <GridItem>
                <Box marginTop="40px">
                  {MARKETING_OPTIONS_LABEL.map(({ id, label }) => (
                    <ContentBlock
                      key={id}
                      startAdornment={
                        <Controller
                          name={id}
                          control={control}
                          render={({ field: { value, onChange, onBlur } }) => (
                            <Checkbox
                              id={id}
                              color="primary"
                              onChange={onChange}
                              onBlur={onBlur}
                              checked={value}
                            />
                          )}
                        />
                      }
                      content={
                        <BODY1
                          component="label"
                          htmlFor={id}
                          color="textSecondary"
                          $marginBottom={16}
                        >
                          {label}
                        </BODY1>
                      }
                    />
                  ))}
                </Box>
              </GridItem>
              <GridItem>
                <Button type="submit">Create account</Button>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
      </Container>
    </form>
  );
};
