import React, { useCallback, useState, ReactNode } from 'react';
import { useSelector } from 'react-redux';
import {
  RecommendationTable,
  RecommendationTableProps,
} from '@tuunetech/tuune-components';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { getEmail } from 'modules/Account/selectors';
import { Benefits, Flag as FlagType } from 'modules/Results/types';
import NextBar, { NextBarProps } from '../NextBar';
import { HowRecommendationsMadeContainer } from '../../containers';
import RecommendationDetails from '../RecommendationDetails';
import { RecommendationContainer } from './styles';
import { PILL_TYPE_MAPPING } from '../../constants';

type CurrentRecommendation = RecommendationTableProps['recommendations'][number] & {
  slug: string;
  id: number;
};

export type ContracptiveTypes = 'chc' | 'pop';

export type RecommendationLayoutProps = {
  recommendations?: RecommendationTableProps['recommendations'];
  cart: number[];
  handleCheckout: NextBarProps['handleCheckout'];
  isNextBarVisible: boolean;
  infoRequiredFlags: FlagType[];
  isRecommendationDetailsDialogOpen: boolean;
  onRecommendationDetailsDialogClose: () => void;
  handleRecommendationAdd: (id: number, isAdded: boolean) => void;
  currentRecommendation?: CurrentRecommendation;
  considerationsSummaryContent: Record<string, ReactNode>;
  pillRegimenSummaryContent: Record<string, ReactNode>;
  switchingPillInfoSummary: ReactNode;
};

const RecommendationLayout: React.FC<RecommendationLayoutProps> = ({
  recommendations,
  currentRecommendation,
  cart,
  isNextBarVisible,
  infoRequiredFlags,
  isRecommendationDetailsDialogOpen,
  onRecommendationDetailsDialogClose,
  handleRecommendationAdd,
  considerationsSummaryContent,
  pillRegimenSummaryContent,
  switchingPillInfoSummary,
  handleCheckout,
}) => {
  const analytics = useAnalytics();
  const email = useSelector(getEmail);

  const handleHowRecommendationsMadeOpen = useCallback(() => {
    setIsHowRecommendationsMadeOpen(true);
    analytics.track(
      ANALYTICS_EVENT.RECOMMENDATION_HOW_WAS_IT_MADE,
      {
        category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
      },
      email,
    );
  }, [analytics, email]);

  const [
    isHowRecommendationsMadeOpen,
    setIsHowRecommendationsMadeOpen,
  ] = useState(false);

  const handleHowRecommendationsMadeClose = useCallback(() => {
    setIsHowRecommendationsMadeOpen(false);
  }, []);

  const formattedRecommendations = recommendations?.map((recommendation) => {
    return {
      ...recommendation,
      type: PILL_TYPE_MAPPING[recommendation.type as ContracptiveTypes],
    };
  });

  return (
    <>
      <HowRecommendationsMadeContainer
        isHowRecommendationsMadeOpen={isHowRecommendationsMadeOpen}
        handleHowRecommendationsMadeOpen={handleHowRecommendationsMadeOpen}
        handleHowRecommendationsMadeClose={handleHowRecommendationsMadeClose}
      />
      {currentRecommendation && (
        <RecommendationDetails
          name={currentRecommendation.name}
          slug={currentRecommendation.slug}
          id={currentRecommendation.id}
          isAdded={cart.includes(currentRecommendation.id)}
          preventAdd={!!infoRequiredFlags.length}
          generics={currentRecommendation.generics}
          benefits={(currentRecommendation.benefits as unknown) as Benefits}
          considerationSummary={
            considerationsSummaryContent[currentRecommendation.slug]
          }
          pillRegimenSummary={
            pillRegimenSummaryContent[currentRecommendation.slug]
          }
          switchingPillInfoSummary={switchingPillInfoSummary}
          type={currentRecommendation.type as ContracptiveTypes}
          handleRecommendationAdd={handleRecommendationAdd}
          status={currentRecommendation.status}
          isOpen={isRecommendationDetailsDialogOpen}
          onClose={onRecommendationDetailsDialogClose}
        />
      )}
      {isNextBarVisible && (
        <NextBar isActive={!!cart.length} handleCheckout={handleCheckout} />
      )}
      <RecommendationContainer disableGutters>
        {formattedRecommendations ? (
          <RecommendationTable
            recommendations={formattedRecommendations}
            prioritizeLinkClickCallback={handleHowRecommendationsMadeOpen}
          />
        ) : null}
      </RecommendationContainer>
    </>
  );
};

export default RecommendationLayout;
