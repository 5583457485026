import React, { useEffect } from 'react';

import { AnalyticsContext } from './context';
import { Analytics } from './types';
import { track, identify, logout, initAnalytics } from './amplitude';

const apiKey: string = process.env.REACT_APP_AMPLITUDE_API_KEY || '';

export const AnalyticsProvider: React.FC = ({ children }) => {
  useEffect(() => {
    // init
    initAnalytics(apiKey);
  }, []);

  const analtics: Analytics = {
    track,
    identify,
    logout,
  };

  if (!apiKey) {
    return <>{children}</>;
  }

  return (
    <AnalyticsContext.Provider value={analtics}>
      {children}
    </AnalyticsContext.Provider>
  );
};
