import React, { ReactNode } from 'react';
import { IonRow, IonToast } from '@ionic/react';
import { Formik } from 'formik';
import { ContinueButton, Input, Form } from 'shared';
import AuthLayout from '../AuthLayout';
import { ResetPasswordValues } from '../../types';

interface ResetPasswordProps {
  initialValues: ResetPasswordValues;
  onSubmit: (values: ResetPasswordValues) => void;
  validate: (values: ResetPasswordValues) => void;
  isResetPasswordConfirmed: boolean;
  handleResetPopupDismiss: (e: CustomEvent) => void;
  initialErrors: { [field: string]: string };
}

const ResetPassword: React.FC<ResetPasswordProps> = ({
  initialValues,
  onSubmit,
  validate,
  isResetPasswordConfirmed,
  handleResetPopupDismiss,
  initialErrors,
}) => {
  const cardContent = (
    <Formik
      initialValues={initialValues}
      initialErrors={initialErrors}
      onSubmit={onSubmit}
      validate={validate}
      validateOnMount
      enableReinitialize
    >
      {({ values, errors, touched, submitForm, isValid }): ReactNode => (
        <Form>
          <IonRow>
            <Input
              autofocus
              required
              name="password"
              placeholder="Password"
              type="password"
              value={values.password}
              error={errors.password}
              touched={touched.password}
            />
            <Input
              required
              name="confirmPassword"
              placeholder="Confirm password"
              type="password"
              value={values.confirmPassword}
              error={errors.confirmPassword}
              touched={touched.confirmPassword}
            />
          </IonRow>
          <IonRow className="ion-justify-content-center">
            <ContinueButton
              text="Change Password"
              disabled={!isValid}
              onClick={submitForm}
            />
          </IonRow>
        </Form>
      )}
    </Formik>
  );

  return (
    <>
      <AuthLayout
        title="New password"
        subTitle="Numbers and letters make your password safer."
        cardContent={cardContent}
      />
      <IonToast
        isOpen={isResetPasswordConfirmed}
        position="top"
        message="Password reset successfully. You'll be redirected to Login"
        color="purple"
        duration={3000}
        onDidDismiss={handleResetPopupDismiss}
      />
    </>
  );
};

export default ResetPassword;
