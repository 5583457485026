import React from 'react';
import { Control, Controller, UseControllerProps } from 'react-hook-form';
import {
  Input,
  InputProps as BaseInputProps,
} from '@tuunetech/tuune-components';

type InputProps = BaseInputProps & {
  name: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<any>;
  rules?: UseControllerProps['rules'];
  'data-vp'?: string;
};

export const TuuneInput: React.FC<InputProps> = ({
  name,
  control,
  rules,
  defaultValue = '',
  ...props
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => (
        <Input value={value} onChange={onChange} onBlur={onBlur} {...props} />
      )}
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
    />
  );
};
