import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import Login from '../components/Login';
import { loginRequest } from '../actions';
import { getIsAuthenticated } from '../selectors';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { LoginFormSchema } from '../constants/validation';

export type LoginFormData = {
  email: string;
  password: string;
};

const LoginPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const isAuthenticated = useSelector(getIsAuthenticated);

  const submit = useCallback(
    ({ email, password }) => {
      dispatch(loginRequest(email, password));
    },
    [dispatch],
  );

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<LoginFormData>({
    resolver: yupResolver(LoginFormSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.replace(Routes.DEFAULT_ROUTE);
    }
  }, [isAuthenticated, history]);

  return (
    <Login
      control={control}
      handleSubmit={handleSubmit(submit)}
      errors={errors}
      isValid={isValid}
    />
  );
};

export default LoginPage;
