import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { ComplexOutputSelector } from 'reduxStore';
import { ContentfulDocument, ContentfulReducerState } from '../types';

const selectContentful: Selector<RootState, ContentfulReducerState> = (state) =>
  state.contentful;

const FALLBACK = {};

export const getArrayContentMapped = (
  key: string,
): ComplexOutputSelector<ContentfulReducerState, ContentfulDocument> =>
  createSelector(selectContentful, (state) => {
    return state[key] || FALLBACK;
  });
