import React from 'react';
import { H3 } from '@tuunetech/tuune-components';
import { Flag as FlagType } from 'modules/Results/types';
import Flag, { FLAG_TYPES } from '../../components/Flag';
import { REPORT_STATIC_DATA_SLUGS } from '../../constants';

export type FlagsSectionLayoutProps = {
  redFlags: FlagType[];
  greenFlags: FlagType[];
  orangeFlags: FlagType[];
  staticDataContent: Record<string, string>;
};

export const FlagsSectionLayout: React.FC<FlagsSectionLayoutProps> = ({
  redFlags,
  greenFlags,
  orangeFlags,
  staticDataContent,
}) => {
  const allFlags = [...greenFlags, ...orangeFlags, ...redFlags];

  return allFlags.length ? (
    <>
      <H3 $marginBottom={24}>
        {staticDataContent[REPORT_STATIC_DATA_SLUGS.RECOMMENDATION_OTHER_TITLE]}
      </H3>
      {greenFlags.map(({ content, id }) => (
        <Flag key={id} info={content} type={FLAG_TYPES.GREEN} />
      ))}
      {orangeFlags.map(({ content, id }) => (
        <Flag key={id} info={content} type={FLAG_TYPES.ORANGE} />
      ))}
      {redFlags.map(({ content, id }) => (
        <Flag key={id} info={content} type={FLAG_TYPES.RED} />
      ))}
    </>
  ) : null;
};
