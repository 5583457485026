import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import { CheckoutReducerState } from '../types';
import {
  UPDATE_ACCOUNT_DATA_SUCCESS,
  UPDATE_ADDRESS_SUCCESS,
} from 'modules/Account/constants';
import {
  SET_CHECKOUT_PAGE_ID,
  CHECKOUT_PAGES,
  GET_ORDER_SUCCESS,
  POST_PRODUCT_TO_ORDER_SUCCESS,
  SET_IS_SHIPPING_DETAILS_UPDATED,
  SET_IS_CONTACT_DETAILS_UPDATED,
  CHECKOUT_SUCCESS,
  GET_VIVA_WALLET_TOKEN_FAIL,
  RESET_VIVA_WALLET_TOKEN_ERROR,
} from '../constants';

const defaultState: CheckoutReducerState = {
  pageId: CHECKOUT_PAGES[0].id,
  ampp: [],
  consultation: [],
  orderId: undefined,
  status: '',
  total: 0,

  isContactDetailsUpdated: false,
  isShippingDetailsUpdated: false,
  isOrderPlaced: false,

  tokenError: false,
};

const checkoutReducer: Reducer<CheckoutReducerState> = (
  state = defaultState,
  { type, payload = {}, response: { data = {} } = {} },
) => {
  switch (type) {
    case SET_CHECKOUT_PAGE_ID:
      return {
        ...state,
        pageId: payload.pageId,
        isContactDetailsUpdated: payload.directionBack
          ? false
          : state.isContactDetailsUpdated,
        isShippingDetailsUpdated: payload.directionBack
          ? false
          : state.isShippingDetailsUpdated,
      };
    case GET_ORDER_SUCCESS:
      return {
        ...state,
        ...camelcaseKeys(data.order, { deep: true }),
        orderId: data.order.id,
      };
    case POST_PRODUCT_TO_ORDER_SUCCESS:
      if (payload.method === 'post') {
        return {
          ...state,
          ...camelcaseKeys(data.order, { deep: true }),
          orderId: data.order.id,
        };
      }
      return {
        ...state,
        ampp: state.ampp.filter(({ id }) => id !== payload.productId),
        consultation: state.consultation.filter(
          ({ id }) => id !== payload.productId,
        ),
      };

    case UPDATE_ACCOUNT_DATA_SUCCESS:
    case SET_IS_CONTACT_DETAILS_UPDATED:
      return {
        ...state,
        isContactDetailsUpdated: true,
      };
    case UPDATE_ADDRESS_SUCCESS:
    case SET_IS_SHIPPING_DETAILS_UPDATED:
      return {
        ...state,
        isShippingDetailsUpdated: true,
      };
    case CHECKOUT_SUCCESS:
      return {
        ...state,
        isOrderPlaced: true,
      };

    case GET_VIVA_WALLET_TOKEN_FAIL:
      return {
        ...state,
        tokenError: true,
      };
    case RESET_VIVA_WALLET_TOKEN_ERROR:
      return {
        ...state,
        tokenError: false,
      };

    default:
      return state;
  }
};

export { checkoutReducer as checkout };
