import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { phoneAsYouType } from 'utils/helpers';
import { getEmail, getFirstName, getLastName, getPhone } from '../selectors';
import { getAccountDataRequest } from '../actions';
import { PersonalInfo } from '../components/PersonalInfo';

export const PersonalInfoContainer: React.FC = () => {
  const dispatch = useDispatch();

  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const email = useSelector(getEmail);
  const phone = useSelector(getPhone);

  useEffect(() => {
    dispatch(getAccountDataRequest());
  }, [dispatch]);

  const profileData = useMemo(() => {
    phoneAsYouType.reset();
    const formattedPhone = phoneAsYouType.input(phone || '');
    return [
      { name: 'First name', value: firstName },
      { name: 'Surname', value: lastName },
      { name: 'Email', value: email },
      {
        name: 'Phone',
        value: `${formattedPhone.substring(0, formattedPhone.length - 3)}***`,
      },
      { name: 'Password', value: '*********' },
    ];
  }, [firstName, lastName, email, phone]);

  return <PersonalInfo profileData={profileData} />;
};
