import React, { useMemo } from 'react';
import { ReportContentfulKeys as CK } from '../constants';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { useSelector } from 'react-redux';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import { getCart } from 'modules/Checkout/selectors';
import ChatToAnObgyn from '../components/ChatToAnObgyn';
import { getReferralRecommendation } from '../selectors/recommendations';
import { ResultsLayoutProps } from '../components/ResultsLayout';

type ChatToAnObgynContainerProps = {
  handleRecommendationAdd: ResultsLayoutProps['handleRecommendationAdd'];
};

export const ChatToAnObgynContainer: React.FC<ChatToAnObgynContainerProps> = ({
  handleRecommendationAdd,
}) => {
  // static data
  const staticData = useSelector(
    getArrayContentMapped(CK[CTN.STATIC_CONTENT].reqId),
  );

  const staticDataContent = useMemo(() => {
    return documentToPlainTextRecords(staticData);
  }, [staticData]);

  const referralRecommendation = useSelector(getReferralRecommendation);
  const cart = useSelector(getCart);

  return (
    <ChatToAnObgyn
      staticDataContent={staticDataContent}
      staticData={staticData}
      isAdded={cart.includes(referralRecommendation.id)}
      id={referralRecommendation.id}
      handleRecommendationAdd={handleRecommendationAdd}
    />
  );
};
