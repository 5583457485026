import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { CheckoutLayout } from 'modules/Checkout/components';
import { getIsLoading } from 'modules/Loading/selectors';
import { getCheckoutPageId, getIsOrderEmpty } from '../selectors';
import { getOrderRequest, setCheckoutPageId } from '../actions';
import { CHECKOUT_IDS, CHECKOUT_PAGES, GET_ORDER } from '../constants';

export type PageProps = {
  handleNext?: () => void;
  setErrorMessage?: (msg: string) => void;
};

export type Page = {
  id: string;
  name: string;
  description: string;
  Component: React.FC<PageProps>;
  backText?: string;
};

export const CheckoutPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const checkoutPageId = useSelector(getCheckoutPageId);
  const isOrderEmpty = useSelector(getIsOrderEmpty);
  const isGetOrderLoading = useSelector(getIsLoading(GET_ORDER));

  const [errorMessage, setErrorMessage] = useState('');
  const [direction, setDirection] = useState<'left' | 'right'>('left');

  useEffect(() => {
    // I request order each time checkout is mounted
    // because on Delete we do not sync with BE cart
    // and total is not updated
    dispatch(getOrderRequest());
  }, [dispatch]);

  useEffect(() => {
    if (
      isOrderEmpty &&
      isGetOrderLoading === 0 &&
      checkoutPageId !== CHECKOUT_IDS.CONFIRMED
    ) {
      history.replace(Routes.RESULTS);
    }
  }, [history, isOrderEmpty, isGetOrderLoading, checkoutPageId]);

  const pageIdx = useMemo(() => {
    const pageIdx = CHECKOUT_PAGES.findIndex(
      (page) => page.id === checkoutPageId,
    );
    return pageIdx === -1 ? 0 : pageIdx;
  }, [checkoutPageId]);

  const handleNext = useCallback(() => {
    setDirection('left');
    setErrorMessage('');
    const nextPageIdx = pageIdx + 1;
    if (nextPageIdx < CHECKOUT_PAGES.length) {
      history.push(`${Routes.CHECKOUT}#${CHECKOUT_PAGES[nextPageIdx].id}`);
      dispatch(setCheckoutPageId({ pageId: CHECKOUT_PAGES[nextPageIdx].id }));
    } else {
      history.replace(Routes.ACCOUNT);
    }
  }, [dispatch, history, pageIdx]);

  const handleBack = useCallback(() => {
    setDirection('right');
    setErrorMessage('');
    const prevPageIdx = pageIdx - 1;

    if (pageIdx + 1 === CHECKOUT_PAGES.length) return;

    if (prevPageIdx > -1) {
      dispatch(
        setCheckoutPageId({
          pageId: CHECKOUT_PAGES[prevPageIdx].id,
          directionBack: true,
        }),
      );
      history.replace(`${Routes.CHECKOUT}#${CHECKOUT_PAGES[prevPageIdx].id}`);
    } else {
      // in case user opened checkout not after results ans results are absent in history stack
      history.replace(Routes.RESULTS);
    }
  }, [dispatch, history, pageIdx]);

  useEffect(() => {
    const locationPageId = history.location.hash.replace('#', '');
    if (!locationPageId && history.location.pathname === Routes.CHECKOUT) {
      history.replace(`${Routes.CHECKOUT}#${checkoutPageId}`);
    } else if (locationPageId !== checkoutPageId) {
      dispatch(setCheckoutPageId({ pageId: locationPageId }));
    }
  }, [
    checkoutPageId,
    history.location.pathname,
    history.location.hash,
    history,
    dispatch,
  ]);

  return (
    <CheckoutLayout
      page={CHECKOUT_PAGES[pageIdx]}
      handleNext={handleNext}
      handleBack={handleBack}
      direction={direction}
      errorMessage={errorMessage}
      setErrorMessage={setErrorMessage}
    />
  );
};
