import styled from 'styled-components';
import Icon from '@material-ui/icons/Done';
import { CAPTION, GridItem } from '@tuunetech/tuune-components';

export const DoneIcon = styled(Icon)`
  font-size: 10px;
  margin-left: 3px;
  margin-top: 3px;
`;

export const Indicator = styled.div`
  width: 100%;
  margin-bottom: 4px;
  height: 5px;
  background: ${({ theme }) => theme.palette.primary.main};
`;

export const SectionGridItem = styled(GridItem)<{ $isActive: boolean }>`
  margin-left: 1px;
  margin-right: 1px;
  opacity: ${({ $isActive }) => ($isActive ? 1 : 0.1)};

  ${({ theme }) => theme.breakpoints.only('xs')} {
    ${CAPTION} {
      font-size: 8px;
    }
  }

  ${({ theme }) => theme.breakpoints.up('sm')} {
    min-width: 75px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    min-width: 102px;
    margin-left: 4px;
    margin-right: 4px;
  }
`;

export const SectionNameGridItem = styled(GridItem)`
  display: flex;
  align-items: start;
  justify-content: center;
  padding: 0 2px;
`;
