import styled from 'styled-components';
import { Container } from '@tuunetech/tuune-components';
import { InfoIcon } from 'assets/icons';

export const Info = styled(InfoIcon)`
  font-size: 18px;
  margin-right: 7px;
`;

export const HelpLabelContainer = styled(Container)`
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  width: fit-content;
`;
