import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getFirstName } from 'modules/Account/selectors';
import { CheckoutResult } from '../components/CheckoutResult';
import { getAccountDataRequest } from '../../Account/actions';
import { PageProps } from '../pages/CheckoutPage';

export const CheckoutResultContainer: React.FC<PageProps> = ({
  handleNext,
}) => {
  const dispatch = useDispatch();

  const firstName = useSelector(getFirstName);

  useEffect(() => {
    if (!firstName) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, firstName]);

  return <CheckoutResult firstName={firstName} handleNext={handleNext} />;
};
