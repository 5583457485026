import { Check } from 'shared';
import styled from 'styled-components';

export const Option = styled.div`
  display: flex;
  margin: 24px 0 24px;
  cursor: pointer;
  max-width: 414px;

  &:first-of-type {
    margin-top: 32px;
  }
`;

export const Checkbox = styled(Check)`
  margin-right: 16px;
`;
