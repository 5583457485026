import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BODY1 } from '@tuunetech/tuune-components';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { TestingConsent } from 'modules/Questionnaire/components/Answers';
import { getStartedData } from 'modules/Questionnaire/selectors';
import {
  setGetStartedPrivacy,
  setGetStartedQuestion,
} from 'modules/Questionnaire/actions';
import { PRIVACY } from 'modules/Questionnaire/constants/privacy';
import { PrivacyFormData } from '../../types';
import { privacySchema } from '../../constants';

const OPTIONS = [
  {
    id: PRIVACY.acceptTestingAgreement,
    label: (
      <BODY1 align="left" color="textSecondary">
        I accept {"Tuune's"} User Testing Agreement.
      </BODY1>
    ),
  },
];

const TestingConsentContainer: React.FC = () => {
  const dispatch = useDispatch();
  const { currentQuestion } = useSelector(getStartedData);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<PrivacyFormData>({
    resolver: yupResolver(privacySchema),
    defaultValues: {
      [PRIVACY.acceptTestingAgreement]: false,
    },
  });

  const onSubmit = useCallback(
    (data) => {
      const activeOptions = Object.keys(data).filter(
        (key) => data[key] === true,
      );
      dispatch(setGetStartedPrivacy(activeOptions));
      dispatch(setGetStartedQuestion(currentQuestion + 1));
    },
    [dispatch, currentQuestion],
  );

  return (
    <TestingConsent
      options={OPTIONS}
      handleSubmit={handleSubmit(onSubmit)}
      error={!!Object.keys(errors).length}
      control={control}
    />
  );
};

export default TestingConsentContainer;
