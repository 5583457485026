import styled from 'styled-components';
import { Button as BaseButton } from '@tuunetech/tuune-components';

export const Button = styled(BaseButton)`
  padding: 0 3px;
  min-width: auto;
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text.secondary};
  font-size: 14px;
`;
