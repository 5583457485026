import styled from 'styled-components';
import {
  GridContainer,
  BODY1,
  H3,
  Container,
  ContentBlock,
} from '@tuunetech/tuune-components';
import { Avatar as BaseAvatar, Chip as MuiChip } from '@material-ui/core';
import CheckIcon from '@material-ui/icons/CheckCircleRounded';
import CancelIcon from '@material-ui/icons/CancelRounded';

export const StyledH3 = styled(H3)`
  margin-bottom: 8px;
`;

export const TeamContainer = styled('div')`
  display: flex;
  margin-bottom: 24px;
`;

export const Avatar = styled(BaseAvatar)`
  height: 80px;
  width: 80px;
  margin-right: -15px;
`;

export const EligibilityGridContainer = styled(GridContainer)`
  margin-bottom: 48px;
`;

export const RecommendationInfoLink = styled(BODY1)`
  text-decoration: underline;
`;

export const StyledChip = styled(MuiChip)`
  margin-right: 4px;
  background: ${({ theme }) => theme.palette.common.red[50]};
`;

export const StyledCheckIcon = styled(CheckIcon)`
  color: ${({ theme }) => theme.palette.common.green[400]};
`;

export const StyledCancelIcon = styled(CancelIcon)`
  color: ${({ theme }) => theme.palette.common.red[900]};
`;

export const MecChipsContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: nowrap;
  overflow-x: auto;
  margin-top: 10px;
  padding-left: 40px;
`;

export const InfoRequiredContainer = styled(Container)`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin: 0;
  padding: 9px;
`;

export const MecEligibilityBlock = styled(ContentBlock)`
  margin-top: 24px;

  & + & {
    margin-top: 16px;
  }
`;
