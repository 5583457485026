import styled from 'styled-components';
import {
  Button,
  Container as BaseContainer,
} from '@tuunetech/tuune-components';

export const Container = styled(BaseContainer)<{
  $align: 'left' | 'center' | 'right';
}>`
  display: flex;
  justify-content: ${({ $align }) =>
    $align === 'left'
      ? 'flex-start'
      : $align === 'right'
      ? 'flex-end'
      : 'center'};

  ${({ theme }) => theme.breakpoints.down('xs')} {
    ${Button} {
      width: 100%;
    }
  }
`;
