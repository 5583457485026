import { Reducer } from 'redux';
import { REGISTER_TO_QUESTIONNAIRE_SUCCESS } from 'modules/Questionnaire/constants';
import {
  LOG_OUT_SUCCESS,
  LOGIN_SUCCESS,
  SET_IS_AUTHENTICATED,
  RESET_PASSWORD_CONFIRM_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_FLOW_RESET,
  RESET_PASSWORD_SUCCESS,
  ACTIVATE_EMAIL_SUCCESS,
  ACTIVATE_EMAIL_FAIL,
  RESEND_ACTIVATION_SUCCESS,
} from '../constants';
import { AuthReducerState } from '../types';

export const defaultState: AuthReducerState = {
  isAuthenticated: undefined,
  resetPasswordStage: 0,
  isResetPasswordConfirmed: false,
  isEmailActivated: false,
  unauthenticatedEmailAddress: '',
  errors: {},
};

const authReducer: Reducer<AuthReducerState> = (
  state = defaultState,
  action,
) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
    case REGISTER_TO_QUESTIONNAIRE_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case SET_IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.payload.isAuthenticated,
      };
    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isResetPasswordConfirmed: false,
        resetPasswordStage: 1,
      };
    case RESET_PASSWORD_CONFIRM_SUCCESS:
      return {
        ...state,
        isResetPasswordConfirmed: true,
      };
    case RESET_PASSWORD_FLOW_RESET:
      return {
        ...state,
        isResetPasswordConfirmed: false,
        errors: {},
        resetPasswordStage: 0,
      };
    case LOG_OUT_SUCCESS:
      return {
        ...defaultState,
        isAuthenticated: false,
      };
    case ACTIVATE_EMAIL_SUCCESS:
      return {
        ...state,
        isEmailActivated: true,
      };
    case RESEND_ACTIVATION_SUCCESS:
      return {
        ...state,
        unauthenticatedEmailAddress: action.payload.email,
      };
    case RESET_PASSWORD_CONFIRM_FAIL:
    case ACTIVATE_EMAIL_FAIL:
      if (action.error.data) {
        return {
          ...state,
          errors: action.error.data,
        };
      } else return state;
    default:
      return state;
  }
};

export { authReducer as auth };
