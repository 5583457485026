import { ServerQuestionTypesMap } from './questions';

const BOOL = 'bool';
const CHOICE = 'choice';
const DATE = 'date';
const DATE_MONTH = 'date_month';
const FUNC = 'func';
const NUMBER = 'number';
const DOB = 'dob';
const MULTI_CHOICE = 'multi_choice';
const TEXT = 'text';
const PILL = 'pill';

export const ServerAnswerTypesMap = {
  BOOL,
  CHOICE,
  DATE,
  DATE_MONTH,
  FUNC,
  NUMBER,
  TEXT,
  PILL,
};

export const AnswerTypesMap = {
  HEIGHT: ServerQuestionTypesMap.HEIGHT,
  WEIGHT: ServerQuestionTypesMap.WEIGHT,
  INFO: ServerQuestionTypesMap.INFO,
  SIDE_EFFECTS: ServerQuestionTypesMap.SIDE_EFFECTS,
  CURRENT_PILL: ServerQuestionTypesMap.CURRENT_PILL,
  EMAIL: ServerQuestionTypesMap.EMAIL,
  CYCLE: ServerQuestionTypesMap.CYCLE,
  SUBJECTIVE: ServerQuestionTypesMap.SUBJECTIVE,
  BOOL,
  CHOICE,
  DATE,
  DATE_MONTH,
  FUNC,
  NUMBER,
  TEXT,
  PILL,
  DOB,
  MULTI_CHOICE,
};
