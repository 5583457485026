import React, { useMemo } from 'react';
import { SwipeableDrawerProps } from '@material-ui/core';
import { DrawerModal } from 'shared';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { ContentTypeNames } from 'modules/Contentful/constants';
import { ReportContentfulKeys as CK } from '../constants';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import { useSelector } from 'react-redux';
import { BODY2, H3 } from '@tuunetech/tuune-components';

export type PillRecommendationInfoProps = {
  contentSlug?: string;
  isPillRecommendationInfoOpen: boolean;
  handlePillRecommendationInfoOpen: (
    event: React.SyntheticEvent<HTMLButtonElement>,
    contentSlug?: string,
  ) => void;
  handlePillRecommendationInfoClose: SwipeableDrawerProps['onClose'];
};

export const PillRecommendationInfoContainer: React.FC<PillRecommendationInfoProps> = ({
  contentSlug,
  isPillRecommendationInfoOpen,
  handlePillRecommendationInfoOpen,
  handlePillRecommendationInfoClose,
}) => {
  const ocSuitabilityExplanationData = useSelector(
    getArrayContentMapped(
      CK[ContentTypeNames.OC_SUITABILITY_EXPLANATION].reqId,
    ),
  );

  const ocSuitabilityExplanationContent = useMemo(() => {
    return documentToPlainTextRecords(ocSuitabilityExplanationData);
  }, [ocSuitabilityExplanationData]);

  return (
    <DrawerModal
      onClose={handlePillRecommendationInfoClose}
      onOpen={handlePillRecommendationInfoOpen}
      open={isPillRecommendationInfoOpen}
    >
      <>
        <H3 $marginBottom={16}>
          {contentSlug && ocSuitabilityExplanationData[contentSlug].name}
        </H3>
        <BODY2 color="textSecondary">
          {contentSlug && ocSuitabilityExplanationContent[contentSlug]}
        </BODY2>
      </>
    </DrawerModal>
  );
};
