import React from 'react';
import {
  List as MuiList,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import { BODY2, TypographyProps } from '@tuunetech/tuune-components';
import { Indicator } from './styles';

type ListProps = {
  listItems: string[];
  textColor?: TypographyProps['color'];
};

const List: React.FC<ListProps> = ({
  listItems,
  textColor = 'textPrimary',
}) => {
  return (
    <MuiList>
      {listItems.map((listItem) => (
        <ListItem key={listItem}>
          <ListItemIcon>
            <Indicator color="primary" />
          </ListItemIcon>
          <ListItemText disableTypography>
            <BODY2 color={textColor} align="left">
              {listItem}
            </BODY2>
          </ListItemText>
        </ListItem>
      ))}
    </MuiList>
  );
};

export default List;
