import React, { createContext, useState } from 'react';
import { getCurrentDomain, DOMAIN_MAP, environment, LOCAL_ENV } from './axios';
import { MenuItem, Select } from '@material-ui/core';
import { BODY2, BODY3 } from '@tuunetech/tuune-components';
import styled from 'styled-components';
import { Link } from '../shared';

const Wrapper = styled.div`
  position: fixed;
  padding: 8px;
  border-radius: 8px;
  bottom: 0;
  z-index: 100;
  background: #fff;
  opacity: 0.3;
  :hover {
    opacity: 1;
  }
`;

const domain = getCurrentDomain();

export const DomainContext = createContext(domain);
const isLocalEnv = environment === LOCAL_ENV;

export const DomainProvider: React.FC = ({ children }) => {
  const [localDomain, setLocalDomain] = useState(domain);

  return (
    <DomainContext.Provider value={localDomain}>
      {isLocalEnv && (
        <Wrapper>
          <BODY2>
            Change domain in <b>local</b> env:
          </BODY2>
          <BODY3 $marginBottom={8}>
            If you see this select in dev, staging, production, <b>please</b>{' '}
            report to{' '}
            <Link href="mailto:tech@tuune.com" variant="caption">
              tech@tuune.com
            </Link>
          </BODY3>
          <Select
            value={localDomain}
            onChange={(e) => setLocalDomain(e.target.value as string)}
            variant="outlined"
          >
            {Object.keys(DOMAIN_MAP).map((domain) => (
              <MenuItem key={domain} value={domain}>
                {domain}
              </MenuItem>
            ))}
          </Select>
        </Wrapper>
      )}
      {children}
    </DomainContext.Provider>
  );
};
