import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { useDomain } from 'utils/hooks/useDomain';
import { DOMAIN_MAP } from 'utils/axios';
import QuestionLayout from '../components/QuestionLayout';
import { getStartedData } from '../selectors';
import { setGetStartedQuestion } from '../actions';
import InfoContainer from '../containers/Answers/InfoContainer';
import { LandingAnswer } from '../components/Answers';
import PrivacyContainer from '../containers/Answers/PrivacyContainer';
import TestingConsentContainer from '../containers/Answers/TestingConsentContainer';
import { AccountInfoContainer } from '../containers/Answers';
import { getIsAuthenticated } from '../../Auth/selectors';

const GetStartedPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector(getIsAuthenticated);

  const { currentQuestion } = useSelector(getStartedData);
  const [animation, setAnimation] = useState('forward');
  const domain = useDomain();

  const contentRef = useRef<HTMLIonContentElement>(null);

  const QUESTIONS = useMemo(() => {
    return [
      {
        component: <InfoContainer component={LandingAnswer} />,
      },
      {
        questionTitle: 'Our transparent approach to privacy',
        component: <PrivacyContainer />,
      },
      {
        questionTitle: "Tuune's User Testing Agreement",
        component: <TestingConsentContainer />,
        domain: DOMAIN_MAP.us,
      },
      {
        questionTitle: 'Create your account',
        subTitle:
          'This will allow you to save your progress, so you can take a break at any time without your answers being lost.',
        component: <AccountInfoContainer />,
      },
    ]
      .filter((question) =>
        question.domain ? question.domain === domain : true,
      )
      .map((question, index) => ({
        ...question,
        totalCount: undefined,
        current: index + 1,
      }));
  }, [domain]);

  const handleGoBack = useCallback(() => {
    setAnimation('back');
    dispatch(setGetStartedQuestion(currentQuestion - 1));
  }, [currentQuestion, dispatch]);

  useEffect(() => {
    setAnimation('forward');
  }, [currentQuestion]);

  useEffect(() => {
    if (isAuthenticated) {
      history.push(Routes.VERIFY_EMAIL);
    }
  }, [history, isAuthenticated]);

  useEffect(() => {
    // when question changes ensure that content is scrolled to top
    contentRef.current?.scrollToTop();
  }, [currentQuestion]);

  return (
    <QuestionLayout
      handleGoBack={handleGoBack}
      isGoBackHidden={currentQuestion === 0}
      questionData={QUESTIONS[currentQuestion]}
      customAnswer={QUESTIONS[currentQuestion].component}
      animation={animation}
      contentRef={contentRef}
    />
  );
};

export default GetStartedPage;
