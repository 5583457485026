import React, { useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useSelector } from 'react-redux';
import { Header } from 'shared';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { IntroContainer } from 'modules/Results/containers/IntroContainer';
import { SymptomSectionContainer } from 'modules/Results/containers/SymptomSectionContainer';
import { RecommendationContainer } from 'modules/Results/containers/RecommendationContainer';
import { FlagsContainer } from 'modules/Results/containers/FlagsContainer';
import { ChatToAnObgynContainer } from 'modules/Results/containers/ChatToAnObgynContainer';
import { RECOMMENDATION_GROUP } from 'modules/Results/constants/recommendations';
import { Version } from '../Version';
import {
  getAssessmentData,
  getGreenFlags,
  getOrangeFlags,
  getRedFlags,
  getReportVersions,
} from '../../selectors';
import { SectionContainer, BaseDivider } from './styles';

export type ResultsLayoutProps = {
  recommendationGroup: string;
  handleRecommendationAdd: (id: number, isAdded: boolean) => void;
};

const ResultsLayout: React.FC<ResultsLayoutProps> = ({
  recommendationGroup,
  handleRecommendationAdd,
}) => {
  useEffect(() => {
    window.dispatchEvent(new CustomEvent('resize'));
  }, []);
  const assessmentData = useSelector(getAssessmentData);
  const reportVersion = useSelector(getReportVersions);
  const redFlags = useSelector(getRedFlags);
  const greenFlags = useSelector(getGreenFlags);
  const orangeFlags = useSelector(getOrangeFlags);

  const Divider = (
    <GridItem xs={12} lg={8}>
      <BaseDivider />
    </GridItem>
  );

  const chatToAnObgynSection = (
    <>
      <SectionContainer xs={12} lg={8}>
        <ChatToAnObgynContainer
          handleRecommendationAdd={handleRecommendationAdd}
        />
      </SectionContainer>
      {Divider}
    </>
  );

  const isRenderSymptoms = !![
    ...assessmentData.symptomsUnknown,
    assessmentData.symptomsHormonal,
    ...assessmentData.conditions,
  ].length;

  const isRenderFlags = !![...redFlags, ...orangeFlags, ...greenFlags].length;

  return (
    <IonPage>
      <Header pageName="Report" />
      <IonContent>
        <Container>
          <GridContainer justify="center">
            {/* INTRO */}
            <SectionContainer xs={12} lg={8}>
              <IntroContainer />
            </SectionContainer>
            {Divider}
            {/* CHAT TO AN OBGYN (NOT GROUP1)*/}
            {recommendationGroup !== RECOMMENDATION_GROUP.GROUP1 &&
              chatToAnObgynSection}
          </GridContainer>
        </Container>
        <Container $paddingRight={0}>
          <GridContainer justify="center">
            {/* RECOMMENDATION TABLE */}
            <SectionContainer xs={12}>
              <RecommendationContainer
                handleRecommendationAdd={handleRecommendationAdd}
              />
            </SectionContainer>
          </GridContainer>
        </Container>
        <Container $paddingBottom={60}>
          <GridContainer justify="center">
            {Divider}
            {/* CHAT TO AN OBGYN (GROUP1)*/}
            {recommendationGroup === RECOMMENDATION_GROUP.GROUP1 &&
              chatToAnObgynSection}
            {/* SYMPTOMS */}
            {isRenderSymptoms && (
              <>
                <SectionContainer xs={12} lg={8}>
                  <SymptomSectionContainer />
                </SectionContainer>
                {Divider}
              </>
            )}
            {/* FLAGS */}
            {isRenderFlags && (
              <>
                <SectionContainer xs={12} lg={8}>
                  <FlagsContainer />
                </SectionContainer>
                {Divider}
              </>
            )}
            {reportVersion && (
              <SectionContainer xs={12} lg={8}>
                <Version version={reportVersion} />
              </SectionContainer>
            )}
          </GridContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};

export default ResultsLayout;
