import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { PillRecommendation, RecommendationReducerState } from '../types';
import {
  RECOMMENDATION_GROUP,
  RECOMMENDATION_TYPE,
  UserFriendlyVersionKeys,
  VersionKeys,
} from '../constants';
import { selectAssessment } from './assessment';

const selectRecommendation: Selector<RootState, RecommendationReducerState> = (
  state,
) => state.recommendation;

export const getRecommendationType = createSelector(
  selectRecommendation,
  ({ isPOPPermitted, isCOCPermitted }) => {
    if (isCOCPermitted && isPOPPermitted) return RECOMMENDATION_GROUP.GROUP1;
    if (isPOPPermitted) return RECOMMENDATION_GROUP.GROUP2;
    return RECOMMENDATION_GROUP.GROUP3;
  },
);

export const getRecommendations = createSelector(
  selectRecommendation,
  (state) => state.recommendations,
);

export const getReferralRecommendation = createSelector(
  selectRecommendation,
  (state) => state.referralRecommendation,
);

export const getOrangeFlags = createSelector(
  selectAssessment,
  (state) => state.flagOrange,
);

export const getIsPOPPermitted = createSelector(
  selectRecommendation,
  (state) => state.isPOPPermitted,
);

export const getIsCOCPermitted = createSelector(
  selectRecommendation,
  (state) => state.isCOCPermitted,
);

export const getMEC = createSelector(
  selectRecommendation,
  (state) => state.mec,
);

export const getInfoRequiredFlags = createSelector(
  selectRecommendation,
  (state) => state.infoRequiredFlags,
);

export const getPillRecommendations = createSelector(
  selectRecommendation,
  (state) =>
    state.recommendations.filter(
      ({ recommendationType }) =>
        recommendationType === RECOMMENDATION_TYPE.PILL,
    ) as PillRecommendation[],
);

export const getVmpSlugs = createSelector(
  getPillRecommendations,
  (pillRecommendations) => pillRecommendations.map(({ slug }) => slug),
);

export const getIngredientSlugs = createSelector(
  getPillRecommendations,
  (pillRecommendations) => {
    return pillRecommendations.reduce((slugsList, { ingredients }) => {
      ingredients.forEach(({ slug }) => {
        if (!slugsList.includes(slug)) slugsList.push(slug);
      });
      return slugsList;
    }, [] as string[]);
  },
);

export const getBenefitsSlugs = createSelector(
  getPillRecommendations,
  (pillRecommendations) => {
    const slugs: Record<'my' | 'other' | 'type', string[]> = {
      my: [],
      other: [],
      type: [],
    };

    return pillRecommendations.reduce(
      (slugs, { slug: pillSlug, benefits, pillType }) => {
        if (!slugs.type.includes(pillType)) {
          slugs.type.push(pillType);
        }
        benefits.contraceptionSpecificBenefits.forEach(({ slug }) =>
          slugs.other.push(`${slug}-${pillSlug}`),
        );
        benefits.recommendationSpecificBenefits.forEach(({ issueSlug }) =>
          slugs.my.push(`${issueSlug}-${pillSlug}`),
        );
        return slugs;
      },
      slugs,
    );
  },
);

export const getRecomVersions = createSelector(
  selectRecommendation,
  selectAssessment,
  (recomState, reportState) => {
    const combinedVersionsObj = {
      ...recomState.versions,
      flags: reportState.versions?.flags,
    };
    return Object.keys(combinedVersionsObj).reduce<Record<string, string>>(
      (acc, versionKey) => {
        if (combinedVersionsObj[versionKey as VersionKeys]) {
          acc[UserFriendlyVersionKeys[versionKey as VersionKeys]] = <string>(
            combinedVersionsObj[versionKey as VersionKeys]
          );
        }
        return acc;
      },
      {},
    );
  },
);

export const getRecommendationErrors = createSelector(
  selectRecommendation,
  (state) => state.errors,
);
