import React, { ReactNode } from 'react';
import { Formik } from 'formik';
import { IonRow, IonCol } from '@ionic/react';
import {
  Container,
  BODY2,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import Routes from 'routes';
import { ContinueButton, Input, Form, Text, Link } from 'shared';
import AuthLayout from '../AuthLayout';
import { ForgotPasswordValues } from '../../types';
import * as yup from 'yup';

interface ForgotPasswordProps {
  initialValues: ForgotPasswordValues;
  onSubmit: (values: ForgotPasswordValues) => void;
  recoveryStage: number;
  isLinkValid: boolean;
}

const ForgotPasswordSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
});

const ForgotPassword: React.FC<ForgotPasswordProps> = ({
  onSubmit,
  initialValues,
  recoveryStage,
  isLinkValid,
}) => {
  const subTitle =
    recoveryStage === 0
      ? 'Enter your email below and we’ll send a link to reset your password.'
      : undefined;
  const cardContent = (
    <>
      <Formik
        onSubmit={onSubmit}
        initialValues={initialValues}
        validationSchema={ForgotPasswordSchema}
        validateOnMount
      >
        {({ values, errors, touched, isValid, submitForm }): ReactNode => (
          <Form>
            {!isLinkValid && recoveryStage === 0 && (
              <IonRow className="ion-margin-bottom">
                <IonCol offset="1" size="10" className="ion-text-center">
                  <Text
                    color="dark"
                    className="ion-text-center ion-margin-bottom"
                  >
                    The link you clicked has expired. Click the button below to
                    send a new link:
                  </Text>
                </IonCol>
              </IonRow>
            )}
            {recoveryStage === 0 ? (
              <IonRow>
                <Input
                  name="email"
                  required
                  autocomplete="on"
                  autofocus
                  placeholder="Email"
                  type="email"
                  inputmode="email"
                  value={values.email}
                  error={errors.email}
                  touched={touched.email}
                />
              </IonRow>
            ) : (
              <>
                <IonRow className="ion-margin-bottom">
                  <IonCol offset="1" size="10" className="ion-text-center">
                    <Text color="dark">
                      We’ve sent an email to
                      <Text fontWeight="bolder">{` ${values.email} `}</Text>
                      with a link your password.
                    </Text>
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin-bottom">
                  <IonCol offset="1" size="10" className="ion-text-center">
                    <Text color="dark">
                      If you don’t receive it in 1 minute click the button below
                      to resend it.
                    </Text>
                  </IonCol>
                </IonRow>
              </>
            )}
            <IonRow className="ion-justify-content-center">
              <ContinueButton
                text={recoveryStage === 0 ? 'Reset password' : 'Resend email'}
                disabled={!isValid}
                onClick={submitForm}
              />
            </IonRow>
          </Form>
        )}
      </Formik>
      <Container $marginTop={20}>
        <GridContainer justify="center">
          <GridItem>
            <BODY2 color="textSecondary">
              Back to{' '}
              <Link href={Routes.LOGIN} display="inline">
                login
              </Link>
            </BODY2>
          </GridItem>
        </GridContainer>
      </Container>
    </>
  );
  return (
    <AuthLayout
      title="Forgot password"
      subTitle={subTitle}
      cardContent={cardContent}
    />
  );
};

export default ForgotPassword;
