import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { Header } from 'shared';
import { DeleteAccountContainer } from 'modules/Account/containers';
import {
  MarketingPreferenceContainer,
  PersonalInfoContainer,
} from '../../containers';

export const AccountLayout: React.FC = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <IonPage>
      <Header pageName="My account" />
      <IonContent>
        <Container $marginTop={isMobile ? 32 : 64} $marginBottom={40}>
          <GridContainer alignItems="center" justify="center">
            <GridItem xs={12} md={6} lg={4}>
              <GridContainer direction="column">
                <GridItem xs={12}>
                  <PersonalInfoContainer />
                </GridItem>
                <GridItem xs={12}>
                  <Box mt={3}>
                    <MarketingPreferenceContainer />
                  </Box>
                </GridItem>
                <GridItem xs={12}>
                  <DeleteAccountContainer />
                </GridItem>
              </GridContainer>
            </GridItem>
          </GridContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};
