import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { Navbar } from 'shared';
import {
  BODY1,
  Button,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { StartContainer } from './styles';

type StartLayoutProps = {
  handleNext: () => void;
};

export const StartLayout: React.FC<StartLayoutProps> = ({ handleNext }) => {
  const [startHA] = useWebpImage('start_ha.jpg');

  return (
    <IonPage>
      <Navbar />
      <IonContent>
        <StartContainer fixed={false} maxWidth="xl">
          <GridContainer justify="center">
            <GridItem xs={12} md={10} lg={6}>
              <img alt="start health assessment" src={startHA} />
              <H3 paragraph>Account activated! Let’s get started...</H3>
              <H3 paragraph>
                We’ll ask you about your health and wellbeing to generate your
                personal recommendations and report.
              </H3>
              <BODY1 color="textSecondary">
                This will take about 20–40 minutes.
              </BODY1>
              <Button onClick={handleNext}>Start</Button>
            </GridItem>
          </GridContainer>
        </StartContainer>
      </IonContent>
    </IonPage>
  );
};
