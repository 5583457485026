import React from 'react';
import { Theme, useMediaQuery } from '@material-ui/core';
import { InfoComponentProps } from 'modules/Questionnaire/containers/Answers/InfoContainer';
import {
  BODY1,
  Button,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { useWebpImage } from 'utils/image';
import { LandingContainer, ListItem, ListItemIndicator } from './styles';
import { DOMAIN_MAP } from 'utils/axios';
import { useDomain } from 'utils/hooks/useDomain';

const VALUES = [
  { key: 1, value: 'Take a complete deep-dive into your hormones and health' },
  {
    key: 2,
    value:
      'Get contraception recommendations personalized to you, your body and your goals',
  },
  {
    key: 3,
    value: 'Connect with one of our expert gynaecologists',
    domain: DOMAIN_MAP.uk,
  },
  {
    key: 3,
    value: 'Connect with one of our expert OBGYNs',
    domain: DOMAIN_MAP.us,
  },
  {
    key: 4,
    value: 'Continuously monitor your symptoms and hormones over time',
  },
];

export const LandingAnswer: React.FC<InfoComponentProps> = ({
  handleSubmit,
}) => {
  const domain = useDomain();
  const [landing] = useWebpImage('landing.jpg');
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm'),
  );

  const description =
    domain === DOMAIN_MAP.uk
      ? 'You’re about to get to know your hormones and your health, at the next level. In Tuune 360 you can:'
      : 'Thanks for participating in our study. This service is under usability testing and does not replace the medical advice of a clinician. Our aim for Tuune 360 is to allow you to:';

  return (
    <LandingContainer>
      <GridContainer justify="center">
        <GridItem xs={12} md={10} lg={6}>
          <img src={landing} alt="landing image" />
          <H3 paragraph>Welcome to Tuune 360! </H3>
          <BODY1 color="textSecondary">{description}</BODY1>
          {VALUES.filter((value) =>
            value.domain ? value.domain === domain : true,
          ).map(({ key, value }, idx) => (
            <ListItem
              key={key}
              startAdornment={<ListItemIndicator>{idx + 1}</ListItemIndicator>}
              content={<BODY1 color="textSecondary">{value}</BODY1>}
            />
          ))}
          <Button onClick={handleSubmit} fullWidth={isMobile}>
            get started
          </Button>
        </GridItem>
      </GridContainer>
    </LandingContainer>
  );
};
