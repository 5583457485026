import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Info: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 19 19" {...props}>
      <path d="M9.625 0.25C4.44782 0.25 0.25 4.44782 0.25 9.625C0.25 14.8022 4.44782 19 9.625 19C14.8022 19 19 14.8022 19 9.625C19 4.44782 14.8022 0.25 9.625 0.25ZM1.75 9.625C1.75 5.27624 5.27624 1.75 9.625 1.75C13.9738 1.75 17.5 5.27624 17.5 9.625C17.5 13.9738 13.9738 17.5 9.625 17.5C5.27624 17.5 1.75 13.9738 1.75 9.625ZM8.3125 7.5625C7.89829 7.5625 7.5625 7.89829 7.5625 8.3125C7.5625 8.72671 7.89829 9.0625 8.3125 9.0625H9.0625V13.1875H7.75C7.33579 13.1875 7 13.5233 7 13.9375C7 14.3517 7.33579 14.6875 7.75 14.6875H11.875C12.2892 14.6875 12.625 14.3517 12.625 13.9375C12.625 13.5233 12.2892 13.1875 11.875 13.1875H10.5625V8.3125C10.5625 7.89829 10.2267 7.5625 9.8125 7.5625H8.3125ZM8.9479 4.29915C9.14832 4.16523 9.38396 4.09375 9.625 4.09375C9.94823 4.09375 10.2582 4.22215 10.4868 4.45071C10.7153 4.67927 10.8438 4.98927 10.8438 5.3125C10.8438 5.55355 10.7723 5.78918 10.6384 5.9896C10.5044 6.19002 10.3141 6.34623 10.0914 6.43848C9.8687 6.53072 9.62365 6.55486 9.38723 6.50783C9.15082 6.46081 8.93366 6.34473 8.76321 6.17429C8.59277 6.00384 8.47669 5.78668 8.42967 5.55027C8.38264 5.31385 8.40678 5.0688 8.49902 4.8461C8.59127 4.62341 8.74748 4.43306 8.9479 4.29915Z" />
    </SvgIcon>
  );
};
