import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { DEFAULT_COUNTRY_OBJ } from 'utils/helpers';
import { AddressPayload } from 'modules/Account/types';
import {
  getAddressRequest,
  updateAddressRequest,
} from 'modules/Account/actions';
import { getIsLoading } from 'modules/Loading/selectors';
import { GET_ADDRESS, UPDATE_ADDRESS } from 'modules/Account/constants';
import {
  getAddressData,
  getUpdateShippingDetailsErrors,
} from 'modules/Account/selectors';
import { shippingDetailsSchema } from '../constants';
import { ShippingDetails } from '../components/ShippingDetails';
import { PageProps } from '../pages/CheckoutPage';
import { getIsShippingDetailsUpdated } from '../selectors';
import { setIsShippingDetailsUpdated } from '../actions';

export type ShippingDetailFormData = AddressPayload;

export const ShippingDetailsContainer: React.FC<PageProps> = ({
  handleNext,
}) => {
  const dispatch = useDispatch();

  const isAddressDataLoading = useSelector(getIsLoading(GET_ADDRESS));
  const isAddressUpdateLoading = useSelector(getIsLoading(UPDATE_ADDRESS));
  const addressData = useSelector(getAddressData);
  const shippingDetailsErrors = useSelector(
    getUpdateShippingDetailsErrors,
  ) as Record<string, string>;
  const isShippingDetailsUpdated = useSelector(getIsShippingDetailsUpdated);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    reset,
    setError,
  } = useForm<ShippingDetailFormData>({
    resolver: yupResolver(shippingDetailsSchema),
    mode: 'onTouched',
    defaultValues: {
      country: DEFAULT_COUNTRY_OBJ.name,
    },
  });

  useEffect(() => {
    if (shippingDetailsErrors) {
      Object.keys(shippingDetailsErrors).forEach((errorKey) => {
        if (shippingDetailsErrors[errorKey]) {
          setError(errorKey as keyof ShippingDetailFormData, {
            message: shippingDetailsErrors[errorKey],
          });
        }
      });
    }
  }, [setError, shippingDetailsErrors]);

  const onSubmit = useCallback(
    (data) => {
      if (isDirty) {
        dispatch(updateAddressRequest(data));
      } else {
        dispatch(setIsShippingDetailsUpdated());
      }
    },
    [dispatch, isDirty],
  );

  useEffect(() => {
    if (isShippingDetailsUpdated) {
      handleNext && handleNext();
    }
  }, [isShippingDetailsUpdated, isDirty, handleNext]);

  useEffect(() => {
    if (isAddressDataLoading === undefined) {
      dispatch(getAddressRequest());
    }
  }, [dispatch, isAddressDataLoading]);

  useEffect(() => {
    if (
      isAddressDataLoading === 0 &&
      !shippingDetailsErrors &&
      isAddressUpdateLoading !== 1
    ) {
      reset(
        {
          ...addressData,
          country: addressData.country || DEFAULT_COUNTRY_OBJ.name,
        },
        { keepDirty: true },
      );
    }
  }, [
    addressData,
    isAddressDataLoading,
    reset,
    isAddressUpdateLoading,
    shippingDetailsErrors,
  ]);

  return (
    <ShippingDetails
      handleSubmit={handleSubmit(onSubmit)}
      control={control}
      errors={errors}
    />
  );
};
