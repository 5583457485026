import React from 'react';
import { BODY2, Container } from '@tuunetech/tuune-components';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { Title } from './styles';

type PageTitleProps = {
  title: string;
  description?: string;
};

export const PageTitle: React.FC<PageTitleProps> = ({ title, description }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Container disableGutters={!isMobile}>
      <Title align="left">{title}</Title>
      {description && (
        <BODY2 align="left" color="textSecondary">
          {description}
        </BODY2>
      )}
    </Container>
  );
};
