import React from 'react';
import {
  GridContainer,
  GridItem,
  H3,
  SymptomBlockProps,
} from '@tuunetech/tuune-components';
import { SymptomsContainer, SymptomBlock } from './styles';

export type SymptomSectionProps = {
  title: string;
  sectionMoreKey: string;
  issues: (SymptomBlockProps & { id: string })[];
  handleOpenMore?: (sectionMoreKey?: string) => () => void;
};

export const SymptomSection: React.FC<SymptomSectionProps> = ({
  title,
  issues,
  handleOpenMore,
  sectionMoreKey,
}) => {
  return (
    <SymptomsContainer disableGutters fixed={false}>
      <H3 onClick={handleOpenMore && handleOpenMore(sectionMoreKey)}>
        {title}
      </H3>
      <GridContainer>
        {issues.map(({ id, ...issue }) => (
          <GridItem key={id} xs={12} md={6} lg={4}>
            <SymptomBlock {...issue} />
          </GridItem>
        ))}
      </GridContainer>
    </SymptomsContainer>
  );
};
