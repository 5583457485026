import { DOMAIN_MAP, getCurrentDomain } from 'utils/axios';
const domain = getCurrentDomain();

const letter = domain === DOMAIN_MAP.us ? 'z' : 's';

export const LOADING_MESSAGE_1 = [
  'Congrats, you made it!',
  'Let’s find the best birth control for you,',
  'based on your answers...',
];

export const LOADING_MESSAGE_2 = [
  'Hold tight!',
  `We’re calculating your personali${letter}ed`,
  'recommendations and report...',
];

export const LOADING_MESSAGE_3 = [
  'When calculating your recommendation,',
  `we prioriti${letter}e your wellbeing`,
  'and your hormonal symptoms',
];

export const LOADING_MESSAGE_ERROR = [
  'Looks like something went wrong.',
  'If the issue persists, email us at help@tuune.com',
];

export const BUTTON_MESSAGE_LOADING = 'LOADING RESULTS...';

export const BUTTON_MESSAGE_READY = 'VIEW REPORT';

export const BUTTON_MESSAGE_ERROR = 'RETRY';
