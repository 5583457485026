import React from 'react';
import { IonCol } from '@ionic/react';
import { Check, Text } from 'shared';
import styles from './style.module.css';

export interface CycleOptionProps {
  text: { day: string; phase: string; desc: string };
  active: boolean;
  onClick: () => void;
}

const CycleOption: React.FC<CycleOptionProps> = ({
  text,
  active,
  onClick,
  ...props
}) => {
  return (
    <IonCol
      size="2"
      className={styles.cycleOption}
      onClick={(): void => onClick()}
      onKeyPress={(e): void => (e.key === 'Enter' ? onClick() : undefined)}
      tabIndex={0}
      {...props}
    >
      {/*<Text>{text.day}</Text>*/}
      <div className={styles.iconContainer}>
        <Check active={active} />
        <div className={styles.line} />
      </div>
      {/*<Text className={`${styles.phase} ion-text-center`}>{text.phase}</Text>*/}
      <Text fontSize={14} className={`${styles.desc} ion-text-center`}>
        {text.desc}
      </Text>
    </IonCol>
  );
};

export default CycleOption;
