import React, { useCallback, useState, useEffect } from 'react';
import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { H4, CAPTION, BODY2, Button } from '@tuunetech/tuune-components';
import { useDomain } from 'utils/hooks/useDomain';
import { DOMAIN_MAP } from 'utils/axios';
import { ContentfulDocument } from 'modules/Contentful/types';
import { RECOMMENDATION_CTA, REPORT_STATIC_DATA_SLUGS } from '../../constants';
import { UnsupportedFeatureDialog } from '../UnsupportedFeatureDialog';

export type ConsultationCardProps = {
  staticDataContent: Record<string, string>;
  staticData: ContentfulDocument;
  handleRecommendationAdd: (id: number, isAdded: boolean) => void;
  isAdded: boolean;
  id: number;
};

const ChatToAnObgyn: React.FC<ConsultationCardProps> = ({
  staticDataContent,
  staticData,
  handleRecommendationAdd,
  isAdded,
  id,
}) => {
  const domain = useDomain();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [UnsupportedPopupOpen, setUnsupportedPopupOpen] = useState(false);

  const [subTitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    const text = documentToPlainTextString(
      staticData[REPORT_STATIC_DATA_SLUGS.CHAT_CLINICAL_CONTENT]?.content,
    );
    const sections = text.split('\n\n');
    setSubtitle(sections[0]);
    setDescription(sections[1]);
  }, [staticData]);

  const handleAddClicked = useCallback(() => {
    if (domain === DOMAIN_MAP.us) {
      setUnsupportedPopupOpen(!UnsupportedPopupOpen);
      return;
    } else if (domain === DOMAIN_MAP.uk) {
      handleRecommendationAdd(id, isAdded);
    }
  }, [
    setUnsupportedPopupOpen,
    UnsupportedPopupOpen,
    domain,
    handleRecommendationAdd,
    isAdded,
    id,
  ]);

  const CTA = (
    <Button color="default" fullWidth={isMobile} onClick={handleAddClicked}>
      {domain === DOMAIN_MAP.us
        ? 'CHAT TO AN OBGYN'
        : isAdded
        ? RECOMMENDATION_CTA.added
        : RECOMMENDATION_CTA.notAdded}
    </Button>
  );

  return (
    <>
      <UnsupportedFeatureDialog
        onClose={() => setUnsupportedPopupOpen(false)}
        isOpen={UnsupportedPopupOpen}
      />
      <H4>{staticDataContent[REPORT_STATIC_DATA_SLUGS.CHAT_CLINICAL_TITLE]}</H4>
      <CAPTION>{subTitle}</CAPTION>
      <BODY2 $marginTop={8} $marginBottom={24} color="textSecondary">
        {description}
      </BODY2>
      {CTA}
    </>
  );
};

export default ChatToAnObgyn;
