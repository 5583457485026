import React, {
  ReactEventHandler,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { DrawerModal } from 'shared';
import { CircularProgress } from '@material-ui/core';
import { Divider, BODY1, Container, H3 } from '@tuunetech/tuune-components';
import { BENEFITS_TABS } from 'modules/Results/constants';
import { Tab, Tabs } from './styles';
import { BenefitsProps as BenefitsContainerProps } from '../../containers/BenefitsContainer';

type BenefitsProps = BenefitsContainerProps & {
  benefits: Record<string, { name: string; content: ReactNode }[]>;
  isLoading: boolean;
};

const Benefits: React.FC<BenefitsProps> = ({
  isBenefitsOpen,
  handleBenefitsOpen,
  handleBenefitsClose,
  benefits,
  isLoading,
}) => {
  const [activeTab, setActiveTab] = useState();
  const tabs = useMemo(() => {
    return BENEFITS_TABS.filter(({ key }) => benefits[key]?.length);
  }, [benefits]);

  const handleTabChange = useCallback((e, newTab) => {
    setActiveTab(newTab);
  }, []);

  return (
    <DrawerModal
      onClose={handleBenefitsClose}
      onOpen={(handleBenefitsOpen as unknown) as ReactEventHandler}
      open={isBenefitsOpen}
      stickyPart={
        isLoading ? undefined : (
          <>
            <Tabs
              value={activeTab || tabs[0]?.key}
              variant="fullWidth"
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {tabs.map(({ key, value }) => (
                <Tab key={key} value={key} label={value} />
              ))}
            </Tabs>
          </>
        )
      }
    >
      {isLoading ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          {!!tabs.length && (
            <H3 align="left">
              {
                tabs.find((tab) => tab.key === (activeTab || tabs[0]?.key))
                  ?.heading
              }
            </H3>
          )}
          <Divider $verticalMargin={24} />
          {benefits[activeTab || tabs[0]?.key]?.map(({ name, content }) => (
            <Container disableGutters $marginTop={24} key={name}>
              <BODY1 align="left" color="textPrimary" $marginBottom={8}>
                {name}
              </BODY1>
              {content}
            </Container>
          ))}
        </>
      )}
    </DrawerModal>
  );
};

export default Benefits;
