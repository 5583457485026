import React, { useMemo, useState, useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ContentTypeNames } from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import {
  getIsCOCPermitted,
  getIsPOPPermitted,
  getMEC,
  getRecommendationType,
  getInfoRequiredFlags,
} from '../selectors';
import {
  REPORT_STATIC_DATA_SLUGS,
  RECOMMENDATION_INTRO_SLUGS,
  ReportContentfulKeys as CK,
} from '../constants';
import { getFirstName } from 'modules/Account/selectors';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import Intro from '../components/Intro';
import { PillRecommendationInfoContainer } from '../containers';

export const IntroContainer: React.FC = ({}) => {
  const firstName = useSelector(getFirstName);
  const isPOPPermitted = useSelector(getIsPOPPermitted);
  const isCOCPermitted = useSelector(getIsCOCPermitted);
  const recommendationGroup = useSelector(getRecommendationType);
  const mecRedFlags = useSelector(getMEC);
  const infoRequiredFlags = useSelector(getInfoRequiredFlags);

  // Static data
  const staticData = useSelector(
    getArrayContentMapped(CK[ContentTypeNames.STATIC_CONTENT].reqId),
  );

  // OC suitability
  const ocSuitabilityData = useSelector(
    getArrayContentMapped(CK[ContentTypeNames.OC_SUITABILITY].reqId),
  );

  // Recommendation intro
  const recommendationIntroData = useSelector(
    getArrayContentMapped(CK[ContentTypeNames.RECOMMENDATION_INTRO].reqId),
  );

  const staticDataContent = useMemo(() => {
    return documentToPlainTextRecords(staticData);
  }, [staticData]);

  const ocSuitabilityContent = useMemo(() => {
    return documentToPlainTextRecords(ocSuitabilityData);
  }, [ocSuitabilityData]);

  const recommendationIntroContent = useMemo(() => {
    return documentToPlainTextRecords(recommendationIntroData);
  }, [recommendationIntroData]);

  const [pillRecommendationInfoSlug, setPillRecommendatoinInfoSlug] = useState(
    '',
  );

  const [
    isPillRecommendationInfoOpen,
    setPillRecommendationInfoOpen,
  ] = useState(false);

  const handlePillRecommendationInfoOpen = useCallback(
    (pillRecommendationInfoSlug) => {
      setPillRecommendatoinInfoSlug(pillRecommendationInfoSlug);
      setPillRecommendationInfoOpen(true);
    },
    [],
  );

  const handlePillRecommendationInfoClose = useCallback(() => {
    setPillRecommendationInfoOpen(false);
  }, []);

  return (
    <>
      <PillRecommendationInfoContainer
        contentSlug={pillRecommendationInfoSlug}
        isPillRecommendationInfoOpen={isPillRecommendationInfoOpen}
        handlePillRecommendationInfoClose={handlePillRecommendationInfoClose}
        handlePillRecommendationInfoOpen={handlePillRecommendationInfoOpen}
      />
      <Intro
        title={staticDataContent[REPORT_STATIC_DATA_SLUGS.CLINICAL_INTRO]}
        firstName={firstName}
        recommendationIntro={
          recommendationIntroContent[RECOMMENDATION_INTRO_SLUGS.GROUP_1_A]
        }
        ocSuitabilityContent={ocSuitabilityContent}
        isCOCPermitted={isCOCPermitted}
        isPOPPermitted={isPOPPermitted}
        recommendationGroup={recommendationGroup}
        mecRedFlags={mecRedFlags}
        infoRequiredFlags={infoRequiredFlags}
        handlePillRecommendationInfoOpen={handlePillRecommendationInfoOpen}
      />
    </>
  );
};
