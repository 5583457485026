import { ActionCreator, AnyAction } from 'redux';
import { getFromStorage, setToStorage } from 'utils/helpers';
import {
  GET_CURRENT_QUESTION_REQUEST,
  SUBMIT_QUESTION_REQUEST,
  SET_MEASURE_SYSTEM,
  GET_PREV_QUESTION_REQUEST,
  SET_GET_STARTED_QUESTION,
  REGISTER_TO_QUESTIONNAIRE_REQUEST,
  SET_PASSWORD_REQUEST,
  SUBMIT_FEEDBACK_REQUEST,
  SET_GET_STARTED_PRIVACY,
  SET_GET_STARTED_MARKETING,
  MARKETING,
  GET_FEEDBACK_REQUEST,
} from '../constants';
import { AnswerPayload, QuestionnaireActionTypes } from '../types';
import { AppThunk } from 'reduxStore';

export const getCurrentQuestionRequest: ActionCreator<QuestionnaireActionTypes> = (
  questionId,
) => ({
  type: GET_CURRENT_QUESTION_REQUEST,
  payload: { questionId },
});

export const getPrevQuestionRequest: ActionCreator<QuestionnaireActionTypes> = (
  questionId,
) => ({
  type: GET_PREV_QUESTION_REQUEST,
  payload: { questionId },
});

export const submitQuestionRequest: ActionCreator<QuestionnaireActionTypes> = ({
  questionId,
  questionTitle,
  currentId,
  answers,
  factsSlugs,
  facts,
  questionType,
}: AnswerPayload) => ({
  type: SUBMIT_QUESTION_REQUEST,
  payload: {
    questionId,
    questionTitle,
    currentId,
    answers,
    factsSlugs,
    facts,
    questionType,
  },
});

export const setMeasureSystem: ActionCreator<QuestionnaireActionTypes> = (
  system: string,
) => ({
  type: SET_MEASURE_SYSTEM,
  payload: { system },
});

export const setGetStartedMarketing: ActionCreator<QuestionnaireActionTypes> = (
  marketingOptions: Set<MARKETING>,
) => ({
  type: SET_GET_STARTED_MARKETING,
  payload: { marketingOptions },
});

export const setGetStartedQuestion: ActionCreator<QuestionnaireActionTypes> = (
  question: number,
) => ({
  type: SET_GET_STARTED_QUESTION,
  payload: { question },
});

export const setGetStartedPrivacy: ActionCreator<QuestionnaireActionTypes> = (
  options: string[],
) => ({
  type: SET_GET_STARTED_PRIVACY,
  payload: {
    options,
  },
});

export const registerToQuestionnaireRequest: ActionCreator<QuestionnaireActionTypes> = ({
  firstName,
  lastName,
  password,
  rePassword,
  email,
  privacyOptions,
  marketingOptions,
}) => ({
  type: REGISTER_TO_QUESTIONNAIRE_REQUEST,
  payload: {
    firstName,
    lastName,
    email,
    password,
    rePassword,
    privacyOptions,
    marketingOptions,
  },
});

export const setPasswordRequest: ActionCreator<QuestionnaireActionTypes> = ({
  password,
  confirmPassword,
}) => ({
  type: SET_PASSWORD_REQUEST,
  payload: {
    password,
    confirmPassword,
  },
});

export const getFeedbackRequest: ActionCreator<AnyAction> = () => ({
  type: GET_FEEDBACK_REQUEST,
});

export const submitFeedbackRequest: ActionCreator<QuestionnaireActionTypes> = (
  feedback: string,
) => ({
  type: SUBMIT_FEEDBACK_REQUEST,
  payload: {
    feedback,
  },
});

export const getMeasureSystemFromStorage = (
  defaultSystem: string,
): AppThunk => async (dispatch): Promise<void> => {
  const system = await getFromStorage('measureSystem');
  if (system) {
    dispatch(setMeasureSystem(system));
  } else {
    dispatch(setMeasureSystem(defaultSystem));
  }
};

export const setMeasureSystemToStorage = (
  system: string,
): AppThunk => async (): Promise<void> => {
  await setToStorage('measureSystem', system);
};
