import React, { useMemo } from 'react';
import { IonText } from '@ionic/react';
import styles from './style.module.css';

type TextProps = React.ComponentProps<typeof IonText> & {
  fontWeight?: string;
  fontSize?: number;
  className?: string;
};

const Text: React.FC<TextProps> = ({
  fontWeight,
  fontSize,
  children,
  className = '',
  ...props
}) => {
  const weight = useMemo(() => {
    switch (fontWeight) {
      case 'light':
      case 'normal':
      case 'bold':
      case 'bolder':
        return fontWeight + 'Weight';
      default:
        return '';
    }
  }, [fontWeight]);

  const size = useMemo(() => {
    switch (fontSize) {
      case 10:
      case 12:
      case 14:
      case 16:
      case 18:
      case 21:
        return 'size' + fontSize;
      default:
        return '';
    }
  }, [fontSize]);

  return (
    <IonText
      className={`${styles[weight] || ''} ${styles[size] || ''} ${className}`}
      style={{
        fontWeight: weight ? '' : weight,
        fontSize: size ? '' : `${fontSize}px`,
      }}
      {...props}
    >
      {children}
    </IonText>
  );
};

export default Text;
