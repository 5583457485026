import styled from 'styled-components';
import {
  DialogActions as MuiDialogActions,
  Dialog,
  Container,
  Chip,
  IconButton,
  Paper as MuiPaper,
  DialogTitle as MuiDialogTitle,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';
import CheckCircle from '@material-ui/icons/CheckCircle';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import { Button } from '@tuunetech/tuune-components';

export const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
`;

export const RecommendationDetailsDialog = styled(Dialog)`
  .MuiPaper-rounded {
    border-radius: 32px;
  }
`;

export const CheckCircleIcon = styled(CheckCircle)`
  color: ${({ theme }) => theme.palette.common.green[500]};
`;

export const CrossCircleIcon = styled(CancelRoundedIcon)`
  color: ${({ theme }) => theme.palette.common.red[500]};
`;

export const ChipContainer = styled(Container)`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  padding: 20px 0 30px;
`;

export const StatusChip = styled(Chip)<{
  $status: 'best' | 'current' | 'currentUnrecommended';
}>`
  border-radius: 15px;
  margin: 4px 4px 0 0;
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'best':
        return theme.palette.common.green[100];
      case 'current':
        return theme.palette.common.grey[300];
      case 'currentUnrecommended':
        return theme.palette.common.red[100];
      default:
        return theme.palette.common.green[100];
    }
  }};
`;

export const AddToPlanButton = styled(Button)<{
  $status: 'best' | 'current' | 'currentUnrecommended';
}>`
  color: ${({ theme }) => theme.palette.common.black};
  background-color: ${({ theme, $status }) => {
    switch ($status) {
      case 'best':
        return theme.palette.common.green[100];
      case 'current':
        return theme.palette.common.grey[300];
      case 'currentUnrecommended':
        return theme.palette.common.red[100];
      default:
        return theme.palette.common.green[100];
    }
  }};
  :hover {
    background-color: ${({ theme, $status }) => {
      switch ($status) {
        case 'best':
          return theme.palette.common.green[100];
        case 'current':
          return theme.palette.common.grey[300];
        case 'currentUnrecommended':
          return theme.palette.common.red[100];
        default:
          return theme.palette.common.green[100];
      }
    }};
  }
`;

export const LearnMoreButton = styled(Button)`
  color: ${({ theme }) => theme.palette.common.black};
  background-color: ${({ theme }) => theme.palette.common.grey[300]};
  :hover {
    background-color: ${({ theme }) => theme.palette.common.grey[300]};
  }
`;

export const CloseDialogIconButton = styled(IconButton)`
  position: absolute;
  right: 5px;
  top: 5px;
  color: ${({ theme }) => theme.palette.common.black};
`;

export const Paper = styled(MuiPaper)`
  padding-left: 0;
  padding-right: 0;
`;

export const DialogTitle = styled(MuiDialogTitle)`
  margin-bottom: 0;
`;

export const DialogContent = styled(MuiDialogContent)`
  margin-bottom: 16px;
`;
