import React, { ReactNode } from 'react';
import { IonCard } from '@ionic/react';

import styles from './style.module.css';

type Props = React.ComponentProps<typeof IonCard> & {
  children: ReactNode;
};

/*
 * Deprecated component.
 * TODO: delete when last dependencies are refactored: AuthLayout
 * */
const Card: React.FC<Props> = ({ children, ...props }) => (
  <IonCard class={styles.card} {...props}>
    {children}
  </IonCard>
);

export default Card;
