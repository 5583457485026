import {
  AnswerOption,
  AnswerPayload,
  BoolOption,
  ChoiceOption,
  Fact,
  PreviousAnswers,
} from '../types';
import { AnswerTypesMap, CYCLE_OPTIONS, MOOD_ICONS } from '../constants';

export const setActiveStateOnOptions = (
  options: AnswerOption[],
  previousAnswers: PreviousAnswers,
  isMultiChoice: boolean,
): AnswerOption[] => {
  const prevAnswer = previousAnswers[options[0].factId];
  const resultOptions = options.map((option) => ({
    ...option,
    isActive: isMultiChoice
      ? !!previousAnswers[option.factId]
      : option.value === prevAnswer,
  }));
  if (isMultiChoice) {
    const noneOfTheAbove = options.every(
      (option) =>
        previousAnswers[option.factId] === false || option.factId === -1,
    );
    if (noneOfTheAbove) {
      const noneOptionIndex = options.findIndex(
        (option) => option.factId === -1,
      );
      resultOptions[noneOptionIndex].isActive = true;
    }
  }
  return resultOptions;
};

export const mapOptionsFromFacts = (
  facts: Fact[],
  answerType: string,
  notaAnswer: string,
): AnswerOption[] => {
  let options: AnswerOption[];
  switch (facts.length) {
    case 0:
      throw Error('empty facts');
    case 1:
      const factAnswers = facts[0].answers;
      const factId = facts[0].id;
      if (Object.keys(factAnswers[0]).includes('id')) {
        // remap choice options
        if (answerType === AnswerTypesMap.SUBJECTIVE) {
          options = factAnswers.map(
            ({ option, id }: ChoiceOption, index: number) => ({
              option: { option, icon: MOOD_ICONS[index] },
              value: id,
              factId: factId,
              isActive: false,
            }),
          );
        } else {
          options = factAnswers.map(({ option, id }: ChoiceOption) => ({
            option,
            value: id,
            factId: factId,
            isActive: false,
          }));
        }
        break;
      }

      options = factAnswers.map((answer: BoolOption) => ({
        ...answer,
        isActive: false,
        factId,
      }));
      break;
    default:
      if (answerType === AnswerTypesMap.MULTI_CHOICE) {
        // remap multi choice
        options = facts.map((fact) => ({
          option: fact.name,
          value: false,
          isActive: false,
          factId: fact.id,
        }));
        options.push({
          option: notaAnswer,
          value: false,
          isActive: false,
          factId: -1,
        });
      } else {
        options = facts.map((fact) => ({
          option: CYCLE_OPTIONS[fact.name],
          value: false,
          isActive: false,
          factId: fact.id,
        }));
      }
      break;
  }

  return options;
};

export const getAnswersFromOptions = (
  options: AnswerOption[],
  isMultiChoice: boolean,
): AnswerPayload['answers'] => {
  if (isMultiChoice) {
    // delete none option from result sent to backend
    const noneOptionIndex = options.findIndex((option) => option.factId === -1);
    noneOptionIndex !== -1 && options.splice(noneOptionIndex, 1);
    return options.reduce((activeOptions: AnswerPayload['answers'], option) => {
      activeOptions.push({
        factId: option.factId,
        answer: option.isActive,
      });
      return activeOptions;
    }, []);
  } else {
    const activeOption = options.find((option) => option.isActive);
    if (!activeOption) {
      throw new Error('no active option found');
    }
    return [{ factId: activeOption.factId, answer: activeOption.value }];
  }
};

export const checkIsDirtyOption = (
  prevOptions: AnswerOption[],
  options: AnswerOption[],
): boolean => {
  return JSON.stringify(prevOptions) !== JSON.stringify(options);
};

export const getIsShowButton = (
  isMultiChoice: boolean,
  options: AnswerOption[],
): boolean => {
  if (isMultiChoice) {
    return true;
  } else {
    return options.some((option) => option.isActive);
  }
};
