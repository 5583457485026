import { isNotEmpty } from 'utils/helpers';
import * as yup from 'yup';
import { TextInputValue } from '../types';
import { PRIVACY } from './privacy';

export const validateSingleTextAnswer = ({
  answer,
}: TextInputValue): TextInputValue => {
  const errors: TextInputValue = {};

  const answerError = isNotEmpty('answer', answer);
  answerError && (errors.answer = answerError);

  return errors;
};

export const accountInfoSchema = yup.object().shape({
  firstName: yup.string().required('First name is a required field').max(255),
  lastName: yup.string().required('Surname is a required field').max(255),
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
  password: yup
    .string()
    .required()
    .min(8)
    .matches(/[0-9]/, 'must contain numbers')
    .matches(/[a-zA-Z]/, 'must contain letters'),
  rePassword: yup
    .string()
    .required('Confirm password is a required field')
    .test('rePassword', 'Passwords should match', function (value = '') {
      const { parent } = this;
      return parent.password === value;
    }),
});

export const privacySchema = yup.object().shape({
  [PRIVACY.acceptAnswersTruthful]: yup.boolean().isTrue(),
  [PRIVACY.acceptAssignedAtBirth]: yup.boolean().isTrue(),
  [PRIVACY.acceptDataUsage]: yup.boolean().isTrue(),
  [PRIVACY.acceptTerms]: yup.boolean().isTrue(),
  [PRIVACY.acceptTestingAgreement]: yup.boolean().isTrue(),
});
