import { ApiHost } from '../axios';
import {
  ACTIVATE_EMAIL_REQUEST,
  GET_USER_ACCESS_TOKEN_REQUEST,
  LOG_OUT_REQUEST,
  LOGIN_REQUEST,
  RESEND_ACTIVATION_REQUEST,
  RESET_PASSWORD_CONFIRM_REQUEST,
  RESET_PASSWORD_REQUEST,
} from 'modules/Auth/constants';
import {
  LoginPayload,
  ResetPasswordPayload,
  ResetPasswordConfirmPayload,
  ActivateEmailPayload,
  AuthRequestActionTypes,
} from 'modules/Auth/types';
import {
  MARKETING,
  PRIVACY,
  REGISTER_TO_QUESTIONNAIRE_REQUEST,
  SET_PASSWORD_REQUEST,
} from 'modules/Questionnaire/constants';
import {
  RegisterPayload,
  SetPasswordPayload,
} from 'modules/Questionnaire/types';
import { ApiReturnFunc } from './index';

const authApi: Record<AuthRequestActionTypes, ApiReturnFunc> = {
  [LOGIN_REQUEST]: ({ email, password }: LoginPayload) => ({
    url: `${ApiHost}/api/v1/auth/jwt/create/`,
    method: 'post',
    data: {
      email,
      password,
    },
    headers: {},
  }),
  [GET_USER_ACCESS_TOKEN_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/jwt/refresh/`,
      method: 'post',
      headers: {},
    };
  },
  [LOG_OUT_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/auth/jwt/logout/`,
      method: 'get',
      headers: {},
    };
  },
  [RESET_PASSWORD_REQUEST]: ({ email }: ResetPasswordPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/reset_password/`,
      method: 'post',
      data: {
        email,
      },
      headers: {},
    };
  },
  [RESEND_ACTIVATION_REQUEST]: ({ email }: ResetPasswordPayload) => {
    return {
      url: `${ApiHost}/api/v1/user/resend_activation/`,
      method: 'post',
      data: {
        email,
      },
    };
  },
  [RESET_PASSWORD_CONFIRM_REQUEST]: ({
    uid,
    token,
    password,
    confirmPassword,
  }: ResetPasswordConfirmPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/reset_password_confirm/`,
      method: 'post',
      data: {
        uid,
        token,
        new_password: password,
        re_new_password: confirmPassword,
      },
    };
  },
  [REGISTER_TO_QUESTIONNAIRE_REQUEST]: ({
    firstName,
    lastName,
    email,
    password,
    rePassword,
    privacyOptions,
    marketingOptions,
  }: RegisterPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/jwt/register/`,
      method: 'post',
      data: {
        email,
        first_name: firstName,
        last_name: lastName,
        password: password,
        re_password: rePassword,
        accept_answers_truthful: privacyOptions.includes(
          PRIVACY.acceptAnswersTruthful,
        ),
        accept_data_usage: privacyOptions.includes(PRIVACY.acceptDataUsage),
        accept_terms: privacyOptions.includes(PRIVACY.acceptTerms),
        accept_assigned_at_birth: privacyOptions.includes(
          PRIVACY.acceptAssignedAtBirth,
        ),
        accept_testing_agreement: privacyOptions.includes(
          PRIVACY.acceptTestingAgreement,
        ),
        [MARKETING.marketingPreference]: marketingOptions.has(
          MARKETING.marketingPreference,
        ),
        [MARKETING.researchPreference]: marketingOptions.has(
          MARKETING.researchPreference,
        ),
      },
      headers: {},
    };
  },
  [SET_PASSWORD_REQUEST]: ({
    password,
    confirmPassword,
  }: SetPasswordPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/set_password/`,
      method: 'post',
      data: {
        new_password: password,
        re_new_password: confirmPassword,
      },
      headers: {},
    };
  },
  [ACTIVATE_EMAIL_REQUEST]: ({ uid, token }: ActivateEmailPayload) => {
    return {
      url: `${ApiHost}/api/v1/auth/users/activation/`,
      method: 'post',
      data: {
        uid,
        token,
      },
    };
  },
};

export default authApi;
