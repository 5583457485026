import React, { useCallback } from 'react';
import { IonInput } from '@ionic/react';

import styles from './style.module.css';
import { useFormikContext } from 'formik';
import InputErrorMsg from '../InputErrorMsg/InputErrorMsg';

type Props = React.ComponentProps<typeof IonInput> & {
  error?: string;
  absoluteError?: boolean;
  name: string;
  touched?: boolean;
  containerClass?: string;
  innerClass?: string;
  withErrorMessage?: boolean;
};

/*
 * Deprecated component.
 * TODO: delete when last dependencies are refactored: Login, ResetPassword, ForgotPassword
 * */
const Input: React.FC<Props> = ({
  error,
  withErrorMessage = true,
  absoluteError = false,
  name,
  touched,
  containerClass = '',
  innerClass = '',
  min = '',
  max = '',
  ...props
}) => {
  const { setFieldValue, setFieldTouched } = useFormikContext();

  // Write custom input change handlers
  // because Ionic Custom Event and React Synthetic Event
  // types do not match
  const handleBlur = useCallback(
    (e) => {
      setFieldTouched(name, true);

      const value = e.target.value;
      setFieldValue(name, value);
    },
    [name, setFieldTouched, setFieldValue],
  );

  const handleInput = useCallback(
    (e) => {
      const value = e.detail.target ? e.detail.target.value : e.detail.value;
      setFieldValue(name, value);
    },
    [name, setFieldValue],
  );

  return (
    <div className={`${styles.inputContainer} ${containerClass}`}>
      <IonInput
        className={`${styles.input} ${innerClass} ${
          touched && error ? styles.error : ''
        }`}
        name={name}
        onIonBlur={handleBlur}
        onIonInput={handleInput}
        onIonChange={handleInput}
        min={min}
        max={max}
        {...props}
      />
      {withErrorMessage && (
        <InputErrorMsg
          isVisible={!!(touched && error)}
          error={error}
          absolutePosition={absoluteError}
        />
      )}
    </div>
  );
};

export default Input;
