import styled from 'styled-components';
import {
  Tab as MuiTab,
  Tabs as MuiTabs,
  TypographyStyle,
} from '@material-ui/core';

export const Tabs = styled(MuiTabs)`
  ::after {
    content: '';
    display: block;
    position: absolute;
    bottom: 2px;
    height: 2px;
    width: 100%;
    background-color: ${({ theme }) => theme.palette.common.purple[100]};
  }
  margin-bottom: 40px;
  .MuiTabs-indicator {
    height: 6px;
    bottom: 0;
  }
`;

export const Tab = styled(MuiTab)`
  text-transform: none;
  letter-spacing: normal;
  align-items: flex-end;
  padding-bottom: 12px;
  font-weight: ${({ theme }) => theme.typography.body2.fontWeight};
  font-size: ${({ theme }) => {
    const mobileTypography = theme.typography.body2[
      theme.breakpoints.up('sm')
    ] as TypographyStyle;
    return mobileTypography.fontSize;
  }};
  line-height: ${({ theme }) => {
    const mobileTypography = theme.typography.body2[
      theme.breakpoints.up('sm')
    ] as TypographyStyle;
    return mobileTypography.lineHeight;
  }};
  ${({ theme }) => theme.breakpoints.up('sm')} {
    font-size: ${({ theme }) => theme.typography.body2.fontSize};
    line-height: ${({ theme }) => theme.typography.body2.lineHeight};
  }
`;
