import React, { useCallback, ReactNode } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Formik } from 'formik';
import { IonContent, IonPage, IonRow } from '@ionic/react';
import { Input, Form, Navbar } from 'shared';
import { Button, GridContainer, H3 } from '@tuunetech/tuune-components';
import Routes from 'routes';
import { StartContainer, ItemContainer } from './styles';
import { resendActivationRequest } from 'modules/Auth/actions';
import * as yup from 'yup';

const ResendActivationSchema = yup.object().shape({
  email: yup
    .string()
    .required('Email is a required field')
    .email('Email is not valid'),
});

export const ResendCustomEmailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const onSubmit = useCallback(
    ({ email }) => {
      dispatch(resendActivationRequest({ email }));
      history.push(Routes.PUBLIC_VERIFY_EMAIL);
    },
    [dispatch, history],
  );

  const initialValues = {
    email: '',
  };

  return (
    <IonPage>
      <Navbar />
      <IonContent>
        <StartContainer fixed={false} maxWidth="xl">
          <GridContainer justify="center">
            <ItemContainer xs={12} md={10} lg={6}>
              <H3 paragraph>There was a problem with your activation link.</H3>
              <H3 paragraph>Enter your email to get a new one.</H3>
              <Formik
                onSubmit={onSubmit}
                initialValues={initialValues}
                validationSchema={ResendActivationSchema}
                validateOnMount
              >
                {({
                  values,
                  errors,
                  touched,
                  isValid,
                  submitForm,
                }): ReactNode => (
                  <Form>
                    <IonRow className="ion-margin-bottom">
                      <Input
                        name="email"
                        required
                        autocomplete="on"
                        autofocus
                        placeholder="Email"
                        type="email"
                        inputmode="email"
                        value={values.email}
                        error={errors.email}
                        touched={touched.email}
                      />
                    </IonRow>
                    <IonRow>
                      <Button disabled={!isValid} onClick={submitForm}>
                        send activation link
                      </Button>
                    </IonRow>
                  </Form>
                )}
              </Formik>
            </ItemContainer>
          </GridContainer>
        </StartContainer>
      </IonContent>
    </IonPage>
  );
};
