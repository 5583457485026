export enum RECOMMENDATION_GROUP {
  GROUP1 = 'group_1',
  GROUP2 = 'group_2',
  GROUP3 = 'group_3',
}

export const RECOMMENDATION_SUMMARY = {
  [RECOMMENDATION_GROUP.GROUP1]:
    "We've found some contraceptive health recommendations just for you, we’ve prioritised the ones that will have the most positive impact on your health.",
  [RECOMMENDATION_GROUP.GROUP2]: {
    header: `${RECOMMENDATION_GROUP.GROUP2}_header`,
    content: `${RECOMMENDATION_GROUP.GROUP2}_content`,
  },
  [RECOMMENDATION_GROUP.GROUP3]: {
    header: `${RECOMMENDATION_GROUP.GROUP3}_header`,
    content: `${RECOMMENDATION_GROUP.GROUP3}_content`,
  },
};

export const CLINICIAN_RECOMMENDATION_CARD_DATA: Record<string, string> = {
  [RECOMMENDATION_GROUP.GROUP1]: 'chat_to_a_clinician_content-group-1',
  [RECOMMENDATION_GROUP.GROUP2]: 'chat_to_a_clinician_content-group-2-or-3',
  [RECOMMENDATION_GROUP.GROUP3]: 'chat_to_a_clinician_content-group-2-or-3',
  HOW_IT_WORKS: 'chat_to_a_clinician_how_it_works',
};

export const RECOMMENDATION_CALCULATION_SLUGS = {
  TITLE: 'recommendation_calculation_title',
  CONTENT: 'recommendation_calculation_content',
};

export const MY_BENEFITS_TAB = {
  key: 'my',
  value: 'My benefits',
  heading: 'Your benefits, explained:',
  isActive: false,
};

export const OTHER_BENEFITS_TAB = {
  key: 'other',
  value: 'Other benefits',
  heading: 'Other benefits',
  isActive: false,
};

export const BENEFITS_RISKS_TAB = {
  key: 'type',
  value: 'Reduced health risks',
  heading: 'Reduced health risks',
  isActive: false,
};

export const BENEFITS_TABS = [
  MY_BENEFITS_TAB,
  OTHER_BENEFITS_TAB,
  BENEFITS_RISKS_TAB,
];

export const REGIMEN_DETAILS_TAB = {
  key: 'details',
  value: 'Regimen details',
};

export const REGIMEN_SAFETY_TAB = {
  key: 'safety',
  value: 'Regimen safety & efficacy',
};
export const REGIMEN_TABS = [REGIMEN_DETAILS_TAB, REGIMEN_SAFETY_TAB];

export const PILL_TYPE_CODE = {
  chc: 'chc',
  pop: 'pop',
};

export const PILL_TYPE = {
  [PILL_TYPE_CODE.chc]: 'combined',
  [PILL_TYPE_CODE.pop]: 'progestogen-only',
};

export const RECOMMENDATION_TYPE = {
  PILL: 'pill',
  REFERRAL: 'referral',
};

export const HOW_TO_START_SUMMARY_SLUG = 'how_to_start_generic';

export const RECOMMENDATION_CTA = {
  added: 'REMOVE',
  notAdded: 'ADD TO PLAN',
};

export const STATUS_MAPPINGS = {
  best: 'Best available to you',
  current: 'Current pill',
  currentUnrecommended: 'Current pill',
};

export const PILL_TYPE_MAPPING = {
  chc: 'Combined pill',
  pop: 'Mini-pill',
};
