import amplitude, { Config } from 'amplitude-js';
import { ANALYTICS_EVENT } from './constants';
import { AnalyticsTrackData, AnalyticsUserData } from './types';

export const initAnalytics = (apiKey: string): void => {
  const config: Config = {
    disableCookies: true,
    includeFbclid: true,
    includeGclid: true,
    includeReferrer: true,
    includeUtm: true,
    trackingOptions: {
      ip_address: false,
      city: false,
      dma: false,
    },
  };
  amplitude.getInstance().init(apiKey, '', config);
};

export const track = (
  event: ANALYTICS_EVENT,
  data: AnalyticsTrackData,
  email?: string,
): void => {
  const internal = email?.includes('tuune.com');
  if (!internal) amplitude.getInstance().logEvent(event, data);
};

export const identify = (
  userId?: string | null,
  data?: AnalyticsUserData,
): void => {
  if (userId) {
    amplitude.getInstance().setUserId(userId);
  }
  if (data) {
    amplitude.getInstance().setUserProperties(data);
  }
};

export const logout = (): void => {
  amplitude.getInstance().setUserId(null);
  amplitude.getInstance().regenerateDeviceId();
};
