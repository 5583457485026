import React, { useCallback, useEffect, useState } from 'react';
import { getIsLoading } from 'modules/Loading/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import ResultsLayout from '../components/ResultsLayout';
import {
  getAssessmentFlagsRequest,
  getAssessmentResultsRequest,
  getRecommendationResultsRequest,
} from '../actions';
import Loading from '../components/Loading';
import {
  CLINICIAN_RECOMMENDATION_CARD_DATA,
  GET_ASSESSMENT_FLAGS,
  GET_ASSESSMENT_RESULTS,
  GET_RECOMMENDATION_RESULTS,
  getClinicalImpact,
  getHormonalImpact,
  HOW_TO_START_SUMMARY_SLUG,
  IMPACT_SCORE_SLUGS,
  OC_SUITABILITY_SLUGS,
  RECOMMENDATION_GROUP,
  RECOMMENDATION_INTRO_SLUGS,
  RECOMMENDATION_SUMMARY,
  RECOMMENDATION_TYPE,
  REPORT_MEC_FLAGS_SLUGS,
  REPORT_STATIC_DATA_SLUGS,
  OC_SUITABILITY_EXPLANATOIN_SLUGS,
  ReportContentfulKeys as RCK,
} from '../constants';
import {
  getAssessmentData,
  getRecommendationErrors,
  getRecommendations,
  getRecommendationType,
} from '../selectors';
import {
  deleteProductFromOrderRequest,
  postProductToOrderRequest,
} from 'modules/Checkout/actions';
import { RecommendationLayoutProps } from '../components/RecommendationLayout';
import { getOrderId } from 'modules/Checkout/selectors';
import { PillRecommendation } from '../types';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getEntries } from 'modules/Contentful/actions';
import { useDomain } from 'utils/hooks/useDomain';

const ResultsPage: React.FC = () => {
  const dispatch = useDispatch();
  const domain = useDomain();
  const history = useHistory();

  const isAssessmentLoading = useSelector(getIsLoading(GET_ASSESSMENT_RESULTS));
  const isRecommendationLoading = useSelector(
    getIsLoading(GET_RECOMMENDATION_RESULTS),
  );
  const errors = useSelector(getRecommendationErrors);
  const isFlagsLoading = useSelector(getIsLoading(GET_ASSESSMENT_FLAGS));
  const recommendations = useSelector(getRecommendations);
  const isRecommendedRegimenSummaryLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY].loadingId),
  );
  const isReportClinicianRecommendationLoading = useSelector(
    getIsLoading(RCK[CTN.CLINICIAN_RECOMMENDATION].loadingId),
  );
  const recommendationGroup = useSelector(getRecommendationType);
  const isRecommendedConsiderationsSummaryLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY].loadingId),
  );
  const isSwitchingContraceptionPreviewLoading = useSelector(
    getIsLoading(RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].loadingId),
  );
  const isStaticDataLoading = useSelector(
    getIsLoading(RCK[CTN.STATIC_CONTENT].loadingId),
  );
  const isEligibilityExplainLoading = useSelector(
    getIsLoading(RCK[CTN.CHC_UNSUITABILITY_EXPLANATION].loadingId),
  );
  const isSymptomsIssueSnippetSpecificLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_SNIPPET_SPECIFIC].loadingId),
  );
  const isOcSuitabilityDataLoading = useSelector(
    getIsLoading(RCK[CTN.OC_SUITABILITY].loadingId),
  );
  const isOcSuitabilityExplanationDataLoading = useSelector(
    getIsLoading(RCK[CTN.OC_SUITABILITY_EXPLANATION].loadingId),
  );
  const isRecommendationIntroDataLoading = useSelector(
    getIsLoading(RCK[CTN.RECOMMENDATION_INTRO].loadingId),
  );
  const isIssueSnippetGenericLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_SNIPPET_GENERIC].loadingId),
  );
  const isImpactScoreLoading = useSelector(
    getIsLoading(RCK[CTN.IMPACT_SCORE].loadingId),
  );
  const isImpactScoreExplainedLoading = useSelector(
    getIsLoading(RCK[CTN.IMPACT_SCORE_EXPLAINED].loadingId),
  );
  const isIssueIconsLoading = useSelector(
    getIsLoading(RCK[CTN.ISSUE_ICON].loadingId),
  );
  const isBenefitDetailsLoading = useSelector(
    getIsLoading(RCK[CTN.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED].loadingId),
  );
  const assessmentData = useSelector(getAssessmentData);

  useEffect(() => {
    if (errors && errors.status === 403) {
      history.replace(Routes.QUESTIONNAIRE);
    }
  }, [errors, history]);

  const [issueSlugs, setIssueSlugs] = useState<string[] | undefined>(undefined);

  useEffect(() => {
    // fetch data from backend
    if (isAssessmentLoading === undefined) {
      dispatch(getAssessmentResultsRequest());
    }
    if (isRecommendationLoading === undefined) {
      dispatch(getRecommendationResultsRequest());
    }
    if (isFlagsLoading === undefined) {
      dispatch(getAssessmentFlagsRequest());
    }

    if (isOcSuitabilityDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.OC_SUITABILITY],
          domain,
          slugs: Object.values(OC_SUITABILITY_SLUGS),
        }),
      );
    }

    if (isRecommendationIntroDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.RECOMMENDATION_INTRO],
          domain,
          slugs: Object.values(RECOMMENDATION_INTRO_SLUGS),
        }),
      );
    }

    // request static layout data
    if (isStaticDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.STATIC_CONTENT],
          domain,
          slugs: Object.values(REPORT_STATIC_DATA_SLUGS),
        }),
      );
    }

    // request static layout data
    if (isOcSuitabilityExplanationDataLoading === undefined) {
      dispatch(
        getEntries({
          ...RCK[CTN.OC_SUITABILITY_EXPLANATION],
          domain,
          slugs: Object.values(OC_SUITABILITY_EXPLANATOIN_SLUGS),
        }),
      );
    }

    if (isAssessmentLoading === 0) {
      if (
        isIssueSnippetGenericLoading === undefined &&
        isImpactScoreLoading === undefined &&
        isImpactScoreExplainedLoading === undefined &&
        isIssueIconsLoading === undefined
      ) {
        const issuesData = [
          ...assessmentData.conditions,
          ...assessmentData.symptomsHormonal,
          ...assessmentData.symptomsUnknown,
        ];

        const slugs: string[] = [];
        const impactSlugs = new Set<string>();
        issuesData.forEach(({ scores: { Hormonal, Clinical }, slug }) => {
          slugs.push(slug);
          const hormonalImpact = getHormonalImpact(Hormonal);
          const clinicalImpact = getClinicalImpact(Clinical);
          const hiSlug = IMPACT_SCORE_SLUGS.hormonal[hormonalImpact];
          const ciSlug = IMPACT_SCORE_SLUGS.clinical[clinicalImpact];
          impactSlugs.add(hiSlug);
          impactSlugs.add(ciSlug);
        });
        setIssueSlugs(slugs);

        // request impact score values and explained
        dispatch(
          getEntries({
            ...RCK[CTN.IMPACT_SCORE],
            domain,
            slugs: Array.from(impactSlugs),
          }),
        );
        dispatch(
          getEntries({
            ...RCK[CTN.IMPACT_SCORE_EXPLAINED],
            domain,
            slugs: Array.from(impactSlugs),
          }),
        );
        // request generic snippets for symptoms
        dispatch(
          getEntries({
            ...RCK[CTN.ISSUE_SNIPPET_GENERIC],
            domain,
            slugs: slugs,
          }),
        );
        // request icons
        dispatch(
          getEntries({
            ...RCK[CTN.ISSUE_ICON],
            domain,
            slugs: slugs,
          }),
        );
      }
    }

    if (isRecommendationLoading === 0) {
      if (isBenefitDetailsLoading === undefined && issueSlugs !== undefined) {
        const benefitDetailSlugs = recommendations
          .map((recommendation) =>
            issueSlugs.map(
              (issueSlug) => `${issueSlug}-${recommendation.slug}`,
            ),
          )
          .flat();

        dispatch(
          getEntries({
            ...RCK[CTN.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED],
            domain,
            slugs: benefitDetailSlugs,
          }),
        );
      }

      if (isEligibilityExplainLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.CHC_UNSUITABILITY_EXPLANATION],
            domain,
            slugs: [recommendationGroup],
          }),
        );
      }

      const pillRecommendations = recommendations.filter(
        ({ recommendationType }) =>
          recommendationType === RECOMMENDATION_TYPE.PILL,
      ) as PillRecommendation[];
      const vmpSlugs = pillRecommendations.map(({ slug }) => slug);

      // request recommended regimen summary
      if (isRecommendedRegimenSummaryLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY],
            domain,
            slugs: vmpSlugs,
          }),
        );
      }

      // request clinician recommendation
      if (isReportClinicianRecommendationLoading === undefined) {
        const slugs = [
          CLINICIAN_RECOMMENDATION_CARD_DATA[recommendationGroup],
          CLINICIAN_RECOMMENDATION_CARD_DATA.HOW_IT_WORKS,
        ];
        if (recommendationGroup !== RECOMMENDATION_GROUP.GROUP1) {
          slugs.push(RECOMMENDATION_SUMMARY[recommendationGroup].header);
          slugs.push(RECOMMENDATION_SUMMARY[recommendationGroup].content);
          slugs.push(REPORT_MEC_FLAGS_SLUGS[recommendationGroup]);
        }
        dispatch(
          getEntries({
            ...RCK[CTN.CLINICIAN_RECOMMENDATION],
            domain,
            slugs: slugs,
          }),
        );
      }

      // request considerations summary
      if (isRecommendedConsiderationsSummaryLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY],
            domain,
            slugs: vmpSlugs,
          }),
        );
      }

      // request switching contraception summary
      if (isSwitchingContraceptionPreviewLoading === undefined) {
        dispatch(
          getEntries({
            ...RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC],
            domain,
            slugs: [HOW_TO_START_SUMMARY_SLUG],
          }),
        );
      }
    }
  }, [
    dispatch,
    domain,
    isRecommendationLoading,
    isFlagsLoading,
    recommendations,
    isRecommendedRegimenSummaryLoading,
    isRecommendedConsiderationsSummaryLoading,
    recommendationGroup,
    isReportClinicianRecommendationLoading,
    isSwitchingContraceptionPreviewLoading,
    isStaticDataLoading,
    assessmentData,
    isAssessmentLoading,
    isEligibilityExplainLoading,
    isSymptomsIssueSnippetSpecificLoading,
    isOcSuitabilityDataLoading,
    isOcSuitabilityExplanationDataLoading,
    isRecommendationIntroDataLoading,
    isImpactScoreExplainedLoading,
    isImpactScoreLoading,
    isIssueSnippetGenericLoading,
    isIssueIconsLoading,
    issueSlugs,
    isBenefitDetailsLoading,
  ]);

  const isAssessmentClinicianRecommendationFinishedLoading =
    recommendationGroup === RECOMMENDATION_GROUP.GROUP1 ||
    isReportClinicianRecommendationLoading === 0;

  const isDataLoading = !(
    isRecommendationLoading === 0 &&
    isAssessmentLoading === 0 &&
    isFlagsLoading === 0 &&
    isStaticDataLoading === 0 &&
    isEligibilityExplainLoading === 0 &&
    isRecommendedRegimenSummaryLoading === 0 &&
    isRecommendedConsiderationsSummaryLoading === 0 &&
    isSwitchingContraceptionPreviewLoading === 0 &&
    isOcSuitabilityDataLoading === 0 &&
    isOcSuitabilityExplanationDataLoading === 0 &&
    isRecommendationIntroDataLoading === 0 &&
    isAssessmentClinicianRecommendationFinishedLoading &&
    isBenefitDetailsLoading === 0
  );

  const [isReady, setIsReady] = useState<boolean>(false);
  const onReadyButtonClickCallback = useCallback(() => {
    setIsReady(true);
  }, []);
  const onRetryButtonClickCallback = useCallback(() => {
    // want to trigger a full refresh so that we try and fetch all the data from the server again
    window.location.reload();
  }, []);

  const orderId = useSelector(getOrderId);
  const handleRecommendationAdd = useCallback<
    RecommendationLayoutProps['handleRecommendationAdd']
  >(
    (id, isAdded) => {
      if (orderId) {
        const orderItem = {
          productId: id,
          orderId,
        };
        dispatch(
          isAdded
            ? deleteProductFromOrderRequest(orderItem)
            : postProductToOrderRequest(orderItem),
        );
      }
    },
    [orderId, dispatch],
  );

  return isReady ? (
    <ResultsLayout
      recommendationGroup={recommendationGroup}
      handleRecommendationAdd={handleRecommendationAdd}
    />
  ) : (
    <Loading
      isDataLoading={isDataLoading}
      onReadyButtonClickCallback={onReadyButtonClickCallback}
      onRetryButtonClickCallback={onRetryButtonClickCallback}
      errors={errors}
    />
  );
};

export default ResultsPage;
