import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { AuthReducerState } from '../types';
import { QuestionnaireReducerState } from '../../Questionnaire/types';

const selectAuth: Selector<RootState, AuthReducerState> = (state) => state.auth;

export const getIsAuthenticated = createSelector(
  selectAuth,
  (state) => state.isAuthenticated,
);

export const getResetPasswordStage = createSelector(
  selectAuth,
  (state) => state.resetPasswordStage,
);

export const getIsResetPasswordConfirmed = createSelector(
  selectAuth,
  (state) => state.isResetPasswordConfirmed,
);

export const getIsEmailActivated = createSelector(
  selectAuth,
  (state) => state.isEmailActivated,
);

export const getUnauthenticatedEmailAddress = createSelector(
  selectAuth,
  (state) => state.unauthenticatedEmailAddress,
);

export const passwordErrorsCombiner = (
  state: AuthReducerState | QuestionnaireReducerState,
): { [field: string]: string } => {
  const errors: { [field: string]: string } = {};
  // convert server snake case values to front-end camel case
  Object.keys(state.errors).forEach((key) => {
    if (key === 'new_password') {
      errors.password = state.errors[key].join('\n');
    } else if (key === 're_new_password') {
      errors.confirmPassword = state.errors[key].join('\n');
    } else {
      if (Array.isArray(state.errors[key])) {
        // It is necessary to check this because the back-end is not consistent
        // with the error messages format (sometimes they are arrays, sometimes they are objects)
        errors[key] = state.errors[key].join('\n');
      } else {
        errors[key] = 'unexpected';
      }
    }
  });

  return errors;
};

export const getAuthErrors = createSelector(selectAuth, passwordErrorsCombiner);
