import React, { MutableRefObject, ReactNode } from 'react';
import { IonPage, IonContent } from '@ionic/react';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import { Navbar, NavButton } from 'shared';
import backIcon from 'assets/icons/backIcon';
import { AnswerTypesMap } from 'modules/Questionnaire/constants';
import QuestionTitle from '../QuestionTitle';
import HelperBox, { HelpLabel } from '../HelperBox';
import AnswerContainer from '../../containers/AnswerContainer';
import { QuestionnaireReducerState, Section } from '../../types';
import TransitionAnimation from '../TransitionAnimation';
import { QuestionContainer } from './styles';

export interface QuestionLayoutProps {
  handleLogout?: () => void;
  handleGoBack?: () => void;
  isGoBackHidden?: boolean;
  questionData: Partial<QuestionnaireReducerState>;
  progress?: {
    current: number;
    currentSection: Section;
    sections: Section[];
  };
  animation?: string;
  customAnswer?: ReactNode;
  isPageHidden?: boolean;
  handleToggleHelperBox?: (isOpen: boolean) => void;
  isHelperBoxOpen?: boolean;
  contentRef?: MutableRefObject<HTMLIonContentElement | null>;
}

const QuestionLayout: React.FC<QuestionLayoutProps> = ({
  handleLogout,
  handleGoBack,
  isGoBackHidden = true,
  questionData,
  progress,
  animation,
  customAnswer: CustomAnswer,
  isPageHidden,
  handleToggleHelperBox,
  isHelperBoxOpen,
  contentRef,
}) => {
  return (
    <IonPage hidden={isPageHidden}>
      <Navbar
        progress={progress}
        left={
          <NavButton
            text="Back"
            icon={backIcon}
            handleClick={isGoBackHidden ? undefined : handleGoBack}
          />
        }
        right={
          <NavButton
            text="Save & sign out"
            icon={<ExitToAppRoundedIcon />}
            withIcon={false}
            handleClick={handleLogout}
          />
        }
      />
      <IonContent ref={contentRef}>
        <HelperBox
          text={questionData.helpText}
          isOpen={isHelperBoxOpen}
          handleToggleHelperBox={handleToggleHelperBox}
        />
        <TransitionAnimation
          animation={animation}
          duration={500}
          key={questionData.questionTitle}
        >
          <QuestionContainer
            disableGutters
            $alignVertical={questionData.questionType === AnswerTypesMap.INFO}
            $paddingBottom={40}
          >
            {questionData.questionTitle && (
              <QuestionTitle
                title={questionData.questionTitle}
                subTitle={questionData.subTitle}
                helpLabel={
                  questionData.helpText ? (
                    <HelpLabel
                      label={/* questionData.helpLabel */ undefined}
                      handleToggleHelperBox={handleToggleHelperBox}
                    />
                  ) : undefined
                }
              />
            )}
            {CustomAnswer ? CustomAnswer : <AnswerContainer />}
          </QuestionContainer>
        </TransitionAnimation>
      </IonContent>
    </IonPage>
  );
};

export default QuestionLayout;
