import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import {
  H2,
  Button,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { IonImg } from '@ionic/react';

export const BackgroundContainer = styled(GridContainer)`
  background-color: ${({ theme }) => theme.palette.common.purple[700]};
  width: 100%;
  height: 100vh;
  overflow-y: hidden;
  margin: 0;
`;

export const ContentContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;

export const Message = styled(H2)`
  color: ${({ theme }) => theme.palette.common.white};
  text-align: center;
`;

export const TopMoonImage = styled(IonImg)`
  margin-bottom: auto;
`;

export const BottomMoonImage = styled(IonImg)`
  margin-top: auto;
`;

export const LoadingButton = styled(Button)`
  background-color: ${({ theme }) => theme.palette.common.white};
  color: ${({ theme }) => theme.palette.common.black};
  margin-top: 20px;
  :hover {
    background-color: ${({ theme }) => theme.palette.common.white};
  }
`;

export const LoadingIcon = styled(CircularProgress)`
  color: ${({ theme }) => theme.palette.common.black};
`;
