import { Reducer } from 'redux';
import { RecomModalsReducerState } from '../types';
import {
  SET_BENEFITS_CURRENT,
  SET_PILL_INFO_CURRENT,
  SET_REGIMEN_CURRENT,
  SET_SYMPTOM_CURRENT,
} from '../constants';

const defaultState: RecomModalsReducerState = {
  pillInfo: undefined,
  symptom: undefined,
  benefits: undefined,
  regimen: undefined,
};

const recomModalsReducer: Reducer<RecomModalsReducerState> = (
  state = defaultState,
  { type, payload },
) => {
  switch (type) {
    case SET_PILL_INFO_CURRENT:
      return {
        ...state,
        pillInfo: payload.pillInfo,
      };
    case SET_SYMPTOM_CURRENT:
      return {
        ...state,
        symptom: payload.symptom,
      };
    case SET_BENEFITS_CURRENT:
      return {
        ...state,
        benefits: payload.benefits,
      };
    case SET_REGIMEN_CURRENT:
      return {
        ...state,
        regimen: payload.regimen,
      };
    default:
      return state;
  }
};

export default recomModalsReducer;
