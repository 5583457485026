import styled from 'styled-components';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
} from '@material-ui/core';

export const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
`;

export const DialogContent = styled(MuiDialogContent)`
  margin-bottom: 1em;
`;

export const Progress = styled.div`
  display: flex;
  padding: 45px 0;
  justify-content: center;
  align-items: center;
`;
