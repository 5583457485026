import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import { Box } from './Box';

export const Visa: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <rect
        x="0.5"
        y="0.5"
        width="41.5"
        height="29"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.4378 19.8228H10.8631L8.93244 12.2404C8.8408 11.8916 8.64623 11.5833 8.36001 11.438C7.64573 11.0727 6.85864 10.7821 6 10.6355V10.3436H10.1476C10.72 10.3436 11.1493 10.7821 11.2209 11.2914L12.2227 16.7608L14.7961 10.3436H17.2992L13.4378 19.8228ZM18.7303 19.8228H16.2987L18.301 10.3436H20.7325L18.7303 19.8228ZM23.8784 12.9696C23.9499 12.4591 24.3792 12.1672 24.8801 12.1672C25.6672 12.0939 26.5246 12.2405 27.2401 12.6044L27.6694 10.5635C26.9539 10.2716 26.1668 10.125 25.4525 10.125C23.0925 10.125 21.3752 11.438 21.3752 13.2603C21.3752 14.6466 22.5917 15.3745 23.4503 15.813C24.3792 16.2503 24.737 16.5422 24.6655 16.9794C24.6655 17.6353 23.9499 17.9272 23.2356 17.9272C22.377 17.9272 21.5183 17.7086 20.7325 17.3434L20.3032 19.3856C21.1618 19.7495 22.0908 19.8961 22.9494 19.8961C25.5956 19.9681 27.2401 18.6564 27.2401 16.6875C27.2401 14.2081 23.8784 14.0628 23.8784 12.9696ZM35.75 19.8228L33.8193 10.3436H31.7455C31.3162 10.3436 30.8869 10.6355 30.7438 11.0727L27.1686 19.8228H29.6717L30.1713 18.4377H33.2469L33.5331 19.8228H35.75ZM32.1033 12.8962L32.8176 16.4688H30.8153L32.1033 12.8962Z"
        fill="#172B85"
      />
    </Box>
  );
};
