import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import { Box } from './Box';

export const Amex: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <rect width="42.5" height="30" rx="4" fill="#1F72CD" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.61897 10.625L3.64258 19.6834H8.40287L8.99301 18.2391H10.3419L10.9321 19.6834H16.1718V18.5811L16.6387 19.6834H19.3491L19.816 18.5578V19.6834H30.7133L32.0383 18.2766L33.279 19.6834L38.8761 19.6951L34.8871 15.1795L38.8761 10.625H33.3658L32.076 12.0058L30.8743 10.625H19.0195L18.0016 12.9631L16.9597 10.625H12.2094V11.6898L11.6809 10.625H7.61897ZM8.54005 11.9113H10.8604L13.498 18.0539V11.9113H16.0399L18.0771 16.3155L19.9546 11.9113H22.4838V18.4114H20.9448L20.9322 13.318L18.6886 18.4114H17.3119L15.0557 13.318V18.4114H11.8897L11.2895 16.9542H8.0468L7.44783 18.4101H5.75153L8.54005 11.9113ZM30.1495 11.9113H23.8918V18.4076H30.0526L32.0383 16.2546L33.9523 18.4076H35.953L33.045 15.1783L35.953 11.9113H34.0391L32.0634 14.0396L30.1495 11.9113ZM9.66885 13.0112L8.60049 15.6071H10.736L9.66885 13.0112ZM25.4371 14.4436V13.257V13.2559H29.3417L31.0455 15.1535L29.2662 17.0615H25.4371V15.7661H28.851V14.4436H25.4371Z"
        fill="white"
      />
    </Box>
  );
};
