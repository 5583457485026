import styled from 'styled-components';
import { H6 } from '@tuunetech/tuune-components';

export const ProfileItemHeader = styled(H6)`
  margin-top: 24px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 32px;
  }
`;
