import {
  ACTIVATE_EMAIL_FAIL,
  GET_USER_ACCESS_TOKEN_FAIL,
  LOGIN_FAIL,
  RESET_PASSWORD_CONFIRM_FAIL,
} from 'modules/Auth/constants';
import {
  SUBMIT_QUESTION_FAIL,
  REGISTER_TO_QUESTIONNAIRE_FAIL,
  SET_PASSWORD_FAIL,
} from 'modules/Questionnaire/constants';
import {
  CHECKOUT_FAIL,
  GET_VIVA_WALLET_TOKEN_FAIL,
  POST_PRODUCT_TO_ORDER_FAIL,
} from 'modules/Checkout/constants';
import { GET_RECOMMENDATION_RESULTS_FAIL } from 'modules/Results/constants';
import { UPDATE_ADDRESS_FAIL } from 'modules/Account/constants';
import { ErrorsList } from '../types';

// general errors common for the app
export const FALLBACK_ERROR_MESSAGE = 'Ooops! Something went wrong.';
export const AXIOS_NETWORK_ERROR_MESSAGE = 'Network Error';
export const NETWORK_ERROR_MESSAGE =
  'Seems that something is temporarily wrong with your network connection. Please make sure you are connected to the internet and try again.';
export const SESSION_EXPIRED_MESSAGE =
  'Sorry, your session is expired. Please Login again.';

export const ERROR_TYPE = {
  DEFAULT: 'default',
  INPUT: 'input',
};

export const STATUSES = {
  st401: '401',
  st400: '400',
  st403: '403',
  st404: '404',
  st500: '500',
};

// custom errors for separate parts of the app
// `default` value means use error message from response
// `input` value means don't show error popup, show error within input field
// string value is used when server error is not enough detailed
export const ACTION_ERRORS_LIST: ErrorsList = {
  [LOGIN_FAIL]: {
    [STATUSES.st401]: ERROR_TYPE.DEFAULT,
  },
  [RESET_PASSWORD_CONFIRM_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.INPUT,
  },
  [SET_PASSWORD_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.INPUT,
  },
  [GET_USER_ACCESS_TOKEN_FAIL]: {
    [STATUSES.st500]:
      "Sorry, something went wrong on our side. We can't check your session. Please login again.",
  },
  [ACTIVATE_EMAIL_FAIL]: {
    [STATUSES.st403]: 'Account already activated',
  },
  [REGISTER_TO_QUESTIONNAIRE_FAIL]: {
    [STATUSES.st400]: 'Sorry, we couldn’t create an account right now',
  },
  [POST_PRODUCT_TO_ORDER_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.DEFAULT,
    [STATUSES.st404]: ERROR_TYPE.DEFAULT,
  },
  [UPDATE_ADDRESS_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.INPUT,
  },
  [CHECKOUT_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.DEFAULT,
    // TODO: ideally replace error that asks users to reload the page with auto-refetching of token
    [STATUSES.st401]: 'Token expired. Please reload the page and try again',
  },
  [GET_VIVA_WALLET_TOKEN_FAIL]: {
    [STATUSES.st403]: ERROR_TYPE.DEFAULT,
  },
  [SUBMIT_QUESTION_FAIL]: {
    [STATUSES.st400]: ERROR_TYPE.INPUT,
  },
  [GET_RECOMMENDATION_RESULTS_FAIL]: {
    [STATUSES.st403]: ERROR_TYPE.INPUT,
  },
};
