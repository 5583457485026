import styled from 'styled-components';
import {
  BODY1,
  Button,
  Container,
  H3,
  ContentBlock,
} from '@tuunetech/tuune-components';

export const LandingContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;

  ${H3}, ${Button} {
    margin-top: ${({ theme }) => theme.spacing(4)}px;
  }

  ${H3} + ${BODY1} {
    margin-bottom: ${({ theme }) => theme.spacing(4)}px;
  }

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${({ theme }) => theme.spacing(7)}px;
    ${H3}, ${Button} {
      margin-top: ${({ theme }) => theme.spacing(5)}px;
    }

    ${H3} + ${BODY1} {
      margin-bottom: ${({ theme }) => theme.spacing(5)}px;
    }
  }

  img {
    width: 100%;
    border-radius: 40px;
  }
`;

export const ListItemIndicator = styled.div`
  width: 24px;
  height: 24px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 0.75rem;
  background: ${({ theme }) => theme.palette.primary.main};
  color: ${({ theme }) => theme.palette.common.white};
`;

export const ListItem = styled(ContentBlock)`
  margin-bottom: ${({ theme }) => theme.spacing(2)}px;
`;
