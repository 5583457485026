import React, { useEffect, useMemo } from 'react';
import { CircularProgress, SwipeableDrawerProps } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { getIsLoading } from 'modules/Loading/selectors';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { getEntries } from 'modules/Contentful/actions';
import { useDomain } from 'utils/hooks/useDomain';
import { DrawerModal } from 'shared';
import {
  getResultsContentfulOptions,
  RECOMMENDATION_CALCULATION_SLUGS,
} from '../constants';

export type HowRecommendationsMadeProps = {
  isHowRecommendationsMadeOpen: boolean;
  handleHowRecommendationsMadeOpen: SwipeableDrawerProps['onOpen'];
  handleHowRecommendationsMadeClose: SwipeableDrawerProps['onClose'];
};

const CK = {
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
};

export const HowRecommendationsMadeContainer: React.FC<HowRecommendationsMadeProps> = ({
  handleHowRecommendationsMadeOpen,
  handleHowRecommendationsMadeClose,
  isHowRecommendationsMadeOpen,
}) => {
  const dispatch = useDispatch();

  // get how recommendation calculated content and title
  const recommendationCalculation = useSelector(
    getArrayContentMapped(CK[CTN.STATIC_CONTENT].reqId),
  );
  const isRecommendationCalculationLoading = useSelector(
    getIsLoading(CK[CTN.STATIC_CONTENT].loadingId),
  );
  const domain = useDomain();

  useEffect(() => {
    // request how recommendation calculated title and content
    if (
      isHowRecommendationsMadeOpen &&
      isRecommendationCalculationLoading === undefined
    ) {
      dispatch(
        getEntries({
          ...CK[CTN.STATIC_CONTENT],
          domain,
          slugs: [RECOMMENDATION_CALCULATION_SLUGS.CONTENT],
        }),
      );
    }
  }, [
    dispatch,
    domain,
    isHowRecommendationsMadeOpen,
    isRecommendationCalculationLoading,
  ]);

  const recommendationCalculationContent = useMemo(() => {
    return documentToReactComponents(
      recommendationCalculation[RECOMMENDATION_CALCULATION_SLUGS.CONTENT]
        ?.content,
      getResultsContentfulOptions(),
    );
  }, [recommendationCalculation]);

  return (
    <DrawerModal
      onClose={handleHowRecommendationsMadeClose}
      onOpen={handleHowRecommendationsMadeOpen}
      open={isHowRecommendationsMadeOpen}
    >
      {isRecommendationCalculationLoading !== 0 ? (
        <CircularProgress color="primary" />
      ) : (
        recommendationCalculationContent
      )}
    </DrawerModal>
  );
};
