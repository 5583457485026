import React from 'react';
import {
  IonCol,
  IonContent,
  IonGrid,
  IonPage,
  IonRouterLink,
  IonRow,
} from '@ionic/react';
import { Card, CardContent, Text } from 'shared';
import { BODY2, H2 } from '@tuunetech/tuune-components';

import styles from './style.module.css';
import { useWebpImage } from 'utils/image';

interface AuthLayoutProps {
  title: string;
  subTitle?: string;
  bottomSection?: {
    text?: string;
    link?: {
      linkText: string;
      linkUrl: string;
    };
  };
  cardContent: React.ReactNode;
}

const AuthLayout: React.FC<AuthLayoutProps> = ({
  title,
  subTitle,
  bottomSection,
  cardContent,
}) => {
  const [authBg] = useWebpImage('auth_bg.png');

  return (
    <IonPage>
      <IonContent
        className={styles.authContainer}
        style={{ backgroundImage: `url(${authBg})` }}
      >
        <IonGrid fixed className={styles.ionGridVerticalAlign}>
          <IonRow className="ion-justify-content-center">
            <IonCol size="12" sizeSm="12" sizeMd="8" sizeXl="4">
              <Card className={styles.authCard}>
                <div className="ion-text-center">
                  <H2 color="textPrimary" $marginBottom={10}>
                    {title}
                  </H2>
                </div>
                {subTitle && (
                  <BODY2 align="center" color="textSecondary">
                    {subTitle}
                  </BODY2>
                )}
                <CardContent className={styles.cardContent}>
                  {cardContent}
                </CardContent>
              </Card>
            </IonCol>
          </IonRow>
          {bottomSection && (
            <IonRow>
              <IonCol>
                <p className="ion-text-center">
                  <Text fontSize={14}>
                    {bottomSection.text}{' '}
                    {bottomSection.link && (
                      <IonRouterLink
                        color="light"
                        href={bottomSection.link.linkUrl}
                      >
                        {bottomSection.link.linkText}
                      </IonRouterLink>
                    )}
                  </Text>
                </p>
              </IonCol>
            </IonRow>
          )}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default AuthLayout;
