import React, { ReactNode } from 'react';
import { Paper, useMediaQuery, useTheme } from '@material-ui/core';
import {
  BODY1,
  BODY2,
  Button,
  Container,
  Divider,
  GridContainer,
  GridItem,
  H4,
} from '@tuunetech/tuune-components';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Control, Controller } from 'react-hook-form';
import {
  CardBlock,
  RowGridItem,
  FreeLabel,
  ErrorBox,
  CheckboxWithLabel,
  Checkbox,
  Form,
} from './styles';
import { CheckoutReducerState } from '../../types';
import { PageProps } from '../../pages/CheckoutPage';
import { ButtonWrapper } from '../ContactDetail/styles';
import { ConfirmFormData } from '../../containers';

const CHECKBOXES: {
  name: 'willUseMedMyself' | 'willReferToLeaflet' | 'willAskClinician';
  label: string;
}[] = [
  {
    name: 'willUseMedMyself',
    label: 'The medication will only be used by myself.',
  },
  {
    name: 'willReferToLeaflet',
    label:
      'I will refer to the information leaflet of my medication before using it.',
  },
  {
    name: 'willAskClinician',
    label: 'I will get in touch with my clinician if I have any concerns.',
  },
];

export type ConfirmOrderProps = Pick<
  CheckoutReducerState,
  'ampp' | 'consultation' | 'total'
> &
  PageProps & {
    isError: boolean;
    control: Control<ConfirmFormData>;
    handleSubmit: () => void;
    // shows in what role component is used: page or card
    isPageRole: boolean;
  };

export const ConfirmOrder: React.FC<ConfirmOrderProps> = ({
  ampp,
  consultation,
  total,
  isError,
  control,
  handleSubmit,
  isPageRole,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('md'));

  const renderRow = ({ left, right }: Record<'left' | 'right', ReactNode>) => (
    <RowGridItem xs={12}>
      <GridContainer justify="space-between">
        <GridItem>{left}</GridItem>
        <GridItem>{right}</GridItem>
      </GridContainer>
    </RowGridItem>
  );

  return (
    <>
      <Paper>
        <Container
          disableGutters
          $verticalPadding={24}
          $horizontalPadding={isMobile ? 16 : 24}
        >
          <CardBlock>
            <H4 color="primary">My plan</H4>
          </CardBlock>
          <Divider $verticalMargin={24} />
          <CardBlock>
            <GridContainer>
              {ampp.map(({ salePrice, name }) => (
                <React.Fragment key={name}>
                  {renderRow({
                    left: (
                      <>
                        <BODY2 $marginBottom={8}>{name}</BODY2>
                        <BODY2 color="textSecondary">3 months supply</BODY2>
                      </>
                    ),
                    right: <BODY2>£{salePrice}</BODY2>,
                  })}
                </React.Fragment>
              ))}
              {consultation.map(({ salePrice, name }) => (
                <React.Fragment key={name}>
                  {renderRow({
                    left: (
                      <>
                        <BODY2 $marginBottom={8}>
                          1-on-1 chat to clinician
                        </BODY2>
                        <BODY2 color="textSecondary">{name}</BODY2>
                      </>
                    ),
                    right: <BODY2>£{salePrice}</BODY2>,
                  })}
                </React.Fragment>
              ))}
            </GridContainer>
          </CardBlock>
          <Divider $verticalMargin={24} />
          <CardBlock>
            <GridContainer>
              {renderRow({
                left: <BODY2>Subtotal</BODY2>,
                right: <BODY2>£{total}</BODY2>,
              })}
              {renderRow({
                left: <BODY2>VAT</BODY2>,
                right: <BODY2>Exempt / Included</BODY2>,
              })}
              {renderRow({
                left: <BODY2>Shipping</BODY2>,
                right: <FreeLabel>FREE</FreeLabel>,
              })}
            </GridContainer>
          </CardBlock>
          <Divider $verticalMargin={24} />
          <CardBlock>
            <GridContainer>
              {renderRow({
                left: <BODY1>Total</BODY1>,
                right: <BODY1>£{total}</BODY1>,
              })}
            </GridContainer>
          </CardBlock>
        </Container>
      </Paper>
      {isPageRole && (
        <Form onSubmit={handleSubmit}>
          <Container disableGutters={!isMobile}>
            <BODY2 color="textSecondary">
              Please check the boxes to confirm each of the following
              statements. All of them are required to proceed.
            </BODY2>
            {CHECKBOXES.map(({ label, name }) => (
              <Controller
                name={name}
                key={label}
                control={control}
                render={({ field }) => (
                  <CheckboxWithLabel
                    label={<BODY2 color="textSecondary">{label}</BODY2>}
                    control={<Checkbox color="primary" {...field} />}
                  />
                )}
              />
            ))}
            {isError && (
              <ErrorBox>
                <ErrorOutlineIcon color="error" />
                <BODY2 $marginLeft={8} $marginTop={4} color="error">
                  All boxes must be ticked in order to proceed.
                </BODY2>
              </ErrorBox>
            )}
            <ButtonWrapper $alignCenter>
              <Button onClick={handleSubmit}>PROCEED</Button>
            </ButtonWrapper>
          </Container>
        </Form>
      )}
    </>
  );
};
