import React from 'react';
import { IonIcon } from '@ionic/react';
import styles from './style.module.css';
import { alertCircleOutline } from 'ionicons/icons';
import { BODY2 } from '@tuunetech/tuune-components';

interface InputErrorMsgProps {
  isVisible: boolean;
  error?: string;
  absolutePosition?: boolean;
}

const InputErrorMsg: React.FC<InputErrorMsgProps> = ({
  isVisible,
  error,
  absolutePosition = false,
}) =>
  isVisible ? (
    <div
      className={`${styles.errorMsgContainer} ${
        absolutePosition ? styles.absolute : ''
      }`}
    >
      <IonIcon
        className={styles.errorIcon}
        icon={alertCircleOutline}
        color="danger"
      />
      <BODY2 className={`${styles.errorMsg}`} color="error" $paddingTop={3}>
        {error && error.charAt(0).toUpperCase() + error.slice(1)}
      </BODY2>
    </div>
  ) : null;

export default InputErrorMsg;
