import { Reducer } from 'redux';
import { GET_REGULATION_VERSIONS_SUCCESS } from '../constants';
import { RegulationReducerState } from '../types';

export const defaultState: RegulationReducerState = {
  versions: undefined,
  backendVersion: undefined,
};

const regulationReducer: Reducer<RegulationReducerState> = (
  state = defaultState,
  { type, response: { data = {} } = {} },
) => {
  switch (type) {
    case GET_REGULATION_VERSIONS_SUCCESS:
      return {
        ...state,
        versions: data.upload_data,
        backendVersion: data.build_number,
      };
    default:
      return state;
  }
};

export { regulationReducer as regulation };
