import countries from 'i18n-iso-countries';
import enLocale from 'i18n-iso-countries/langs/en.json';
import {
  AsYouType,
  CountryCode,
  getCountryCallingCode,
} from 'libphonenumber-js';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
export const countryToFlag = (isoCode: string): string => {
  return typeof String.fromCodePoint !== 'undefined'
    ? isoCode
        .toUpperCase()
        .replace(/./g, (char) =>
          String.fromCodePoint(char.charCodeAt(0) + 127397),
        )
    : isoCode;
};

countries.registerLocale(enLocale);

export const COUNTRY_ALPHA_2_CODES = {
  GB: 'GB',
  // US: 'US',
};

export type CountryObj = {
  code: string;
  name: string;
  phoneCode: string;
  flag: string;
};

export const AVAILABLE_COUNTRIES = Object.values(COUNTRY_ALPHA_2_CODES).map(
  (countryAlpha2Code) => {
    if (!countries.isValid(countryAlpha2Code)) {
      throw new Error('Alpha2 value probably changed, please check!');
    } else {
      return {
        code: countryAlpha2Code,
        name: countries.getName(countryAlpha2Code, 'en', {
          select: 'official',
        }),
        phoneCode: `+${getCountryCallingCode(
          countryAlpha2Code as CountryCode,
        )}`,
        flag: countryToFlag(countryAlpha2Code),
      };
    }
  },
);

// fallback to GB
export const DEFAULT_COUNTRY_OBJ = AVAILABLE_COUNTRIES[0];

export const getCountryObjByCode = (
  countryCode: string,
): CountryObj | undefined => {
  return AVAILABLE_COUNTRIES.find(({ code }) => code === countryCode);
};

export const getCountryObjByPhoneCode = (
  countryPhoneCode: string,
): CountryObj | undefined => {
  return AVAILABLE_COUNTRIES.find(
    ({ phoneCode }) => phoneCode === countryPhoneCode,
  );
};

export const getCountryObjByName = (
  countryName: string,
): CountryObj | undefined =>
  AVAILABLE_COUNTRIES.find(({ name }) => name === countryName);

export const getPhoneWithoutCallingCode = (
  phone: string,
  callingCode: string,
): string => {
  return phone.replace(callingCode, '').replace('+', '').trim();
};

const phoneAsYouType = new AsYouType();

export { countries, phoneAsYouType };
