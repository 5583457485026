import React, { ReactNode, useCallback } from 'react';
import { BODY2, Container } from '@tuunetech/tuune-components';
import { Info, HelpLabelContainer } from './styles';
import { DrawerModal } from 'shared';

interface HelperBoxProps {
  title?: string;
  text?: string | ReactNode;
  isOpen?: boolean;
  handleToggleHelperBox?: (isOpen: boolean) => void;
}

const HelperBox: React.FC<HelperBoxProps> = ({
  isOpen = false,
  text,
  handleToggleHelperBox,
}) => {
  const toggleHelperBox = useCallback(
    (event) => {
      event.stopPropagation();
      handleToggleHelperBox && handleToggleHelperBox(false);
    },
    [handleToggleHelperBox],
  );

  return (
    <DrawerModal
      onClose={toggleHelperBox}
      onOpen={toggleHelperBox}
      open={isOpen}
    >
      <Container disableGutters $marginTop={30}>
        {typeof text === 'string'
          ? text?.split('\\n').map((par) => (
              <BODY2 key={par} paragraph align="left" color="textSecondary">
                {par}
              </BODY2>
            ))
          : text}
      </Container>
    </DrawerModal>
  );
};

export default HelperBox;

type HelpLabelProps = {
  label?: string;
  handleToggleHelperBox?: (isOpen: boolean) => void;
};

export const HelpLabel: React.FC<HelpLabelProps> = ({
  label = 'Help',
  handleToggleHelperBox,
}) => {
  const toggleHelperBox = useCallback(
    (event) => {
      event.stopPropagation();
      handleToggleHelperBox && handleToggleHelperBox(true);
    },
    [handleToggleHelperBox],
  );

  return (
    <HelpLabelContainer onClick={toggleHelperBox}>
      <Info color="primary" />
      <BODY2 color="primary">{label}</BODY2>
    </HelpLabelContainer>
  );
};
