import React from 'react';
import { TitleProps } from 'shared/Title/Title';
import {
  BODY2,
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';

const QuestionTitle: React.FC<TitleProps> = ({
  title,
  subTitle,
  helpLabel,
}) => {
  return (
    <Container $marginBottom={24}>
      <GridContainer direction="column" alignItems="center">
        <GridItem lg={6}>
          <H3 $marginTop={40} align="center">
            {title}
          </H3>
        </GridItem>
        {subTitle && (
          <GridItem md={8} lg={6}>
            <BODY2 $marginTop={16} color="textSecondary" align="center">
              {subTitle}
            </BODY2>
          </GridItem>
        )}
        <GridItem>{helpLabel}</GridItem>
      </GridContainer>
    </Container>
  );
};

export default QuestionTitle;
