import { createGlobalStyle } from 'styled-components';
import { palette, typography, breakpoints } from '@tuunetech/tuune-components';

/*
 * GlobalStyle represents default styles we want ionic to use
 * */

export const GlobalStyle = createGlobalStyle`
  // NOTE: we possibly won't need css variables if use @tuunetech/tuune-components based on MUI
  /* KV: I'd say we should leave core ionic vars being defined with shared theme values (e.g. --ion-color-primary).
   * It will mean that any default ionic component used will follow shared theme.
   * At the same time we should remove as many as possible custom css vars and classes.
   */
  :root {
    /** new palette **/
    --purple-50: ${palette.colors.purple[50]};
    --purple-100: ${palette.colors.purple[100]};
    --purple-200: ${palette.colors.purple[200]};
    --purple-300: ${palette.colors.purple[300]};
    --purple-400: ${palette.colors.purple[400]};
    --purple-500: ${palette.colors.purple[500]};
    --purple-600: ${palette.colors.purple[600]};
    --purple-700: ${palette.colors.purple[700]};
    --purple-800: ${palette.colors.purple[800]};
    --purple-900: ${palette.colors.purple[900]};

    --red-50: ${palette.colors.red[50]};
    --red-100: ${palette.colors.red[100]};
    --red-200: ${palette.colors.red[200]};
    --red-300: ${palette.colors.red[300]};
    --red-400: ${palette.colors.red[400]};
    --red-500: ${palette.colors.red[500]};
    --red-600: ${palette.colors.red[600]};
    --red-700: ${palette.colors.red[700]};
    --red-800: ${palette.colors.red[800]};
    --red-900: ${palette.colors.red[900]};

    --yellow-50: ${palette.colors.yellow[50]};
    --yellow-100: ${palette.colors.yellow[100]};
    --yellow-200: ${palette.colors.yellow[200]};
    --yellow-300: ${palette.colors.yellow[300]};
    --yellow-400: ${palette.colors.yellow[400]};
    --yellow-500: ${palette.colors.yellow[500]};
    --yellow-600: ${palette.colors.yellow[600]};
    --yellow-700: ${palette.colors.yellow[700]};
    --yellow-800: ${palette.colors.yellow[800]};
    --yellow-900: ${palette.colors.yellow[900]};

    --green-50: ${palette.colors.green[50]};
    --green-100: ${palette.colors.green[100]};
    --green-200: ${palette.colors.green[200]};
    --green-300: ${palette.colors.green[300]};
    --green-400: ${palette.colors.green[400]};
    --green-500: ${palette.colors.green[500]};
    --green-600: ${palette.colors.green[600]};
    --green-700: ${palette.colors.green[700]};
    --green-800: ${palette.colors.green[800]};
    --green-900: ${palette.colors.green[900]};

    --grey-50: ${palette.colors.grey[50]};
    --grey-100: ${palette.colors.grey[100]};
    --grey-200: ${palette.colors.grey[200]};
    --grey-300: ${palette.colors.grey[300]};
    --grey-400: ${palette.colors.grey[400]};
    --grey-500: ${palette.colors.grey[500]};
    --grey-600: ${palette.colors.grey[600]};
    --grey-700: ${palette.colors.grey[700]};
    --grey-800: ${palette.colors.grey[800]};
    --grey-900: ${palette.colors.grey[900]};

    /** primary **/
    --ion-color-primary: ${palette.colors.purple[900]};
    --ion-color-primary-contrast: ${palette.colors.white};
    --ion-color-primary-shade: ${palette.colors.black};
    --ion-color-primary-tint: ${palette.colors.purple[100]};
    
    /** secondary **/
    --ion-color-secondary: ${palette.colors.red[600]};
    --ion-color-secondary-contrast: ${palette.colors.white};
    --ion-color-secondary-shade: ${palette.colors.red[900]};
    --ion-color-secondary-tint: ${palette.colors.red[400]};
    
    /** danger **/
    --ion-color-danger: ${palette.colors.red[900]};
    --ion-color-danger-contrast: ${palette.colors.white};
    --ion-color-danger-shade: ${palette.colors.red[900]};
    --ion-color-danger-tint: ${palette.colors.red[600]};
    
    /** dark **/
    --ion-color-dark: ${palette.colors.black};
    --ion-color-dark-contrast: ${palette.colors.white};
    --ion-color-dark-shade: ${palette.colors.black};

    /** medium **/
    --ion-color-medium: ${palette.colors.grey[600]};
    --ion-color-medium-contrast: ${palette.colors.white};
    --ion-color-medium-shade: ${palette.colors.black};
    --ion-color-medium-tint: ${palette.colors.grey};

    /** light **/
    --ion-color-light: ${palette.colors.white};
    --ion-color-light-contrast: ${palette.colors.black};
    --ion-color-light-shade: ${palette.colors.grey[300]};
    --ion-color-light-tint: ${palette.colors.white};
  
    /** Brand font-family **/
    --ion-font-family: ${typography.fontFamily};
    --ion-text-color: ${palette.colors.black};
  
  	/*
  	* START
  	* TODO: delete grid vars when all IonGrid, IonRow and IonCol replaced with shared Grid
  	*/
    /** Set up grid padding and width for the app **/
    --ion-grid-padding: 0;
    --ion-grid-width-xl: ${`${breakpoints.screenSizes.xl}px`};
    --ion-grid-width-lg: ${`${breakpoints.screenSizes.lg}px`};
    --ion-grid-width-md: ${`${breakpoints.screenSizes.md}px`};
    --ion-grid-width-sm: ${`${breakpoints.screenSizes.sm}px`};
    --ion-grid-width-xs: 100%;
    --ion-grid-column-padding: ${`${breakpoints.gutters.desktop}px`};
  
    --ion-grid-width: 100%;
  }
  
  @media (min-width: 1920px) {
    :root {
      --ion-grid-width: var(--ion-grid-width-xl);
    }
  }
  
  @media (min-width: 1280px) {
    :root {
      --ion-grid-width: var(--ion-grid-width-lg);
    }
  }
  
  @media (min-width: 960px) {
    :root {
      --ion-grid-width: var(--ion-grid-width-md);
    }
  }
  
  @media (min-width: 600px) {
    :root {
      --ion-grid-column-padding: ${`${breakpoints.gutters.mobile}px`};
      --ion-grid-width: var(--ion-grid-width-sm);
    }
  }
  /*
  * END
  */
  
  .ion-row-expand {
    width: 100%;
  }
  
  .ion-no-vertical-padding {
    padding-top: 0;
    padding-bottom: 0;
  }
  
  body,
  html {
    // base font-size. @tuunetech/tuune-components use 16px as a base and use rems for fontSizes
    font-size: 16px;
    font-weight: normal;
    font-family: ${typography.fontFamily};
  }
  
  // TODO: remove later when use @tuunetech/tuune-components typography: BODY1, H1, etc.
  /* styles for remove START */
  a:hover {
    text-decoration: underline;
  }
  /* styles for remove END */
  
  div:focus:not(:focus-visible) {
    outline: none;
  }
  
  div:focus-visible {}
  
  // NOTE: shadow root doesn't support scrollbar styling
  // styles will be applied in modals only
  ::-webkit-scrollbar {
    width: 9px;
    height: 3px;
    background-color: rgba(255,255,255,0);
  }
  
  ::-webkit-scrollbar-track,
  ::-webkit-scrollbar-thumb {
    border-left: 3px solid rgba(255,255,255,0);
    border-right: 3px solid rgba(255,255,255,0);
    background-clip: padding-box;
  }
  
  ::-webkit-scrollbar-track {
    background-color: ${palette.colors.purple[100]};
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: ${palette.colors.purple[600]};
    -webkit-border-radius: 6px;
  }
  
  ion-page,
  ion-app {
    // TODO: check if could be replaced with --ion-background-color
    background-color: rgba(255, 255, 255, 1);
  }
  
  .backdrop-hide {
    display: none;
  }
  
  // possibly better to place those styles in sharedButton component not to forget that it exists here
  ion-button {
    font-weight: bold;
    --box-shadow: none;
  }
  
  ion-button.md {
    --border-width: 1px !important;
  }
  
  ion-content {
    --background: var(--ion-color-lightpurple-tint);
  }
  
  ion-header {
    background: var(--ion-color-light);
  }
  
  ion-item {
    font-size: 14px;
  }
  
  ion-router-link {
    font-weight: bold;
    text-decoration: underline;
  }
  
  ion-list {
    padding: 0 !important;
  }
  
  /*
   * When ionic fix the issue remove style
   * ref: https://github.com/ionic-team/ionic/issues/18641
  */
  ion-picker {
    touch-action: none;
  }
  
  ion-badge {
    --padding-top: 5px;
  }
`;
