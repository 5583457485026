import { combineReducers, Reducer } from 'redux';
import { auth } from 'modules/Auth/reducers';
import { errorPopup } from 'modules/ErrorPopup/reducers';
import { account } from 'modules/Account/reducers';
import { questionnaire } from 'modules/Questionnaire/reducers';
import { contentful } from 'modules/Contentful/reducers';
import {
  assessment,
  recommendation,
  recomModals,
} from 'modules/Results/reducers';
import { loading } from 'modules/Loading/reducers';
import { checkout } from 'modules/Checkout/reducers';
import { regulation } from 'modules/Regulation/reducers';
import { LOG_OUT_SUCCESS } from 'modules/Auth/constants';

const appReducer = combineReducers({
  auth,
  account,
  errorPopup,
  questionnaire,
  contentful,
  assessment,
  recommendation,
  loading,
  recomModals,
  checkout,
  regulation,
});

const rootReducer: Reducer = (state, action) => {
  if (action.type === LOG_OUT_SUCCESS) {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
