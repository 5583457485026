import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { RecomModalsReducerState } from '../types';

const selectRecomModals: Selector<RootState, RecomModalsReducerState> = (
  state,
) => state.recomModals;

export const getPillInfoCurrent = createSelector(
  selectRecomModals,
  (state) => state.pillInfo,
);

export const getSymptomCurrent = createSelector(
  selectRecomModals,
  (state) => state.symptom,
);

export const getBenefitsCurrent = createSelector(
  selectRecomModals,
  (state) => state.benefits,
);

export const getRegimenCurrent = createSelector(
  selectRecomModals,
  (state) => state.regimen,
);
