import React from 'react';
import { ContinueButton } from 'shared';
import { PillOption } from 'modules/Questionnaire/types';
import {
  Autocomplete,
  BODY1,
  Container,
  GridContainer,
  GridItem,
  Input,
} from '@tuunetech/tuune-components';
import { Control, Controller, FieldErrors } from 'react-hook-form';
import {
  PillFormData,
  SkipChoice,
} from 'modules/Questionnaire/containers/Answers/PillContainer';

interface PillAnswerProps {
  pills: PillOption[];
  current: boolean;
  handleSubmit: () => void;
  handleSkip: (choice: SkipChoice) => void;
  control: Control<PillFormData>;
  errors: FieldErrors<PillFormData>;
  defaultValue?: string | null;
}

const PillAnswer: React.FC<PillAnswerProps> = ({
  pills,
  current,
  handleSubmit,
  handleSkip,
  control,
  errors,
  defaultValue,
}) => {
  return (
    <>
      <Container>
        <GridContainer justify="center">
          <GridItem xs={12} md={4}>
            <Controller
              control={control}
              name="pill"
              defaultValue={defaultValue}
              render={({ field: { onChange, onBlur, value } }) => {
                return (
                  <Autocomplete
                    options={pills.map(({ name }) => name)}
                    renderOption={(option) => <BODY1>{option}</BODY1>}
                    onChange={(e, newValue) => {
                      onChange({
                        ...e,
                        target: { value: newValue },
                      });
                    }}
                    onBlur={(e) => {
                      if (!(e.target as HTMLInputElement).value) {
                        onChange({ ...e, target: { value: defaultValue } });
                      }
                      onBlur();
                    }}
                    value={value}
                    renderInput={(params) => (
                      <Input
                        {...params}
                        name="pill"
                        placeholder="Select pill"
                        ref={params.InputProps.ref}
                        fullWidth
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: 'disabled',
                        }}
                        error={!!errors.pill}
                        helperText={
                          errors.pill && 'Please, select pill from the list'
                        }
                      />
                    )}
                  />
                );
              }}
            />
          </GridItem>
        </GridContainer>
      </Container>
      <ContinueButton onClick={handleSubmit} />
      <GridContainer justify="center">
        {!current && (
          <GridItem xs={6}>
            <ContinueButton
              align="right"
              variant="text"
              onClick={() => handleSkip('unknown')}
              text="Can't remember"
            />
          </GridItem>
        )}
        <GridItem xs={current ? 12 : 6}>
          <ContinueButton
            align={current ? 'center' : 'left'}
            variant="text"
            onClick={() => handleSkip('unlisted')}
            text="Not listed"
          />
        </GridItem>
      </GridContainer>
    </>
  );
};

export default PillAnswer;
