import React from 'react';
import { IonToast } from '@ionic/react';

interface ErrorPopupProps {
  isOpen: boolean;
  handleDismiss: (e: CustomEvent) => void;
  errorMessage?: string;
}

const ErrorPopup: React.FC<ErrorPopupProps> = ({
  handleDismiss,
  isOpen,
  errorMessage,
}) => (
  <IonToast
    onDidDismiss={handleDismiss}
    isOpen={isOpen}
    message={errorMessage}
    position="top"
    color="danger"
    buttons={[
      {
        text: 'Dismiss',
        role: 'cancel',
      },
    ]}
    duration={4000}
  />
);

export default ErrorPopup;
