import React, { ReactNode } from 'react';
import { IonCardContent } from '@ionic/react';

import styles from './style.module.css';

type Props = React.ComponentProps<typeof IonCardContent> & {
  children: ReactNode;
};

const CardContent: React.FC<Props> = ({ children, ...props }) => (
  <IonCardContent class={styles.cardContent} {...props}>
    {children}
  </IonCardContent>
);

export default CardContent;
