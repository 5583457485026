import { RECOMMENDATION_GROUP } from './recommendations';

export const REPORT_MEC_FLAGS_SLUGS: Record<RECOMMENDATION_GROUP, string> = {
  [RECOMMENDATION_GROUP.GROUP1]: '',
  [RECOMMENDATION_GROUP.GROUP2]: 'group_2_assessment_header',
  [RECOMMENDATION_GROUP.GROUP3]: 'group_3_assessment_header',
};

export const HOW_TO_READ_SCALES_SLUGS = {
  HTRS_HEADER: 'how_to_read_scales_header',
  HTRS_CONTENT: 'how_to_read_scales_content',
  HTRS_CONTINUED: 'how_to_read_scales_continued',
  HTRS_HEALTH_TITLE: 'how_to_read_scales_health_title',
  HTRS_HEALTH_CONTENT: 'how_to_read_scales_health_content',
  HTRS_WELLBEING_TITLE: 'how_to_read_scales_wellbeing_title',
  HTRS_WELLBEING_CONTENT: 'how_to_read_scales_wellbeing_content',
  HTRS_HORMONE_TITLE: 'how_to_read_scales_hormone_title',
  HTRS_HORMONE_CONTENT: 'how_to_read_scales_hormone_content',
  ASSESSMENT_CALCULATION_TITLE: 'assessment_calculation_title',
  ASSESSMENT_CALCULATION_CONTENT: 'assessment_calculation_content',
};

export const RECAP_SLUGS = {
  HEADER: 'recap screen_header',
  INTRO: 'recap screen_intro',
};

export const SYMPTOM_LEARN_MORE_SLUGS = {
  TRACK_PROGRESS_HEADER: 'track_your_progress_header',
  TRACK_PROGRESS_CONTENT: 'track_your_progress_content',
  HOW_HORMONES_WORK_TITLE: 'how_hormones_work_title',
  HOW_HORMONES_WORK_CONTENT: 'how_hormones_work_content',
  HORMONES_CYCLE_TITLE: 'hormones_and_cycle_title',
  HORMONES_CYCLE_CONTENT: 'hormones_and_cycle_content',
};

export const REPORT_STATIC_DATA_SLUGS = {
  CLINICAL_INTRO: 'clinical_intro',
  RECOMMENDATION_PRIORITIZE_TITLE: 'recommendation_prioritize_title',
  RECOMMENDATION_PRIORITIZE_CONTENT: 'recommendation_prioritize_content',
  CHAT_CLINICAL_TITLE: 'chat_clinician_title',
  CHAT_CLINICAL_CONTENT: 'chat_clinician_content',
  HORMONE_CONDITION_TITLE: 'assessment_screen_hormone_conditions_title',
  HORMONE_CONDITION_CONTENT: 'assessment_screen_hormone_conditions_content',
  HORMONE_SYMPTOMS_TITLE: 'assessment_screen_hormone_symptoms_title',
  HORMONE_SYMPTOMS_CONTENT: 'assessment_screen_hormone_symptoms_content',
  UNKNOWN_SYMPTOMS_TITLE: 'assessment_screen_unknown_symptoms_title',
  UNKNOWN_SYMPTOMS_CONTENT: 'assessment_screen_unknown_symptoms_content',
  RECOMMENDATION_OTHER_TITLE: 'recommendation_other_title',
};

type Impact = 1 | 2 | 3 | 4 | 5;
export const IMPACT_SCORE_SLUGS = {
  hormonal: {
    1: 'H_I_1',
    2: 'H_I_2',
    3: 'H_I_3',
    4: 'H_I_4',
    5: 'H_I_5',
  },
  clinical: {
    1: 'C_I_1',
    2: 'C_I_2',
    3: 'C_I_3_+_C_I_4',
    4: 'C_I_5',
    5: 'C_I_6',
  },
};

export const getHormonalImpact = (score: number): Impact => {
  const localScore = score * 100;
  let impact: Impact = 1;

  if (localScore >= 20) {
    impact = 2;
  }

  if (localScore >= 40) {
    impact = 3;
  }

  if (localScore >= 60) {
    impact = 4;
  }

  if (localScore >= 80) {
    impact = 5;
  }
  return impact;
};

export const getClinicalImpact = (score: number): Impact => {
  const localScore = score * 100;
  let impact: Impact = 1;

  if (localScore >= 16.66) {
    impact = 2;
  }
  if (localScore >= 33.33) {
    impact = 3;
  }

  if (localScore >= 66.66) {
    impact = 4;
  }

  if (localScore >= 83.33) {
    impact = 5;
  }
  return impact;
};

export const RECOMMENDATION_INTRO_SLUGS = {
  GROUP_1_A: 'group_1_a',
};

export const OC_SUITABILITY_SLUGS = {
  GROUP_1_COC: 'group_1_coc',
  GROUP_2_A_COC: 'group_2_a_coc',
  GROUP_2_B_COC: 'group_2_b_coc',
  GROUP_3_COC: 'group_3_coc',
  GROUP_1_POP: 'group_1_pop',
  GROUP_2_A_POP: 'group_2_a_pop',
  GROUP_2_B_POP: 'group_2_b_pop',
  GROUP_3_POP: 'group_3_pop',
};

export const OC_SUITABILITY_EXPLANATOIN_SLUGS = {
  ...OC_SUITABILITY_SLUGS,
};
