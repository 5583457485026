import axios from 'axios';

export const DOMAIN_MAP = {
  uk: 'uk',
  us: 'us',
};
export const LOCAL_ENV = 'local';

export const LOCAL_HOST = 'http://localhost:8000';
const HOST_MAP: Record<string, Record<string, string>> = {
  [DOMAIN_MAP.us]: {
    prod: 'https://api.tuune.com',
    staging: 'https://api.staging.tuune.com',
    dev: 'https://api.dev.tuune.com',
    localDev: 'https://api.dev.tuune.com',
  },
  [DOMAIN_MAP.uk]: {
    prod: 'https://api.tuune.co.uk',
    staging: 'https://api.staging.tuune.co.uk',
    dev: 'https://api.dev.tuune.co.uk',
    localDev: 'https://api.dev.tuune.co.uk',
  },
};

const getCurrentDomain = (): string => {
  const env = process.env.REACT_APP_ENV || LOCAL_ENV;
  let domain = DOMAIN_MAP.uk;

  if (env !== LOCAL_ENV) {
    const hostname = window.location.hostname;
    const hostnameChunks = hostname.split('.');

    if (hostnameChunks.includes('uk')) {
      domain = DOMAIN_MAP.uk;
    }
    if (hostnameChunks.includes('com')) {
      domain = DOMAIN_MAP.us;
    }
  }

  return domain;
};

const currentEnv = process.env.REACT_APP_ENV || LOCAL_ENV;
const ApiHost =
  currentEnv === LOCAL_ENV
    ? LOCAL_HOST
    : HOST_MAP[getCurrentDomain()][currentEnv];

export { ApiHost, getCurrentDomain, currentEnv as environment };

const instance = axios.create({
  baseURL: ApiHost,
  timeout: 30000,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json',
  },
});

export default instance;
