import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import { Input } from '@tuunetech/tuune-components';

export const IconWrapper = styled.div`
  .MuiSvgIcon-root {
    font-size: 43px;
  }
`;

export const DateInputWrapper = styled.div`
  display: flex;
  margin-right: 32px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-right: 28px;
  }

  ${Input} {
    width: 90px;
    margin-right: 8px;
    :last-of-type {
      margin-left: 16px;
    }
  }
`;

export const Dialog = styled(Paper)`
  max-height: 90%;
  max-width: 95%;
  width: 500px;
  height: 450px;
  margin-top: 130px;
`;
