import React from 'react';
import { Analytics } from './types';

const analyticsWarning = () => {
  console.warn(
    'Analytics - Please wrap the root with anlytics provider to enable tracking',
  );
};

const defaultValue: Analytics = {
  track: analyticsWarning,
  identify: analyticsWarning,
  logout: analyticsWarning,
};

export const AnalyticsContext = React.createContext(defaultValue);
