import styled from 'styled-components';
import { GridItem } from '@tuunetech/tuune-components';

export const RecommendationTableContainer = styled(GridItem)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    padding-left: 0;
  }
`;

export const BaseDivider = styled.div`
  background-color: ${({ theme }) => theme.palette.common.black};
  opacity: 0.1;
  height: 1px;
  width: 100%;
`;

export const SectionContainer = styled(GridItem)`
  margin: 24px 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 48px 0;
  }
`;
