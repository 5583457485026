import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import { Flag, RecommendationReducerState } from '../types';
import {
  GET_RECOMMENDATION_RESULTS_SUCCESS,
  GET_RECOMMENDATION_RESULTS_FAIL,
  RECOMMENDATION_TYPE,
  remapRawVersions,
} from '../constants';

const defaultState: RecommendationReducerState = {
  recommendations: [],
  referralRecommendation: {
    id: 0,
    price: 0,
    clinician: undefined,
  },
  mec: [],
  infoRequiredFlags: [],
  isCOCPermitted: false,
  isPOPPermitted: false,
  versions: undefined,
  errors: undefined,
};

const recommendationReducer: Reducer<RecommendationReducerState> = (
  state = defaultState,
  { type, status, response: { data = {} } = {} },
) => {
  switch (type) {
    case GET_RECOMMENDATION_RESULTS_SUCCESS:
      const current = Object.keys(data.current_pill).length
        ? [{ ...data.current_pill, current: true }]
        : [];
      const recommendations = [...data.recommendations, ...current].filter(
        // eslint-disable-next-line camelcase
        ({ recommendation_type: type }: { recommendation_type: string }) =>
          type !== RECOMMENDATION_TYPE.REFERRAL,
      );

      const infoRequiredFlags = data.recommendations.reduce((
        acc: Flag[],
        // eslint-disable-next-line camelcase
        {
          information_required: infoRequiredFlags,
          recommendation_type: type,
        }: // eslint-disable-next-line camelcase
        { information_required: Flag[]; recommendation_type: string },
      ) => {
        if (!infoRequiredFlags || type !== RECOMMENDATION_TYPE.PILL) {
          return acc;
        }

        const flags = camelcaseKeys(infoRequiredFlags).filter(
          (infoRequired: Flag) => {
            return !acc.some(
              (flag: Flag) => flag.contentHeader === infoRequired.contentHeader,
            );
          },
        );
        return [...acc, ...flags];
      }, []);

      return {
        ...state,
        isPOPPermitted: data.mec.POP.permitted,
        isCOCPermitted: data.mec.COC.permitted,
        mec: Object.keys({ ...data.mec.POP, ...data.mec.COC }).filter(
          (key) => key !== 'permitted',
        ),
        recommendations: camelcaseKeys(recommendations, {
          deep: true,
        }),
        referralRecommendation: camelcaseKeys(data.referral_details, {
          deep: true,
        }),
        infoRequiredFlags,
        versions: remapRawVersions(data.versions),
      };
    case GET_RECOMMENDATION_RESULTS_FAIL:
      return {
        ...state,
        errors: { status: status },
      };
    default:
      return state;
  }
};

export default recommendationReducer;
