import { MARKETING } from 'modules/Questionnaire/constants';
import { RootState } from 'reducers';
import { createSelector, Selector } from 'reselect';
import { AccountReducerState } from '../types';

const selectAccount: Selector<RootState, AccountReducerState> = (state) =>
  state.account;

export const getFirstName = createSelector(
  selectAccount,
  (state) => state.firstName,
);

export const getLastName = createSelector(
  selectAccount,
  (state) => state.lastName,
);

export const getEmail = createSelector(selectAccount, (state) => state.email);

export const getAvatar = createSelector(selectAccount, (state) => state.avatar);

export const getPhone = createSelector(
  selectAccount,
  (state) => state.phoneNumber,
);

export const getSubscriptions = createSelector(
  selectAccount,
  (state) => state.subscriptions,
);

export const getEmailPreferences = createSelector(
  selectAccount,
  (state) => state.emailPreferences,
);

export const getMarketingPreference = createSelector(selectAccount, (state) => {
  const marketingPreference: Set<MARKETING> = new Set();
  if (state.acceptMarketingPreference) {
    marketingPreference.add(MARKETING.marketingPreference);
  }
  if (state.acceptResearchPreference) {
    marketingPreference.add(MARKETING.researchPreference);
  }
  return marketingPreference;
});

export const getIsActive = createSelector(
  selectAccount,
  (state) => state.isActive,
);

export const getAddressData = createSelector(
  selectAccount,
  ({ city, county, country, postcode, addressLine1, addressLine2 }) => ({
    city,
    county,
    country,
    postcode,
    addressLine1,
    addressLine2,
  }),
);

export const getUpdateShippingDetailsErrors = createSelector(
  selectAccount,
  (state) => {
    const errors = {
      city: state.errors.city?.join('\n'),
      county: state.errors.county?.join('\n'),
      country: state.errors.country?.join('\n'),
      postcode: state.errors.postcode?.join('\n'),
      addressLine1: state.errors.line1?.join('\n'),
      addressLine2: state.errors.line2?.join('\n'),
    };
    if (Object.values(errors).every((error) => !error)) return undefined;
    return errors;
  },
);
