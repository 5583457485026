import React from 'react';
import { Control, FieldErrors } from 'react-hook-form';
import {
  GridContainer,
  GridItem,
  Button,
  BODY2,
} from '@tuunetech/tuune-components';
import {
  Backdrop,
  CircularProgress,
  Container,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { TuuneInput } from 'shared';
import { ButtonWrapper, FieldWrapper, Form } from '../ContactDetail/styles';
import { PaymentDetailFormData } from '../../containers';
import { CARD_TYPE_ICON, CardType } from '../../constants/cardType';
import { DateInputWrapper, IconWrapper, Dialog } from './styles';

export type PaymentDetailsProps = {
  errors: FieldErrors<PaymentDetailFormData>;
  control: Control<PaymentDetailFormData>;
  handleSubmit: () => void;
  cardType?: CardType;
  isThreedPaneVisible: boolean;
  isLoading: boolean;
  isDisabled: boolean;
};

export const PaymentDetails: React.FC<PaymentDetailsProps> = ({
  errors,
  control,
  handleSubmit,
  cardType,
  isThreedPaneVisible,
  isLoading,
  isDisabled,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const CardTypeIcon = cardType && CARD_TYPE_ICON[cardType];

  return (
    <Container disableGutters={!isMobile}>
      <Form id="payment-form" onSubmit={handleSubmit}>
        <GridContainer>
          <GridItem xs={12} lg={7}>
            <GridContainer>
              <GridItem xs={12}>
                <FieldWrapper>
                  <TuuneInput
                    name="txtCardNumber"
                    placeholder="xxxx xxxx xxxx xxxx"
                    inputProps={{
                      'data-vp': 'cardnumber',
                      maxLength: 24,
                      inputMode: 'numeric',
                    }}
                    control={control}
                    autoComplete="cc-number"
                    helperText={errors.txtCardNumber?.message}
                    error={!!errors.txtCardNumber}
                    InputProps={{
                      endAdornment: CardTypeIcon && (
                        <IconWrapper>
                          <CardTypeIcon />
                        </IconWrapper>
                      ),
                    }}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12}>
                <FieldWrapper>
                  <TuuneInput
                    name="txtCardHolder"
                    placeholder="Name on card"
                    inputProps={{
                      'data-vp': 'cardholder',
                    }}
                    control={control}
                    autoComplete="cc-name"
                    helperText={errors.txtCardHolder?.message}
                    error={!!errors.txtCardHolder}
                    fullWidth
                  />
                </FieldWrapper>
              </GridItem>
              <GridItem xs={12} lg={9}>
                <GridContainer $disableNegativeMargins>
                  <GridItem $disableGutters>
                    <BODY2 color="textSecondary" $marginBottom={16}>
                      Expiration date
                    </BODY2>
                    <DateInputWrapper>
                      <TuuneInput
                        name="txtMonth"
                        placeholder="mm"
                        inputProps={{
                          'data-vp': 'month',
                          maxLength: 2,
                          inputMode: 'numeric',
                        }}
                        autoComplete="cc-exp-month"
                        control={control}
                        helperText={errors.txtMonth?.message}
                        error={!!errors.txtMonth}
                      />
                      <TuuneInput
                        name="txtYear"
                        placeholder="yyyy"
                        inputProps={{
                          'data-vp': 'year',
                          maxLength: 4,
                          inputMode: 'numeric',
                        }}
                        control={control}
                        autoComplete="cc-exp-year"
                        helperText={errors.txtYear?.message}
                        error={!!errors.txtYear}
                      />
                      <TuuneInput
                        name="txtCVV"
                        placeholder="CVV"
                        type="password"
                        inputProps={{
                          'data-vp': 'cvv',
                          maxLength: 4,
                        }}
                        control={control}
                        autoComplete="cc-csc"
                        helperText={errors.txtCVV?.message}
                        error={!!errors.txtCVV}
                      />
                    </DateInputWrapper>
                  </GridItem>
                </GridContainer>
              </GridItem>
            </GridContainer>
          </GridItem>
        </GridContainer>
        <ButtonWrapper>
          <Button type="submit" disabled={isDisabled || isLoading}>
            {isLoading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              'place order'
            )}
          </Button>
        </ButtonWrapper>
      </Form>
      <Backdrop open={isThreedPaneVisible}>
        <Dialog id="threed-pane" />
      </Backdrop>
    </Container>
  );
};
