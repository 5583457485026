import { Action, compose, createStore } from 'redux';
import rootReducer, { RootState } from './reducers';
import rootMiddleware from './middlewares';
import { ThunkAction } from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';
import { OutputSelector } from 'reselect';

const composeEnhancers =
  process.env.REACT_APP_ENV === 'local' ? composeWithDevTools({}) : compose;

const enhancer = composeEnhancers(rootMiddleware);

const store = createStore(rootReducer, enhancer);

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type ComplexOutputSelector<S, O> = OutputSelector<
  RootState,
  O,
  (res: S) => O
>;

export default store;
