import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SoftwareLabel } from '../components/SoftwareLabel';
import { getRegulationVersionsRequest } from '../actions';
import { getRegulationBEVersion, getRegulationVersions } from '../selectors';

const RegulationContainer: React.FC = () => {
  const dispatch = useDispatch();

  const versions = useSelector(getRegulationVersions);
  const backendVersion = useSelector(getRegulationBEVersion);

  useEffect(() => {
    dispatch(getRegulationVersionsRequest());
  }, [dispatch]);

  return <SoftwareLabel versions={versions} backendVersion={backendVersion} />;
};

export default RegulationContainer;
