import {
  GET_ASSESSMENT_FLAGS_REQUEST,
  GET_ASSESSMENT_RESULTS_REQUEST,
  GET_RECAP_GOALS_REQUEST,
  GET_RECOMMENDATION_RESULTS_REQUEST,
} from 'modules/Results/constants';
import { ResultsRequestActionTypes } from 'modules/Results/types';
import { ApiHost } from 'utils/axios';
import { ApiReturnFunc } from './index';

const resultsApi: Record<ResultsRequestActionTypes, ApiReturnFunc> = {
  [GET_ASSESSMENT_RESULTS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/assessment`,
      method: 'get',
      headers: {},
    };
  },
  [GET_RECAP_GOALS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/recap-goals/`,
      method: 'get',
      headers: {},
    };
  },
  [GET_RECOMMENDATION_RESULTS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/recommendation`,
      method: 'get',
      headers: {},
    };
  },
  [GET_ASSESSMENT_FLAGS_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/flags/`,
      method: 'get',
      headers: {},
    };
  },
};

export default resultsApi;
