import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Box, Slide, useMediaQuery, useTheme } from '@material-ui/core';
import {
  BODY2,
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Header } from 'shared';
import { Page } from 'modules/Checkout/pages/CheckoutPage';
import { PageTitle } from '../PageTitle';
import { CHECKOUT_IDS, PAGES_WITHOUT_ORDER_CARD } from '../../constants';
import { ConfirmOrderContainer } from '../../containers';
import { ErrorBox } from './styles';

type CheckoutLayoutProps = {
  page: Page;
  handleNext: () => void;
  handleBack: () => void;
  direction: 'left' | 'right';
  errorMessage: string;
  setErrorMessage?: (msg: string) => void;
};

export const CheckoutLayout: React.FC<CheckoutLayoutProps> = ({
  page,
  handleNext,
  handleBack,
  direction,
  errorMessage,
  setErrorMessage,
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <IonPage>
      <Header pageName="Check out" />
      <IonContent>
        <Container $marginBottom={40} $marginTop={isMobile ? 16 : 40}>
          <GridContainer justify="space-between">
            <GridItem
              xs={12}
              md={!PAGES_WITHOUT_ORDER_CARD.includes(page.id) ? 6 : 12}
              lg={!PAGES_WITHOUT_ORDER_CARD.includes(page.id) ? 8 : 12}
            >
              <Box>
                <Slide direction={direction} in key={page.id}>
                  <div>
                    <GridContainer>
                      {page.id !== CHECKOUT_IDS.CONFIRMED && (
                        <GridItem xs={12}>
                          <Button
                            variant="text"
                            startIcon={<ArrowBackIosIcon />}
                            color="default"
                            size="small"
                            onClick={handleBack}
                          >
                            {page.backText || 'Back'}
                          </Button>
                        </GridItem>
                      )}
                      <GridItem
                        $offsetLg={
                          !PAGES_WITHOUT_ORDER_CARD.includes(page.id)
                            ? 1
                            : undefined
                        }
                        xs
                      >
                        <Container disableGutters>
                          {errorMessage && (
                            <GridContainer>
                              <GridItem xs={12} lg={9}>
                                <ErrorBox>
                                  <ErrorOutlineIcon color="error" />
                                  <BODY2
                                    $marginLeft={8}
                                    $marginTop={4}
                                    color="error"
                                  >
                                    {errorMessage}
                                  </BODY2>
                                </ErrorBox>
                              </GridItem>
                            </GridContainer>
                          )}
                          {!PAGES_WITHOUT_ORDER_CARD.includes(page.id) && (
                            <PageTitle
                              title={page.name}
                              description={page.description}
                            />
                          )}
                          {page.id === CHECKOUT_IDS.SUMMARY ? (
                            <GridContainer justify="center">
                              <GridItem xs={12} md={10}>
                                <PageTitle title={page.name} />
                              </GridItem>
                              <GridItem xs={12} md={6} lg={5} xl={4}>
                                <ConfirmOrderContainer
                                  handleNext={handleNext}
                                />
                              </GridItem>
                            </GridContainer>
                          ) : (
                            <page.Component
                              handleNext={handleNext}
                              setErrorMessage={setErrorMessage}
                            />
                          )}
                        </Container>
                      </GridItem>
                    </GridContainer>
                  </div>
                </Slide>
              </Box>
            </GridItem>
            {!isMobile && !PAGES_WITHOUT_ORDER_CARD.includes(page.id) && (
              <GridItem md={5} lg={4}>
                <ConfirmOrderContainer />
              </GridItem>
            )}
          </GridContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};
