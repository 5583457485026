import React, { ReactNode } from 'react';
import {
  CircularProgress,
  DialogTitle,
  Dialog,
  Paper,
  Box,
  Link,
} from '@material-ui/core';
import { DialogActions, DialogContent, Progress } from './styles';
import { Button, GridContainer, GridItem } from '@tuunetech/tuune-components';

export type RecapProps = {
  onClose: () => void;
  isOpen: boolean;
  header: ReactNode;
  intro: ReactNode;
  isDataLoading: boolean;
};

export const DeleteAccountDialog: React.FC<RecapProps> = ({
  onClose,
  isOpen,
  header,
  intro,
  isDataLoading,
}) => {
  return (
    <Dialog
      open={isOpen}
      disableBackdropClick
      aria-labelledby="alert-dialog-title"
      PaperProps={{ variant: 'outlined' }}
      PaperComponent={Paper}
      maxWidth="sm"
    >
      {isDataLoading ? (
        <Progress>
          <CircularProgress color="primary" />
        </Progress>
      ) : (
        <>
          <DialogTitle disableTypography id="alert-dialog-title">
            {header}
          </DialogTitle>
          <DialogContent>{intro}</DialogContent>
        </>
      )}
      <DialogActions>
        <GridContainer alignItems="center" direction="column">
          <GridItem>
            <Box mb={1} textAlign="center">
              <Link
                href="mailto:help@tuune.com?subject=Delete my account"
                onClick={onClose}
                target="_blank"
                component={Button}
                underline="none"
              >
                Delete my account
              </Link>
            </Box>
          </GridItem>
          <GridItem>
            <Button onClick={onClose} variant="text">
              I don’t want to leave!
            </Button>
          </GridItem>
        </GridContainer>
      </DialogActions>
    </Dialog>
  );
};
