import styled from 'styled-components';
import {
  DialogActions as MuiDialogActions,
  DialogContent as MuiDialogContent,
  DialogTitle as MuiDialogTitle,
} from '@material-ui/core';
import { GridItem, Button } from '@tuunetech/tuune-components';

export const DialogActions = styled(MuiDialogActions)`
  justify-content: center;
`;

export const DialogContent = styled(MuiDialogContent)`
  font-size: 18px;
  margin-bottom: 24px;
  overflow: hidden;
  color: ${({ theme }) => theme.palette.text.secondary};
`;

export const DialogTitle = styled(MuiDialogTitle)`
  font-weight: 600;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 24px;
`;

export const GridItemButton = styled(GridItem)`
  width: 100%;
`;

export const StyledButton = styled(Button)`
  width: 100%;
`;

export const PaperStyle = {
  borderRadius: 32,
  padding: 32,
};
