import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import { Box } from './Box';

export const Mastercard: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <rect
        x="1"
        y="0.5"
        width="41.5"
        height="29"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9737 21.0365C20.4937 22.284 18.5738 23.0372 16.4759 23.0372C11.7948 23.0372 8 19.2875 8 14.6621C8 10.0367 11.7948 6.28711 16.4759 6.28711C18.5738 6.28711 20.4937 7.04024 21.9737 8.2878C23.4538 7.04024 25.3737 6.28711 27.4716 6.28711C32.1527 6.28711 35.9475 10.0367 35.9475 14.6621C35.9475 19.2875 32.1527 23.0372 27.4716 23.0372C25.3737 23.0372 23.4538 22.284 21.9737 21.0365Z"
        fill="#ED0006"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9746 21.0365C23.797 19.5004 24.9526 17.2146 24.9526 14.6621C24.9526 12.1097 23.797 9.82392 21.9746 8.28778C23.4547 7.04023 25.3746 6.28711 27.4725 6.28711C32.1535 6.28711 35.9483 10.0367 35.9483 14.6621C35.9483 19.2875 32.1535 23.0372 27.4725 23.0372C25.3746 23.0372 23.4547 22.284 21.9746 21.0365Z"
        fill="#F9A000"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.9741 21.0364C23.7965 19.5003 24.952 17.2146 24.952 14.6621C24.952 12.1097 23.7965 9.82397 21.9741 8.28784C20.1517 9.82397 18.9961 12.1097 18.9961 14.6621C18.9961 17.2146 20.1517 19.5003 21.9741 21.0364Z"
        fill="#FF5E00"
      />
    </Box>
  );
};
