import styled from 'styled-components';
import {
  BODY1,
  Container,
  H3,
  GridItem,
  Button,
} from '@tuunetech/tuune-components';

export const VerifyContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${({ theme }) => theme.spacing(7)}px;
  }
  ${H3}, ${BODY1} {
    span {
      display: block;
    }
  }

  ${H3} {
    margin-top: ${({ theme }) => theme.spacing(5)}px;
  }

  ${BODY1} {
    margin-bottom: 20px;
  }

  img {
    width: 100%;
    border-radius: 40px;
  }
`;

export const SpinnerContainer = styled(GridItem)`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20px;
`;

export const ChangeEmailButton = styled(Button)`
  margin-top: 20px;
  margin-bottom: 20px;
`;
