import React, { useCallback } from 'react';
import { TextAnswer } from '../../components/Answers';
import { AnswerComponentProps } from '../../types';
import { validateSingleTextAnswer } from '../../constants';

const TextContainer: React.FC<AnswerComponentProps> = ({
  handleRequestNext,
  answers,
  previousAnswers,
  handleSubmitAnswer,
}) => {
  const factId = answers[0].id;
  const prevAnswer = previousAnswers[factId];
  const initialValues = { answer: prevAnswer as string };

  const handleSubmit = useCallback(
    ({ answer }) => {
      handleSubmitAnswer([{ factId, answer }]);
    },
    [handleSubmitAnswer, factId],
  );

  return (
    <TextAnswer
      initialValues={initialValues}
      handleSubmit={handleSubmit}
      handleRequestNext={handleRequestNext}
      validate={validateSingleTextAnswer}
    />
  );
};

export default TextContainer;
