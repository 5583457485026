import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from 'modules/Loading/selectors';
import { GET_ACCOUNT_DATA } from 'modules/Account/constants';
import {
  getAccountDataRequest,
  updateAccountData,
} from 'modules/Account/actions';
import {
  getEmail,
  getFirstName,
  getLastName,
  getPhone,
} from 'modules/Account/selectors';
import {
  getCountryObjByCode,
  phoneAsYouType,
  getPhoneWithoutCallingCode,
  DEFAULT_COUNTRY_OBJ,
} from 'utils/helpers';
import { ContactDetail } from '../components/ContactDetail';
import { PageProps } from '../pages/CheckoutPage';
import { contactDetailsSchema } from '../constants';
import { getIsContactDetailsUpdated } from '../selectors';
import { setIsContactDetailsUpdated } from '../actions';

export type ContactDetailFormData = {
  firstName: string;
  lastName: string;
  phoneCode: string;
  phone: string;
  email: string;
};

export const ContactDetailsContainer: React.FC<PageProps> = ({
  handleNext,
}) => {
  const dispatch = useDispatch();
  const isAccountLoading = useSelector(getIsLoading(GET_ACCOUNT_DATA));
  const email = useSelector(getEmail);
  const firstName = useSelector(getFirstName);
  const lastName = useSelector(getLastName);
  const phone = useSelector(getPhone);
  const isContactDetailsUpdated = useSelector(getIsContactDetailsUpdated);

  const {
    handleSubmit,
    formState: { errors, isDirty },
    control,
    watch,
    setValue,
    reset,
  } = useForm<ContactDetailFormData>({
    resolver: yupResolver(contactDetailsSchema),
    mode: 'onTouched',
  });

  const phoneNumber = watch('phone');
  const phoneCode = watch('phoneCode');

  useEffect(() => {
    if (phoneNumber) {
      phoneAsYouType.reset();
      const parsedPhone = phoneAsYouType.input(phoneNumber);

      if (!phoneAsYouType.isInternational()) {
        // if input phone is raw: add phone code to it and allow asYouType format output
        // it also trims all non-number characters from input
        const intPhone = `${phoneCode}${parsedPhone}`;
        phoneAsYouType.reset();
        phoneAsYouType.input(intPhone);
      }
      // trim back countryCode from phone
      // but leave formatting of the rest of the phone
      const phoneWithoutCallingCode = getPhoneWithoutCallingCode(
        phoneAsYouType.getNumber()?.format('INTERNATIONAL') || '',
        phoneAsYouType.getCallingCode() || '',
      );
      setValue('phone', phoneWithoutCallingCode);
    }
  }, [errors, phoneNumber, phoneCode, setValue]);

  const onSubmit = useCallback(
    (data) => {
      const { firstName, lastName, phone, phoneCode } = data;
      if (isDirty) {
        phoneAsYouType.reset();
        phoneAsYouType.input(`${phoneCode}${phone}`);
        dispatch(
          updateAccountData({
            firstName,
            lastName,
            // .getNumber()?.number will return number without formatting
            phone: phoneAsYouType.getNumber()?.number,
          }),
        );
      } else {
        dispatch(setIsContactDetailsUpdated());
      }
    },
    [dispatch, isDirty],
  );

  useEffect(() => {
    if (isContactDetailsUpdated) {
      handleNext && handleNext();
    }
  }, [handleNext, isContactDetailsUpdated]);

  useEffect(() => {
    if (isAccountLoading === undefined) {
      dispatch(getAccountDataRequest);
    }
  }, [dispatch, isAccountLoading]);

  useEffect(() => {
    if (isAccountLoading === 0) {
      phoneAsYouType.reset();
      phoneAsYouType.input(phone || '');
      const phoneCode =
        getCountryObjByCode(phoneAsYouType.getCountry() || '')?.phoneCode || '';

      reset(
        {
          email,
          firstName,
          lastName,
          phone: phone?.replace(phoneCode, ''),
          // react-form-hook Controller doesn't use defaultValue if passed value is empty string
          // setting a fallback is required if you want it to appear
          phoneCode: phoneCode || DEFAULT_COUNTRY_OBJ.phoneCode,
        },
        { keepDirty: true },
      );
    }
  }, [email, firstName, lastName, phone, isAccountLoading, reset]);

  return (
    <ContactDetail
      handleSubmit={handleSubmit(onSubmit)}
      control={control}
      errors={errors}
    />
  );
};
