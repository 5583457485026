import React, { MutableRefObject, useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getStartedData } from '../../selectors';
import { setGetStartedQuestion } from '../../actions';
import { AnswerComponentProps } from '../../types';
import { InfoAnswer } from '../../components/Answers';

export type InfoComponentProps = {
  handleSubmit: () => void;
  continueRef?: MutableRefObject<HTMLIonButtonElement | undefined>;
};

type InfoContainerProps = Partial<AnswerComponentProps> & {
  component?: React.FC<InfoComponentProps>;
};

const InfoContainer: React.FC<InfoContainerProps> = ({
  component: Component,
  handleRequestNext,
}) => {
  const continueRef = useRef<HTMLIonButtonElement>();
  const dispatch = useDispatch();
  const { currentQuestion } = useSelector(getStartedData);

  const handleSubmit = useCallback(() => {
    dispatch(setGetStartedQuestion(currentQuestion + 1));
  }, [dispatch, currentQuestion]);

  const handleSubmitByKey = useCallback(
    (e) => {
      if (e.key === 'Enter') {
        if (Component !== undefined) {
          handleSubmit();
        } else if (handleRequestNext) {
          handleRequestNext();
        }
      }
    },
    [Component, handleRequestNext, handleSubmit],
  );

  useEffect(() => {
    window.addEventListener('keypress', handleSubmitByKey);
    return (): void => {
      window.removeEventListener('keypress', handleSubmitByKey);
    };
  }, [handleSubmitByKey]);

  return Component !== undefined ? (
    <Component handleSubmit={handleSubmit} continueRef={continueRef} />
  ) : (
    <InfoAnswer handleRequestNext={handleRequestNext} />
  );
};

export default InfoContainer;
