import { FAIL, REQUEST, SUCCESS } from 'utils/requestStates';

export const GET_CURRENT_QUESTION = 'GET_CURRENT_QUESTION';
export const GET_CURRENT_QUESTION_REQUEST = 'GET_CURRENT_QUESTION_REQUEST';
export const GET_CURRENT_QUESTION_SUCCESS = 'GET_CURRENT_QUESTION_SUCCESS';
export const GET_CURRENT_QUESTION_FAIL = `${GET_CURRENT_QUESTION}_${FAIL}` as const;

export const SUBMIT_QUESTION = 'SUBMIT_QUESTION';
export const SUBMIT_QUESTION_REQUEST = 'SUBMIT_QUESTION_REQUEST';
export const SUBMIT_QUESTION_SUCCESS = 'SUBMIT_QUESTION_SUCCESS';
export const SUBMIT_QUESTION_FAIL = `${SUBMIT_QUESTION}_${FAIL}` as const;

export const SET_MEASURE_SYSTEM = 'SET_MEASURE_SYSTEM';

export const GET_PREV_QUESTION_REQUEST = 'GET_PREV_QUESTION_REQUEST';
export const GET_PREV_QUESTION_SUCCESS = 'GET_PREV_QUESTION_SUCCESS';

export const SET_GET_STARTED_MARKETING = 'SET_GET_STARTED_MARKETING';
export const SET_GET_STARTED_QUESTION = 'SET_GET_STARTED_QUESTION';
export const SET_GET_STARTED_PRIVACY = 'SET_GET_STARTED_PRIVACY';

export const REGISTER_TO_QUESTIONNAIRE_REQUEST =
  'REGISTER_TO_QUESTIONNAIRE_REQUEST';
export const REGISTER_TO_QUESTIONNAIRE_SUCCESS =
  'REGISTER_TO_QUESTIONNAIRE_SUCCESS';
export const REGISTER_TO_QUESTIONNAIRE_FAIL = 'REGISTER_TO_QUESTIONNAIRE_FAIL';

export const SET_PASSWORD_REQUEST = 'SET_PASSWORD_REQUEST';
export const SET_PASSWORD_SUCCESS = 'SET_PASSWORD_SUCCESS';
export const SET_PASSWORD_FAIL = 'SET_PASSWORD_FAIL';

export const SUBMIT_FEEDBACK_REQUEST = 'SUBMIT_FEEDBACK_REQUEST';
export const SUBMIT_FEEDBACK_SUCCESS = 'SUBMIT_FEEDBACK_SUCCESS';

export const GET_FEEDBACK = 'GET_FEEDBACK';
export const GET_FEEDBACK_REQUEST = `${GET_FEEDBACK}_${REQUEST}`;
export const GET_FEEDBACK_SUCCESS = `${GET_FEEDBACK}_${SUCCESS}`;
