import { Middleware } from 'redux';
import * as analytics from 'utils/analytics';
import { GET_ACCOUNT_DATA_SUCCESS } from 'modules/Account/constants';
import {
  ACTIVATE_EMAIL_SUCCESS,
  LOGIN_FAIL,
  LOGIN_SUCCESS,
  LOG_OUT_SUCCESS,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_SUCCESS,
} from 'modules/Auth/constants';
import {
  REGISTER_TO_QUESTIONNAIRE_SUCCESS,
  SUBMIT_QUESTION_SUCCESS,
  ServerQuestionTypesMap,
  GET_PREV_QUESTION_SUCCESS,
  AnswerTypesMap,
} from 'modules/Questionnaire/constants';
import { POST_PRODUCT_TO_ORDER_SUCCESS } from 'modules/Checkout/constants';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { getNonTextAnswers } from 'modules/Questionnaire/helpers/validation';

export const analyticsMiddleware: Middleware = ({ getState }) => (next) => (
  action,
): void => {
  const { type, response = {}, payload, error = {} } = action;
  const state = getState();

  switch (type) {
    case GET_ACCOUNT_DATA_SUCCESS: {
      const { data = {} } = response;
      const userId = `${data.id}` || null;
      analytics.identify(userId);
      break;
    }

    case ACTIVATE_EMAIL_SUCCESS:
      analytics.identify(null, { activationDate: new Date().toUTCString() });
      analytics.track(
        ANALYTICS_EVENT.ACTIVATE_ACCOUNT,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      break;

    case LOGIN_SUCCESS:
      analytics.track(
        ANALYTICS_EVENT.LOGIN_ACCOUNT,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      break;

    case LOG_OUT_SUCCESS:
      analytics.track(
        ANALYTICS_EVENT.LOGOUT_ACCOUNT,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      analytics.logout();
      break;

    case SUBMIT_QUESTION_SUCCESS: {
      const {
        factsSlugs,
        answers,
        questionType,
        questionTitle,
        facts,
      } = payload;
      const { data = {} } = response;
      if (
        questionType === ServerQuestionTypesMap.FACT &&
        facts[0].name === AnswerTypesMap.DOB
      ) {
        const dob = answers[0]?.answer;
        if (dob) {
          const age =
            new Date(
              new Date().getTime() - new Date(dob).getTime(),
            ).getUTCFullYear() - 1970;
          analytics.identify(null, { age });
        }

        analytics.track(
          ANALYTICS_EVENT.START_HA,
          {
            category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          },
          state.account.email,
        );
        break;
      }

      analytics.track(
        ANALYTICS_EVENT.COMPLETE_QUESTION,
        {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          questionId: factsSlugs,
          questionTitle,
          answers: getNonTextAnswers(payload),
        },
        state.account.email,
      );

      if (data.status) {
        analytics.track(
          ANALYTICS_EVENT.COMPLETE_HA,
          {
            category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          },
          state.account.email,
        );
      }
      break;
    }

    case REGISTER_TO_QUESTIONNAIRE_SUCCESS:
      analytics.track(
        ANALYTICS_EVENT.CREATE_ACCOUNT,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      break;

    case POST_PRODUCT_TO_ORDER_SUCCESS: {
      const { productId } = payload;
      analytics.track(
        ANALYTICS_EVENT.ADD_TO_PLAN,
        {
          category: ANALYTICS_CATEGORY.ORDER,
          recommendationId: productId,
        },
        state.account.email,
      );
      break;
    }

    case LOGIN_FAIL: {
      analytics.track(
        ANALYTICS_EVENT.LOGIN_FAILED,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
          error: error.data?.detail || error.message,
        },
        state.account.email,
      );
      break;
    }

    case GET_PREV_QUESTION_SUCCESS: {
      const { data: responseData = {} } = response;
      const { factsSlugs } = responseData;
      analytics.track(
        ANALYTICS_EVENT.QUESTION_BACK,
        {
          category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
          questionId: factsSlugs,
        },
        state.account.email,
      );
      break;
    }

    case RESET_PASSWORD_SUCCESS:
      analytics.track(
        ANALYTICS_EVENT.FORGOT_PASSWORD,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      break;

    case RESET_PASSWORD_CONFIRM_SUCCESS:
      analytics.track(
        ANALYTICS_EVENT.RESET_PASSWORD,
        {
          category: ANALYTICS_CATEGORY.LOGIN,
        },
        state.account.email,
      );
      break;

    default:
      break;
  }
  next(action);
};
