import { RootState } from 'reducers';
import { createSelector, Selector } from 'reselect';
import { ErrorPopupReducerState } from '../types';

const selectErrorPopup: Selector<RootState, ErrorPopupReducerState> = (state) =>
  state.errorPopup;

export const getErrorMessage = createSelector(
  selectErrorPopup,
  (state) => state.message,
);
