import { useEffect, useRef } from 'react';

export const useInterval = (
  callback: () => void,
  delay: number | null,
): void => {
  const savedCallback = useRef<() => void>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    const tick = (): void => {
      savedCallback.current && savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return (): void => clearInterval(id);
    }
  }, [delay]);
};
