import React from 'react';
import { Button, GridContainer, GridItem } from '@tuunetech/tuune-components';
import { PageTitle } from '../PageTitle';
import { CHECKOUT_PAGES } from '../../constants';
import { ButtonWrapper } from '../ContactDetail/styles';

export type CheckoutResultProps = {
  firstName: string;
  handleNext?: () => void;
};

export const CheckoutResult: React.FC<CheckoutResultProps> = ({
  firstName,
  handleNext,
}) => {
  return (
    <GridContainer justify="center">
      <GridItem xs={12} lg={6}>
        <PageTitle
          title={`${firstName}, your order has been placed!`}
          description={CHECKOUT_PAGES[CHECKOUT_PAGES.length - 1].description}
        />
        <ButtonWrapper $alignCenter>
          <Button onClick={handleNext}>Go to account dashboard</Button>
        </ButtonWrapper>
      </GridItem>
    </GridContainer>
  );
};
