import React, { ReactNode, useEffect, useMemo } from 'react';
import { Box, CircularProgress, SwipeableDrawerProps } from '@material-ui/core';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { useDispatch, useSelector } from 'react-redux';
import { H5, Divider } from '@tuunetech/tuune-components';
import { DrawerModal } from 'shared';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { getIsLoading } from 'modules/Loading/selectors';
import { getEntries } from 'modules/Contentful/actions';
import { useDomain } from 'utils/hooks/useDomain';
import trackData from 'assets/images/track_data.svg';
import hormonesGraph from 'assets/images/hormones_graph.svg';
import {
  getResultsContentfulOptions,
  SYMPTOM_LEARN_MORE_SLUGS,
} from '../constants';
import { getAssessmentData, getSymptomCurrent } from '../selectors';

export type SymptomLearnMoreProps = {
  isSymptomLearnMoreOpen: boolean;
  handleSymptomLearnMoreOpen: (
    event: React.SyntheticEvent<HTMLButtonElement>,
    symptom?: string,
  ) => void;
  handleSymptomLearnMoreClose: SwipeableDrawerProps['onClose'];
};

const CK = {
  [CTN.ISSUE_SNIPPET_GENERIC]: keyGen.getKey(
    CONTENT_TYPE.ISSUE_SNIPPET_GENERIC,
  ),
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
};

export const SymptomLearnMoreContainer: React.FC<SymptomLearnMoreProps> = ({
  isSymptomLearnMoreOpen,
  handleSymptomLearnMoreClose,
  handleSymptomLearnMoreOpen,
}) => {
  const dispatch = useDispatch();

  const symptomCurrent = useSelector(getSymptomCurrent);

  const assessmentData = useSelector(getAssessmentData);
  const symptomsIssueSnippetsGeneric = useSelector(
    getArrayContentMapped(CK[CTN.ISSUE_SNIPPET_GENERIC].reqId),
  );
  const isSymptomsIssueSnippetsGenericLoading = useSelector(
    getIsLoading(CK[CTN.ISSUE_SNIPPET_GENERIC].loadingId),
  );
  const symptomLearnMoreData = useSelector(
    getArrayContentMapped(CK[CTN.STATIC_CONTENT].reqId),
  );
  const isSymptomLearnMoreDataLoading = useSelector(
    getIsLoading(CK[CTN.STATIC_CONTENT].loadingId),
  );
  const domain = useDomain();

  useEffect(() => {
    if (isSymptomLearnMoreOpen) {
      if (isSymptomLearnMoreDataLoading === undefined) {
        dispatch(
          getEntries({
            ...CK[CTN.STATIC_CONTENT],
            domain,
            slugs: Object.values(SYMPTOM_LEARN_MORE_SLUGS),
          }),
        );
      }
      if (isSymptomsIssueSnippetsGenericLoading === undefined) {
        // TODO: delete whole container if there is no need in symptom snippet generic
      }
    }
  }, [
    dispatch,
    domain,
    isSymptomLearnMoreOpen,
    isSymptomLearnMoreDataLoading,
    isSymptomsIssueSnippetsGenericLoading,
    assessmentData,
  ]);

  const issueSnippetContent = useMemo(() => {
    return Object.keys(symptomsIssueSnippetsGeneric).reduce((content, key) => {
      content[key] = (
        <>
          <H5 paragraph align="left">
            {symptomsIssueSnippetsGeneric[key]?.name}
          </H5>
          {documentToReactComponents(
            symptomsIssueSnippetsGeneric[key]?.content,
            getResultsContentfulOptions(),
          )}
        </>
      );
      return content;
    }, {} as Record<string, ReactNode>);
  }, [symptomsIssueSnippetsGeneric]);

  const content = useMemo(() => {
    return Object.keys(symptomLearnMoreData).reduce((content, key) => {
      content[key] = documentToReactComponents(
        symptomLearnMoreData[key].content,
        getResultsContentfulOptions(),
      );
      return content;
    }, {} as Record<string, ReactNode>);
  }, [symptomLearnMoreData]);

  return (
    <DrawerModal
      onClose={handleSymptomLearnMoreClose}
      onOpen={handleSymptomLearnMoreOpen}
      open={isSymptomLearnMoreOpen}
    >
      {isSymptomsIssueSnippetsGenericLoading !== 0 ? (
        <CircularProgress color="primary" size={15} />
      ) : (
        <>
          {symptomCurrent && issueSnippetContent[symptomCurrent]}
          <Divider $verticalMargin={24} />
        </>
      )}
      {isSymptomLearnMoreDataLoading !== 0 ? (
        <CircularProgress color="primary" />
      ) : (
        <>
          {content[SYMPTOM_LEARN_MORE_SLUGS.TRACK_PROGRESS_HEADER]}
          {content[SYMPTOM_LEARN_MORE_SLUGS.TRACK_PROGRESS_CONTENT]}
          <img alt="track data" src={trackData} />
          <Divider $verticalMargin={24} />
          {content[SYMPTOM_LEARN_MORE_SLUGS.HOW_HORMONES_WORK_TITLE]}
          {content[SYMPTOM_LEARN_MORE_SLUGS.HOW_HORMONES_WORK_CONTENT]}
          <Box marginBottom="24px">
            <img alt="hormones graph" src={hormonesGraph} width="100%" />
          </Box>
          {content[SYMPTOM_LEARN_MORE_SLUGS.HORMONES_CYCLE_TITLE]}
          {content[SYMPTOM_LEARN_MORE_SLUGS.HORMONES_CYCLE_CONTENT]}
        </>
      )}
    </DrawerModal>
  );
};
