import React, { createContext, useContext } from 'react';
import { ContentfulClientApi, createClient } from 'contentful';
import { CONTENTFUL_TOKEN, SPACE } from './index';

export const client = createClient({
  space: SPACE,
  accessToken: CONTENTFUL_TOKEN,
});

export const ContentfulContext = createContext(client);

export const ContentfulProvider: React.FC = ({ children }) => {
  return (
    <ContentfulContext.Provider value={client}>
      {children}
    </ContentfulContext.Provider>
  );
};

export const useContentfulClient = (): ContentfulClientApi => {
  return useContext(ContentfulContext);
};
