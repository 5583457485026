import { AppThunk } from 'reduxStore';
import { client } from '../constants/ContentfulContext';
import {
  REQUEST_CONTENTFUL_DATA,
  SUCCESS_CONTENTFUL_DATA,
} from '../constants/actions';

type Props = {
  contentType: string;
  domain: string;
  reqId: string;
  slugs: string[];
  match?: boolean;
  ne?: boolean;
};

const LOCALE_MAP: Record<string, string> = {
  uk: 'en-GB',
  us: 'en-US',
};

export const getEntries = ({
  contentType,
  domain,
  slugs,
  reqId,
  match = false,
  ne = false,
}: Props): AppThunk => async (dispatch): Promise<void> => {
  dispatch({
    type: `${REQUEST_CONTENTFUL_DATA}_${reqId}`,
    payload: { reqId },
  });

  const query: Record<string, string> = {
    content_type: contentType,
    locale: LOCALE_MAP[domain],
  };
  if (match) {
    query['fields.slug[match]'] = slugs.join(',');
  } else if (ne) {
    query['fields.slug[ne]'] = slugs.join(',');
  } else {
    query['fields.slug[in]'] = slugs.join(',');
  }
  const data = await client.getEntries(query);

  dispatch({
    type: `${SUCCESS_CONTENTFUL_DATA}_${reqId}`,
    payload: { data, reqId },
  });
};
