import { FAIL, REQUEST, SUCCESS } from 'utils/requestStates';

export const GET_ACCOUNT_DATA = 'GET_ACCOUNT_DATA';
export const GET_ACCOUNT_DATA_REQUEST = `${GET_ACCOUNT_DATA}_${REQUEST}` as const;
export const GET_ACCOUNT_DATA_SUCCESS = `${GET_ACCOUNT_DATA}_${SUCCESS}` as const;

const UPDATE_ACCOUNT_DATA = 'UPDATE_ACCOUNT_DATA';
export const UPDATE_ACCOUNT_DATA_REQUEST = `${UPDATE_ACCOUNT_DATA}_${REQUEST}`;
export const UPDATE_ACCOUNT_DATA_SUCCESS = `${UPDATE_ACCOUNT_DATA}_${SUCCESS}`;

const UPDATE_MARKETING_PREFERENCE = 'UPDATE_MARKETING_PREFERENCE';
export const UPDATE_MARKETING_PREFERENCE_REQUEST = `${UPDATE_MARKETING_PREFERENCE}_${REQUEST}`;
export const UPDATE_MARKETING_PREFERENCE_SUCCESS = `${UPDATE_MARKETING_PREFERENCE}_${SUCCESS}`;

export const GET_IS_ACTIVE_REQUEST = 'GET_IS_ACTIVE_REQUEST';
export const GET_IS_ACTIVE_SUCCESS = 'GET_IS_ACTIVE_SUCCESS';
export const SET_IS_ACTIVE = 'SET_IS_ACTIVE';

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS';
export const UPDATE_ADDRESS_REQUEST = `${UPDATE_ADDRESS}_${REQUEST}` as const;
export const UPDATE_ADDRESS_SUCCESS = `${UPDATE_ADDRESS}_${SUCCESS}` as const;
export const UPDATE_ADDRESS_FAIL = `${UPDATE_ADDRESS}_${FAIL}` as const;

export const GET_ADDRESS = 'GET_ADDRESS';
export const GET_ADDRESS_REQUEST = `${GET_ADDRESS}_${REQUEST}` as const;
export const GET_ADDRESS_SUCCESS = `${GET_ADDRESS}_${SUCCESS}` as const;
