import React from 'react';
import { IonPage, IonContent } from '@ionic/react';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Header } from 'shared';
import { RegulationContainer } from '../../containers';

export const RegulationLayout: React.FC = () => {
  return (
    <IonPage>
      <Header pageName="Regulation" />
      <IonContent>
        <Container $marginTop={32} $marginBottom={40}>
          <GridContainer justify="center" direction="column">
            <GridItem xs={12}>
              <RegulationContainer />
            </GridItem>
          </GridContainer>
        </Container>
      </IonContent>
    </IonPage>
  );
};
