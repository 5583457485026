import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { PageProps } from '../pages/CheckoutPage';
import { ConfirmOrder } from '../components/ConfirmOrder';
import { getOrderDetails, getOrderId } from '../selectors';
import { confirmDataSchema } from '../constants';
import { SetOrderConfirmPayload } from '../types';
import { setOrderConfirmRequest } from '../actions';

export type ConfirmFormData = Omit<SetOrderConfirmPayload, 'orderId'>;

export const ConfirmOrderContainer: React.FC<PageProps> = ({ handleNext }) => {
  const dispatch = useDispatch();
  const orderDetails = useSelector(getOrderDetails);
  const orderId = useSelector(getOrderId);

  const {
    handleSubmit,
    control,
    formState: { errors, isValid },
  } = useForm<ConfirmFormData>({
    resolver: yupResolver(confirmDataSchema),
  });

  const [isError, setIsError] = useState(false);

  const onSubmit = useCallback(
    (data) => {
      dispatch(setOrderConfirmRequest({ ...data, orderId }));
      handleNext && handleNext();
    },
    [dispatch, orderId, handleNext],
  );

  useEffect(() => {
    if (Object.keys(errors).length !== 0) {
      setIsError(!isValid);
    } else {
      setIsError(false);
    }
  }, [isValid, errors]);

  return (
    <ConfirmOrder
      isError={isError}
      control={control}
      handleSubmit={handleSubmit(onSubmit)}
      isPageRole={!!handleNext}
      {...orderDetails}
    />
  );
};
