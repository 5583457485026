import React, { useCallback, useMemo, useState } from 'react';
import { SvgIcon } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { BODY2 } from '@tuunetech/tuune-components';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import { DrawerModal } from 'shared';
import { symptomIcons } from 'assets/icons/symptoms';
import { SymptomIconSvg } from 'assets/icons/symptoms/SymptomIcon';

import {
  SymptomSection,
  SymptomSectionProps,
} from '../components/SymptomSection';
import { getAssessmentData } from '../selectors';
import {
  getClinicalImpact,
  getHormonalImpact,
  IMPACT_SCORE_SLUGS,
  REPORT_STATIC_DATA_SLUGS,
  ReportContentfulKeys as RCK,
} from '../constants';
import { Assessment } from '../types';

const IconPlaceHolder: React.FC = () => (
  <SvgIcon>
    <circle cx="12" cy="12" r="10" fill="#BDBDBD" />
  </SvgIcon>
);

export const SymptomSectionContainer: React.FC = () => {
  const assessmentData = useSelector(getAssessmentData);
  const staticData = useSelector(
    getArrayContentMapped(RCK[CTN.STATIC_CONTENT].reqId),
  );
  const impactScore = useSelector(
    getArrayContentMapped(RCK[CTN.IMPACT_SCORE].reqId),
  );
  const impactScoreExplained = useSelector(
    getArrayContentMapped(RCK[CTN.IMPACT_SCORE_EXPLAINED].reqId),
  );
  const issueSnippetGeneric = useSelector(
    getArrayContentMapped(RCK[CTN.ISSUE_SNIPPET_GENERIC].reqId),
  );

  const staticDataRemapped = documentToPlainTextRecords(staticData);
  const impactScoreRemapped = documentToPlainTextRecords(impactScore);
  const impactScoreExplainedRemapped = documentToPlainTextRecords(
    impactScoreExplained,
  );
  const issueSnippetGenericRemapped = documentToPlainTextRecords(
    issueSnippetGeneric,
  );

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSection, setSelectedSection] = useState<string>('');
  const [selectedImpact, setSelectedImpact] = useState<string>('');

  const handleOpenModal = useCallback(
    (sectionMoreKey?: string) => () => {
      setSelectedSection(sectionMoreKey || '');
      setIsModalOpen(true);
    },
    [],
  );

  const handleCloseModal = useCallback(() => {
    setSelectedSection('');
    setSelectedImpact('');
    setIsModalOpen(false);
  }, []);

  const onImpactClick = useCallback(
    (impactSlug: string) => () => {
      setIsModalOpen(true);
      setSelectedImpact(impactSlug);
    },
    [],
  );

  const remapIssues = useCallback(
    (issues: Assessment[]): SymptomSectionProps['issues'] => {
      return issues.map(({ slug, name, scores: { Hormonal, Clinical } }) => {
        const hormonalImpact = getHormonalImpact(Hormonal);
        const clinicalImpact = getClinicalImpact(Clinical);
        const hiSlug = IMPACT_SCORE_SLUGS.hormonal[hormonalImpact];
        const ciSlug = IMPACT_SCORE_SLUGS.clinical[clinicalImpact];
        const SymptomIcon = symptomIcons[slug] ? (
          <SymptomIconSvg>{symptomIcons[slug]}</SymptomIconSvg>
        ) : (
          <IconPlaceHolder />
        );

        return {
          id: slug,
          icon: SymptomIcon,
          name,
          hormonal: hormonalImpact,
          clinical: clinicalImpact,
          hormonalTitle: impactScoreRemapped[hiSlug],
          clinicalTitle: impactScoreRemapped[ciSlug],
          snippet: issueSnippetGenericRemapped[slug],
          onClinicalClick: onImpactClick(ciSlug),
          onHormonalClick: onImpactClick(hiSlug),
        };
      });
    },
    [impactScoreRemapped, issueSnippetGenericRemapped, onImpactClick],
  );

  const sections = useMemo(() => {
    const sect: SymptomSectionProps[] = [];
    if (assessmentData.conditions.length) {
      sect.push({
        title:
          staticDataRemapped[REPORT_STATIC_DATA_SLUGS.HORMONE_CONDITION_TITLE],
        sectionMoreKey: REPORT_STATIC_DATA_SLUGS.HORMONE_CONDITION_CONTENT,
        issues: remapIssues(assessmentData.conditions),
      });
    }
    if (assessmentData.symptomsHormonal.length) {
      sect.push({
        title:
          staticDataRemapped[REPORT_STATIC_DATA_SLUGS.HORMONE_SYMPTOMS_TITLE],
        sectionMoreKey: REPORT_STATIC_DATA_SLUGS.HORMONE_SYMPTOMS_CONTENT,
        issues: remapIssues(assessmentData.symptomsHormonal),
      });
    }

    if (assessmentData.symptomsUnknown.length) {
      sect.push({
        title:
          staticDataRemapped[REPORT_STATIC_DATA_SLUGS.UNKNOWN_SYMPTOMS_TITLE],
        sectionMoreKey: REPORT_STATIC_DATA_SLUGS.UNKNOWN_SYMPTOMS_CONTENT,
        issues: remapIssues(assessmentData.symptomsUnknown),
      });
    }

    return sect;
  }, [
    assessmentData.conditions,
    assessmentData.symptomsHormonal,
    assessmentData.symptomsUnknown,
    staticDataRemapped,
    remapIssues,
  ]);

  return (
    <>
      <DrawerModal
        onClose={handleCloseModal}
        onOpen={handleOpenModal()}
        open={isModalOpen}
      >
        {selectedSection && (
          <BODY2 color="textSecondary">
            {staticDataRemapped[selectedSection]}
          </BODY2>
        )}
        {selectedImpact && (
          <BODY2 color="textSecondary">
            {impactScoreExplainedRemapped[selectedImpact]}
          </BODY2>
        )}
      </DrawerModal>
      {sections.map((section) => (
        <SymptomSection
          key={section.title}
          {...section}
          handleOpenMore={handleOpenModal}
        />
      ))}
    </>
  );
};
