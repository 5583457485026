import styled from 'styled-components';

export const FieldWrapper = styled.div`
  margin-bottom: 16px;
`;

export const ButtonWrapper = styled.div<{ $alignCenter?: boolean }>`
  margin-top: 40px;
  display: flex;
  justify-content: center;
  ${({ theme }) => theme.breakpoints.up('md')} {
    justify-content: ${({ $alignCenter }) =>
      $alignCenter ? 'center' : 'flex-start'};
  }
`;

export const Form = styled.form`
  margin-top: 16px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 24px;
  }
`;
