import {
  CHECKOUT_REQUEST,
  GET_ORDER_REQUEST,
  GET_VIVA_WALLET_TOKEN_REQUEST,
  POST_PRODUCT_TO_ORDER_REQUEST,
  SET_ORDER_CONFIRM_REQUEST,
} from 'modules/Checkout/constants';
import {
  CheckoutPayload,
  CheckoutRequestActionTypes,
  PostProductPayload,
  SetOrderConfirmPayload,
} from 'modules/Checkout/types';
import { ApiHost } from '../axios';
import { ApiReturnFunc } from './index';

const checkoutApi: Record<CheckoutRequestActionTypes, ApiReturnFunc> = {
  [GET_ORDER_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/ordering/order/`,
      method: 'post',
      headers: {},
    };
  },
  [POST_PRODUCT_TO_ORDER_REQUEST]: ({
    orderId,
    productId,
    method,
  }: PostProductPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/order/${orderId}/product/${productId}/`,
      method: method,
      headers: {},
    };
  },
  [GET_VIVA_WALLET_TOKEN_REQUEST]: () => {
    return {
      url: `${ApiHost}/api/v1/ordering/vivawallet/token/`,
      method: 'get',
      headers: {},
    };
  },
  [CHECKOUT_REQUEST]: ({
    accessToken,
    chargeToken,
    amount,
  }: CheckoutPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/checkout/`,
      method: 'post',
      headers: {},
      data: {
        access_token: accessToken,
        charge_token: chargeToken,
        amount,
      },
    };
  },
  [SET_ORDER_CONFIRM_REQUEST]: ({
    willAskClinician,
    willReferToLeaflet,
    willUseMedMyself,
    orderId,
  }: SetOrderConfirmPayload) => {
    return {
      url: `${ApiHost}/api/v1/ordering/order/${orderId}/confirm/`,
      method: 'put',
      headers: {},
      data: {
        medication_used_by_myself: willUseMedMyself,
        refer_to_information_leaflet_before_using: willReferToLeaflet,
        get_in_touch_with_my_clinician: willAskClinician,
      },
    };
  },
};

export default checkoutApi;
