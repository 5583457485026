import { setIsActive } from 'modules/Account/actions';
import {
  getUserAccessTokenRequest,
  logout,
  logoutClientOnly,
} from 'modules/Auth/actions';
import {
  ACTIVATE_EMAIL_ERROR,
  GET_USER_ACCESS_TOKEN_ERROR,
  GET_USER_ACCESS_TOKEN_SUCCESS,
  LOGIN_ERROR,
  LOGIN_SUCCESS,
} from 'modules/Auth/constants';
import { REGISTER_TO_QUESTIONNAIRE_SUCCESS } from 'modules/Questionnaire/constants';
import { RootState } from 'reducers';
import { AnyAction, Middleware } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import api, { RequestActions } from 'utils/api';
import authStorage from 'utils/authStorage';
import { setToStorage } from 'utils/helpers';
import request from 'utils/request';
import { GET_RECOMMENDATION_RESULTS } from 'modules/Results/constants';
import { GET_VIVA_WALLET_TOKEN } from '../modules/Checkout/constants';

const apiRequestMiddleware: Middleware = ({ dispatch }) => (next) => async (
  action,
): Promise<AnyAction> => {
  if (action.type.split('_').pop() !== 'REQUEST') return next(action);

  const { type, payload } = action;

  const requestConfig = api[type as RequestActions](payload);

  const accessToken = authStorage.accessToken;
  if (accessToken && requestConfig.headers) {
    requestConfig.headers['Authorization'] = `Bearer ${accessToken}`;
  }

  // allow REQUEST action go forward till reducer
  // to catch the moment that REQUEST was initiated
  next(action);

  try {
    // dispatch RESPONSE action if request was successful
    const response = await request(requestConfig);
    return dispatch({
      type: type.replace('_REQUEST', '_RESPONSE'),
      payload,
      response,
    });
  } catch (error) {
    // dispatch ERROR action if request failed
    return dispatch({
      type: type.replace('_REQUEST', '_ERROR'),
      payload,
      error,
    });
  }
};

const apiProcessResponseMiddleware: Middleware<
  unknown,
  unknown,
  ThunkDispatch<RootState, unknown, AnyAction>
> = ({ dispatch }) => (next) => (action): Promise<AnyAction> | void => {
  if (
    action.type.split('_').pop() !== 'RESPONSE' &&
    action.type.split('_').pop() !== 'ERROR'
  )
    return next(action);

  // RESPONSE and ERROR actions won't come to the reducer
  // they are changed to the SUCCESS and FAIL types and format known for reducers
  const { type, payload, response, error } = action;
  if (response) {
    const nextAction = {
      response,
      type: type.replace('_RESPONSE', '_SUCCESS'),
      payload,
    };
    dispatch(nextAction);
  } else if (error) {
    const response = {
      status: 'Error occured when making a request',
      message: error.message,
      ...error.response,
    };

    const errorModel = {
      type: type.replace('_ERROR', '_FAIL'),
      payload,
      message:
        response.data?.detail !== undefined
          ? response.data.detail
          : response.message,
      status: response.status,
      error: response,
    };

    if (type === GET_USER_ACCESS_TOKEN_ERROR) {
      dispatch({ ...errorModel, status: 500 });
      dispatch(logoutClientOnly());
    } else if (
      response.status === 401 &&
      response.data.code === 'user_inactive'
    ) {
      dispatch(setIsActive(false));
    } else if (response.status === 401 && type !== LOGIN_ERROR) {
      dispatch(
        getUserAccessTokenRequest({
          ...action,
          type: action.type.replace('_ERROR', '_REQUEST'),
        }),
      );
    } else if (response.status === 403) {
      dispatch(errorModel);
      if (
        type !== `${GET_VIVA_WALLET_TOKEN}_ERROR` &&
        // Activation errors can occur also when the user is not logged in
        // so we avoid dispatcing a logout action because it will clear
        // all the auth state (i.e. error messages )
        type !== ACTIVATE_EMAIL_ERROR &&
        type !== `${GET_RECOMMENDATION_RESULTS}_ERROR`
      ) {
        dispatch(logout());
      }
    } else {
      dispatch(errorModel);
    }
  }
};

const apiSetTokenMiddleware: Middleware = () => (next) => (action): void => {
  // list all actions that give a token
  const { type, response } = action;
  if (
    type !== GET_USER_ACCESS_TOKEN_SUCCESS &&
    type !== LOGIN_SUCCESS &&
    type !== REGISTER_TO_QUESTIONNAIRE_SUCCESS
  )
    return next(action);

  // set token
  authStorage.accessToken = response.data.access;
  next(action);
};

const apiRetryRequest: Middleware = ({ dispatch }) => (next) => (
  action,
): void => {
  const { type, payload } = action;
  if (type !== GET_USER_ACCESS_TOKEN_SUCCESS) return next(action);

  // retry request
  dispatch(payload.action);
};

const apiSetLoginStatusToStorage: Middleware = () => (next) => async (
  action,
): Promise<AnyAction | void> => {
  if (
    action.type !== LOGIN_SUCCESS &&
    action.type !== REGISTER_TO_QUESTIONNAIRE_SUCCESS
  )
    return next(action);

  await setToStorage('isLoggedIn', true);
  next(action);
};

export {
  apiRequestMiddleware,
  apiProcessResponseMiddleware,
  apiSetTokenMiddleware,
  apiRetryRequest,
  apiSetLoginStatusToStorage,
};
