import React, { ReactNode, SyntheticEvent } from 'react';
import { SwipeableDrawerProps, IconButton, Paper } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { Drawer, StickyContainer } from './styles';

export const ModalStickyContainer: React.FC<{
  handleClose: (e: SyntheticEvent) => void;
}> = ({ children, handleClose }) => (
  <StickyContainer>
    <Paper elevation={0}>
      <GridContainer justify="flex-end">
        <GridItem $disableGutters>
          <IconButton onClick={handleClose}>
            <CloseIcon fontSize="large" color="action" />
          </IconButton>
        </GridItem>
      </GridContainer>
      {children}
    </Paper>
  </StickyContainer>
);

const DrawerModal: React.FC<
  SwipeableDrawerProps & { stickyPart?: ReactNode }
> = ({
  children,
  open = true,
  anchor = 'right',
  onClose,
  onOpen,
  disableSwipeToOpen = true,
  stickyPart,
}) => {
  return (
    <Drawer
      anchor={anchor}
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      disableSwipeToOpen={disableSwipeToOpen}
    >
      <Container $paddingBottom={40}>
        <ModalStickyContainer handleClose={onClose}>
          {stickyPart}
        </ModalStickyContainer>
        {children}
      </Container>
    </Drawer>
  );
};

export default DrawerModal;
