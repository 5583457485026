import React from 'react';
import { Control, Controller } from 'react-hook-form';
import {
  BODY2,
  InputProps,
  Autocomplete,
  Input,
} from '@tuunetech/tuune-components';
import { AVAILABLE_COUNTRIES, getCountryObjByName } from 'utils/helpers';
import { SelectItem } from './styles';
import { ShippingDetailFormData } from '../../containers';

type CountriesSelectProps = {
  control: Control<ShippingDetailFormData>;
} & InputProps;

export const CountriesSelect: React.FC<CountriesSelectProps> = ({
  control,
  ...props
}) => {
  return (
    <Controller
      render={({ field: { onChange, onBlur, value } }) => {
        return (
          <Autocomplete
            options={Object.values(AVAILABLE_COUNTRIES).map(({ name }) => name)}
            renderOption={(optionName) => {
              const countryObj = getCountryObjByName(optionName);
              return (
                <SelectItem>
                  <span>{countryObj?.flag}</span>
                  <BODY2 $marginLeft={7}>
                    {countryObj?.name} ({countryObj?.code})
                  </BODY2>
                </SelectItem>
              );
            }}
            onChange={(e, newValue) => {
              onChange({
                ...e,
                target: { value: newValue },
              });
            }}
            onBlur={onBlur}
            value={value}
            renderInput={(params) => (
              <Input
                {...params}
                name="country"
                placeholder="Country*"
                ref={params.InputProps.ref}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'disabled',
                }}
                error={props.error}
                helperText={props.helperText}
              />
            )}
          />
        );
      }}
      name="country"
      control={control}
    />
  );
};
