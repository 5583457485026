import { BODY1, BODY2 } from '@tuunetech/tuune-components';
import React, { useCallback, useState } from 'react';
import { Link } from 'shared';
import { HelpTitle } from './styles';
import HelperBox from '../HelperBox';

const AssignedAtBirth: React.FC = () => {
  const [isHelperBoxOpen, setIsHelperBoxOpen] = useState(false);

  const handleToggleHelperBox = useCallback((isOpen: boolean) => {
    setIsHelperBoxOpen(isOpen);
  }, []);

  const handleOpenHelperBox = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    setIsHelperBoxOpen(true);
  }, []);

  const helpText = (
    <>
      <HelpTitle $marginBottom={24} color="textSecondary">
        Why do I need to be assigned female sex at birth to use Tuune?
      </HelpTitle>
      <BODY2 $marginBottom={24} color="textSecondary">
        We embrace and support all gender identities, however, at this time we
        are unable to make recommendations for people who were assigned at
        birth, or have, an intersex or male reproductive system.
      </BODY2>
      <BODY2 color="textSecondary">
        We care about your health and don’t want to put you at risk. If you were
        assigned at birth, or have, an intersex or male reproductive system–we
        recommend that, at this time, you see a clinician in-person to ensure
        you receive appropriate care. Your safety and wellbeing is always our
        top priority.
      </BODY2>
    </>
  );

  return (
    <>
      <HelperBox
        text={helpText}
        isOpen={isHelperBoxOpen}
        handleToggleHelperBox={handleToggleHelperBox}
      />
      <BODY1 align="left" color="textSecondary">
        I confirm that I was assigned female sex at birth.{' '}
        <Link variant="body1" display="inline" onClick={handleOpenHelperBox}>
          Why?
        </Link>
      </BODY1>
    </>
  );
};

export default AssignedAtBirth;
