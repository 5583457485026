import { createSelector, Selector } from 'reselect';
import { ComplexOutputSelector } from 'reduxStore';
import { RootState } from 'reducers';
import { LoadingReducerState } from '../reducers';

const selectLoading: Selector<RootState, LoadingReducerState> = (state) =>
  state.loading;

export const getIsLoading = (
  actionType: string,
): ComplexOutputSelector<LoadingReducerState, undefined | 0 | 1> =>
  createSelector(selectLoading, (state) => {
    return state[actionType];
  });
