import { createSelector, Selector } from 'reselect';
import { RootState } from 'reducers';
import { CheckoutReducerState } from '../types';

const selectCheckout: Selector<RootState, CheckoutReducerState> = (state) =>
  state.checkout;

export const getCheckoutPageId = createSelector(
  selectCheckout,
  (state) => state.pageId,
);

export const getIsOrderEmpty = createSelector(selectCheckout, (state) => {
  return state.consultation.length === 0 && state.ampp.length === 0;
});

export const getOrderId = createSelector(
  selectCheckout,
  (state) => state.orderId,
);

export const getCart = createSelector(selectCheckout, (state) => {
  return state.ampp
    .map(({ id }) => id)
    .concat(state.consultation.map(({ id }) => id));
});

export const getOrderDetails = createSelector(selectCheckout, (state) => {
  return {
    total: state.total,
    ampp: state.ampp,
    consultation: state.consultation,
  };
});

export const getIsContactDetailsUpdated = createSelector(
  selectCheckout,
  (state) => state.isContactDetailsUpdated,
);

export const getIsShippingDetailsUpdated = createSelector(
  selectCheckout,
  (state) => state.isShippingDetailsUpdated,
);

export const getIsOrderPlaced = createSelector(
  selectCheckout,
  (state) => state.isOrderPlaced,
);

export const getVivaWalletTokenError = createSelector(
  selectCheckout,
  (state) => state.tokenError,
);
