import React from 'react';
import { IonRouterLink, IonRow } from '@ionic/react';
import { Control, FieldErrors } from 'react-hook-form';
import { ContinueButton } from 'shared';
import Routes from 'routes';
import AuthLayout from '../AuthLayout';
import { LoginFormData } from '../../pages/LoginPage';
import { Input } from './styles';

interface LoginProps {
  control: Control<LoginFormData>;
  handleSubmit: () => void;
  errors: FieldErrors<LoginFormData>;
  isValid: boolean;
}

const Login: React.FC<LoginProps> = ({
  control,
  errors,
  handleSubmit,
  isValid,
}) => {
  const bottomSection = {
    text: `Don't have an account? `,
    link: {
      linkText: `Let's get started`,
      linkUrl: Routes.GET_STARTED,
    },
  };

  const cardContent = (
    <form onSubmit={handleSubmit}>
      <IonRow>
        <Input
          name="email"
          placeholder="Email"
          control={control}
          required
          autoFocus
          autoComplete="email"
          type="email"
          inputProps={{
            inputMode: 'email',
          }}
          fullWidth
          error={!!errors.email}
          helperText={errors.email?.message}
        />
        <Input
          name="password"
          placeholder="Password"
          control={control}
          required
          type="password"
          fullWidth
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <ContinueButton
          disabled={!isValid}
          type="submit"
          text="Log in"
          $marginBottom={18}
        />
      </IonRow>
      <div className="ion-text-center">
        <IonRouterLink color="medium" href="/forgot-password">
          I forgot my password
        </IonRouterLink>
      </div>
    </form>
  );

  return (
    <AuthLayout
      title="Sign in"
      subTitle="Enter your details below to continue:"
      cardContent={cardContent}
      bottomSection={bottomSection}
    />
  );
};

export default Login;
