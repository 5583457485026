import { CONTENTFUL_DATA } from './actions';

type OutputKey = {
  reqId: string;
  loadingId: string;
  contentType: string;
};

class ContentfulRequestKeysGenerator {
  private keys: Record<string, number>;
  constructor() {
    this.keys = {};
  }

  static formatOutputKey(key: string, originKey: string): OutputKey {
    return {
      reqId: key,
      loadingId: `${CONTENTFUL_DATA}_${key}`,
      contentType: originKey,
    };
  }

  getKey(key: string): OutputKey {
    if (this.keys[key] !== undefined) {
      const numId = this.keys[key] + 1;
      this.keys[key] = numId;
      return ContentfulRequestKeysGenerator.formatOutputKey(
        `${key}${numId}`,
        key,
      );
    }

    this.keys[key] = 0;
    return ContentfulRequestKeysGenerator.formatOutputKey(key, key);
  }
}

export const keyGen = new ContentfulRequestKeysGenerator();
