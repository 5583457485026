import React from 'react';
import { ContinueButton } from 'shared';
import {
  Container,
  GridContainer,
  GridItem,
  Input,
} from '@tuunetech/tuune-components';

interface FeedbackAnswerProps {
  handleSubmit: () => void;
  feedback: string;
  handleFeedbackChange: (fb: string) => void;
}

const FeedbackAnswer: React.FC<FeedbackAnswerProps> = ({
  handleSubmit,
  feedback,
  handleFeedbackChange,
}) => {
  return (
    <>
      <Container>
        <GridContainer justify="center">
          <GridItem xs={12} md={8} lg={6}>
            <Input
              autoFocus
              multiline
              rows={5}
              value={feedback}
              onChange={(e): void => handleFeedbackChange(e.target.value || '')}
              inputProps={{
                maxLength: 240,
              }}
              fullWidth
            />
          </GridItem>
        </GridContainer>
      </Container>
      <ContinueButton
        onClick={handleSubmit}
        text={feedback ? undefined : 'Nope, all good!'}
      />
    </>
  );
};

export default FeedbackAnswer;
