import React from 'react';
import { Route, RouteProps } from 'react-router';

const AuthRoute: React.FC<RouteProps> = ({
  component: Component,
  ...props
}) => {
  return <Route component={Component} {...props} />;
};

export default AuthRoute;
