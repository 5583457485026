import React, { ReactNode, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { DeleteAccount } from '../components/DeleteAccount';
import {
  CONTENT_TYPE,
  ContentTypeNames as CTN,
  keyGen,
} from 'modules/Contentful/constants';
import { getEntries } from 'modules/Contentful/actions';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import { useDomain } from 'utils/hooks/useDomain';

import {
  documentToReactComponents,
  Options,
} from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import { BODY2, H5 } from '@tuunetech/tuune-components';

const ContentKeys = {
  [CTN.STATIC_CONTENT]: keyGen.getKey(CONTENT_TYPE.STATIC_CONTENT),
};

const deleteHeaderSlug = 'delete_header';
const deleteBodySlug = 'delete_body';

const renderOption: Options = {
  /* eslint-disable react/display-name */
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children): ReactNode => {
      return (
        <BODY2 color="textSecondary" paragraph>
          {children}
        </BODY2>
      );
    },
    [BLOCKS.HEADING_5]: (node, children): ReactNode => {
      return <H5>{children}</H5>;
    },
  },
};

export const DeleteAccountContainer: React.FC = () => {
  const dispatch = useDispatch();
  const staticContent = useSelector(
    getArrayContentMapped(ContentKeys[CTN.STATIC_CONTENT].reqId),
  );
  const domain = useDomain();

  useEffect(() => {
    dispatch(
      getEntries({
        ...ContentKeys[CTN.STATIC_CONTENT],
        domain,
        slugs: [deleteHeaderSlug, deleteBodySlug],
      }),
    );
  }, [dispatch, domain]);

  const dialogHeader = documentToReactComponents(
    staticContent[deleteHeaderSlug]?.content,
    renderOption,
  );

  const dialogIntro = documentToReactComponents(
    staticContent[deleteBodySlug]?.content,
    renderOption,
  );

  return (
    <DeleteAccount dialogHeader={dialogHeader} dialogIntro={dialogIntro} />
  );
};
