import React from 'react';
import { Paper } from '@material-ui/core';
import {
  BODY2,
  Container,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { ProfileItemHeader } from './styles';

export type PersonalInfoProps = {
  profileData: {
    name: string;
    value?: string;
  }[];
};

export const PersonalInfo: React.FC<PersonalInfoProps> = ({ profileData }) => {
  return (
    <Paper>
      <Container $verticalPadding={24} $horizontalPading={24}>
        <H3>Your personal info</H3>
        <GridContainer alignItems="flex-end">
          {profileData.map(({ name, value }) => (
            <React.Fragment key={name}>
              <GridItem xs={4}>
                <ProfileItemHeader color="textSecondary">
                  {name}
                </ProfileItemHeader>
              </GridItem>
              <GridItem xs={8}>
                <BODY2 color="textSecondary">{value}</BODY2>
              </GridItem>
            </React.Fragment>
          ))}
        </GridContainer>
      </Container>
    </Paper>
  );
};
