import styled from 'styled-components';
import { TuuneInput } from 'shared';
import { Button as BaseButton } from '@tuunetech/tuune-components';

export const Input = styled(TuuneInput)`
  margin-top: ${({ theme }) => theme.spacing(2)}px;
`;

export const Button = styled(BaseButton)`
  margin-top: ${({ theme }) => theme.spacing(5)}px;
`;
