import styled from 'styled-components';
import { IonImg, IonToolbar } from '@ionic/react';
import { GridItem } from '@tuunetech/tuune-components';

export const NativeToolbar = styled(IonToolbar)`
  --background: ${({ theme }) => theme.palette.common.white};
`;

export const Logo = styled(IonImg)`
  width: 100px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 120px;
  }
`;

export const LogoContainer = styled(GridItem)`
  position: absolute;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
`;
