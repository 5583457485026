import React from 'react';
import { Control, Controller } from 'react-hook-form';
import { InputBase, MenuItem } from '@material-ui/core';
import { BODY1, BODY2, InputProps, Select } from '@tuunetech/tuune-components';
import {
  AVAILABLE_COUNTRIES,
  DEFAULT_COUNTRY_OBJ,
  getCountryObjByPhoneCode,
} from 'utils/helpers';
import { TuuneInput } from 'shared';
import { ContactDetailFormData } from '../../containers';

export type PhoneInputProps = {
  control: Control<ContactDetailFormData>;
} & InputProps;

export const PhoneInput: React.FC<PhoneInputProps> = ({
  control,
  ...props
}) => (
  <TuuneInput
    name="phone"
    control={control}
    autoComplete="tel"
    {...props}
    InputProps={{
      startAdornment: (
        <>
          <Controller
            name="phoneCode"
            control={control}
            defaultValue={DEFAULT_COUNTRY_OBJ.phoneCode}
            render={({ field: { onChange, onBlur, value } }) => {
              const countryObj = getCountryObjByPhoneCode(value as string);

              return (
                <>
                  <Select
                    onChange={onChange}
                    onBlur={onBlur}
                    value={value}
                    input={<InputBase />}
                    renderValue={() => <span>{countryObj?.flag}</span>}
                  >
                    {AVAILABLE_COUNTRIES.map(({ name, phoneCode }) => (
                      <MenuItem key={phoneCode} value={phoneCode}>
                        <BODY2>{name}</BODY2>
                      </MenuItem>
                    ))}
                  </Select>
                  <BODY1>{value}</BODY1>
                </>
              );
            }}
          />
        </>
      ),
    }}
  />
);
