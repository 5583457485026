import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Box: React.FC<SvgIconProps> = ({ children, ...props }) => {
  return (
    <SvgIcon viewBox="0 0 43 30" width="43" height="30" {...props}>
      {children}
    </SvgIcon>
  );
};
