import { Middleware } from 'redux';
import { GET_VIVA_WALLET_TOKEN_SUCCESS } from 'modules/Checkout/constants';
import { vivaWalletAuthTokenStorage } from 'modules/Checkout/constants/tokenStorage';

export const checkoutSetVivaWalletTokenMiddleware: Middleware = () => (
  next,
) => (action): void => {
  const { type, response, payload } = action;
  if (type !== GET_VIVA_WALLET_TOKEN_SUCCESS) return next(action);

  vivaWalletAuthTokenStorage.accessToken = response.data.access_token;
  // pass action further but without response data (hiding token)
  next({
    type,
    payload,
  });
};
