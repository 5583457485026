import React from 'react';
import { BODY1, BODY2 } from '@tuunetech/tuune-components';

const frontEndVersion = process.env.REACT_APP_BUILD_VERSION || 'V.dev';

export const Version: React.FC<{
  version: string | Record<string, string>;
}> = ({ version }) => {
  return (
    <>
      {typeof version === 'string' ? (
        <BODY1 color="textSecondary">Version {version}</BODY1>
      ) : (
        <>
          <BODY1 color="textSecondary" $marginBottom={8}>
            Versions:
          </BODY1>
          {Object.keys(version).map((key) => (
            <BODY2
              key={key}
              color="textSecondary"
              $marginLeft={10}
              $marginTop={5}
            >
              {key}: {version[key]}
            </BODY2>
          ))}
          <BODY2 color="textSecondary" $marginLeft={10} $marginTop={5}>
            Frontend Version: {frontEndVersion}
          </BODY2>
        </>
      )}
    </>
  );
};
