import { Reducer } from 'redux';
import { ADD_TO_LOADING_LIST, SET_LOADING_FINISH } from '../constants';

export type LoadingReducerState = Record<string, undefined | 1 | 0>;

export const defaultState: LoadingReducerState = {};

const loadingReducer: Reducer<LoadingReducerState> = (
  state = defaultState,
  { type, payload },
) => {
  switch (type) {
    case ADD_TO_LOADING_LIST:
      return {
        ...state,
        [payload.actionType]: 1,
      };
    case SET_LOADING_FINISH:
      return {
        ...state,
        [payload.actionType]: 0,
      };
    default:
      return state;
  }
};

export { loadingReducer as loading };
