import React from 'react';

import { Section } from 'modules/Questionnaire/types';
import {
  Container,
  GridContainer,
  GridItem,
  CAPTION,
} from '@tuunetech/tuune-components';
import {
  DoneIcon,
  Indicator,
  SectionGridItem,
  SectionNameGridItem,
} from './styles';

type ProgressBarProps = {
  currentSection: Section;
  sections: Section[];
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  currentSection,
  sections,
}) => {
  return (
    <Container $marginBottom={16}>
      <GridContainer $disableNegativeMargins justify="center" wrap="nowrap">
        {sections
          .sort(({ order: orderA }, { order: orderB }) => orderA - orderB)
          .map(({ name, order }) => (
            <SectionGridItem
              key={name}
              $disableGutters
              $isActive={order <= currentSection.order}
              data-testid="section"
            >
              <GridContainer direction="column" $disableNegativeMargins>
                <GridItem $disableGutters>
                  <Indicator />
                </GridItem>
                <SectionNameGridItem $disableGutters>
                  <CAPTION color="primary" display="block">
                    {name}
                  </CAPTION>
                  {order < currentSection.order && (
                    <DoneIcon color="primary" data-testid="indicatorIcon" />
                  )}
                </SectionNameGridItem>
              </GridContainer>
            </SectionGridItem>
          ))}
      </GridContainer>
    </Container>
  );
};

export default ProgressBar;
