export default `
  <svg width="28" height="40" viewBox="0 0 28 40" class="ionicon" stroke-width="0" xmlns="http://www.w3.org/2000/svg">
    <path d="M0.0999756 35.5C0.0999756 36.7 0.599976 37.8 1.39998 38.6C1.79998 39 2.19998 39.3 2.69998 39.5C3.19998 39.7 3.89998 39.9 4.49998 39.9H22.8C24 39.9 25.1 39.4 25.9 38.6C26.7 37.7 27.2 36.7 27.2 35.5V4.59999C27.2 3.39999 26.7 2.29999 25.9 1.49999C25.1 0.699991 24 0.0999908 22.9 0.199991H4.59998C3.39998 0.199991 2.29998 0.699991 1.49998 1.49999C0.699976 2.29999 0.0999756 3.39999 0.199976 4.59999L0.0999756 35.5ZM2.19998 2.29999C2.79998 1.69999 3.69998 1.29999 4.49998 1.29999H22.8C23.2 1.29999 23.7 1.39999 24.1 1.59999C24.5 1.79999 24.8 1.99999 25.1 2.29999C25.7 2.89999 26.1 3.79999 26.1 4.59999V35.5C26.1 36.3 25.8 37.1 25.1 37.8C24.5 38.4 23.6 38.8 22.8 38.8L4.49998 38.7C3.69998 38.7 2.89998 38.4 2.19998 37.7C1.59998 37.1 1.19998 36.2 1.19998 35.4V4.49999C1.29998 3.69999 1.69998 2.89999 2.19998 2.29999Z"/>
    <path d="M23 40H4.60002C3.80002 40 3.10002 39.8 2.70002 39.6C2.30002 39.4 1.80002 39.1 1.30002 38.7C0.400024 37.8 -0.0999756 36.7 -0.0999756 35.5L2.44156e-05 4.6C2.44156e-05 3.4 0.400024 2.3 1.30002 1.4C2.20002 0.5 3.30002 0 4.50002 0H22.8C22.9 0 23 0 23 0C24.1 0 25.2 0.4 26 1.3C26.9 2.2 27.4 3.3 27.4 4.5V35.4C27.5 36.6 27 37.7 26.1 38.6C25.3 39.5 24.2 40 23 40ZM4.60002 39.7H22.9C24 39.7 25.1 39.2 25.9 38.4C26.7 37.5 27.2 36.5 27.1 35.4V4.4C27.1 3.3 26.6 2.2 25.8 1.4C24.9 0.6 23.9 0.1 22.8 0.2H4.50002C3.40002 0.2 2.30002 0.7 1.50002 1.5C0.700024 2.4 0.200024 3.4 0.300024 4.5V35.4C0.300024 36.5 0.800024 37.6 1.60002 38.4C2.00002 38.8 2.50002 39.1 2.90002 39.3C3.30002 39.5 3.90002 39.8 4.60002 39.7ZM22.9 38.9L4.50002 38.8C3.70002 38.8 2.80002 38.4 2.20002 37.8C1.60002 37.2 1.20002 36.3 1.20002 35.4V4.5C1.20002 3.6 1.60002 2.7 2.20002 2.1C2.80002 1.5 3.70002 1.1 4.60002 1.1H22.9C23.4 1.1 23.9 1.2 24.3 1.4C24.7 1.6 25 1.8 25.3 2.1C25.9 2.7 26.3 3.6 26.3 4.5V35.4C26.3 36.3 26 37.1 25.3 37.8C24.7 38.5 23.8 38.9 22.9 38.9ZM4.50002 38.5L22.9 38.6C23.7 38.6 24.5 38.3 25.1 37.7C25.7 37.1 26 36.3 26 35.5V4.6C26 3.8 25.7 3 25.1 2.4C24.8 2.1 24.6 1.9 24.2 1.7C23.9 1.6 23.4 1.5 23 1.5H4.70002C3.90002 1.5 3.10002 1.8 2.50002 2.4C1.80002 3 1.50002 3.7 1.40002 4.6V35.5C1.40002 36.3 1.70002 37.1 2.30002 37.7C3.00002 38.2 3.70002 38.5 4.50002 38.5Z"/>
    <path d="M5.6 11.7C6 12.1 6.3 12.3 6.7 12.5C7.2 12.7 7.7 12.8 8.2 12.8C10.3 12.8 12 11.1 12 9.00001C12 6.90001 10.3 5.20001 8.2 5.20001C7.2 5.20001 6.2 5.60001 5.5 6.30001C4.8 7.00001 4.5 8.00001 4.5 9.00001C4.5 10 4.9 11 5.6 11.7ZM8.3 11.7C7.6 11.7 6.9 11.4 6.5 10.9C6.1 10.5 5.9 10.1 5.8 9.60001H11C10.6 10.8 9.6 11.7 8.3 11.7ZM6.5 7.20001C7 6.70001 7.7 6.40001 8.4 6.40001C8.8 6.40001 9.1 6.50001 9.5 6.60001C10.3 6.90001 10.8 7.60001 11 8.40001H5.8C5.9 8.00001 6.1 7.50001 6.5 7.20001Z"/>
    <path d="M8.10005 13C7.60005 13 7.10005 12.9 6.60005 12.7C6.10005 12.5 5.80005 12.2 5.40005 11.9C4.80005 11.1 4.40005 10.1 4.30005 9C4.30005 7.9 4.70005 7 5.50005 6.2C6.20005 5.4 7.20005 5 8.30005 5C10.5 5 12.3 6.8 12.3 9C12.3 11.2 10.5 13 8.30005 13C8.20005 13 8.20005 13 8.10005 13ZM8.30005 5.3C7.30005 5.3 6.40005 5.7 5.70005 6.4C5.00005 7.1 4.60005 8 4.60005 9C4.60005 10.1 5.00005 11 5.70005 11.7C6.00005 12 6.40005 12.2 6.80005 12.4C7.30005 12.6 7.80005 12.7 8.30005 12.7C10.3 12.7 11.9 11.1 12 9C11.9 7 10.3 5.3 8.30005 5.3ZM8.30005 11.8C7.60005 11.8 6.90005 11.5 6.40005 11C6.00005 10.6 5.70005 10.2 5.60005 9.7V9.5H11.1V9.7C10.8 10.9 9.70005 11.8 8.30005 11.8ZM6.00005 9.8C6.10005 10.2 6.30005 10.5 6.60005 10.8C7.10005 11.3 7.70005 11.5 8.30005 11.5C9.40005 11.5 10.4 10.8 10.7 9.7L6.00005 9.8ZM5.60005 8.6V8.4C5.70005 8 5.90005 7.4 6.40005 7C6.90005 6.5 7.60005 6.2 8.40005 6.2C8.80005 6.2 9.10005 6.3 9.50005 6.4C10.3 6.7 10.8 7.4 11.1 8.3L11.2 8.5L5.60005 8.6ZM8.50005 6.6C8.40005 6.6 8.40005 6.6 8.50005 6.6C7.70005 6.6 7.00005 6.8 6.60005 7.3C6.20005 7.6 6.00005 8 6.00005 8.3H10.8C10.5 7.6 10 7.1 9.40005 6.8C9.10005 6.6 8.80005 6.6 8.50005 6.6Z"/>
    <path d="M17.5 12.5C18 12.7 18.5 12.8 19 12.8C20 12.8 21 12.4 21.7 11.7C22.5 11 22.9 10 22.9 9.00001C22.9 6.90001 21.2 5.20001 19.1 5.20001C17 5.20001 15.3 6.90001 15.3 9.00001C15.3 10.6 16.2 11.9 17.5 12.5ZM21 10.9C20.5 11.4 19.8 11.7 19.1 11.7C17.9 11.7 16.8 10.8 16.5 9.70001H21.7C21.6 10.1 21.3 10.5 21 10.9ZM19.1 6.40001C20.3 6.40001 21.4 7.30001 21.7 8.40001H16.5C16.8 7.30001 17.8 6.40001 19.1 6.40001Z"/>
    <path d="M18.9 13C18.4 13 17.9 12.9 17.4 12.7C16 12.1 15.1 10.7 15 9C15 6.8 16.8 5 19 5C21.2 5 23 6.8 23 9C23 10 22.6 11 21.8 11.8C21.1 12.5 20.1 13 19 13C19 13 19 13 18.9 13ZM19.1 5.3C17.1 5.3 15.5 6.9 15.4 9C15.4 10.5 16.3 11.8 17.6 12.4C18.1 12.6 18.6 12.7 19.1 12.7C20.1 12.7 21 12.3 21.7 11.6C22.4 10.9 22.8 10 22.8 9C22.8 7 21.1 5.3 19.1 5.3ZM19 11.8C17.8 11.8 16.6 10.9 16.3 9.6V9.4H21.8V9.6C21.7 10.1 21.5 10.6 21.1 11C20.6 11.5 19.9 11.8 19 11.8C19.1 11.8 19 11.8 19 11.8ZM16.7 9.8C17 10.8 18 11.5 19 11.5C19.7 11.5 20.3 11.2 20.8 10.8C21.1 10.5 21.3 10.1 21.4 9.8H16.7ZM16.3 8.6V8.4C16.6 7.1 17.7 6.2 19 6.2C19 6.2 19 6.2 19.1 6.2C20.3 6.2 21.5 7.1 21.8 8.4V8.6H16.3ZM19.1 6.5C18 6.5 17 7.2 16.7 8.3H21.5C21.2 7.3 20.2 6.5 19.1 6.5C19.2 6.5 19.1 6.5 19.1 6.5Z"/>
    <path d="M4.5 20C4.5 21 4.9 22 5.6 22.7C6 23 6.3 23.3 6.7 23.5C7.2 23.7 7.7 23.8 8.2 23.8C10.3 23.8 12 22.1 12 20C12 17.9 10.3 16.2 8.2 16.2C6.2 16.2 4.5 17.9 4.5 20ZM8.3 22.7C7.6 22.7 6.9 22.4 6.5 21.9C6.1 21.5 5.9 21.1 5.8 20.6H11C10.6 21.8 9.6 22.6 8.3 22.7ZM9.4 17.6C10.2 17.9 10.7 18.6 10.9 19.4H5.7C6 18.2 7 17.3 8.3 17.3C8.7 17.4 9 17.5 9.4 17.6Z"/>
    <path d="M8.10005 23.9C7.60005 23.9 7.10005 23.8 6.60005 23.6C6.10005 23.4 5.80005 23.1 5.40005 22.8C4.70005 22 4.30005 21.1 4.30005 20C4.30005 17.8 6.10005 16 8.20005 16C10.4 16 12.2 17.8 12.2 20C12.2 22.2 10.4 24 8.20005 24C8.20005 23.9 8.20005 23.9 8.10005 23.9ZM4.60005 20C4.60005 21 5.00005 21.9 5.70005 22.6C6.00005 22.9 6.30005 23.1 6.80005 23.3C7.30005 23.5 7.80005 23.6 8.30005 23.6C10.3 23.6 11.9 22 12 19.9C12 17.9 10.4 16.3 8.30005 16.2C6.30005 16.3 4.70005 18 4.60005 20ZM8.30005 22.8C7.60005 22.8 6.90005 22.5 6.40005 22C6.00005 21.6 5.70005 21.1 5.60005 20.6V20.4H11.1V20.6C10.8 21.9 9.60005 22.8 8.30005 22.8ZM5.90005 20.8C6.00005 21.2 6.20005 21.5 6.50005 21.8C7.00005 22.3 7.60005 22.5 8.20005 22.5C9.30005 22.5 10.3 21.7 10.6 20.7L5.90005 20.8ZM5.60005 19.6V19.4C5.90005 18.1 7.00005 17.2 8.30005 17.2C8.60005 17.2 9.00005 17.2 9.40005 17.4C10.2 17.7 10.7 18.4 11 19.3L11.1 19.5L5.60005 19.6ZM8.50005 17.6C8.40005 17.6 8.40005 17.6 8.40005 17.6C7.30005 17.6 6.30005 18.3 6.00005 19.4H10.8C10.6 18.7 10.1 18.1 9.40005 17.9C9.00005 17.6 8.80005 17.6 8.50005 17.6Z"/>
    <path d="M15.3 20C15.3 21 15.7 22 16.4 22.7C16.8 23.1 17.1 23.3 17.5 23.5C18 23.7 18.5 23.8 19 23.8C21.1 23.8 22.8 22.1 22.8 20C22.8 17.9 21.1 16.2 19 16.2C17 16.2 15.3 17.9 15.3 20ZM19.1 22.7C18.4 22.7 17.7 22.4 17.3 21.9C16.9 21.5 16.7 21.1 16.6 20.6H21.8C21.4 21.8 20.3 22.6 19.1 22.7ZM20.2 17.6C21 17.9 21.5 18.6 21.7 19.4H16.5C16.8 18.2 17.8 17.3 19.1 17.3C19.5 17.4 19.8 17.5 20.2 17.6Z"/>
    <path d="M18.9 23.9C18.4 23.9 17.9 23.8 17.4 23.6C16.9 23.4 16.6 23.1 16.2 22.8C15.4 22.1 15 21.1 15 20C15 17.8 16.8 16 18.9 16C21.1 16 22.9 17.8 22.9 20C22.9 22.2 21.1 24 18.9 24C19 23.9 19 23.9 18.9 23.9ZM15.4 20C15.4 21 15.8 21.9 16.5 22.6C16.8 22.9 17.1 23.1 17.6 23.3C18.1 23.5 18.6 23.6 19.1 23.6C21.1 23.6 22.7 22 22.8 19.9C22.8 17.9 21.2 16.3 19.1 16.2C17.1 16.3 15.5 18 15.4 20ZM19.1 22.8C18.4 22.8 17.7 22.5 17.2 22C16.8 21.6 16.5 21.1 16.4 20.6V20.4H21.9V20.6C21.6 21.9 20.4 22.8 19.1 22.8ZM16.7 20.8C16.8 21.2 17 21.5 17.3 21.8C17.8 22.3 18.4 22.5 19 22.5C20.1 22.5 21.1 21.7 21.4 20.7L16.7 20.8ZM16.4 19.6V19.4C16.7 18.1 17.8 17.2 19.1 17.2C19.4 17.2 19.8 17.2 20.2 17.4C21 17.7 21.5 18.4 21.8 19.3L21.9 19.5L16.4 19.6ZM19.3 17.6C19.2 17.6 19.2 17.6 19.2 17.6C18.1 17.6 17.1 18.3 16.8 19.4H21.6C21.4 18.7 20.9 18.1 20.2 17.9C19.8 17.6 19.5 17.6 19.3 17.6Z"/>
    <path d="M4.5 30.1C4.5 31.1 4.9 32.1 5.6 32.8C6 33.2 6.3 33.4 6.7 33.6C7.2 33.8 7.7 33.9 8.2 33.9C10.3 33.9 12 32.2 12 30.1C12 28 10.3 26.3 8.2 26.3C6.2 26.3 4.6 28 4.5 30.1ZM8.4 32.8C7.7 32.8 7 32.5 6.6 32C6.2 31.6 6 31.2 5.9 30.7H11.1C10.7 31.9 9.6 32.8 8.4 32.8ZM9.4 27.8C10.2 28.1 10.7 28.8 10.9 29.6H5.7C6 28.4 7 27.5 8.3 27.5C8.7 27.5 9.1 27.6 9.4 27.8Z"/>
    <path d="M8.20005 34C7.70005 34 7.20005 33.9 6.70005 33.7C6.20005 33.5 5.90005 33.2 5.50005 32.9C4.70005 32.2 4.30005 31.2 4.30005 30.1C4.30005 27.9 6.10005 26.1 8.20005 26.1C10.4 26.1 12.2 27.9 12.2 30.1C12.2 32.3 10.4 34.1 8.20005 34.1C8.30005 34 8.20005 34 8.20005 34ZM4.70005 30.1C4.70005 31.1 5.10005 32 5.80005 32.7C6.10005 33 6.40005 33.2 6.90005 33.4C7.40005 33.6 7.90005 33.7 8.40005 33.7C10.4 33.7 12 32.1 12.1 30C12.1 28 10.5 26.4 8.40005 26.3C6.30005 26.5 4.70005 28.1 4.70005 30.1ZM8.30005 33C7.60005 33 6.90005 32.7 6.40005 32.2C6.00005 31.8 5.70005 31.3 5.60005 30.8V30.6H11.1V30.8C10.8 32 9.70005 32.9 8.30005 33ZM6.00005 30.9C6.10005 31.3 6.30005 31.6 6.60005 31.9C7.10005 32.4 7.70005 32.6 8.30005 32.6C9.40005 32.6 10.4 31.8 10.7 30.8L6.00005 30.9ZM5.60005 29.8V29.6C5.90005 28.3 7.00005 27.4 8.30005 27.4C8.60005 27.4 9.00005 27.4 9.40005 27.6C10.2 27.9 10.7 28.6 11 29.5L11.1 29.7L5.60005 29.8ZM8.50005 27.7H8.40005C7.30005 27.7 6.30005 28.4 6.00005 29.5H10.8C10.6 28.8 10.1 28.2 9.40005 28C9.10005 27.8 8.80005 27.7 8.50005 27.7Z"/>
    <path d="M15.2999 30.1C15.2999 31.1 15.6999 32.1 16.3999 32.8C16.7999 33.2 17.0999 33.4 17.4999 33.6C17.9999 33.8 18.4999 33.9 18.9999 33.9C21.0999 33.9 22.7999 32.2 22.7999 30.1C22.7999 28 21.0999 26.3 18.9999 26.3C16.9999 26.3 15.3999 28 15.2999 30.1ZM19.0999 32.8C18.3999 32.8 17.6999 32.5 17.2999 32C16.8999 31.6 16.6999 31.2 16.5999 30.7H21.7999C21.4999 31.9 20.3999 32.8 19.0999 32.8ZM20.1999 27.8C20.9999 28.1 21.4999 28.8 21.6999 29.6H16.4999C16.7999 28.4 17.7999 27.5 19.0999 27.5C19.4999 27.5 19.8999 27.6 20.1999 27.8Z"/>
    <path d="M19 34C18.5 34 18 33.9 17.5 33.7C17 33.5 16.7 33.2 16.3 32.9C15.5 32.2 15.1 31.2 15.1 30.1C15.1 27.9 16.9 26.1 19 26.1C21.2 26.1 23 27.9 23 30.1C23 32.3 21.2 34.1 19 34.1C19.1 34 19 34 19 34ZM15.5 30.1C15.5 31.1 15.9 32 16.6 32.7C16.9 33 17.2 33.2 17.7 33.4C18.2 33.6 18.7 33.7 19.2 33.7C21.2 33.7 22.8 32.1 22.9 30C22.9 28 21.3 26.4 19.2 26.3C17.1 26.5 15.5 28.1 15.5 30.1ZM19.1 33C18.4 33 17.7 32.7 17.2 32.2C16.8 31.8 16.5 31.3 16.4 30.8V30.6H21.9V30.8C21.6 32 20.4 32.9 19.1 33ZM16.8 30.9C16.9 31.3 17.1 31.6 17.4 31.9C17.9 32.4 18.5 32.6 19.1 32.6C20.2 32.6 21.2 31.8 21.5 30.8L16.8 30.9ZM16.4 29.8V29.6C16.7 28.3 17.8 27.4 19.1 27.4C19.4 27.4 19.8 27.4 20.2 27.6C21 27.9 21.5 28.6 21.8 29.5L21.9 29.7L16.4 29.8ZM19.3 27.7C19.2 27.7 19.2 27.7 19.2 27.7C18.1 27.7 17.1 28.4 16.8 29.5H21.6C21.4 28.8 20.9 28.2 20.2 28C19.8 27.8 19.6 27.7 19.3 27.7Z"/>
  </svg>
`;
