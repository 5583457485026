import styled from 'styled-components';

export const ErrorBox = styled.div`
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 40px;
    margin-bottom: 32px;
  }
`;
