import { Reducer } from 'redux';
import camelcaseKeys from 'camelcase-keys';
import {
  GET_ACCOUNT_DATA_SUCCESS,
  GET_ADDRESS_SUCCESS,
  GET_IS_ACTIVE_SUCCESS,
  SET_IS_ACTIVE,
  UPDATE_ACCOUNT_DATA_SUCCESS,
  UPDATE_ADDRESS_FAIL,
  UPDATE_ADDRESS_SUCCESS,
  UPDATE_MARKETING_PREFERENCE_SUCCESS,
} from '../constants';
import { AccountReducerState } from '../types';

export const defaultState: AccountReducerState = {
  id: undefined,
  email: '',
  firstName: '',
  lastName: '',
  phoneNumber: '',
  isActive: undefined,

  addressLine1: '',
  addressLine2: '',
  city: '',
  county: '',
  country: '',
  postcode: '',

  errors: {},

  // TODO: redefine when backend ready
  avatar: undefined,
  subscriptions: {},
  emailPreferences: true,

  acceptMarketingPreference: false,
  acceptResearchPreference: false,
};

const shippingErrorKeys = ['city', 'country', 'postcode', 'line1', 'line2'];

const accountReducer: Reducer<AccountReducerState> = (
  state = defaultState,
  { type, response: { data = {} } = {}, error, payload = {} },
) => {
  switch (type) {
    case GET_ACCOUNT_DATA_SUCCESS:
    case UPDATE_ACCOUNT_DATA_SUCCESS:
    case UPDATE_MARKETING_PREFERENCE_SUCCESS:
      return {
        ...state,
        ...camelcaseKeys(data, { deep: true }),
      };
    case UPDATE_ADDRESS_SUCCESS:
      if (data) {
        const { city, county, country, postcode, line_1, line_2 } = data;
        return {
          ...state,
          city,
          county,
          country,
          postcode,
          addressLine1: line_1,
          addressLine2: line_2,
          errors: Object.keys(state.errors).reduce((newErrors, errorKey) => {
            if (shippingErrorKeys.includes(errorKey)) return newErrors;
            newErrors[errorKey] = state.errors[errorKey];
            return newErrors;
          }, {} as Record<string, string[]>),
        };
      }
      return state;
    case GET_ADDRESS_SUCCESS:
      if (data[0]) {
        // NOTE: [0] is temporary until we decide how to manage multiple addresses
        const { city, county, country, postcode, line_1, line_2 } = data[0];
        return {
          ...state,
          city,
          county,
          country,
          postcode,
          addressLine1: line_1,
          addressLine2: line_2,
          errors: Object.keys(state.errors).reduce((newErrors, errorKey) => {
            if (shippingErrorKeys.includes(errorKey)) return newErrors;
            newErrors[errorKey] = state.errors[errorKey];
            return newErrors;
          }, {} as Record<string, string[]>),
        };
      }
      return state;
    case GET_IS_ACTIVE_SUCCESS:
      return {
        ...state,
        isActive: data.active,
        email: data.email,
      };
    case UPDATE_ADDRESS_FAIL:
      return {
        ...state,
        errors: { ...state.errors, ...camelcaseKeys(error.data) },
      };
    case SET_IS_ACTIVE:
      return {
        ...state,
        isActive: payload.isActive,
      };
    default:
      return state;
  }
};

export { accountReducer as account };
