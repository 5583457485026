import { Reducer } from 'redux';
import { getErrorMessage, isActionFailed } from '../helpers';
import { ErrorPopupReducerState } from '../types';
import { CLEAR_ERROR_POPUP } from '../constants';

const defaultState: ErrorPopupReducerState = {
  status: undefined,
  message: undefined,
};

const errorPopupReducer: Reducer<ErrorPopupReducerState> = (
  state = defaultState,
  action,
) => {
  if (isActionFailed(action.type)) {
    return {
      ...state,
      status: action.status,
      message: getErrorMessage(action.type, action.status, action.message),
    };
  } else if (action.type === CLEAR_ERROR_POPUP) {
    return defaultState;
  }

  return state;
};

export { errorPopupReducer as errorPopup };
