import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const Account: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <SvgIcon viewBox="0 0 38 43" {...props}>
      <path d="M7.0149 26.2498C3.43463 28.5838 1.0874 31.8556 0.193359 35.7449C2.04527 37.4248 4.12308 38.86 6.37581 39.9998L6.37695 39.9998C10.17 41.9188 14.4589 43 19.0003 43C23.5418 43 27.8307 41.9188 31.6237 39.9998L31.6226 39.9998C33.8754 38.86 35.9532 37.4247 37.8052 35.7448C36.9113 31.8551 34.564 28.5831 30.9835 26.2498C27.6239 24.0624 23.3682 22.857 18.9992 22.857C14.6302 22.857 10.3745 24.0615 7.0149 26.2498ZM25.9998 2.90891C24.2222 1.03303 21.7395 0 18.9992 0C16.2443 0 13.7533 1.02678 11.984 2.89105C10.1955 4.77587 9.32407 7.33746 9.52868 10.1035C9.93425 15.5606 14.1826 19.9999 18.9992 19.9999C23.8157 19.9999 28.0568 15.5615 28.4688 10.1053C28.6761 7.36424 27.7992 4.80801 25.9998 2.90891Z" />
    </SvgIcon>
  );
};
