import styled from 'styled-components';
import { SymptomIconSvg } from 'assets/icons/symptoms/SymptomIcon';
import { Container } from '@tuunetech/tuune-components';

export const SymptomIcon = styled(SymptomIconSvg)`
  color: ${({ theme }) => theme.palette.common.grey[600]};
`;

export const RecommendationContainer = styled(Container)`
  ${({ theme }) => theme.breakpoints.up('lg')} {
    margin-left: max(calc(16.6% - 240px), calc((100% + 18px - 100vw) / 2));
  }
`;
