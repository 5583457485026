import styled from 'styled-components';
import { BODY2, GridItem } from '@tuunetech/tuune-components';
import {
  Checkbox as MuiCheckbox,
  FormControlLabel,
  Theme,
  withStyles,
} from '@material-ui/core';

export const CardBlock = styled.div`
  margin: 0 16px;
`;

export const RowGridItem = styled(GridItem)`
  margin-bottom: 16px;
  :last-of-type {
    margin-bottom: 0;
  }
`;

export const FreeLabel = styled(BODY2)`
  color: ${({ theme }) => theme.palette.success.main};
`;

export const ErrorBox = styled.div`
  display: flex;
  margin-top: 16px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 24px;
  }
`;

export const CheckboxWithLabel = withStyles((theme: Theme) => ({
  root: {
    marginRight: 0,
    alignItems: 'flex-start',
    marginTop: '16px',
  },
  label: {
    marginTop: '4px',
  },
  [theme.breakpoints.up('md')]: {
    root: {
      marginTop: '24px',
    },
  },
}))(FormControlLabel);

export const Checkbox = withStyles({
  root: {
    paddingTop: 0,
    paddingBottom: 0,
  },
})(MuiCheckbox);

export const Form = styled.form`
  margin-top: 32px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 40px;
  }
`;
