import {
  ConfirmOrderContainer,
  ContactDetailsContainer,
  PaymentDetailsContainer,
  ShippingDetailsContainer,
  CheckoutResultContainer,
} from '../containers';
import { Page } from '../pages/CheckoutPage';

export const CHECKOUT_IDS = {
  SUMMARY: 'checkout_summary',
  CONTACT: 'contact_details',
  SHIPPING: 'shipping_details',
  PAYMENT: 'payment_details',
  CONFIRMED: 'confirmed_order',
};

export const PAGES_WITHOUT_ORDER_CARD = [
  CHECKOUT_IDS.SUMMARY,
  CHECKOUT_IDS.CONFIRMED,
];

export const CHECKOUT_PAGES: Page[] = [
  {
    id: CHECKOUT_IDS.SUMMARY,
    name: 'Confirm Order',
    description: '',
    backText: 'back to my report',
    Component: ConfirmOrderContainer,
  },
  {
    id: CHECKOUT_IDS.CONTACT,
    name: 'Contact details',
    description:
      'We need your full legal name in order to prescribe you any medication.',
    Component: ContactDetailsContainer,
  },
  {
    id: CHECKOUT_IDS.SHIPPING,
    name: 'Shipping details',
    description: '',
    Component: ShippingDetailsContainer,
  },
  {
    id: CHECKOUT_IDS.PAYMENT,
    name: 'Payment details',
    description:
      'We accept all major debit and credit cards, including:\n Visa, Mastercard, American Express, JCB, Bancontact.',
    Component: PaymentDetailsContainer,
  },
  {
    id: CHECKOUT_IDS.CONFIRMED,
    name: 'Confirmed order',
    description:
      'Your transaction was successful and your prescription has been sent to our pharmacy partner. We’ll keep you updated via e-mail.',
    Component: CheckoutResultContainer,
  },
];
