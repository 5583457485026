import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getErrorMessage } from '../selectors';
import { clearError } from '../actions';
import ErrorPopup from '../components/ErrorPopup';

const ErrorPopupContainer: React.FC = () => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const errorMessage = useSelector(getErrorMessage);

  useEffect(() => {
    if (errorMessage) {
      setIsOpen(true);
    }
  }, [errorMessage]);

  const handleDismiss = useCallback(() => {
    setIsOpen(false);
    dispatch(clearError());
  }, [dispatch]);

  return (
    <ErrorPopup
      isOpen={isOpen}
      handleDismiss={handleDismiss}
      errorMessage={errorMessage}
    />
  );
};

export default ErrorPopupContainer;
