import lightnings from './lightnings';
import danger from './danger';
import level from './level';
import dna from './dna';
import drop from './drop';
import calendar from './calendar';
import medHistory from './medHistory';
import pillHistory from './pillHistory';
import heart from './heart';
import computer from './computer';
import browser from './browser';
import mood1 from './mood1';
import mood2 from './mood2';
import mood3 from './mood3';
import mood4 from './mood4';
import mood5 from './mood5';
import mood6 from './mood6';
import email from './email';
import phone from './phone';
import lens from './lens';
import pills from './pills';
import baby from './baby';
import backIcon from './backIcon';

const iconsObject: { [key: string]: string } = {
  lightnings,
  danger,
  level,
  dna,
  drop,
  calendar,
  medHistory,
  pillHistory,
  heart,
  computer,
  mood1,
  mood2,
  mood3,
  mood4,
  mood5,
  mood6,
  browser,
  email,
  phone,
  lens,
  pills,
  baby,
};

Object.keys(iconsObject).forEach(
  (key) => (iconsObject[key] = `data:image/svg+xml,${iconsObject[key]}`),
);

export const icons: { [key: string]: string } = {
  ...iconsObject,
};

export { Account as AccountIcon } from './Account';
export { Info as InfoIcon } from './Info';
export { backIcon };
