import styled from 'styled-components';
import MuiFeedbackIcon from '@material-ui/icons/Feedback';
import { Container } from '@tuunetech/tuune-components';
import { FLAG_TYPES } from './index';

export const Alert = styled(Container)`
  margin-bottom: 16px;
  display: flex;
  flex-wrap: nowrap;
`;

export const FeedbackIcon = styled(MuiFeedbackIcon)<{ $type: string }>`
  color: ${({ $type, theme }) =>
    $type === FLAG_TYPES.ORANGE
      ? theme.palette.common.yellow[600]
      : $type === FLAG_TYPES.GREEN
      ? theme.palette.common.green[500]
      : theme.palette.common.red[500]};
`;
