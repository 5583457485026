import React from 'react';
import {
  BODY2,
  Container,
  GridContainer,
  GridItem,
  H4,
} from '@tuunetech/tuune-components';
import { capitalCase } from 'capital-case';
import { Box, Paper } from '@material-ui/core';

import { Logo, Image } from './styles';
import { Link } from 'shared';
import { VersionDetail } from 'modules/Regulation/types';
import { useWebpImage } from 'utils/image';

const frontEndVersion = process.env.REACT_APP_BUILD_VERSION || 'V.dev';

type SoftwareLabelProps = {
  versions?: VersionDetail[];
  backendVersion?: string;
};

export const SoftwareLabel: React.FC<SoftwareLabelProps> = ({
  versions,
  backendVersion,
}) => {
  const [
    logo,
    md,
    ce,
    companyFilled,
    companyOutline,
    warning,
    ecAddress,
  ] = useWebpImage(
    'logo.png',
    'md.jpeg',
    'ce.png',
    'company_filled.png',
    'company_outline.png',
    'warning.png',
    'ec_address.png',
  );

  return (
    <Paper>
      <Container $verticalPadding={24} $horizontalPading={24}>
        <GridContainer direction="column">
          <GridItem>
            <Logo src={logo} alt="Tuune" />
            <H4 $marginTop={16} $marginBottom={24}>
              Contraceptive Pathway Tool
            </H4>
          </GridItem>

          <GridItem>
            <GridContainer alignItems="flex-start">
              <GridItem>
                <Image src={md} alt="Medical device" />
              </GridItem>
              <GridItem>
                <Image src={ce} alt="CE marking" />
              </GridItem>
            </GridContainer>
          </GridItem>

          <GridItem>
            <Box mt={2}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>
                  <Image src={companyFilled} />
                </GridItem>
                <GridItem xs={9}>
                  <BODY2>
                    <b>Uniq Health Ltd t/a Tuune</b>
                  </BODY2>
                  <BODY2>
                    82b High Street, Sawston, Cambridge, England, CB22 3HJ
                  </BODY2>
                  <Link href="http://www.tuune.com">www.tuune.com</Link>
                </GridItem>
              </GridContainer>
            </Box>
          </GridItem>

          <GridItem>
            <Box mt={2}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>
                  <Image src={companyOutline} />
                </GridItem>
                <GridItem xs={9}>
                  <BODY2>2020-06</BODY2>
                  {versions &&
                    versions.map(({ entity, version }: VersionDetail) => (
                      <BODY2 key={entity}>
                        {capitalCase(entity)} Version: <b>{version}</b>
                      </BODY2>
                    ))}
                  <BODY2>
                    Backend Version: <b>{backendVersion}</b>
                  </BODY2>
                  <BODY2>
                    Frontend Version: <b>{frontEndVersion}</b>
                  </BODY2>
                </GridItem>
              </GridContainer>
            </Box>
          </GridItem>

          <GridItem>
            <Box mt={2}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>
                  <Image src={warning} />
                </GridItem>
                <GridItem xs={9}>
                  <BODY2>
                    Using the Tuune system incorrectly may lead to you being
                    supplied a contraception option that is not safe for you to
                    use. Please always read the patient leaflet supplied with
                    any medication and consult with your doctor if you have any
                    concerns.
                  </BODY2>
                </GridItem>
              </GridContainer>
            </Box>
          </GridItem>
          <GridItem>
            <Box mt={2}>
              <GridContainer alignItems="center">
                <GridItem xs={3}>
                  <Image src={ecAddress} />
                </GridItem>
                <GridItem xs={9}>
                  <BODY2>Donawa Lifescience Consulting Srl</BODY2>
                  <BODY2>Piazza Albania, 10</BODY2>
                  <BODY2>00153 Rome, Italy</BODY2>
                </GridItem>
              </GridContainer>
            </Box>
          </GridItem>
        </GridContainer>
      </Container>
    </Paper>
  );
};
