import React, { Fragment } from 'react';
import { useWebpImage } from 'utils/image';
import { BODY2, H3, theme } from '@tuunetech/tuune-components';
import { useMediaQuery } from '@material-ui/core';
import { Flag as FlagType } from 'modules/Results/types';
import {
  PILL_TYPE,
  PILL_TYPE_CODE,
  RECOMMENDATION_GROUP,
} from '../../constants';
import Flag, { FLAG_TYPES } from '../../components/Flag';

import {
  Avatar,
  StyledH3,
  TeamContainer,
  RecommendationInfoLink,
  StyledChip,
  StyledCheckIcon,
  StyledCancelIcon,
  MecChipsContainer,
  InfoRequiredContainer,
  MecEligibilityBlock,
} from './styles';

export type IntroProps = {
  title: string;
  firstName: string;
  recommendationIntro: string;
  isPOPPermitted: boolean;
  isCOCPermitted: boolean;
  ocSuitabilityContent: Record<string, string>;
  recommendationGroup: string;
  mecRedFlags: string[];
  infoRequiredFlags: FlagType[];
  handlePillRecommendationInfoOpen: (contentSlug?: string) => void;
};

const Intro: React.FC<IntroProps> = ({
  title,
  firstName,
  recommendationIntro,
  isPOPPermitted,
  isCOCPermitted,
  ocSuitabilityContent,
  recommendationGroup,
  mecRedFlags,
  infoRequiredFlags,
  handlePillRecommendationInfoOpen,
}) => {
  const isMobile = useMediaQuery(() => theme.breakpoints.down('sm'));
  const medicalTeam = useWebpImage(
    'laura_joigneau.png',
    'peter_fish.png',
    'arushee_prasad.png',
    'ale_ontiveros.png',
  );

  const getPillContentSlug = (
    recommendationGroup: string,
    pillCode: string,
  ): string => {
    if (recommendationGroup === RECOMMENDATION_GROUP.GROUP2) {
      // TODO we hardcode anything in the group_2 to group_2_a for now
      // since we don't have entries in contentful and the back-end
      // is not doing the seapparation into sub-groups yet
      recommendationGroup = `${recommendationGroup}_a`;
    }
    return `${recommendationGroup}_${pillCode}`;
  };

  const ELIGIBILITY_DATA = [
    {
      pillType: PILL_TYPE.chc,
      pillCode: 'coc',
      isPermitted: isCOCPermitted,
    },
    {
      pillType: PILL_TYPE.pop,
      pillCode: PILL_TYPE_CODE.pop,
      isPermitted: isPOPPermitted,
    },
  ];

  const getEligibilityIcon = (isPermitted: boolean) => {
    return isPermitted ? (
      <StyledCheckIcon data-testid="permitted" />
    ) : (
      <StyledCancelIcon data-testid="notPermitted" />
    );
  };

  const getTitleSection = () => {
    const titleText = title?.replace('[name]', firstName);
    if (isMobile) {
      return <StyledH3 align="left">{titleText}</StyledH3>;
    } else {
      const separatorIndex = titleText.indexOf('.');
      const line1 = titleText.slice(0, separatorIndex + 1);
      const line2 = titleText.slice(separatorIndex + 1).trim();
      return (
        <>
          <H3 align="left">{line1}</H3>
          <StyledH3 align="left">{line2}</StyledH3>
        </>
      );
    }
  };

  return (
    <>
      <TeamContainer>
        {medicalTeam.map((imgSrc: string) => (
          <Avatar key={imgSrc} alt="Medical team" src={imgSrc} />
        ))}
      </TeamContainer>
      {getTitleSection()}
      <BODY2 align="left" $marginBottom={24} color="textSecondary">
        {recommendationIntro}
      </BODY2>
      {ELIGIBILITY_DATA.map(({ pillType, pillCode, isPermitted }) => (
        <Fragment key={pillType}>
          <MecEligibilityBlock
            startAdornment={getEligibilityIcon(isPermitted)}
            content={
              <RecommendationInfoLink
                onClick={() =>
                  handlePillRecommendationInfoOpen(
                    getPillContentSlug(recommendationGroup, pillCode),
                  )
                }
              >
                {
                  ocSuitabilityContent[
                    getPillContentSlug(recommendationGroup, pillCode)
                  ]
                }
              </RecommendationInfoLink>
            }
          />
          {!isPermitted && !!mecRedFlags.length && (
            <MecChipsContainer>
              {mecRedFlags.map((flag) => (
                <StyledChip key={flag} label={flag} />
              ))}
            </MecChipsContainer>
          )}
        </Fragment>
      ))}
      {!!infoRequiredFlags.length && (
        <InfoRequiredContainer>
          {infoRequiredFlags.map(({ content, id }) => (
            <Flag key={id} info={content} type={FLAG_TYPES.ORANGE} />
          ))}
        </InfoRequiredContainer>
      )}
    </>
  );
};

export default Intro;
