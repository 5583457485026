import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { getIsActive as fetchIsActive } from 'modules/Account/actions';
import { logout } from 'modules/Auth/actions';
import { useAnalytics } from 'utils/analytics';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import QuestionLayout from '../components/QuestionLayout';
import {
  getCurrentQuestionRequest,
  getFeedbackRequest,
  getPrevQuestionRequest,
} from '../actions';
import { getHasFeedback, getIsCompleted, getQuestionData } from '../selectors';
import { getEmail } from 'modules/Account/selectors';

const QuestionPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const analytics = useAnalytics();
  const email = useSelector(getEmail);

  const isCompleted = useSelector(getIsCompleted);
  const hasFeedback = useSelector(getHasFeedback);
  const [animation, setAnimation] = useState('forward');
  const [isPageHidden, setIsPageHidden] = useState(false);
  const [isHelperBoxOpen, setIsHelperBoxOpen] = useState(false);

  const questionData = useSelector(getQuestionData);

  useEffect(() => {
    dispatch(getCurrentQuestionRequest(0));
    dispatch(fetchIsActive());
    dispatch(getFeedbackRequest());
  }, [dispatch]);

  useEffect(() => {
    if (isCompleted) {
      setIsPageHidden(true);
      // TODO: use BE flag that HA is completed
      if (hasFeedback) {
        history.replace({
          pathname: Routes.RESULTS,
          state: { from: Routes.QUESTIONNAIRE },
        });
      } else {
        history.replace(Routes.FEEDBACK);
      }
    }
  }, [isCompleted, history, hasFeedback]);

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleGoBack = useCallback(() => {
    dispatch(getPrevQuestionRequest((questionData.current as number) - 1));
    setAnimation('back');
  }, [dispatch, questionData]);

  useEffect(() => {
    setAnimation('forward');
  }, [questionData]);

  const progress = useMemo(() => {
    if (
      !questionData.current ||
      !questionData.currentSection ||
      !questionData.sections
    )
      return;
    return {
      current: questionData.current,
      currentSection: questionData.currentSection,
      sections: questionData.sections,
    };
  }, [questionData]);

  const handleToggleHelperBox = useCallback(
    (isOpen: boolean) => {
      if (isOpen) {
        analytics.track(
          ANALYTICS_EVENT.QUESTION_HELP,
          {
            category: ANALYTICS_CATEGORY.HEALTH_ASSESSMENT,
            questionId: questionData.current,
          },
          email,
        );
      }
      setIsHelperBoxOpen(isOpen);
    },
    [analytics, questionData, email],
  );

  return (
    <QuestionLayout
      handleLogout={handleLogout}
      handleGoBack={handleGoBack}
      isGoBackHidden={questionData.current === 1}
      questionData={questionData}
      progress={progress}
      animation={animation}
      isPageHidden={isPageHidden}
      handleToggleHelperBox={handleToggleHelperBox}
      isHelperBoxOpen={isHelperBoxOpen}
    />
  );
};

export default QuestionPage;
