import styled from 'styled-components';
import {
  Container,
  H3,
  SymptomBlock as BaseSymptomBlock,
} from '@tuunetech/tuune-components';

export const SymptomBlock = styled(BaseSymptomBlock)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;

  ${({ theme }) => theme.breakpoints.up('md')} {
    border-bottom: none;
    margin-top: ${({ theme }) => theme.spacing(6)}px;
    padding-bottom: 0;
  }
`;

export const SymptomsContainer = styled(Container)`
  ${H3} {
    text-decoration: underline;
  }

  & + & {
    margin-top: ${({ theme }) => theme.spacing(6)}px;
  }
`;
