import useWaitForGlobal from '../hooks/useWaitForGlobal';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { AnalyticsProvider } from 'utils/analytics';
import { CIVIC_COOKIE_CONFIG, COOKIE_SCRIPTS } from './civicCookie';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

const CookieWrapper: React.FC = ({ children }) => {
  const cookieControlLoaded = useWaitForGlobal('CookieControl');

  useEffect(() => {
    if (cookieControlLoaded) {
      window.CookieControl.load(CIVIC_COOKIE_CONFIG);
    }
  }, [cookieControlLoaded]);

  return (
    <AnalyticsProvider>
      <Helmet>{COOKIE_SCRIPTS}</Helmet>
      {children}
    </AnalyticsProvider>
  );
};

export default CookieWrapper;
