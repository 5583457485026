import React from 'react';
import { Toolbar, Paper } from '@material-ui/core';
import {
  BODY1,
  Button,
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { AppBar } from './styles';

export type NextBarProps = {
  isActive: boolean;
  handleCheckout: () => void;
};

const NextBar: React.FC<NextBarProps> = ({ isActive, handleCheckout }) => {
  return (
    <AppBar position="fixed" component="div" color="inherit" elevation={7}>
      <Toolbar disableGutters>
        <Container fixed={false} disableGutters $verticalPadding={19}>
          <Paper square elevation={0}>
            <Container>
              <GridContainer justify="center">
                <GridItem xs={12} lg={8}>
                  <GridContainer alignItems="center" justify="space-between">
                    <GridItem>
                      <BODY1 color="textPrimary">Are you ready?</BODY1>
                    </GridItem>
                    <GridItem>
                      <Button disabled={!isActive} onClick={handleCheckout}>
                        Continue
                      </Button>
                    </GridItem>
                  </GridContainer>
                </GridItem>
              </GridContainer>
            </Container>
          </Paper>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default NextBar;
