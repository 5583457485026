import { icons } from 'assets/icons';

export const FACTORS = [
  {
    title: 'Symptoms Assessment',
    shortDesc: 'Here is a holistic summary of your symptom experience',
    desc:
      "Based on the answers that you gave in the health assessment, we have been able to gain a deeper understanding of the symptoms you experience. We have summarised how active these symptoms are for you, when you get them, how severe they are and how they're being managed. Importantly, we have also captured how these symptoms impact your mental health and wellbeing. This 360 symptom assessment allows us to make recommendations with both your physical and emotional health in mind.",
    icon: icons.heart,
  },
  {
    title: 'Contraception History',
    shortDesc: 'Here is a list of your contraceptive methods to date',
    desc:
      'Understanding the methods of contraception that you have used in the past can help decide what the best method of contraception for you, moving forward, can be. \n' +
      "We have compiled a chronilogical list of past contraceptives so that we can gain a deeper understanding of which methods worked or didn't work for you, at different points in your life.",
    icon: icons.pillHistory,
  },
  {
    title: 'Medical History',
    shortDesc: 'Your Tuune medical record',
    desc:
      'Here is a digital summary of your medical and gynaecological history. Whether you experience them now or in the past, certain medical or hormone-related conditions can determine whether you are medically eligibility for certain methods of contraception.',
    icon: icons.medHistory,
  },
  {
    title: 'Cycle',
    shortDesc: 'Your unique menstrual cycle',
    desc:
      'Based on your health assessment results and hormone test results, we have built a model of your unique menstrual cycle. \n' +
      'The orchestrated rise and fall of your hormones, in your individual cyclical pattern, can inform which contraceptive method is best for you.\n' +
      'The best way to build an even clearer picture of your moving hormones, is to track your menstrual cycle symptoms over time.',
    icon: icons.calendar,
  },
  {
    title: 'Hormone Levels',
    shortDesc: 'Your hormone test results are here',
    desc:
      'Your unique hormone levels are linked with your health assessment results. \n' +
      'This allows us to directly demonstrate the relationship between your hormonal levels and the symptoms you experience. \n' +
      "Hormone levels are dynamic and change over time so it's important to track and re-test them.",
    icon: icons.level,
  },
  {
    title: 'Genetics & Drug Metabolism',
    shortDesc: 'Your genetic results are here',
    desc:
      "We don't test every gene. Using a targeted approach, we only test the genes that are directly linked to your hormonal health. \n" +
      'This includes looking at genes that are linked to the way that you metabolise hormones, your predisposition to certain conditions or risk factors for taking certain hormonal medications.\n' +
      'Understanding you, at a genetic level, allows us greater precision in making a recommendation.',
    icon: icons.dna,
  },
];
