import { AxiosRequestConfig } from 'axios';
import { AuthRequestActionTypes } from 'modules/Auth/types';
import { AccountRequestActionTypes } from 'modules/Account/types';
import { QuestionnaireRequestActionTypes } from 'modules/Questionnaire/types';
import { ResultsRequestActionTypes } from 'modules/Results/types';
import { CheckoutRequestActionTypes } from 'modules/Checkout/types';
import { RegulationRequestActionTypes } from 'modules/Regulation/types';
import account from './account';
import auth from './auth';
import questionnaire from './questionnaire';
import results from './results';
import checkout from './checkout';
import regulation from './regulation';

// add other types as alternatives with | for other modules
export type RequestActions =
  | AuthRequestActionTypes
  | AccountRequestActionTypes
  | QuestionnaireRequestActionTypes
  | ResultsRequestActionTypes
  | CheckoutRequestActionTypes
  | RegulationRequestActionTypes;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type ApiReturnFunc = (props: any) => AxiosRequestConfig;

const api: Record<RequestActions, ApiReturnFunc> = {
  ...auth,
  ...account,
  ...questionnaire,
  ...results,
  ...checkout,
  ...regulation,
};

export default api;
