import styled from 'styled-components';
import { BODY1, Container } from '@tuunetech/tuune-components';

export const StartContainer = styled(Container)`
  margin-top: ${({ theme }) => theme.spacing(3)}px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: ${({ theme }) => theme.spacing(7)}px;
  }

  img {
    width: 100%;
    border-radius: 40px;
  }

  ${BODY1}, img {
    margin-bottom: ${({ theme }) => theme.spacing(5)}px;
  }
`;
