import React from 'react';
import { theme } from '@tuunetech/tuune-components';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare const window: any;

type CivicCookieCategory = {
  name: string;
  label: string;
  description: string;
  cookies: string[];
  thirdPartyCookies?: { name: string; optOutLink: string }[];
  vendors: {
    name: string;
    description: string;
    url: string;
    thirdPartyCookies?: boolean;
  }[];
  onAccept?: () => void;
  onRevoke?: () => void;
  recommendedState?: boolean;
  lawfulBasis?: string;
};

export enum COOKIE_CATEGORY_NAMES {
  ANALYTICS = 'analytics',
  MARKETING = 'marketing',
  FUNCTIONAL = 'functional',
}

const ANALYTICS: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.ANALYTICS,
  label: 'Analytics',
  description: `We use some cookies to collect information about how people are using our Services.
                  For example, these cookies allow us to know which pages are visited the most often, how people are moving from one link to another and if they get error messages from certain pages. Overall, these cookies provide us with analytical information about how our Services are performing and how we can improve them.`,
  cookies: ['_ga', '_gid', '_gat*'],
  vendors: [
    {
      name: 'Google',
      url: 'https://policies.google.com/technologies/types?hl=en-US',
      description: 'Google Analytics',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'granted' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_given',
        event: 'analytics_consent_given',
      });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { analytics_storage: 'denied' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_analytics: 'consent_revoked',
      });
  },
};

const MARKETING: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.MARKETING,
  label: 'Marketing',
  description: `These show us which products you’ve been looking at so we can show you ads you might like later.`,
  cookies: ['fr'],
  vendors: [
    {
      name: 'Facebook',
      url: 'https://www.facebook.com/policies/cookies/',
      description: 'Facebook',
    },
  ],
  onAccept: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'granted' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_marketing: 'consent_given',
        event: 'marketing_consent_given',
      });
  },
  onRevoke: function () {
    window.gtag &&
      window.gtag('consent', 'update', { marketing_storage: 'denied' });
    window.dataLayer &&
      window.dataLayer.push({
        civic_cookies_marketing: 'consent_revoked',
      });
  },
};

const FUNCTIONAL: CivicCookieCategory = {
  name: COOKIE_CATEGORY_NAMES.FUNCTIONAL,
  label: 'Functional',
  description: `These cookies are used to help us surface specific bits of functionality, aimed at gathering your feedback on the product.`,
  cookies: ['_delighted'],
  vendors: [
    {
      name: 'Delighted',
      url: 'https://app.delighted.com/privacy/',
      description: 'Delighted',
    },
  ],
  onRevoke: function () {
    window.CookieControl.delete('_delighted');
  },
};

const optionalCookies: CivicCookieCategory[] = [];

if (typeof navigator !== 'undefined' && typeof window !== 'undefined') {
  // respect do not track option
  if (!(navigator.doNotTrack == '1' || window.doNotTrack == '1')) {
    optionalCookies.push(ANALYTICS);
    optionalCookies.push(MARKETING);
    optionalCookies.push(FUNCTIONAL);
  }
}

export function isConsentGranted(category: string): boolean {
  const categoryIndex = optionalCookies.findIndex(
    (element) => element.name === category,
  );
  if (!categoryIndex) {
    return false;
  }
  return window.CookieControl.getCategoryConsent(categoryIndex);
}

export const CIVIC_COOKIE_CONFIG = {
  apiKey: process.env.REACT_APP_CIVIC_API_KEY,
  product: 'PRO',
  optionalCookies: optionalCookies,
  excludedCountries: 'all',
  mode: 'CCPA',

  statement: {
    description: 'For more information visit our',
    name: 'Privacy Policy',
    url: 'https://www.tuune.com/privacy-policy',
    updated: '14/07/2021',
  },
  ccpaConfig: {
    description:
      'This may include the website or our third-party tools processing personal data. In the case of your personal data, you may opt out of such activity by using the buttons below. To find out more about the categories of personal information collected and the purposes for which such information will be used, please refer to our',
    name: 'Privacy Policy',
    url: 'https://www.tuune.com/privacy-policy',
    updated: '14/07/2021',
  },

  initialState: 'notify',
  position: 'RIGHT',
  theme: 'DARK',
  notifyDismissButton: false,
  branding: {
    fontFamily: theme.typography.fontFamily,
    fontColor: theme.palette.common.white,
    notifyFontColor: theme.palette.common.white,
    backgroundColor: theme.palette.common.purple[900],
    alertBackground: theme.palette.common.purple[900],
  },
};

const CIVIC_SCRIPT = (
  <script
    key="civic_source"
    src="https://cc.cdn.civiccomputing.com/9/cookieControl-9.x.min.js"
  />
);

const DELIGHTED_SCRIPT = (
  <script key="delighted_init">
    {`
      if ("%REACT_APP_DELIGHTED_ENABLED%") {
        !function(e,t,r,n){if(!e[n]){for(var a=e[n]=[],i=["survey","reset","config","init","set","get","event","identify","track","page","screen","group","alias"],c=0;c<i.length;c++){var s=i[c];a[s]=a[s]||function(e){return function(){var t=Array.prototype.slice.call(arguments);a.push([e,t])}}(s)}a.SNIPPET_VERSION="1.0.1";var o=t.createElement("script");o.type="text/javascript",o.async=!0,o.src="https://d2yyd1h5u9mauk.cloudfront.net/integrations/web/v1/library/"+r+"/"+n+".js";var p=t.getElementsByTagName("script")[0];p.parentNode.insertBefore(o,p)}}(window,document,"k3BEzgoc2h8SwIHv","delighted");
      }
    `}
  </script>
);

export const COOKIE_SCRIPTS = [
  <script
    key="gtag_script"
    async
    src={`https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GTM}`}
  />,
  CIVIC_SCRIPT,
  <script key="data_layer_init">
    {`
    window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', '${process.env.REACT_APP_GTM}');
  `}
  </script>,
  DELIGHTED_SCRIPT,
];
