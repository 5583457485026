import React from 'react';
import { CreateAnimation } from '@ionic/react';

interface TransitionAnimationProps {
  duration: number;
  animation?: string;
}

const TransitionAnimation: React.FC<TransitionAnimationProps> = ({
  duration,
  animation,
  children,
}) => {
  return (
    <CreateAnimation
      duration={duration}
      fromTo={[
        {
          property: 'opacity',
          fromValue: '0.3',
          toValue: '1',
        },
        {
          property: 'transform',
          fromValue: `translateX(${
            animation?.includes('back') ? '-' : ''
          }100%)`,
          toValue: 'translateX(0px)',
        },
      ]}
      play={!!animation}
      iterations={1}
    >
      <div style={{ height: '100%' }}>{children}</div>
    </CreateAnimation>
  );
};

export default TransitionAnimation;
