import React from 'react';
import { Link } from 'shared';

export const TERMS = [
  {
    key: 1,
    value: (
      <>
        I confirm that I have read and understood the information about the user
        testing in this agreement and have had the opportunity to ask questions
        via email (
        <Link
          href="mailto:help@tuune.com"
          display="inline"
          variant="body1"
          color="textSecondary"
          target="_blank"
        >
          help@tuune.com
        </Link>
        ).
      </>
    ),
  },
  {
    key: 2,
    value: (
      <>
        I understand that this testing is with an unvalidated system and any
        information given cannot be used without consulting with a doctor, that
        my participation is voluntary, and that I am free to withdraw at any
        time without giving any reason.
      </>
    ),
  },
  {
    key: 3,
    value: (
      <>
        I understand that relevant sections of my health records and data
        collected during the user testing may be looked at by authorized
        individuals from regulatory bodies and user testing study sponsors for
        the purpose of monitoring the research. I give permission to these
        individuals to have access to my records.
      </>
    ),
  },
  {
    key: 4,
    value: (
      <>
        I agree that the information gathered about me may be shared anonymously
        with researchers for the purposes of understanding female health.
      </>
    ),
  },
  {
    key: 5,
    value: (
      <>
        I agree to take part in the assessment as described in this agreement
        and consent to take part in the user testing.
      </>
    ),
  },
  {
    key: 6,
    value: (
      <>
        I agree to be contacted again about further Tuune research and for my
        contact details to be retained. I understand that I will be able to
        choose whether to take part in further research.
      </>
    ),
  },
];
