class AuthStorage {
  private _accessToken: string;
  constructor(accessToken = '') {
    this._accessToken = accessToken;
  }

  get accessToken(): string {
    return this._accessToken;
  }

  set accessToken(accessToken: string) {
    this._accessToken = accessToken;
  }
}

export default new AuthStorage();
