import React from 'react';
import { IonCol } from '@ionic/react';
import { Check, Text } from 'shared';
import { CycleOptionProps } from './DesktopCycleOption';
import styles from './style.module.css';

const MobileCycleOption: React.FC<CycleOptionProps> = ({
  text,
  active,
  onClick,
  ...props
}) => {
  return (
    <IonCol
      size="12"
      className={`${styles.cycleOption} ${styles.cycleOption_mobile}`}
      onClick={(): void => onClick()}
      {...props}
    >
      <div className={`${styles.iconContainer} ${styles.iconContainer_mobile}`}>
        <Check active={active} />
        <div className={`${styles.line} ${styles.line_mobile}`} />
      </div>
      <div className={styles.textContainer_mobile}>
        {/*<Text>{text.day}</Text>*/}
        {/*<Text className={styles.phase}>{text.phase}</Text>*/}
        <Text fontSize={14} className={`${styles.desc} ion-text-start`}>
          {text.desc}
        </Text>
      </div>
    </IonCol>
  );
};

export default MobileCycleOption;
