const Routes = {
  DEFAULT_ROUTE: '/questions',
  HOME: '/',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password',
  VERIFY_EMAIL: '/verify-email',
  PUBLIC_VERIFY_EMAIL: '/verify',
  RESEND_EMAIL_CUSTOM: '/resend-email',
  ACCOUNT: '/account',
  HELP: '/help',
  REGULATION: '/regulation',
  EDIT: '/edit',
  DELETE: '/delete',
  QUESTIONNAIRE: '/questions',
  GET_STARTED: '/get-started',
  RESULTS: '/results',
  ACTIVATE: '/activate',
  START_HA: '/start',
  FEEDBACK: '/feedback',
  CHECKOUT: '/checkout',
};

export default Routes;
