import React from 'react';
import {
  Container,
  H5,
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '@tuunetech/tuune-components';
import { useMediaQuery, useTheme } from '@material-ui/core';

type RecommendationCardPanelProps = {
  title: string;
  onChange?: (event: Event, expanded: boolean) => void;
};

const RecommendationCardPanel: React.FC<RecommendationCardPanelProps> = ({
  title,
  children,
  onChange,
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'));
  return (
    <Accordion
      $disablePaper
      onChange={onChange}
      $horizontalPadding={isDesktop ? 24 : 16}
    >
      <AccordionSummary $verticalPadding={24}>
        <H5 align="left">{title}</H5>
      </AccordionSummary>
      <AccordionDetails $paddingTop={0} $paddingBottom={24}>
        <Container disableGutters>{children}</Container>
      </AccordionDetails>
    </Accordion>
  );
};

export default RecommendationCardPanel;
