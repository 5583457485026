import { Visa, Mastercard, Amex, JCB } from 'assets/icons/cardType';

export enum CardType {
  visa = 'visa',
  mastercard = 'mastercard',
  amex = 'amex',
  jcb = 'jcb',
}

export const CARD_TYPE_ICON = {
  [CardType.visa]: Visa,
  [CardType.mastercard]: Mastercard,
  [CardType.amex]: Amex,
  [CardType.jcb]: JCB,
};
const CARD_TYPE_REGEXP = {
  [CardType.visa]: /^4[0-9]{0,}$/,
  [CardType.mastercard]: /^(5[1-5]|222[1-9]|22[3-9]|2[3-6]|27[01]|2720)[0-9]{0,}$/,
  [CardType.amex]: /^3[47][0-9]{0,}$/,
  [CardType.jcb]: /^(?:2131|1800|35)[0-9]{0,}$/,
};

export const detectCardType = (number: string): CardType | undefined => {
  for (const key in CARD_TYPE_REGEXP) {
    if (CARD_TYPE_REGEXP[key as CardType].test(number)) {
      return key as CardType;
    }
  }
};
