import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Toolbar, useTheme, useMediaQuery } from '@material-ui/core';
import {
  BODY1,
  Container,
  GridContainer,
  GridItem,
  H2,
} from '@tuunetech/tuune-components';
import { logout } from 'modules/Auth/actions';
import { getAccountDataRequest } from 'modules/Account/actions';
import { getFirstName } from 'modules/Account/selectors';
import {
  Avatar,
  AppBar,
  AccountIcon,
  NativeHeader,
  NativeToolbar,
} from './styles';
import { NavBox } from './NavBox';
import { useHistory } from 'react-router';

export type HeaderProps = {
  pageName: string;
};

const Header: React.FC<HeaderProps> = ({ pageName, children }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const dispatch = useDispatch();
  const history = useHistory();

  const userName = useSelector(getFirstName);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  const handleRoute = useCallback(
    (route: string) => {
      setAnchorEl(null);
      history.push(route);
    },
    [history],
  );

  useEffect(() => {
    if (!userName) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, userName]);

  return (
    <NativeHeader className="ion-no-border">
      <NativeToolbar>
        <AppBar position="static" color="inherit" elevation={0}>
          <Toolbar disableGutters>
            <Container fixed={!isMobile}>
              <GridContainer justify="space-between" alignItems="flex-end">
                <GridItem>
                  <H2 align="left" paragraph>
                    {pageName}
                  </H2>
                </GridItem>
                <GridItem>
                  <Avatar src="/broken" onClick={handleClick}>
                    {userName ? (
                      <BODY1 color="primary">{userName.charAt(0)}</BODY1>
                    ) : (
                      <AccountIcon color="primary" />
                    )}
                  </Avatar>
                  <NavBox
                    isOpen={!!anchorEl}
                    anchorEl={anchorEl}
                    handleClose={handleClose}
                    handleLogout={handleLogout}
                    handleRoute={handleRoute}
                  />
                </GridItem>
              </GridContainer>
              {children}
            </Container>
          </Toolbar>
        </AppBar>
      </NativeToolbar>
    </NativeHeader>
  );
};

export default Header;
