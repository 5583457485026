import React, {
  useCallback,
  useEffect,
  ReactElement,
  useState,
  useMemo,
  ReactNode,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { SvgIcon } from '@material-ui/core';
import { useHistory } from 'react-router';
import Routes from 'routes';
import { getIsLoading } from 'modules/Loading/selectors';
import { getOrderRequest } from 'modules/Checkout/actions';
import { GET_ORDER, POST_PRODUCT_TO_ORDER } from 'modules/Checkout/constants';
import { getCart } from 'modules/Checkout/selectors';
import { PillRecommendation, Benefits } from 'modules/Results/types';
import { getArrayContentMapped } from 'modules/Contentful/selectors';
import {
  ReportContentfulKeys as RCK,
  getResultsContentfulOptions,
  HOW_TO_START_SUMMARY_SLUG,
} from '../constants';
import { ContentTypeNames as CTN } from 'modules/Contentful/constants';
import { documentToPlainTextRecords } from 'modules/Contentful/helpers';
import { symptomIcons } from '../../../assets/icons/symptoms';
import { SymptomIcon } from '../components/RecommendationLayout/styles';
import RecommendationLayout from '../components/RecommendationLayout';
import {
  getRecommendations,
  getRecommendationType,
  getInfoRequiredFlags,
} from '../selectors';
import { RECOMMENDATION_GROUP } from '../constants';
import { ResultsLayoutProps } from '../components/ResultsLayout';
import {
  COOKIE_CATEGORY_NAMES,
  isConsentGranted,
} from 'utils/cookies/civicCookie';
import { useDomain } from 'utils/hooks/useDomain';

interface BenefitProps {
  name: string;
  icon: ReactElement;
  impact: string;
  caption: string;
}

type RecommendationContainerProps = {
  handleRecommendationAdd: ResultsLayoutProps['handleRecommendationAdd'];
};

export const RecommendationContainer: React.FC<RecommendationContainerProps> = ({
  handleRecommendationAdd,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const domain = useDomain();

  const [
    isRecommendationDetailsDialogOpen,
    setIsRecommendationDetailsDialogOpen,
  ] = useState(false);
  const [currentRecommendation, setCurrentRecommendation] = useState(undefined);

  const cart = useSelector(getCart);
  const isOrderLoading = useSelector(getIsLoading(GET_ORDER));
  const isPostRemoveProductLoading = useSelector(
    getIsLoading(POST_PRODUCT_TO_ORDER),
  );
  const infoRequiredFlags = useSelector(getInfoRequiredFlags);
  // define the group for patient based on POP.permitted & COC.permitted
  const recommendationGroup = useSelector(getRecommendationType);
  // get pill&referral recommendations list from BE response
  const recommendations = useSelector(getRecommendations);
  // get regimen summary for each pill in recommendations list

  const recommendationBenefitDetails = useSelector(
    getArrayContentMapped(
      RCK[CTN.CONTRACEPTION_BENEFIT_DETAIL_PERSONALISED].reqId,
    ),
  );
  const recommendedConsiderationsSummary = useSelector(
    getArrayContentMapped(RCK[CTN.RECOMMENDED_CONSIDERATIONS_SUMMARY].reqId),
  );
  const pillRegimenSummary = useSelector(
    getArrayContentMapped(RCK[CTN.RECOMMENDED_REGIMEN_SUMMARY].reqId),
  );
  const switchingContraceptionPreview = useSelector(
    getArrayContentMapped(
      RCK[CTN.SWITCHING_CONTRACEPTIVE_METHOD_GENERIC].reqId,
    ),
  );

  const contracptiveStatus = (
    current: boolean | undefined,
    eligible: 'yes' | 'no',
  ): 'current' | 'currentUnrecommended' | 'best' => {
    if (current && eligible === 'yes') {
      return 'current';
    } else if (current && eligible === 'no') {
      return 'currentUnrecommended';
    } else {
      return 'best';
    }
  };

  const convertDocumentToReactComponents = useCallback((documents) => {
    const content: Record<string, ReactNode> = {};

    return Object.keys(documents).reduce((content, key) => {
      content[key] = documentToReactComponents(
        documents[key]?.content,
        getResultsContentfulOptions(),
      );
      return content;
    }, content);
  }, []);

  const pillRegimenSummaryContent = useMemo(() => {
    // remap contentful document to React components for regimen summary
    return convertDocumentToReactComponents(pillRegimenSummary);
  }, [convertDocumentToReactComponents, pillRegimenSummary]);
  const considerationsSummaryContent = useMemo(() => {
    // remap contentful document to React components for considerations summary
    return convertDocumentToReactComponents(recommendedConsiderationsSummary);
  }, [convertDocumentToReactComponents, recommendedConsiderationsSummary]);
  const switchingPillInfoSummary = useMemo(() => {
    return documentToReactComponents(
      switchingContraceptionPreview[HOW_TO_START_SUMMARY_SLUG]?.content,
      getResultsContentfulOptions(),
    );
  }, [switchingContraceptionPreview]);

  const recommendationBenefitDetailsRemapped = documentToPlainTextRecords(
    recommendationBenefitDetails,
  );

  const handleCheckout = useCallback(() => {
    history.push(Routes.CHECKOUT);
  }, [history]);

  useEffect(() => {
    // for group_3
    // when added (cart.length === 1) request ended redirect to checkout
    // when deleted (cart.length === 0) request ended stay on results page
    if (
      recommendationGroup === RECOMMENDATION_GROUP.GROUP3 &&
      isPostRemoveProductLoading === 0 &&
      cart.length === 1
    ) {
      handleCheckout();
    }
  }, [recommendationGroup, isPostRemoveProductLoading, cart, handleCheckout]);

  useEffect(() => {
    if (isOrderLoading === undefined) {
      dispatch(getOrderRequest());
    }
  }, [dispatch, isOrderLoading]);

  useEffect(() => {
    const SECONDS_ON_PAGE: number = 150 * 1000; // 2.5 minutes
    setTimeout(
      () =>
        isConsentGranted(COOKIE_CATEGORY_NAMES.FUNCTIONAL) &&
        window.delighted &&
        window.delighted.survey({
          forceDisplay: true,
          properties: {
            domain: domain,
            environment: process.env.REACT_APP_ENV,
          },
        }),
      SECONDS_ON_PAGE,
    );
  }, [domain]);

  const IconPlaceHolder: React.FC = () => (
    <SvgIcon>
      <circle cx="12" cy="12" r="10" fill="#BDBDBD" />
    </SvgIcon>
  );

  const remapBenefits = useCallback(
    (benefits: Benefits, recommendationSlug: string): BenefitProps[] => {
      return benefits?.recommendationSpecificBenefits?.map(
        ({ impact, issueName, issueSlug }) => {
          const symptomIcon = symptomIcons[issueSlug] ? (
            <SymptomIcon>{symptomIcons[issueSlug]}</SymptomIcon>
          ) : (
            <IconPlaceHolder />
          );
          return {
            name: issueName,
            icon: symptomIcon as ReactElement,
            impact: impact,
            caption:
              recommendationBenefitDetailsRemapped[
                `${issueSlug}-${recommendationSlug}`
              ],
          };
        },
      );
    },
    [recommendationBenefitDetailsRemapped],
  );

  const onLearnMoreClickCallback = useCallback(
    (recommendation, benefits: Benefits) => {
      const recommendationWithBenefits = {
        ...recommendation,
        benefits: benefits,
      };
      setIsRecommendationDetailsDialogOpen(true);
      setCurrentRecommendation(recommendationWithBenefits);
    },
    [],
  );

  const onRecommendationDetailsDialogClose = useCallback(() => {
    setIsRecommendationDetailsDialogOpen(false);
    setCurrentRecommendation(undefined);
  }, []);

  const contraceptiveRecommendations = recommendations.length
    ? recommendations.map((recommendation) => {
        const contraceptiveRecommendation = recommendation as PillRecommendation;
        const formattedRecommendation = {
          name: contraceptiveRecommendation.name,
          id: contraceptiveRecommendation.id,
          generics: contraceptiveRecommendation.otherBrands?.map(
            (generic) => generic.name,
          ),
          status: contracptiveStatus(
            contraceptiveRecommendation.current,
            contraceptiveRecommendation.eligible,
          ),
          slug: contraceptiveRecommendation.slug,
          type: contraceptiveRecommendation.pillType,
          benefits: remapBenefits(
            contraceptiveRecommendation.benefits,
            recommendation.slug,
          ),
        };
        return {
          ...formattedRecommendation,
          onLearnMoreClickCallback: () =>
            onLearnMoreClickCallback(
              formattedRecommendation,
              contraceptiveRecommendation.benefits,
            ),
        };
      })
    : undefined;

  return (
    <RecommendationLayout
      cart={cart}
      isNextBarVisible={
        recommendationGroup !== RECOMMENDATION_GROUP.GROUP3 || cart.length !== 0
      }
      handleCheckout={handleCheckout}
      handleRecommendationAdd={handleRecommendationAdd}
      recommendations={contraceptiveRecommendations}
      isRecommendationDetailsDialogOpen={isRecommendationDetailsDialogOpen}
      currentRecommendation={currentRecommendation}
      onRecommendationDetailsDialogClose={onRecommendationDetailsDialogClose}
      considerationsSummaryContent={considerationsSummaryContent}
      pillRegimenSummaryContent={pillRegimenSummaryContent}
      switchingPillInfoSummary={switchingPillInfoSummary}
      infoRequiredFlags={infoRequiredFlags}
    />
  );
};

export default RecommendationContainer;
