import React, { useCallback } from 'react';
import { BODY2 } from '@tuunetech/tuune-components';
import { FeedbackIcon, Alert } from './styles';

export const FLAG_TYPES = {
  RED: 'RED',
  ORANGE: 'ORANGE',
  GREEN: 'GREEN',
  MEC_RED: 'MEC_RED',
};

type FlagProps = {
  info: string;
  type: string;
};

const Flag: React.FC<FlagProps> = ({ info, type }) => {
  const renderFlagIcon = useCallback(() => {
    return <FeedbackIcon $type={type} />;
  }, [type]);

  return (
    <>
      <Alert disableGutters>
        {renderFlagIcon()}
        <BODY2 align="left" $marginTop={3} $marginLeft={16}>
          {info}
        </BODY2>
      </Alert>
    </>
  );
};

export default Flag;
