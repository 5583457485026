import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import { useInterval } from 'utils/hooks';
import Routes from 'routes';
import { getEmail, getIsActive } from 'modules/Account/selectors';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import { getIsActive as fetchIsActive } from 'modules/Account/actions';
import { resendActivationRequest } from 'modules/Auth/actions';
import VerifyEmail from '../components/Answers/VerifyEmail';
import QuestionLayout from '../components/QuestionLayout';
import { getUnauthenticatedEmailAddress } from 'modules/Auth/selectors';
import { getIsLoading } from 'modules/Loading/selectors';
import { RESEND_ACTIVATION } from 'modules/Auth/constants';

const VerifyEmailPage: React.FC = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const email = useSelector(getEmail);
  const unauthenticatedEmailAddress = useSelector(
    getUnauthenticatedEmailAddress,
  );
  const isUnauthenticatedEmailAddressLoading = useSelector(
    getIsLoading(RESEND_ACTIVATION),
  );
  const isActive = useSelector(getIsActive);
  const isAuthenticated = useSelector(getIsAuthenticated);
  const [interval, setInterval] = useState<number | null>(5000);
  const [isPageHidden, setIsPageHidden] = useState(false);

  useInterval(() => {
    if (isAuthenticated) dispatch(fetchIsActive());
  }, interval);

  useEffect(() => {
    if (!isAuthenticated) {
      setInterval(null);
      if (
        !isUnauthenticatedEmailAddressLoading &&
        !unauthenticatedEmailAddress
      ) {
        setIsPageHidden(true);
        history.replace(Routes.RESEND_EMAIL_CUSTOM);
      }
    }

    if (isActive) {
      setInterval(null);
      setIsPageHidden(true);
      history.replace(Routes.START_HA);
    }
  }, [
    dispatch,
    isActive,
    isAuthenticated,
    history,
    unauthenticatedEmailAddress,
    isUnauthenticatedEmailAddressLoading,
  ]);

  const emailToUse = email || unauthenticatedEmailAddress;

  const handleResend = useCallback(() => {
    if (emailToUse) {
      dispatch(resendActivationRequest({ email: emailToUse }));
    }
  }, [dispatch, emailToUse]);

  const handleChangeEmail = useCallback(() => {
    setIsPageHidden(true);
    history.replace(Routes.RESEND_EMAIL_CUSTOM);
  }, [history]);

  return (
    <QuestionLayout
      handleGoBack={undefined}
      questionData={{}}
      isPageHidden={isPageHidden}
      customAnswer={
        <VerifyEmail
          email={emailToUse}
          handleResend={handleResend}
          handleChangeEmail={handleChangeEmail}
          isAuthenticated={isAuthenticated}
        />
      }
    />
  );
};
export default VerifyEmailPage;
