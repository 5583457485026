import React, { ReactNode } from 'react';
import {
  BODY1,
  GridItem,
  Container,
  GridContainer,
} from '@tuunetech/tuune-components';
import { Control, Controller } from 'react-hook-form';
import { ContinueButton, ErrorBox } from 'shared';
import { PrivacyFormData } from 'modules/Questionnaire/types';
import { PRIVACY } from 'modules/Questionnaire/constants';
import { TERMS } from './terms';
import { Checkbox } from '@material-ui/core';
import { Option, StyledBody1, ListItem } from './styles';

interface TestingConsentProps {
  options: { id: PRIVACY; label: string | ReactNode }[];
  handleSubmit: () => void;
  error: boolean;
  control: Control<PrivacyFormData>;
}

const TestingConsent: React.FC<TestingConsentProps> = ({
  options,
  handleSubmit,
  error,
  control,
}) => {
  return (
    <>
      <Container>
        <GridContainer justify="center">
          <GridItem md={8} lg={6}>
            <StyledBody1 paragraph align="left" color="textSecondary">
              <span>Fine-tuning with you first.</span> The data and feedback you
              share with us will help us build a product that you not only need
              but love to use.
            </StyledBody1>
            <StyledBody1 align="left" color="textSecondary" gutterBottom>
              <span>This is a test environment.</span> User testing is not
              validated and should not be used for clinical purposes.
            </StyledBody1>
            <BODY1 align="left" color="textSecondary" gutterBottom>
              Please read the following statements carefully before agreeing to
              this agreement.
            </BODY1>
            {TERMS.map(({ key, value }) => (
              <ListItem
                key={key}
                startAdornment={<BODY1 color="textSecondary">{key}.</BODY1>}
                content={
                  <BODY1 gutterBottom color="textSecondary">
                    {value}
                  </BODY1>
                }
              />
            ))}
          </GridItem>
        </GridContainer>
        {error && <ErrorBox text="All fields are required to continue" />}
        <GridContainer alignContent="center" direction="column">
          {options.map(({ id, label }) => (
            <GridItem key={id} md={8} lg={6}>
              <Option
                startAdornment={
                  <Controller
                    name={id}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        id={id}
                        color="primary"
                        checked={value}
                        {...field}
                      />
                    )}
                  />
                }
                content={
                  <label htmlFor={id}>
                    {typeof label === 'string' ? (
                      <BODY1 align="left" color="textSecondary">
                        {label}
                      </BODY1>
                    ) : (
                      label
                    )}
                  </label>
                }
              />
            </GridItem>
          ))}
        </GridContainer>
        <ContinueButton onClick={handleSubmit} />
      </Container>
    </>
  );
};

export default TestingConsent;
