import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useDispatch, useSelector } from 'react-redux';
import { getIsLoading } from 'modules/Loading/selectors';
import { getErrorMessage } from 'modules/ErrorPopup/selectors';
import { PageProps } from '../pages/CheckoutPage';
import { PaymentDetails } from '../components/PaymentDetails';
import {
  GET_ORDER,
  GET_VIVA_WALLET_TOKEN,
  paymentDetailsSchema,
} from '../constants';
import { CardType, detectCardType } from '../constants/cardType';
import {
  checkoutRequest,
  getOrderRequest,
  getVivaWalletTokenRequest,
  resetTokenError,
} from '../actions';
import { vivaWalletAuthTokenStorage } from '../constants/tokenStorage';
import {
  getIsOrderPlaced,
  getOrderDetails,
  getVivaWalletTokenError,
} from '../selectors';

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    VivaPayments: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    delighted: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    CookieControl: any;
  }
}

export type PaymentDetailFormData = {
  txtCardNumber: string;
  txtCardHolder: string;
  txtMonth: string;
  txtYear: string;
  txtCVV: string;
};

export const PaymentDetailsContainer: React.FC<PageProps> = ({
  setErrorMessage,
  handleNext,
}) => {
  const dispatch = useDispatch();

  const [cardType, setCardType] = useState<CardType | undefined>(undefined);
  const [isThreedPaneVisible, setIsThreedPaneVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const isTokenLoading = useSelector(getIsLoading(GET_VIVA_WALLET_TOKEN));
  const orderDetails = useSelector(getOrderDetails);
  const isOrderDetailsLoading = useSelector(getIsLoading(GET_ORDER));
  const isOrderPlaced = useSelector(getIsOrderPlaced);
  const error = useSelector(getErrorMessage);
  const tokenError = useSelector(getVivaWalletTokenError);

  const {
    handleSubmit,
    formState: { errors },
    control,
    watch,
    setValue,
  } = useForm<PaymentDetailFormData>({
    resolver: yupResolver(paymentDetailsSchema),
    mode: 'onTouched',
  });

  useEffect(() => {
    dispatch(getVivaWalletTokenRequest());
    dispatch(resetTokenError());
  }, [dispatch]);

  useEffect(() => {
    if (isOrderPlaced) {
      handleNext && handleNext();
    }
  }, [handleNext, isOrderPlaced]);

  useEffect(() => {
    if (isOrderDetailsLoading === undefined) {
      dispatch(getOrderRequest());
    }
  }, [isOrderDetailsLoading, dispatch]);

  useEffect(() => {
    if (error) {
      setErrorMessage && setErrorMessage(error);
      setIsLoading(false);
    }
  }, [error, setErrorMessage]);

  useEffect(() => {
    if (isTokenLoading === 0 && !tokenError) {
      const baseUrl = process.env.REACT_APP_VIVA_WALLET_URL;
      window.VivaPayments.cards.setup({
        baseURL: baseUrl,
        authToken: vivaWalletAuthTokenStorage.accessToken,
        cardHolderAuthOptions: {
          cardHolderAuthPlaceholderId: 'threed-pane',
          cardHolderAuthInitiated: function () {
            setIsThreedPaneVisible(true);
          },
          cardHolderAuthFinished: function () {
            setIsThreedPaneVisible(false);
          },
        },
      });
    }
  }, [isTokenLoading, tokenError]);

  const onSubmit = useCallback(() => {
    setIsLoading(true);
    window.VivaPayments.cards
      .requestToken({
        amount: orderDetails.total * 100,
      })
      .done(function (data: { chargeToken: string }) {
        dispatch(
          checkoutRequest({
            accessToken: vivaWalletAuthTokenStorage.accessToken,
            chargeToken: data.chargeToken,
            amount: orderDetails.total * 100,
          }),
        );
        setErrorMessage && setErrorMessage('');
      })
      .fail(
        (err: {
          Error: { ErrorCode: number; EventId: string; ErrorText: string };
        }) => {
          // TODO: handle error properly and show to user
          let message = err.Error.ErrorText;
          console.error(err.Error.ErrorText);
          if (err.Error.EventId === '6000') {
            message =
              'Your payment could not be processed as entered. Double check your details or try paying with a different method.';
          }
          setErrorMessage && setErrorMessage(message);
          setIsLoading(false);
        },
      );
  }, [dispatch, orderDetails.total, setErrorMessage]);

  const watchCardNumber = watch('txtCardNumber');

  // format card number
  useEffect(() => {
    if (watchCardNumber?.length > 4) {
      const cardNumberWithoutFormat = watchCardNumber.replaceAll(' ', '');
      const formattedCardNumber = cardNumberWithoutFormat
        .match(/.{1,4}/g)
        ?.join(' ');
      if (formattedCardNumber) {
        setValue('txtCardNumber', formattedCardNumber);
      }
    }
  }, [setValue, watchCardNumber]);

  // detect card type
  useEffect(() => {
    setCardType(detectCardType(watchCardNumber?.replaceAll(' ', '')));
  }, [watchCardNumber]);

  return (
    <PaymentDetails
      control={control}
      errors={errors}
      cardType={cardType}
      isThreedPaneVisible={isThreedPaneVisible}
      handleSubmit={handleSubmit(onSubmit)}
      isLoading={isLoading}
      isDisabled={tokenError}
    />
  );
};
