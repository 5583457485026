import styled from 'styled-components';
import {
  Avatar as MuiAvatar,
  AppBar as MuiAppBar,
  Paper,
  MenuList as MuiMenuList,
  MenuItem as MuiMenuItem,
} from '@material-ui/core';
import { BODY1 } from '@tuunetech/tuune-components';
import { AccountIcon as BaseAccountIcon } from 'assets/icons';
import { IonHeader, IonToolbar } from '@ionic/react';

export const NativeHeader = styled(IonHeader)`
  position: relative;
  --ion-safe-area-bottom: 0;

  ::before {
    content: '';
    display: block;
    height: 2px;
    width: 100vw;
    background-color: ${({ theme }) => theme.palette.common.purple[50]};
    position: absolute;
    bottom: 0;
    z-index: 1000;
  }
`;

export const NativeToolbar = styled(IonToolbar)`
  --min-height: auto;
  --padding-bottom: 0;
`;

export const AppBar = styled(MuiAppBar)`
  background-color: ${({ theme }) => theme.palette.common.white};
  height: 100%;
`;

export const AccountIcon = styled(BaseAccountIcon)`
  width: 80%;
  height: 80%;
  align-self: flex-end;
`;

export const Avatar = styled(MuiAvatar)`
  width: 52px;
  height: 52px;
  background: ${({ theme }) => theme.palette.primary.light};
  margin-top: 1.5rem;
  margin-bottom: 2.187rem;
  cursor: pointer;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }

  ${BODY1} {
    font-size: 28px;
  }
`;

export const NavPaper = styled(Paper)`
  border-radius: ${({ theme }) => theme.borderRadius.medium}px;
  margin-top: 20px;
`;

export const MenuList = styled(MuiMenuList)`
  padding: 4px 0;
`;

export const MenuItem = styled(MuiMenuItem)`
  padding: 12px 24px;
`;
