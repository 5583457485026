import React, { ReactNode } from 'react';
import { IonHeader } from '@ionic/react';
import { AppBar, Toolbar, useMediaQuery, useTheme } from '@material-ui/core';
import {
  Container,
  GridContainer,
  GridItem,
} from '@tuunetech/tuune-components';
import { ProgressBar } from 'shared';
import { Section } from 'modules/Questionnaire/types';
import { NativeToolbar, Logo, LogoContainer } from './styles';
import { useWebpImage } from 'utils/image';

interface HeaderProps {
  left?: ReactNode;
  right?: ReactNode;
  progress?: {
    currentSection: Section;
    sections: Section[];
  };
}

const Navbar: React.FC<HeaderProps> = ({ progress, left, right }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [logo] = useWebpImage('logo.png');

  return (
    <IonHeader className="ion-no-border">
      <NativeToolbar>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar disableGutters>
            <Container fixed={!isMobile}>
              <GridContainer justify="space-between" alignItems="center">
                <GridItem>{left}</GridItem>
                <LogoContainer>
                  <Logo src={logo} alt="Tuune" />
                </LogoContainer>
                <GridItem>{right}</GridItem>
              </GridContainer>
            </Container>
          </Toolbar>
          {progress && progress?.currentSection?.name ? (
            <ProgressBar {...progress} />
          ) : null}
        </AppBar>
      </NativeToolbar>
    </IonHeader>
  );
};

export default Navbar;
