import React, { useState, useEffect } from 'react';
import { IonContent, IonPage } from '@ionic/react';
import { useInterval } from 'utils/hooks';
import {
  BackgroundContainer,
  ContentContainer,
  Message,
  TopMoonImage,
  BottomMoonImage,
  LoadingButton,
  LoadingIcon,
} from './styles';
import {
  LOADING_MESSAGE_1,
  LOADING_MESSAGE_2,
  LOADING_MESSAGE_3,
  LOADING_MESSAGE_ERROR,
  BUTTON_MESSAGE_LOADING,
  BUTTON_MESSAGE_READY,
  BUTTON_MESSAGE_ERROR,
} from '../../constants';
import { useWebpImage } from 'utils/image';

type LoadingProps = {
  isDataLoading: boolean;
  onReadyButtonClickCallback: () => void;
  onRetryButtonClickCallback: () => void;
  errors?: { status: number | string };
};

const Loading: React.FC<LoadingProps> = ({
  isDataLoading,
  onReadyButtonClickCallback,
  onRetryButtonClickCallback,
  errors,
}) => {
  const [topMoon, bottomMoon] = useWebpImage('top_moon.png', 'bottom_moon.png');
  const [interval, setInterval] = useState<number | null>(5000);
  const [currentLoadingMessageIndex, setCurrentLoadingMessageIndex] = useState(
    0,
  );
  const messages = [
    LOADING_MESSAGE_1,
    LOADING_MESSAGE_2,
    LOADING_MESSAGE_3,
    LOADING_MESSAGE_ERROR,
  ];

  useEffect(() => {
    if (!!errors) {
      setCurrentLoadingMessageIndex(3);
      setInterval(null);
    }
  }, [errors]);

  // Cycle through the messages
  useInterval(() => {
    if (currentLoadingMessageIndex < 2) {
      setCurrentLoadingMessageIndex(currentLoadingMessageIndex + 1);
    } else {
      setInterval(null);
    }
  }, interval);

  const onButtonClick = () => {
    if (!!errors) {
      onRetryButtonClickCallback();
    } else if (!isLoading) onReadyButtonClickCallback();
  };

  const isLoading = !(currentLoadingMessageIndex === 2) || isDataLoading;

  const buttonText = !!errors
    ? BUTTON_MESSAGE_ERROR
    : isLoading
    ? BUTTON_MESSAGE_LOADING
    : BUTTON_MESSAGE_READY;

  return (
    <IonPage>
      <IonContent>
        <BackgroundContainer justify="center">
          <ContentContainer xs={12} md={10} lg={8}>
            <TopMoonImage src={topMoon} alt="Top Moon" />
            {messages[currentLoadingMessageIndex].map((snippet) => {
              return <Message key={snippet}>{snippet}</Message>;
            })}
            <LoadingButton
              size="medium"
              startIcon={
                isLoading && !errors ? <LoadingIcon size={20} /> : null
              }
              onClick={onButtonClick}
            >
              {buttonText}
            </LoadingButton>
            <BottomMoonImage src={bottomMoon} alt="Bottom Moon" />
          </ContentContainer>
        </BackgroundContainer>
      </IonContent>
    </IonPage>
  );
};

export default Loading;
