import styled from 'styled-components';
import { BODY2, H3 } from '@tuunetech/tuune-components';

export const Title = styled(H3)`
  margin: 1rem 0;

  ${({ theme }) => theme.breakpoints.up('md')} {
    margin: 1.5rem 0;
  }

  + ${BODY2} {
    white-space: pre-line;
  }
`;
