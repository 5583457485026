import { Box } from '@material-ui/core';
import { Container } from '@tuunetech/tuune-components';
import React, { ReactNode, useCallback, useState } from 'react';
import { Link } from 'shared';
import { DeleteAccountDialog } from '../DeleteAccountDialog';

type DeleteAccountProps = {
  dialogHeader: ReactNode;
  dialogIntro: ReactNode;
};

export const DeleteAccount: React.FC<DeleteAccountProps> = ({
  dialogHeader,
  dialogIntro,
}) => {
  const [isDialogOpen, setDialogOpen] = useState<boolean>(false);

  const handleDialogOpen = useCallback(() => {
    setDialogOpen(true);
  }, [setDialogOpen]);

  const handleDialogClose = useCallback(() => {
    setDialogOpen(false);
  }, [setDialogOpen]);

  return (
    <Container $verticalPadding={24} $horizontalPading={24}>
      <Box textAlign="center">
        <Link onClick={handleDialogOpen}>Delete my account</Link>
      </Box>
      <DeleteAccountDialog
        header={dialogHeader}
        intro={dialogIntro}
        isDataLoading={false}
        isOpen={isDialogOpen}
        onClose={handleDialogClose}
      />
    </Container>
  );
};
