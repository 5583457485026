import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import Routes from 'routes';
import { validateConfirmPassword, validatePassword } from 'utils/helpers';
import { ResetPasswordFormErrors, ResetPasswordValues } from '../types';
import ResetPassword from '../components/ResetPassword';
import {
  resetPasswordConfirmRequest,
  resetPasswordFlowReset,
} from '../actions';
import { getAuthErrors, getIsResetPasswordConfirmed } from '../selectors';

const ResetPasswordPage: React.FC = () => {
  const dispatch = useDispatch();
  const isResetPasswordConfirmed = useSelector(getIsResetPasswordConfirmed);
  const initialErrors = useSelector(getAuthErrors);
  const history = useHistory();
  const { uid, token } = useParams<{ uid: string; token: string }>();

  const initialValues = {
    password: '',
    confirmPassword: '',
  };

  useEffect(() => {
    if (initialErrors.uid || initialErrors.token) {
      dispatch(resetPasswordFlowReset());
      history.replace(Routes.FORGOT_PASSWORD, { isLinkValid: false });
    }
  }, [initialErrors.uid, initialErrors.token, history, dispatch]);

  const handleResetPopupDismiss = useCallback(() => {
    history.replace(Routes.LOGIN);
  }, [history]);

  const onSubmit = useCallback(
    ({ password, confirmPassword }) => {
      dispatch(
        resetPasswordConfirmRequest({ uid, token, password, confirmPassword }),
      );
    },
    [uid, token, dispatch],
  );

  const validate = ({
    password,
    confirmPassword,
  }: ResetPasswordValues): ResetPasswordFormErrors => {
    const errors: ResetPasswordFormErrors = {};

    // password validation
    const passwordError = validatePassword(password);
    passwordError && (errors.password = passwordError);
    // confirmPassword validation
    const confirmPasswordError = validateConfirmPassword(
      password,
      confirmPassword,
    );
    confirmPasswordError && (errors.confirmPassword = confirmPasswordError);

    return errors;
  };

  return (
    <ResetPassword
      onSubmit={onSubmit}
      initialValues={initialValues}
      initialErrors={initialErrors}
      validate={validate}
      isResetPasswordConfirmed={isResetPasswordConfirmed}
      handleResetPopupDismiss={handleResetPopupDismiss}
    />
  );
};

export default ResetPasswordPage;
