import { RootState } from 'reducers';
import { Assessment, AssessmentReducerState } from '../types';

import { createSelector, Selector } from 'reselect';
import { UserFriendlyVersionKeys, VersionKeys } from '../constants';

export const selectAssessment: Selector<RootState, AssessmentReducerState> = (
  state,
) => state.assessment;

export const getAllAssessmentData = createSelector(
  selectAssessment,
  (state) => state.assessment,
);

export const getAssessmentData = createSelector(selectAssessment, (state) => {
  const conditions: Assessment[] = [];
  const symptomsHormonal: Assessment[] = [];
  const symptomsUnknown: Assessment[] = [];
  state.assessment.forEach((issue) => {
    const {
      issueType,
      scores: { Hormonal },
    } = issue;
    // Hormonal Condition
    if (issueType === 'condition') {
      conditions.push(issue);
    } else {
      // Likely affected by hormones
      if (Hormonal >= 0.6) {
        symptomsHormonal.push(issue);
      } else {
        // Needs more investigation
        symptomsUnknown.push(issue);
      }
    }
  });
  return {
    conditions,
    symptomsHormonal,
    symptomsUnknown,
  };
});

export const getRecapData = createSelector(
  selectAssessment,
  ({ recapCurrent, recapDestination, recapRoute }) => {
    return recapCurrent
      .map(({ content }) => content)
      .concat(
        recapDestination
          .map(({ content }) => content)
          .concat(recapRoute.map(({ content }) => content)),
      );
  },
);

export const getIsRecapGoalsClosed = createSelector(
  selectAssessment,
  (state) => state.isRecapGoalsClosed,
);

export const getRedFlags = createSelector(
  selectAssessment,
  (state) => state.flagRed,
);

export const getGreenFlags = createSelector(
  selectAssessment,
  (state) => state.flagGreen,
);

export const getReportVersions = createSelector(selectAssessment, (state) =>
  Object.keys(state.versions || {}).reduce<Record<string, string>>(
    (acc, versionKey) => {
      acc[UserFriendlyVersionKeys[versionKey as VersionKeys]] = state.versions
        ? state.versions[versionKey as VersionKeys]
        : '';
      return acc;
    },
    {},
  ),
);
