import React from 'react';
import { SvgIconProps } from '@material-ui/core';
import { Box } from './Box';

export const JCB: React.FC<SvgIconProps> = ({ ...props }) => {
  return (
    <Box {...props}>
      <rect
        x="1"
        y="0.5"
        width="41.5"
        height="29"
        rx="3.5"
        fill="white"
        stroke="#D9D9D9"
      />
      <path
        d="M27.9767 14.9426C28.6408 14.9571 29.3087 14.9133 29.9701 14.9654C30.6399 15.0903 30.8014 16.1044 30.2063 16.4365C29.8002 16.6553 29.3179 16.518 28.8777 16.5566H27.9767V14.9426ZM30.3541 13.1159C30.5016 13.6367 29.9996 14.1043 29.498 14.0325H27.9767C27.9874 13.5414 27.9559 13.0084 27.9922 12.5431C28.6016 12.5603 29.2167 12.5081 29.8226 12.5704C30.0829 12.6358 30.3008 12.8498 30.3541 13.1159ZM34.0152 5.39298C34.0435 6.38753 34.0193 7.43464 34.0274 8.44936C34.0256 12.5749 34.0314 16.7002 34.0242 20.8259C33.9975 22.3721 32.6273 23.7155 31.0918 23.7464C29.5549 23.7526 28.0178 23.7474 26.4806 23.7491V17.5122C28.1554 17.5035 29.831 17.5297 31.5051 17.4991C32.2817 17.4504 33.1324 16.9379 33.1685 16.0833C33.26 15.225 32.4508 14.6313 31.6824 14.5375C31.3871 14.5298 31.3957 14.4514 31.6824 14.4172C32.4152 14.2588 32.9907 13.5004 32.7751 12.7408C32.5914 11.942 31.7084 11.6328 30.9739 11.6343C29.4764 11.6241 27.9786 11.6329 26.481 11.6299C26.4906 10.4656 26.4608 9.29997 26.4971 8.13647C26.6157 6.61825 28.0204 5.36621 29.5343 5.39338C31.0281 5.39315 32.5217 5.39315 34.0152 5.39309V5.39298Z"
        fill="url(#paint0_linear)"
      />
      <path
        d="M9.55741 8.29388C9.59566 6.7502 10.9718 5.41775 12.5052 5.39519C14.0364 5.39047 15.5679 5.39451 17.0991 5.39314C17.0949 10.558 17.1074 15.7234 17.0928 20.8879C17.0339 22.4128 15.6728 23.7199 14.156 23.7469C12.6219 23.7524 11.0876 23.7477 9.55338 23.7492V17.3021C11.0436 17.6541 12.6063 17.804 14.1266 17.5705C15.0353 17.4242 16.0296 16.978 16.3373 16.0352C16.5637 15.2287 16.4362 14.38 16.4699 13.5523V11.6301H13.8389C13.8271 12.9014 13.8631 14.1749 13.8199 15.4447C13.749 16.2252 12.976 16.721 12.2399 16.6946C11.327 16.7042 9.51803 16.033 9.51803 16.033C9.51348 13.6511 9.54451 10.6681 9.55741 8.29411V8.29388Z"
        fill="url(#paint1_linear)"
      />
      <path
        d="M18.0798 12.4055C17.9413 12.4348 18.0519 11.9337 18.0165 11.7436C18.0259 10.5417 17.9968 9.3385 18.0326 8.13744C18.1509 6.6128 19.5664 5.35763 21.0864 5.39332H25.5626C25.5584 10.5581 25.5709 15.7235 25.5563 20.8881C25.4973 22.413 24.1361 23.72 22.6193 23.7471C21.0851 23.7528 19.5507 23.748 18.0164 23.7494V16.6857C19.0643 17.5455 20.4885 17.6793 21.7939 17.6816C22.7781 17.6813 23.7565 17.5295 24.7122 17.3026V16.0085C23.6351 16.5453 22.369 16.8862 21.1751 16.5778C20.3422 16.3705 19.7376 15.5656 19.7511 14.7061C19.6546 13.8122 20.1785 12.8686 21.0569 12.6028C22.1475 12.2614 23.3361 12.5225 24.3584 12.9664C24.5774 13.0811 24.7997 13.2233 24.7121 12.8572V11.8401C23.0024 11.4333 21.1829 11.2836 19.4652 11.7261C18.968 11.8664 18.4836 12.0791 18.0798 12.4056V12.4055Z"
        fill="url(#paint2_linear)"
      />
      <defs>
        <linearGradient
          id="paint0_linear"
          x1="26.4291"
          y1="14.1785"
          x2="33.9799"
          y2="14.1785"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#58B03A" />
          <stop offset="1" stopColor="#55B330" />
        </linearGradient>
        <linearGradient
          id="paint1_linear"
          x1="9.39283"
          y1="14.747"
          x2="16.9754"
          y2="14.747"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#0F6EB6" />
          <stop offset="1" stopColor="#006DBA" />
        </linearGradient>
        <linearGradient
          id="paint2_linear"
          x1="18.0054"
          y1="14.3567"
          x2="25.5612"
          y2="14.3567"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#DE0D3D" />
          <stop offset="1" stopColor="#E30138" />
        </linearGradient>
      </defs>
    </Box>
  );
};
