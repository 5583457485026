import React, { useCallback, ReactNode, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Dialog,
  DialogContentText,
  useMediaQuery,
  useTheme,
  CircularProgress,
} from '@material-ui/core';
import {
  H4,
  CAPTION,
  BODY2,
  Container,
  Divider,
} from '@tuunetech/tuune-components';
import CloseIcon from '@material-ui/icons/Close';
import { useDomain } from 'utils/hooks/useDomain';
import { DOMAIN_MAP } from 'utils/axios';
import { useAnalytics } from 'utils/analytics';
import { getEmail } from 'modules/Account/selectors';
import { Link, List } from 'shared';
import { RECOMMENDATION_CTA, STATUS_MAPPINGS } from 'modules/Results/constants';
import { ANALYTICS_CATEGORY, ANALYTICS_EVENT } from 'utils/analytics/constants';
import { UnsupportedFeatureDialog } from '../UnsupportedFeatureDialog';
import RecommendationCardPanel from '../RecommendationCardPanel';
import { Benefits } from '../../types';
import {
  BenefitsContainer,
  BenefitsProps,
} from 'modules/Results/containers/BenefitsContainer';
import { RegimenInfoContainer } from 'modules/Results/containers/RegimenInfoContainer';
import { SwitchingPillInfoContainer } from 'modules/Results/containers/SwitchingPillInfoContainer';
import { setBenefitsCurrent, setRegimenCurrent } from 'modules/Results/actions';
import { ContracptiveTypes } from 'modules/Results/components/RecommendationLayout';
import {
  DialogActions,
  RecommendationDetailsDialog,
  CheckCircleIcon,
  CrossCircleIcon,
  ChipContainer,
  StatusChip,
  AddToPlanButton,
  CloseDialogIconButton,
  LearnMoreButton,
  Paper,
  DialogTitle,
  DialogContent,
} from './styles';
import { PILL_TYPE_MAPPING } from '../../constants';

export type RecommendationDetailsProps = {
  name: string;
  slug: string;
  generics?: string[];
  benefits?: Benefits;
  considerationSummary: ReactNode;
  pillRegimenSummary: ReactNode;
  switchingPillInfoSummary: ReactNode;
  type: ContracptiveTypes;
  handleRecommendationAdd: (id: number, isAdded: boolean) => void;
  status: 'best' | 'current' | 'currentUnrecommended';
  isOpen: boolean;
  onClose: () => void;
  preventAdd?: boolean;
  isAdded: boolean;
  id: number;
};

const RecommendationDetails: React.FC<RecommendationDetailsProps> = ({
  name,
  slug,
  generics,
  benefits,
  considerationSummary,
  pillRegimenSummary,
  switchingPillInfoSummary,
  type,
  handleRecommendationAdd,
  status,
  isOpen,
  onClose,
  preventAdd,
  isAdded,
  id,
}) => {
  const theme = useTheme();
  const domain = useDomain();
  const dispatch = useDispatch();
  const analytics = useAnalytics();
  const email = useSelector(getEmail);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [BPPopupOpen, setBPPopupOpen] = useState(false);
  const [UnsupportedPopupOpen, setUnsupportedPopupOpen] = useState(false);
  const [isBenefitsOpen, setIsBenefitsOpen] = useState(false);
  const [isRegimenInfoOpen, setIsRegimenInfoOpen] = useState(false);
  const [isSwitchingPillInfoOpen, setIsSwitchingPillInfoOpen] = useState(false);
  const [addToCartPending, setAddToCartPending] = useState(false);

  useEffect(() => {
    if (isAdded) setAddToCartPending(false);
  }, [isAdded]);

  const handleBenefitsOpen = useCallback<BenefitsProps['handleBenefitsOpen']>(
    (pill) => {
      if (pill) {
        dispatch(setBenefitsCurrent(pill));
        setIsBenefitsOpen(true);
      }
    },
    [dispatch],
  );
  const handleBenefitsClose = useCallback(() => {
    setIsBenefitsOpen(false);
  }, []);

  const handleRegimenInfoOpen = useCallback(
    (vmpSlug) => {
      dispatch(setRegimenCurrent(vmpSlug));
      setIsRegimenInfoOpen(true);
    },
    [dispatch],
  );
  const handleRegimenInfoClose = useCallback(() => {
    setIsRegimenInfoOpen(false);
  }, []);

  const handleSwitchingPillInfoOpen = useCallback(() => {
    setIsSwitchingPillInfoOpen(true);
  }, []);
  const handleSwitchingPillInfoClose = useCallback(() => {
    setIsSwitchingPillInfoOpen(false);
  }, []);

  const statusIcon = () => {
    switch (status) {
      case 'best':
        return <CheckCircleIcon />;
      case 'currentUnrecommended':
        return <CrossCircleIcon />;
      case 'current':
      default:
        return undefined;
    }
  };

  const renderBenefits = useCallback((benefits, description) => {
    return (
      !!benefits?.length && (
        <>
          <BODY2 align="left" color="textSecondary">
            {description}
          </BODY2>
          <List textColor="textSecondary" listItems={benefits} />
        </>
      )
    );
  }, []);

  const BPDialogBox = (
    <Dialog open={BPPopupOpen} onClose={() => setBPPopupOpen(false)}>
      <DialogContent>
        <DialogContentText variant="body2">
          Unfortunately we cannot process your order until you have provided a
          recent blood pressure test. Please go and have it tested via your
          local{' '}
          <Link href="https://www.nhs.uk/service-search/find-a-GP">GP</Link> or{' '}
          <Link href="https://www.nhs.uk/service-search/find-a-pharmacy/">
            pharmacy
          </Link>
          . We will let you know when you can update your blood pressure here
          and continue processing your order.
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );

  const UnsupportedDialogBox = (
    <UnsupportedFeatureDialog
      onClose={() => setUnsupportedPopupOpen(false)}
      isOpen={UnsupportedPopupOpen}
    />
  );

  const trackPanelClicked = useCallback(
    (eventName: ANALYTICS_EVENT) => (_event: Event, expanded: boolean) => {
      if (expanded) {
        analytics.track(
          eventName,
          {
            category: ANALYTICS_CATEGORY.RECOMMENDATION_REPORT,
            recommendationId: id,
          },
          email,
        );
      }
    },
    [analytics, id, email],
  );

  const addButtonContent = () => {
    if (addToCartPending) {
      return <CircularProgress color="inherit" size={20} />;
    } else if (isAdded) {
      return RECOMMENDATION_CTA.added;
    } else return RECOMMENDATION_CTA.notAdded;
  };

  const handleAddClicked = useCallback(() => {
    if (domain === DOMAIN_MAP.us) {
      setUnsupportedPopupOpen(true);
    } else if (preventAdd) {
      setBPPopupOpen(true);
    } else {
      if (!isAdded) setAddToCartPending(true);
      handleRecommendationAdd(id, isAdded);
    }
  }, [
    setBPPopupOpen,
    setUnsupportedPopupOpen,
    handleRecommendationAdd,
    id,
    isAdded,
    preventAdd,
    domain,
  ]);

  return (
    <>
      {BPDialogBox}
      {UnsupportedDialogBox}
      <BenefitsContainer
        isBenefitsOpen={isBenefitsOpen}
        handleBenefitsOpen={handleBenefitsOpen}
        handleBenefitsClose={handleBenefitsClose}
      />
      <RegimenInfoContainer
        isRegimenInfoOpen={isRegimenInfoOpen}
        handleRegimenInfoOpen={handleRegimenInfoOpen}
        handleRegimenInfoClose={handleRegimenInfoClose}
      />
      <SwitchingPillInfoContainer
        isSwitchingPillInfoOpen={isSwitchingPillInfoOpen}
        handleSwitchingPillInfoOpen={handleSwitchingPillInfoOpen}
        handleSwitchingPillInfoClose={handleSwitchingPillInfoClose}
      />
      <RecommendationDetailsDialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        PaperProps={{ variant: 'outlined' }}
        PaperComponent={Paper}
        fullScreen={fullScreen}
        maxWidth="sm"
        fullWidth
      >
        <>
          <DialogTitle disableTypography id="alert-dialog-title">
            <Container>
              <CloseDialogIconButton aria-label="close" onClick={onClose}>
                <CloseIcon />
              </CloseDialogIconButton>
              <H4>{name}</H4>
              <CAPTION>{generics?.join(', ') || ''}</CAPTION>
              <ChipContainer>
                <StatusChip
                  label={STATUS_MAPPINGS[status]}
                  icon={statusIcon()}
                  $status={status}
                />
                <StatusChip label={PILL_TYPE_MAPPING[type]} $status={status} />
              </ChipContainer>
            </Container>
            <Divider />
          </DialogTitle>
          <DialogContent>
            <Container disableGutters>
              <RecommendationCardPanel
                title="Benefits"
                onChange={trackPanelClicked(
                  ANALYTICS_EVENT.RECOMMENDATION_BENEFITS,
                )}
              >
                {renderBenefits(
                  benefits?.recommendationSpecificBenefits
                    ?.map(({ issueName, impact }) =>
                      impact === 'IMPROVE' ? issueName : null,
                    )
                    .filter((issue) => !!issue),
                  `${name} can specifically help improve or alleviate your:`,
                )}
                {renderBenefits(
                  benefits?.contraceptionSpecificBenefits?.map(
                    ({ name }) => name,
                  ),
                  `Other known benefits of ${name} include improvements in:`,
                )}
                <LearnMoreButton
                  fullWidth
                  onClick={() =>
                    handleBenefitsOpen({
                      vmpSlug: slug,
                      type: type,
                    })
                  }
                >
                  LEARN MORE
                </LearnMoreButton>
              </RecommendationCardPanel>
              <Divider />
              <RecommendationCardPanel
                title="Side Effects & Considerations"
                onChange={trackPanelClicked(
                  ANALYTICS_EVENT.RECOMMENDATION_CONSIDERATIONS,
                )}
              >
                {considerationSummary}
              </RecommendationCardPanel>
              <Divider />
              {status === 'best' && (
                <>
                  <RecommendationCardPanel
                    title="How should I take the pill?"
                    onChange={trackPanelClicked(
                      ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_TAKE_PILL,
                    )}
                  >
                    {pillRegimenSummary}
                    <LearnMoreButton
                      fullWidth
                      onClick={() => handleRegimenInfoOpen(slug)}
                    >
                      LEARN MORE
                    </LearnMoreButton>
                  </RecommendationCardPanel>
                  <Divider />
                  <RecommendationCardPanel
                    title="How to start?"
                    onChange={trackPanelClicked(
                      ANALYTICS_EVENT.RECOMMENDATION_HOW_TO_START,
                    )}
                  >
                    {switchingPillInfoSummary}
                    <LearnMoreButton
                      fullWidth
                      onClick={handleSwitchingPillInfoOpen}
                    >
                      LEARN MORE
                    </LearnMoreButton>
                  </RecommendationCardPanel>
                </>
              )}
            </Container>
          </DialogContent>
        </>
        {status === 'best' && (
          <DialogActions>
            <Container>
              <AddToPlanButton
                $status={status}
                autoFocus
                fullWidth
                onClick={handleAddClicked}
                disabled={addToCartPending}
              >
                {addButtonContent()}
              </AddToPlanButton>
            </Container>
          </DialogActions>
        )}
      </RecommendationDetailsDialog>
    </>
  );
};

export default RecommendationDetails;
