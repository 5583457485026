import {
  HEIGHT_UNITS,
  WEIGHT_UNITS,
  AnswerTypesMap,
  MEASURE_SYSTEMS,
  CONVERT_MAP,
  UNITS,
  HEIGHT_IMPERIAL,
  HEIGHT_METRIC,
  WEIGHT_IMPERIAL,
  WEIGHT_METRIC,
  NonMetricSystemOption,
  MetricSystemOption,
} from '../constants';

export const getCurrentUnits = (answerType: string): string[] | undefined => {
  switch (answerType) {
    case AnswerTypesMap.HEIGHT:
      return HEIGHT_UNITS;
    case AnswerTypesMap.WEIGHT:
      return WEIGHT_UNITS;
    default:
      return undefined;
  }
};

export const getNumInputStep = (answerType: string): string => {
  switch (answerType) {
    default:
      return '1';
  }
};

export interface GetInitialAnswerProps {
  answerType: string;
  toMeasureSystem: string;
  prevMeasureSystem: string;
  value?: string | number | boolean | null;
}

export type ConversionFunc = (
  prevMeasureSystem: string,
  value: GetInitialAnswerProps['value'],
) => GetInitialAnswerProps['value'];

export const getConversionFunc = (
  answerType: string,
  measureSystem: string,
): ConversionFunc => {
  const funcKey = `to${measureSystem.toLocaleUpperCase()}`;
  if (CONVERT_MAP[answerType] && CONVERT_MAP[answerType][funcKey]) {
    return CONVERT_MAP[answerType][funcKey];
  }
  return (prevMeasureSystem, value) => {
    return value;
  };
};

export const getInitialAnswer = ({
  answerType,
  toMeasureSystem,
  prevMeasureSystem,
  value,
}: GetInitialAnswerProps): GetInitialAnswerProps['value'] => {
  const func = getConversionFunc(answerType, toMeasureSystem);
  return func(prevMeasureSystem, value);
};

export const getMeasureSystemByUnit = (unit: string): string => {
  let resultSystem = MEASURE_SYSTEMS.METRIC;

  for (const unitKey in UNITS) {
    if (UNITS.hasOwnProperty(unitKey)) {
      for (const answerTypeKey in UNITS[unitKey]) {
        if (UNITS[unitKey].hasOwnProperty(answerTypeKey)) {
          if (UNITS[unitKey][answerTypeKey] === unit) {
            resultSystem = unitKey;
          }
        }
      }
    }
  }

  return resultSystem;
};

export const getOptions = (
  measureSystem: string,
  answerType: string,
): (NonMetricSystemOption | MetricSystemOption)[] | undefined => {
  switch (answerType) {
    case AnswerTypesMap.HEIGHT:
      switch (measureSystem) {
        case MEASURE_SYSTEMS.IMPERIAL:
          return HEIGHT_IMPERIAL;
        case MEASURE_SYSTEMS.METRIC:
        default:
          return HEIGHT_METRIC;
      }
    case AnswerTypesMap.WEIGHT:
      switch (measureSystem) {
        case MEASURE_SYSTEMS.IMPERIAL:
          return WEIGHT_IMPERIAL;
        case MEASURE_SYSTEMS.METRIC:
        default:
          return WEIGHT_METRIC;
      }
    default:
      return;
  }
};
