import { REQUEST, SUCCESS, FAIL } from 'utils/requestStates';

export const GET_ASSESSMENT_RESULTS = 'GET_ASSESSMENT_RESULTS';
export const GET_ASSESSMENT_RESULTS_REQUEST = `${GET_ASSESSMENT_RESULTS}_${REQUEST}` as const;
export const GET_ASSESSMENT_RESULTS_SUCCESS = `${GET_ASSESSMENT_RESULTS}_${SUCCESS}` as const;

export const GET_RECAP_GOALS = 'GET_RECAP_GOALS';
export const GET_RECAP_GOALS_REQUEST = `${GET_RECAP_GOALS}_${REQUEST}` as const;
export const GET_RECAP_GOALS_SUCCESS = `${GET_RECAP_GOALS}_${SUCCESS}` as const;

export const GET_ASSESSMENT_FLAGS = 'GET_ASSESSMENT_FLAGS';
export const GET_ASSESSMENT_FLAGS_REQUEST = `${GET_ASSESSMENT_FLAGS}_${REQUEST}` as const;
export const GET_ASSESSMENT_FLAGS_SUCCESS = `${GET_ASSESSMENT_FLAGS}_${SUCCESS}` as const;

export const RECAP_GOALS_CLOSED = 'RECAP_GOALS_CLOSED';
export const SET_RECAP_GOALS_CLOSED = 'SET_RECAP_GOALS_CLOSED';

export const GET_RECOMMENDATION_RESULTS = 'GET_RECOMMENDATION_RESULTS';
export const GET_RECOMMENDATION_RESULTS_REQUEST = `${GET_RECOMMENDATION_RESULTS}_${REQUEST}` as const;
export const GET_RECOMMENDATION_RESULTS_SUCCESS = `${GET_RECOMMENDATION_RESULTS}_${SUCCESS}` as const;
export const GET_RECOMMENDATION_RESULTS_FAIL = `${GET_RECOMMENDATION_RESULTS}_${FAIL}` as const;

export const SET_PILL_INFO_CURRENT = 'SET_PILL_INFO_CURRENT';
export const SET_SYMPTOM_CURRENT = 'SET_SYMPTOM_CURRENT';
export const SET_BENEFITS_CURRENT = 'SET_BENEFITS_CURRENT';
export const SET_REGIMEN_CURRENT = 'SET_REGIMEN_CURRENT';
