import { Reducer } from 'redux';
import { SUCCESS_CONTENTFUL_DATA } from '../constants/actions';
import {
  ContentfulDocument,
  ContentfulReducerState,
  GetContentRequest,
} from '../types';

export const defaultState: ContentfulReducerState = {};

const contentfulReducer: Reducer<ContentfulReducerState, GetContentRequest> = (
  state = defaultState,
  { type, payload: { data = {}, reqId = '' } = {} },
) => {
  if (type.includes(SUCCESS_CONTENTFUL_DATA)) {
    return {
      ...state,
      [reqId]:
        data.items
          ?.map(({ fields }) => fields)
          .reduce((mapData, item) => {
            mapData[item.slug] = item;
            return mapData;
          }, {} as ContentfulDocument) || {},
    };
  }

  return state;
};

export { contentfulReducer as contentful };
