import { IonImg } from '@ionic/react';
import styled from 'styled-components';

export const Logo = styled(IonImg)`
  max-width: 100%;
  width: 200px;
  ${({ theme }) => theme.breakpoints.up('sm')} {
    width: 300px;
  }
`;

export const Image = styled(IonImg)`
  display: inline-block;
  width: 80px;
  ${({ theme }) => theme.breakpoints.up('md')} {
    width: 120px;
  }
`;
