import { RequestActions } from 'utils/api';
import {
  FALLBACK_ERROR_MESSAGE,
  AXIOS_NETWORK_ERROR_MESSAGE,
  NETWORK_ERROR_MESSAGE,
  SESSION_EXPIRED_MESSAGE,
  ACTION_ERRORS_LIST,
  ERROR_TYPE,
} from '../constants';
import { FailedActions } from '../types';

export const isActionFailed = (type: RequestActions): boolean =>
  type.split('_').pop() === 'FAIL';

export const getErrorMessage = (
  type: FailedActions,
  status: string | number,
  defaultMessage: string,
): string | undefined => {
  let error = FALLBACK_ERROR_MESSAGE;

  if (
    status === 500 ||
    (typeof status === 'string' &&
      defaultMessage === AXIOS_NETWORK_ERROR_MESSAGE)
  ) {
    error = NETWORK_ERROR_MESSAGE;
  }

  if (status === 401 || status === 403) {
    error = SESSION_EXPIRED_MESSAGE;
  }

  if (ACTION_ERRORS_LIST[type] && ACTION_ERRORS_LIST[type][`${status}`]) {
    if (ACTION_ERRORS_LIST[type][`${status}`] === ERROR_TYPE.DEFAULT) {
      error = defaultMessage;
    } else if (ACTION_ERRORS_LIST[type][`${status}`] === ERROR_TYPE.INPUT) {
      error = '';
    } else {
      error = ACTION_ERRORS_LIST[type][`${status}`];
    }
  }

  return error;
};
