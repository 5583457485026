import React from 'react';
import {
  Button,
  ButtonProps,
  ContainerProps,
} from '@tuunetech/tuune-components';
import { Container } from './styles';
import { useMediaQuery, useTheme } from '@material-ui/core';

type ContinueButtonProps = ButtonProps &
  Partial<ContainerProps> & {
    text?: string;
    isVisible?: boolean;
    align?: 'left' | 'center' | 'right';
  };

const ContinueButton: React.FC<ContinueButtonProps> = ({
  text,
  color,
  onClick,
  disabled,
  $marginTop,
  align = 'center',
  disableGutters,
  isVisible = true,
  ...props
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return isVisible ? (
    <Container
      {...props}
      disableGutters={disableGutters !== undefined ? disableGutters : !isMobile}
      $marginTop={$marginTop || 40}
      $align={align}
    >
      <Button
        color={color || 'primary'}
        disabled={disabled}
        onClick={onClick}
        {...props}
      >
        {text || 'Continue'}
      </Button>
    </Container>
  ) : null;
};

export default ContinueButton;
