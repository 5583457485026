import { Reducer } from 'redux';
import { QuestionnaireReducerState } from '../types';
import {
  GET_CURRENT_QUESTION_SUCCESS,
  SET_MEASURE_SYSTEM,
  SUBMIT_QUESTION_SUCCESS,
  GET_PREV_QUESTION_SUCCESS,
  SET_GET_STARTED_QUESTION,
  SET_PASSWORD_SUCCESS,
  SET_PASSWORD_FAIL,
  GET_CURRENT_QUESTION_REQUEST,
  SUBMIT_QUESTION_REQUEST,
  GET_PREV_QUESTION_REQUEST,
  SUBMIT_FEEDBACK_SUCCESS,
  SET_GET_STARTED_PRIVACY,
  SET_GET_STARTED_MARKETING,
  SUBMIT_QUESTION_FAIL,
  GET_FEEDBACK_SUCCESS,
} from '../constants';

export const defaultState: QuestionnaireReducerState = {
  questionId: 1,
  totalCount: undefined,
  current: undefined,
  sectionName: '',
  questionTitle: '',
  subTitle: '',
  sectionId: 1,
  questionType: 'fact',
  helpText: '',
  factsSlugs: '',
  answers: [],
  notaAnswer: '',
  previousAnswers: {},
  measureSystem: undefined,
  isCompleted: false,
  passwordSet: false,
  getStarted: {
    currentQuestion: 0,
    privacyOptions: [],
    marketingOptions: new Set(),
  },
  errors: {},
  isQuestionLoading: false,
  feedbackSubmitted: false,
  feedback: undefined,

  currentSection: undefined,
  sections: [],
};

const questionnaireReducer: Reducer<QuestionnaireReducerState> = (
  state = defaultState,
  { type, payload, response: { data = {} } = {}, error },
) => {
  switch (type) {
    case GET_CURRENT_QUESTION_REQUEST:
    case SUBMIT_QUESTION_REQUEST:
    case GET_PREV_QUESTION_REQUEST:
      return {
        ...state,
        isQuestionLoading: true,
      };
    case GET_CURRENT_QUESTION_SUCCESS:
    case SUBMIT_QUESTION_SUCCESS:
    case GET_PREV_QUESTION_SUCCESS:
      if (data.status) {
        return {
          ...defaultState,
          isCompleted: true,
        };
      }
      return {
        ...state,
        questionId: data.id,
        totalCount: data.total,
        current: data.current_question,
        sectionName: data.section_name,
        questionTitle: data.text,
        subTitle: data.sub_text,
        sectionId: data.section_id,
        questionType: data.question_type,
        helpText: data.help_text,
        factsSlugs: data.facts_slugs,
        answers: data.facts,
        notaAnswer: data.nota_answer,
        previousAnswers: data.responses,
        isQuestionLoading: false,

        currentSection: data.current_section,
        sections: data.sections,

        errors: {},
      };
    case SET_MEASURE_SYSTEM:
      return {
        ...state,
        measureSystem: payload.system,
      };
    case SET_GET_STARTED_MARKETING:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          marketingOptions: payload.marketingOptions,
        },
      };
    case SET_GET_STARTED_PRIVACY:
      const newOptions = [
        ...state.getStarted.privacyOptions,
        ...payload.options,
      ].reduce((unique, item) => {
        return unique.includes(item) ? unique : [...unique, item];
      }, []);
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          privacyOptions: newOptions,
        },
      };
    case SET_GET_STARTED_QUESTION:
      return {
        ...state,
        getStarted: {
          ...state.getStarted,
          currentQuestion: payload.question,
        },
      };
    case SET_PASSWORD_SUCCESS:
      return {
        ...state,
        passwordSet: true,
      };
    case SET_PASSWORD_FAIL:
    case SUBMIT_QUESTION_FAIL:
      if (error.data) {
        return {
          ...state,
          errors: error.data,
          isQuestionLoading: false,
        };
      } else return state;
    case SUBMIT_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedbackSubmitted: true,
      };
    case GET_FEEDBACK_SUCCESS:
      return {
        ...state,
        feedback: data[0]?.text,
      };
    default:
      return state;
  }
};

export { questionnaireReducer as questionnaire };
