import React from 'react';
import {
  ClickAwayListener,
  Grow,
  Popper,
  PopperProps,
} from '@material-ui/core';
import Routes from 'routes';
import { MenuItem, MenuList, NavPaper } from './styles';

export type NavBoxProps = {
  handleClose: () => void;
  handleLogout: () => void;
  handleRoute: (route: string) => void;
  isOpen: boolean;
  anchorEl: PopperProps['anchorEl'];
};

const NAV_ITEMS = [
  {
    name: 'My report',
    route: Routes.RESULTS,
  },
  {
    name: 'My account',
    route: Routes.ACCOUNT,
  },
  {
    name: 'Help',
    route: Routes.HELP,
  },
];

export const NavBox: React.FC<NavBoxProps> = ({
  isOpen,
  handleClose,
  handleLogout,
  handleRoute,
  anchorEl,
}) => {
  return (
    <Popper
      open={isOpen}
      anchorEl={anchorEl}
      role={undefined}
      transition
      placement="bottom-end"
    >
      {({ TransitionProps, placement }) => (
        <Grow
          {...TransitionProps}
          style={{
            transformOrigin:
              placement === 'bottom' ? 'center top' : 'center bottom',
          }}
        >
          <NavPaper>
            <ClickAwayListener onClickAway={handleClose}>
              <MenuList id="menu-list-grow" variant="selectedMenu">
                {NAV_ITEMS.map(({ name, route }) => (
                  <MenuItem key={name} onClick={() => handleRoute(route)}>
                    {name}
                  </MenuItem>
                ))}
                <MenuItem onClick={handleLogout}>Log out</MenuItem>
              </MenuList>
            </ClickAwayListener>
          </NavPaper>
        </Grow>
      )}
    </Popper>
  );
};
