import { getIsActive } from 'modules/Account/selectors';
import { checkAuth } from 'modules/Auth/actions';
import { getIsAuthenticated } from 'modules/Auth/selectors';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, RouteProps, useHistory } from 'react-router';
import Routes from 'routes';
import { getEmail } from 'modules/Account/selectors';
import { getAccountDataRequest } from 'modules/Account/actions';

const PrivateRoute: React.FC<RouteProps> = ({
  component: Component,
  children,
  ...props
}) => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector(getIsAuthenticated);
  const isActive = useSelector(getIsActive);
  const history = useHistory();
  const email = useSelector(getEmail);

  useEffect(() => {
    dispatch(checkAuth());
  }, [dispatch]);

  useEffect(() => {
    if (!email) {
      dispatch(getAccountDataRequest());
    }
  }, [dispatch, email]);

  useEffect(() => {
    if (isAuthenticated === false) {
      history.replace(Routes.LOGIN);
    }
    if (isAuthenticated && isActive === false) {
      history.replace(Routes.VERIFY_EMAIL);
    }
  }, [isAuthenticated, isActive, history, dispatch]);

  return isAuthenticated ? (
    <Route component={Component} {...props}>
      {children}
    </Route>
  ) : null;
};

export default PrivateRoute;
