import { CycleOption } from '../types';
import { icons } from 'assets/icons';
const { mood1, mood2, mood3, mood4, mood5, mood6 } = icons;

export const CYCLE_OPTIONS: { [key: string]: CycleOption } = {
  'Menstruation phase': {
    day: 'Days 1-7',
    phase: 'Menstrual phase',
    desc: 'During my period',
  },
  'Follicular phase': {
    day: 'Days 8-13',
    phase: 'Follicular phase',
    desc: 'In the two weeks after my period',
  },
  'Ovulation phase': {
    day: 'Day 14',
    phase: 'Ovulation',
    desc: 'When I ovulate',
  },
  'Luteal phase': {
    day: 'Days 15-28',
    phase: 'Luteal phase',
    desc: 'In the two weeks before my period',
  },
};

export const MOOD_ICONS = [mood1, mood2, mood3, mood4, mood5, mood6];
