import { documentToPlainTextString } from '@contentful/rich-text-plain-text-renderer';
import { ContentfulDocument } from '../types';

export const documentToPlainTextRecords = (
  contentfulDocument: ContentfulDocument,
): Record<string, string> => {
  return Object.keys(contentfulDocument).reduce((content, key) => {
    content[key] = documentToPlainTextString(contentfulDocument[key]?.content);
    return content;
  }, {} as Record<string, string>);
};
