import React from 'react';
import {
  BODY1,
  Button,
  GridContainer,
  GridItem,
  H3,
} from '@tuunetech/tuune-components';
import { VerifyContainer, SpinnerContainer, ChangeEmailButton } from './styles';
import { useWebpImage } from 'utils/image';
import { Theme, useMediaQuery, CircularProgress } from '@material-ui/core';

interface VerifyEmailProps {
  handleResend: () => void;
  handleChangeEmail: () => void;
  email: string;
  isAuthenticated?: boolean;
}

const VerifyEmail: React.FC<VerifyEmailProps> = ({
  email,
  handleResend,
  handleChangeEmail,
  isAuthenticated,
}) => {
  const [activationEmail] = useWebpImage('activation_email.jpg');
  const isMobile = useMediaQuery<Theme>((theme) =>
    theme.breakpoints.down('sm'),
  );

  return (
    <VerifyContainer>
      <GridContainer justify="center">
        <GridItem xs={12} md={10} lg={6}>
          <img src={activationEmail} alt="activate email" />
          {email ? (
            <>
              <H3 paragraph>
                <span>We emailed you an activation link.</span>
                <span>Click it to start the assessment.</span>
              </H3>
              <BODY1 color="textSecondary">
                <span>We sent the link to {email}</span>
                {!isAuthenticated ? (
                  <>
                    <span>
                      If this is the wrong email address click the button below
                      to enter a new one.
                    </span>
                    <ChangeEmailButton
                      variant="outlined"
                      onClick={(): void => handleChangeEmail()}
                      fullWidth={isMobile}
                    >
                      Change email address
                    </ChangeEmailButton>
                  </>
                ) : null}
                <span>
                  If you didn’t get it, check your junk folder or click the
                  button below.
                </span>
              </BODY1>
              <Button
                variant="outlined"
                onClick={(): void => handleResend()}
                fullWidth={isMobile}
              >
                resend activation link
              </Button>
            </>
          ) : (
            <SpinnerContainer>
              <CircularProgress color="primary" />
            </SpinnerContainer>
          )}
        </GridItem>
      </GridContainer>
    </VerifyContainer>
  );
};

export default VerifyEmail;
