export default `
  <svg width="71" height="71" class="ionicon" stroke-width="0" viewBox="0 0 71 71" xmlns="http://www.w3.org/2000/svg">
    <path d="M64.59 70.3H5.71C2.56 70.3 0 67.74 0 64.59V17.35C0 16.78 0.46 16.31 1.04 16.31H69.27C69.84 16.31 70.31 16.77 70.31 17.35V64.59C70.3 67.74 67.74 70.3 64.59 70.3ZM2.08 18.39V64.59C2.08 66.59 3.71 68.22 5.71 68.22H64.59C66.59 68.22 68.22 66.59 68.22 64.59V18.39H2.08Z"/>
    <path d="M69.27 18.39H1.04C0.46 18.39 0 17.93 0 17.35V5.71C0 2.56 2.56 0 5.71 0H64.59C67.74 0 70.3 2.56 70.3 5.71V17.35C70.3 17.93 69.84 18.39 69.27 18.39ZM2.08 16.32H68.23V5.71C68.23 3.71 66.6 2.08 64.6 2.08H5.71C3.71 2.08 2.08 3.71 2.08 5.71V16.32Z"/>
    <path d="M57.2905 28.12H13.3505C12.7805 28.12 12.3105 27.66 12.3105 27.08C12.3105 26.51 12.7705 26.04 13.3505 26.04H57.2905C57.8605 26.04 58.3305 26.5 58.3305 27.08C58.3205 27.65 57.8605 28.12 57.2905 28.12Z"/>
    <path d="M38.73 34.9H13.79C13.22 34.9 12.75 34.44 12.75 33.86C12.75 33.29 13.21 32.82 13.79 32.82H38.72C39.29 32.82 39.76 33.28 39.76 33.86C39.77 34.43 39.3 34.9 38.73 34.9Z"/>
    <path d="M10.5303 13.94C7.91027 13.94 5.78027 11.81 5.78027 9.19C5.78027 6.57 7.91027 4.44 10.5303 4.44C13.1503 4.44 15.2803 6.57 15.2803 9.19C15.2803 11.81 13.1503 13.94 10.5303 13.94ZM10.5303 6.53C9.06027 6.53 7.86027 7.73 7.86027 9.2C7.86027 10.67 9.06027 11.87 10.5303 11.87C12.0003 11.87 13.2003 10.67 13.2003 9.2C13.2003 7.73 12.0003 6.53 10.5303 6.53Z"/>
    <path d="M22.6904 13.94C20.0704 13.94 17.9404 11.81 17.9404 9.19C17.9404 6.57 20.0704 4.44 22.6904 4.44C25.3104 4.44 27.4404 6.57 27.4404 9.19C27.4404 11.81 25.3104 13.94 22.6904 13.94ZM22.6904 6.53C21.2204 6.53 20.0204 7.73 20.0204 9.2C20.0204 10.67 21.2204 11.87 22.6904 11.87C24.1604 11.87 25.3604 10.67 25.3604 9.2C25.3604 7.73 24.1704 6.53 22.6904 6.53Z"/>
    <path d="M17.0605 49.72C14.4405 49.72 12.3105 47.59 12.3105 44.97C12.3105 42.35 14.4405 40.22 17.0605 40.22C19.6805 40.22 21.8105 42.35 21.8105 44.97C21.8005 47.59 19.6705 49.72 17.0605 49.72ZM17.0605 42.31C15.5905 42.31 14.3905 43.51 14.3905 44.98C14.3905 46.45 15.5905 47.65 17.0605 47.65C18.5305 47.65 19.7305 46.45 19.7305 44.98C19.7305 43.51 18.5305 42.31 17.0605 42.31Z"/>
    <path d="M17.0596 61.41C14.4396 61.41 12.3096 59.28 12.3096 56.66C12.3096 54.04 14.4396 51.91 17.0596 51.91C19.6796 51.91 21.8096 54.04 21.8096 56.66C21.7996 59.28 19.6696 61.41 17.0596 61.41ZM17.0596 53.99C15.5896 53.99 14.3896 55.19 14.3896 56.66C14.3896 58.13 15.5896 59.33 17.0596 59.33C18.5296 59.33 19.7296 58.13 19.7296 56.66C19.7296 55.19 18.5296 53.99 17.0596 53.99Z"/>
    <path d="M55.4803 46.02H25.8203C25.2503 46.02 24.7803 45.56 24.7803 44.98C24.7803 44.41 25.2403 43.94 25.8203 43.94H55.4803C56.0503 43.94 56.5203 44.4 56.5203 44.98C56.5203 45.55 56.0603 46.02 55.4803 46.02Z"/>
    <path d="M56.0604 57.7H26.4004C25.8304 57.7 25.3604 57.24 25.3604 56.66C25.3604 56.09 25.8204 55.62 26.4004 55.62H56.0604C56.6304 55.62 57.1003 56.08 57.1003 56.66C57.1003 57.24 56.6404 57.7 56.0604 57.7Z"/>
  </svg>
`;
