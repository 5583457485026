import React from 'react';
import DateContainer from './DateContainer';
import { AnswerComponentProps } from '../../types';
import { validateDob } from '../../helpers';

const DobContainer: React.FC<AnswerComponentProps> = (props) => {
  return <DateContainer additValidate={validateDob} {...props} />;
};

export default DobContainer;
