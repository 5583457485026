import React from 'react';
import { SvgIcon, SvgIconProps } from '@material-ui/core';

export const SymptomIconSvg: React.FC<SvgIconProps> = ({
  children,
  ...props
}) => {
  return (
    <SvgIcon viewBox="0 0 24 24" {...props}>
      {children}
    </SvgIcon>
  );
};
