import * as yup from 'yup';
import {
  DEFAULT_COUNTRY_OBJ,
  getCountryObjByPhoneCode,
  phoneAsYouType,
} from 'utils/helpers';
import { postcodeValidator } from 'postcode-validator';

export const contactDetailsSchema = yup.object().shape({
  firstName: yup.string().required('First Name is a required field'),
  lastName: yup.string().required('Surname is a required field'),
  phone: yup
    .string()
    .required('Mobile is a required field')
    .test('mobile', 'Mobile format incorrect', function (value = '') {
      const { parent } = this;
      phoneAsYouType.reset();
      phoneAsYouType.input(`${parent.phoneCode}${value}`);

      return phoneAsYouType.isValid();
    }),
});

export const shippingDetailsSchema = yup.object().shape({
  addressLine1: yup.string().required(),
  addressLine2: yup.string(),
  city: yup.string().required(),
  county: yup.string().required(),
  country: yup.string().required().nullable(),
  postcode: yup
    .string()
    .required()
    .test('postcode', 'Incorrect postcode', function (value = '') {
      const { parent } = this;
      const countryCode =
        getCountryObjByPhoneCode(parent.phoneCode)?.code ||
        DEFAULT_COUNTRY_OBJ.code;
      return postcodeValidator(value, countryCode);
    }),
});

export const paymentDetailsSchema = yup.object().shape({
  txtCardNumber: yup
    .string()
    .required('Card number is a required field')
    // Arabic numerals and spaces only
    .matches(/^[0-9 ]+$/, 'Card number must contain only digits'),
  txtCardHolder: yup.string().required('Card holder name is a required field'),
  txtMonth: yup
    .number()
    .typeError('Month must contain only digits')
    .required('Month is a required field')
    .min(1, 'Moth must be greater than or equal to 1')
    .max(12, 'Month must be less than or equal to 12')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  txtYear: yup
    .number()
    .typeError('Year must contain only digits')
    .required('Year is a required field')
    .nullable()
    .transform((value, originalValue) => (originalValue === '' ? null : value)),
  txtCVV: yup
    .string()
    .required()
    .matches(/^[0-9]+$/, 'CVV must contain only digits'),
});

export const confirmDataSchema = yup.object().shape({
  willUseMedMyself: yup.boolean().required().oneOf([true]),
  willReferToLeaflet: yup.boolean().required().oneOf([true]),
  willAskClinician: yup.boolean().required().oneOf([true]),
});
