import React, {
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { IonRow, IonCol } from '@ionic/react';
import { ErrorBox, ContinueButton } from 'shared';
import { AnswerOption } from 'modules/Questionnaire/types';
import styles from './style.module.css';

interface ChoiceAnswerProps {
  // isDesktop: boolean;
  options: AnswerOption[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  optionComponent: React.FC<any>;
  handleOptionClick: (option: AnswerOption) => void;
  handleSubmit: () => void;
  handleRequestNext: () => void;
  disableContinue: boolean;
  showContinue: boolean;
  dirty?: boolean;
}

const ChoiceAnswer: React.FC<ChoiceAnswerProps> = ({
  // isDesktop,
  options,
  optionComponent: OptionComponent,
  handleOptionClick,
  handleSubmit,
  disableContinue,
  showContinue,
  dirty = true,
  handleRequestNext,
}) => {
  const [errorVisible, setErrorVisible] = useState(false);

  const submit = useCallback((): void => {
    if (!disableContinue) {
      return dirty ? handleSubmit() : handleRequestNext();
    } else {
      setErrorVisible(true);
    }
  }, [disableContinue, dirty, handleRequestNext, handleSubmit]);

  useEffect(() => {
    if (!disableContinue) {
      setErrorVisible(false);
    }
  }, [disableContinue]);

  const OptionsContainer =
    typeof options[0].option === 'string' ? React.Fragment : IonRow;

  const choiceClassName = useMemo(() => {
    return `
      ${styles.choiceContainer} ion-no-vertical-padding
      ${options.length > 7 ? styles.checkboxOptions : styles.regularOptions}
    `;
  }, [options.length]);

  const renderOption = (
    option: AnswerOption,
    idx: number,
    testId?: string,
  ): ReactNode => {
    return (
      <OptionComponent
        data-testid={testId}
        key={option.value.toString() + idx}
        text={option.option}
        active={option.isActive}
        onClick={(): void => handleOptionClick(option)}
      />
    );
  };

  const renderRegularOptions = (): ReactNode => (
    <>
      {/* This column is shown if options < 4 and options > 4 on mobile screens as a single column */}
      <div
        className={`${options.length > 4 ? 'ion-hide-md-up' : ''} ${
          styles.optionsColumn
        }`}
        data-testid="column"
      >
        {options.map((option, idx) =>
          renderOption(option, idx, 'multipleOptionsSingleColumnItem'),
        )}
      </div>

      {options.length > 4 && (
        <>
          <div className={`ion-hide-md-down ${styles.optionsColumn}`}>
            {options.map(
              (option, idx) =>
                idx < options.length / 2 &&
                renderOption(option, idx, 'multipleOptionsFirstColumnItem'),
            )}
          </div>

          <div className={`ion-hide-md-down ${styles.optionsColumn}`}>
            {options.map(
              (option, idx) =>
                idx >= options.length / 2 &&
                renderOption(option, idx, 'multipleOptionsSecondColumnItem'),
            )}
          </div>
        </>
      )}
    </>
  );

  return (
    <>
      <IonRow className="ion-row-expand ion-justify-content-center">
        {errorVisible && (
          <ErrorBox text="You must select at least one option to continue." />
        )}
        <IonCol className={choiceClassName} sizeSm="auto" size="12">
          <OptionsContainer
            {...(typeof options[0].option !== 'string'
              ? { className: 'ion-row-expand ion-justify-content-center' }
              : {})}
          >
            {typeof options[0].option === 'string'
              ? // render button/checkbox options
                renderRegularOptions()
              : // render cycle/subjective options
                options.map((option, idx) =>
                  renderOption(option, idx, 'irregularOptionsItem'),
                )}
          </OptionsContainer>
        </IonCol>
      </IonRow>
      <ContinueButton isVisible={showContinue} onClick={submit} />
    </>
  );
};

export default ChoiceAnswer;
