import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { IonApp, IonRouterOutlet } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

import { ResetPassword, ForgotPassword, Login } from 'modules/Auth/pages';
import { ErrorPopup } from 'modules/ErrorPopup/pages';
import { Account } from 'modules/Account/pages';
import { Help } from 'modules/Help/pages';
import { Regulation } from 'modules/Regulation/pages';
import { Results } from 'modules/Results/pages';
import {
  Question,
  VerifyEmail,
  GetStarted,
  Feedback,
  Start,
  ActivateAccount,
  ResendCustom,
} from 'modules/Questionnaire/pages';
import NotFound from 'modules/static/NotFoundPage';
import { Checkout } from './modules/Checkout/pages';
import { AuthRoute, PrivateRoute } from 'shared';
import Routes from './routes';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

import '@fontsource/poppins/300.css';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';

const App: React.FC = () => {
  return (
    <IonApp>
      <IonReactRouter>
        <IonRouterOutlet>
          <AuthRoute path={Routes.LOGIN} component={Login} exact={true} />
          <AuthRoute
            path={Routes.FORGOT_PASSWORD}
            component={ForgotPassword}
            exact={true}
          />
          <AuthRoute
            component={ResetPassword}
            exact={true}
            path={`${Routes.RESET_PASSWORD}/confirm/:uid/:token`}
          />
          <PrivateRoute
            path={Routes.HOME}
            render={() => <Redirect to={Routes.ACCOUNT} />}
            exact={true}
          />
          <PrivateRoute path={Routes.ACCOUNT} component={Account} />
          <PrivateRoute path={Routes.HELP} component={Help} />
          <AuthRoute path={Routes.REGULATION} component={Regulation} />
          <AuthRoute path={Routes.GET_STARTED} component={GetStarted} />
          <PrivateRoute
            path={Routes.QUESTIONNAIRE}
            component={Question}
            exact
          />
          <PrivateRoute path={Routes.FEEDBACK} component={Feedback} />
          <PrivateRoute path={Routes.RESULTS} component={Results} exact />
          <PrivateRoute path={Routes.VERIFY_EMAIL} component={VerifyEmail} />
          <AuthRoute
            path={Routes.PUBLIC_VERIFY_EMAIL}
            component={VerifyEmail}
          />
          <AuthRoute
            path={Routes.RESEND_EMAIL_CUSTOM}
            component={ResendCustom}
          />
          <AuthRoute
            path={`${Routes.ACTIVATE}/:uid?/:token?`}
            component={ActivateAccount}
          />
          <PrivateRoute path={Routes.START_HA} component={Start} exact />
          <PrivateRoute path={Routes.CHECKOUT} component={Checkout} exact />
          <Route>
            <NotFound />
          </Route>
        </IonRouterOutlet>
      </IonReactRouter>
      <ErrorPopup />
    </IonApp>
  );
};

export default App;
