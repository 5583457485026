import { GET_REGULATION_VERSIONS_REQUEST } from 'modules/Regulation/constants';
import { RegulationRequestActionTypes } from 'modules/Regulation/types';
import { ApiHost } from 'utils/axios';
import { ApiReturnFunc } from './index';

const regulationApi: Record<RegulationRequestActionTypes, ApiReturnFunc> = {
  [GET_REGULATION_VERSIONS_REQUEST]: () => ({
    url: `${ApiHost}/api/v1/versions`,
    method: 'get',
    headers: {},
  }),
};

export default regulationApi;
