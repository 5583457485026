import { ActionCreator } from 'redux';
import {
  CHECKOUT_REQUEST,
  GET_ORDER_REQUEST,
  GET_VIVA_WALLET_TOKEN_REQUEST,
  POST_PRODUCT_TO_ORDER_REQUEST,
  SET_CHECKOUT_PAGE_ID,
  SET_IS_CONTACT_DETAILS_UPDATED,
  SET_IS_SHIPPING_DETAILS_UPDATED,
  SET_ORDER_CONFIRM_REQUEST,
  RESET_VIVA_WALLET_TOKEN_ERROR,
} from '../constants';
import { CheckoutActionTypes, PostProductPayload } from '../types';

export const setCheckoutPageId: ActionCreator<CheckoutActionTypes> = ({
  pageId,
  directionBack = false,
}) => {
  return {
    type: SET_CHECKOUT_PAGE_ID,
    payload: { pageId, directionBack },
  };
};

export const getOrderRequest: ActionCreator<CheckoutActionTypes> = () => {
  return {
    type: GET_ORDER_REQUEST,
  };
};

export const postProductToOrderRequest: ActionCreator<CheckoutActionTypes> = ({
  orderId,
  productId,
  method = 'post',
}: PostProductPayload) => {
  return {
    type: POST_PRODUCT_TO_ORDER_REQUEST,
    payload: {
      orderId,
      productId,
      method,
    },
  };
};

export const deleteProductFromOrderRequest: ActionCreator<CheckoutActionTypes> = ({
  orderId,
  productId,
}: Omit<PostProductPayload, 'method'>) => {
  return postProductToOrderRequest({ orderId, productId, method: 'delete' });
};

export const setIsShippingDetailsUpdated: ActionCreator<CheckoutActionTypes> = () => {
  return {
    type: SET_IS_SHIPPING_DETAILS_UPDATED,
  };
};

export const setIsContactDetailsUpdated: ActionCreator<CheckoutActionTypes> = () => {
  return {
    type: SET_IS_CONTACT_DETAILS_UPDATED,
  };
};

export const getVivaWalletTokenRequest: ActionCreator<CheckoutActionTypes> = () => {
  return {
    type: GET_VIVA_WALLET_TOKEN_REQUEST,
  };
};

export const checkoutRequest: ActionCreator<CheckoutActionTypes> = ({
  accessToken,
  chargeToken,
  amount,
}) => {
  return {
    type: CHECKOUT_REQUEST,
    payload: {
      accessToken,
      chargeToken,
      amount,
    },
  };
};

export const setOrderConfirmRequest: ActionCreator<CheckoutActionTypes> = ({
  willAskClinician,
  willReferToLeaflet,
  willUseMedMyself,
  orderId,
}) => {
  return {
    type: SET_ORDER_CONFIRM_REQUEST,
    payload: {
      willAskClinician,
      willReferToLeaflet,
      willUseMedMyself,
      orderId,
    },
  };
};

export const resetTokenError: ActionCreator<{
  type: typeof RESET_VIVA_WALLET_TOKEN_ERROR;
}> = () => {
  return {
    type: RESET_VIVA_WALLET_TOKEN_ERROR,
  };
};
