import { MARKETING } from 'modules/Questionnaire/constants';
import { ActionCreator } from 'redux';
import {
  GET_ACCOUNT_DATA_REQUEST,
  UPDATE_ACCOUNT_DATA_REQUEST,
  GET_IS_ACTIVE_REQUEST,
  UPDATE_ADDRESS_REQUEST,
  GET_ADDRESS_REQUEST,
  UPDATE_MARKETING_PREFERENCE_REQUEST,
  SET_IS_ACTIVE,
} from '../constants';
import { AccountActionTypes } from '../types';

export const getAccountDataRequest: ActionCreator<AccountActionTypes> = () => ({
  type: GET_ACCOUNT_DATA_REQUEST,
});

export const updateAccountData: ActionCreator<AccountActionTypes> = (user) => ({
  type: UPDATE_ACCOUNT_DATA_REQUEST,
  payload: {
    ...user,
  },
});

export const UpdateMarketingPreference: ActionCreator<AccountActionTypes> = (
  marketingPreference: Set<MARKETING>,
) => ({
  type: UPDATE_MARKETING_PREFERENCE_REQUEST,
  payload: {
    marketingPreference,
  },
});

export const getIsActive: ActionCreator<AccountActionTypes> = () => ({
  type: GET_IS_ACTIVE_REQUEST,
});

export const updateAddressRequest: ActionCreator<AccountActionTypes> = (
  address,
) => ({
  type: UPDATE_ADDRESS_REQUEST,
  payload: {
    ...address,
  },
});

export const getAddressRequest: ActionCreator<AccountActionTypes> = () => ({
  type: GET_ADDRESS_REQUEST,
});

export const setIsActive: ActionCreator<AccountActionTypes> = (isActive) => ({
  type: SET_IS_ACTIVE,
  payload: {
    isActive,
  },
});
