import React from 'react';
import { IonButton } from '@ionic/react';
import { Text } from 'shared';

import styles from './style.module.css';

export type OptionProps = React.ComponentProps<typeof IonButton> & {
  text: string;
  subText?: string;
  circled?: boolean;
  active?: boolean;
};

const Option: React.FC<OptionProps> = ({
  text,
  // subText,
  active,
  ...props
}) => {
  return (
    <IonButton
      className={`${styles.option} ${active ? styles.active : ''}`}
      mode="md"
      fill="outline"
      shape="round"
      {...props}
    >
      <Text color="dark" fontSize={16} className={`${styles.option__text}`}>
        {text}
      </Text>
      {/* TODO: delete or implement depending on designs */}
      {/*<Text className={`${styles.option__subtext}`}>{subText}</Text>*/}
    </IonButton>
  );
};

export default Option;
