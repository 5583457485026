import React, { ReactNode } from 'react';
import {
  BODY1,
  GridContainer,
  GridItem,
  Container,
} from '@tuunetech/tuune-components';
import { Control, Controller } from 'react-hook-form';
import { Checkbox } from '@material-ui/core';
import { ContinueButton, ErrorBox } from 'shared';
import { PrivacyFormData } from 'modules/Questionnaire/types';
import { PRIVACY } from 'modules/Questionnaire/constants';
import { Option, StyledBody1 } from './styles';

interface PrivacyAnswerProps {
  options: { id: PRIVACY; label: string | ReactNode }[];
  handleSubmit: () => void;
  error: boolean;
  control: Control<PrivacyFormData>;
}

const PrivacyAnswer: React.FC<PrivacyAnswerProps> = ({
  options,
  handleSubmit,
  error,
  control,
}) => {
  return (
    <>
      <Container>
        <GridContainer justify="center">
          <GridItem md={8} lg={6}>
            <StyledBody1 paragraph align="left" color="textSecondary">
              <span>This is not just a box-ticking exercise. </span>
              When it comes to personal data, it’s vital to us that you know
              what data you are sharing with us and how it will be used.
            </StyledBody1>
            <StyledBody1 paragraph align="left" color="textSecondary">
              <span>Total transparency. </span> Female health has taken a
              backseat for too long, we are on a mission to improve it. To
              achieve this we may share your anonymized data with medical
              researchers. We hope you&#39;ll be happy to contribute to our
              mission.
            </StyledBody1>
            <StyledBody1 paragraph align="left" color="textSecondary">
              <span> Health and safety first. </span> We&#39;re committed to
              building a safe space for people with cycles. That&#39;s why we
              are upfront about who can safely use Tuune.
            </StyledBody1>
            <BODY1 align="left" color="textSecondary" gutterBottom>
              Please read the following statements carefully and click the links
              to find out more info
            </BODY1>
          </GridItem>
        </GridContainer>
        {error && <ErrorBox text="All fields are required to continue" />}
        <GridContainer alignContent="center" direction="column">
          {options.map(({ id, label }) => (
            <GridItem key={id} md={8} lg={6}>
              <Option
                startAdornment={
                  <Controller
                    name={id}
                    control={control}
                    render={({ field: { value, ...field } }) => (
                      <Checkbox
                        id={id}
                        color="primary"
                        checked={value}
                        {...field}
                      />
                    )}
                  />
                }
                content={
                  <label htmlFor={id}>
                    {typeof label === 'string' ? (
                      <BODY1 align="left" color="textSecondary">
                        {label}
                      </BODY1>
                    ) : (
                      label
                    )}
                  </label>
                }
              />
            </GridItem>
          ))}
        </GridContainer>
        <ContinueButton onClick={handleSubmit} />
      </Container>
    </>
  );
};

export default PrivacyAnswer;
