import React from 'react';
import { IonIcon } from '@ionic/react';
import { alertCircleOutline } from 'ionicons/icons';
import { Text } from 'shared';

import styles from './style.module.css';

const ErrorBox: React.FC<{ text: string; className?: string }> = ({
  text,
  className = '',
}) => {
  return (
    <div className={`${styles.errorBoxContainer} ${className}`}>
      <div className={styles.errorBox}>
        <IonIcon
          className={styles.errorIcon}
          icon={alertCircleOutline}
          color="danger"
        />
        <Text fontSize={14} fontWeight="bold" color="danger">
          {text}
        </Text>
      </div>
    </div>
  );
};

export default ErrorBox;
